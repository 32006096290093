import { Component, OnInit, OnDestroy } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { IUser } from "src/app/@interfaces/user.interface";
import { UsersService } from "../users/users.service";
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from "@angular/forms";
import Swal from "sweetalert2";
import { IRol } from "src/app/@interfaces/rol.interface";
import { ICompany } from "src/app/@interfaces/company.interface";
import { NgxSpinnerService } from "ngx-spinner";
import { TranslateService } from "@ngx-translate/core";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { AuthService } from "src/app/@shared/authentication/auth.service";

@Component({
  selector: "app-password",
  templateUrl: "./password.component.html",
  styleUrls: ["./password.component.css"],
})
export class PasswordComponent implements OnInit, OnDestroy {
  profileForm: UntypedFormGroup = this.fb.group({
    name: this.route.snapshot.paramMap.get("id")?.slice(3),
  });

  passForm: UntypedFormGroup = this.fb.group({
    password: ["", Validators.required],
    confirm: ["", Validators.required],
  });

  UserList = {} as IUser;
  companyList: Array<ICompany> = [];
  rolList: Array<IRol> = [];
  unsubscribe$ = new Subject();
  profId: number = parseInt(this.profileForm.value.name);

  constructor(
    private routeR: Router,
    private fb: UntypedFormBuilder,
    private route: ActivatedRoute,
    private user: UsersService,
    private spinnerService: NgxSpinnerService,
    private translate: TranslateService,
    private authService: AuthService
  ) {}

  async ngOnInit(): Promise<void> {
  }

  updatePassword() {
    this.passForm.markAllAsTouched();
    if (this.passForm.invalid === true) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: this.translate.instant(
          "Please, fill in all the required fields!"
        ),
      });
      return;
    }
    if (this.passForm.value.password === this.passForm.value.confirm) {
      this.user
      .updatePass(this.profId, this.passForm.value.password)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((result) => {
        if (result) {
          Swal.fire(
            this.translate.instant("Good"),
            this.translate.instant("Updated successfully"),
            "success"
          ).then(() => {
            this.routeR
              .navigateByUrl("/profile", { skipLocationChange: true })
              .then(() => {
                this.routeR.navigate(["/login"]);
              });
          });
        } else {
          Swal.fire(
            "",
            this.translate.instant("something was wrong"),
            "error"
          );
        }
      });
    } else {
      Swal.fire(
        "Error",
        this.translate.instant("The passwords do not match"),
        "error"
      );
    }
  }

  spinner(): void {
    this.spinnerService.show();
    setTimeout(() => {
      this.spinnerService.hide();
    }, 3000);
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}

import { Injectable } from "@angular/core";
import { FilterFullDateQuots } from "src/app/@shared/pipe/graphs-pipes/quotations/full-date-quotation.pipe";
import { FilterFullQuots } from "src/app/@shared/pipe/graphs-pipes/quotations/full-quotation.pipe";
import { IQuota } from "../../../@interfaces/quotation.interface";
import { FilterDIQuots } from "../../pipe/graphs-pipes/quotations/date-item.pipe";
import { FilterIQuots } from "../../pipe/graphs-pipes/quotations/item.pipe";

export interface IResultFilter {
  quotArray: IQuota[];
}

@Injectable({
  providedIn: "root",
})
export class FilterBySearchService {
  constructor(
    private filterFullQuots: FilterFullQuots,
    private filterFullDateQuots: FilterFullDateQuots,
    private filterDIQuots: FilterDIQuots,
    private filterIQuots: FilterIQuots
  ) {}

  filterByCustomer(quotaList: IQuota[], value: string) {
    const filterQuots = this.filterFullQuots.transform(quotaList, value);
    return { quotArray: filterQuots };
  }

  filterBySeller(quotaList: IQuota[], value: string) {
    const filterQuots = this.filterFullQuots.transform(quotaList, value);
    return { quotArray: filterQuots };
  }

  filterByItem(quotaList: IQuota[], value: string) {
    const filterQuots = this.filterIQuots.transform(quotaList, value);
    return { quotArray: filterQuots };
  }

  filterWithDate(quotaList: IQuota[], date1: Date, date2: Date, customer: string, item: string, seller: string) {
    let obj = {} as IResultFilter;
    if (date1 && date2 && customer !== "") {
      obj = this.filterCustomerWithDate(quotaList, date1, date2, customer);
    }
    if (date1 && date2 && item !== "") {
      obj = this.filterItemWithDate(quotaList, date1, date2, item);
    }
    if (date1 && date2 && seller !== "") {
      obj = this.filterSellerWithDate(quotaList, date1, date2, seller);
    }
    return { quotArray: obj.quotArray };
  }

  filterCustomerWithDate(quotaList: IQuota[], date1: Date, date2: Date, customer: string) {
    const filterQuots = this.filterFullDateQuots.transform(quotaList, customer, date1, date2);
    return { quotArray: filterQuots };
  }

  filterItemWithDate(quotaList: IQuota[], date1: Date, date2: Date, item: string) {
    const filterQuots = this.filterDIQuots.transform(quotaList, date1, date2, item);
    return { quotArray: filterQuots };
  }

  filterSellerWithDate(quotaList: IQuota[], date1: Date, date2: Date, seller: string) {
    const filterQuots = this.filterFullDateQuots.transform(quotaList, seller, date1, date2);
    return { quotArray: filterQuots };
  }
}

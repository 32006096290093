import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { MaterialModule } from "src/app/material.module";
import { RouterModule } from "@angular/router";
import { ReactiveFormsModule } from "@angular/forms";
import { NgxSpinnerModule } from "ngx-spinner";
import { FormsModule } from "@angular/forms";
import { MatIconModule } from "@angular/material/icon";

import { DialogComponent } from "./dialog.component";
import { RegisterComponent } from "./crud-quotation/register/register.component";
import { ModifyComponent } from "./crud-quotation/modify/modify.component";
import { RegisterUserComponent } from "./authentication/crud-user/register-user/register-user.component";
import { RegisterCustomerComponent } from "./crud-customer/register-customer/register-customer.component";
import { ModifyCustomerComponent } from "./crud-customer/modify-customer/modify-customer.component";
import { ModifyUserComponent } from "./authentication/crud-user/modify-user/modify-user.component";
import { RegisterArticleComponent } from "./crud-article/register-article/register-article.component";
import { ModifyArticleComponent } from "./crud-article/modify-article/modify-article.component";
import { DeliveryComponent } from "./crud-quotation/delivery/delivery.component";
import { FilterComponent } from "./crud-quotation/modify/filter/filter.component";
import { DebitNoteComponent } from "./fact-notes/debito/debit-note/debit-note.component";
import { CreditNoteComponent } from "./fact-notes/credito/credit-note/credit-note.component";
import { VerDebitoComponent } from "./fact-notes/debito/ver-debito/ver-debito.component";
import { VerCreditoComponent } from "./fact-notes/credito/ver-credito/ver-credito.component";
import { AddCompany } from "./authentication/crud-user/modify-user/add-company/add-company.component";
import { GetAdressComponent } from "./crud-customer/get-adress/get-adress.component";
import { DxMapModule } from "devextreme-angular";
import { TranslateModule } from "@ngx-translate/core";
import { RegisterSellerComponent } from "./crud-seller/register-seller/register-seller.component";
import { ModifySellerComponent } from "./crud-seller/modify-seller/modify-seller.component";
import { CrudMailsComponent } from "./crud-lists/crud-mails/crud-mails.component";
import { CrudBanksComponent } from "./crud-lists/crud-banks/crud-banks.component";
import { CrudCashiersComponent } from "./crud-lists/crud-cashiers/crud-cashiers.component";
import { PaymentsComponent } from "./crud-quotation/payments/payments.component";
import { ExportExcelComponent } from "./export-excel/export-excel.component";
import { ParsePipe } from "../../pipe/parse-string.pipe";
import { FixedPipe } from "../../pipe/fixed-number.pipe";
import { ReducePipe } from "../../pipe/reduce-array.pipe";
import { SelectCurrencyComponent } from "./crud-quotation/select-currency/select-currency.component";
import { DetailsArticleComponent } from "./crud-article/details-article/details-article.component";
import { CrudGroupsComponent } from "./crud-lists/crud-groups/crud-groups.component";
import { CrudPresentationsComponent } from "./crud-lists/crud-presentations/crud-presentations.component";
import { CrudWarehouseComponent } from "./crud-lists/crud-warehouse/crud-warehouse.component";
import { ExportSiigoComponent } from "./export-siigo/export-siigo.component";
import { FilterSDSales } from "src/app/@shared/pipe/graphs-pipes/sales/seller-date.pipe";
import { FilterSSales } from "src/app/@shared/pipe/graphs-pipes/sales/seller.pipe";
import { FilterDSales } from "src/app/@shared/pipe/graphs-pipes/sales/date.pipe";
import { DetailPaymentComponent } from "./crud-quotation/detail-payment/detail-payment.component";
import { SortModule } from "src/app/directives/sortDirective/sort.module";
import { StepperModule } from "../stepper/stepper.module";
import { FilterDQuots } from "../../pipe/graphs-pipes/quotations/date.pipe";
import { FilterDIQuots } from "../../pipe/graphs-pipes/quotations/date-item.pipe";
import { CalculateSiigoService } from "../services/calculate-siigo.service";
import { ExportSiigoService } from "./export-siigo/export-siigo.service";
import { CrudMailsHomeComponent } from "./crud-lists/crud-mails-home/crud-mails-home.component";
import { ModifyProviderComponent } from "./crud-provider/modify-provider/modify-provider.component";
import { RegisterProviderComponent } from "./crud-provider/register-provider/register-provider.component";
import { ModifyPurchaseComponent } from "./crud-purchase/modify-purchase/modify-purchase.component";
import { RegisterPurchaseComponent } from "./crud-purchase/register-purchase/register-purchase.component";
import {
  DxDropDownBoxModule,
  DxTreeViewModule,
  DxDataGridModule,
} from "devextreme-angular";
import { RegistrationModeComponent } from "./crud-purchase/registration-mode/registration-mode.component";
import { ModifyActivitiesComponent } from "./crud-activities/modify-activities/modify-activities.component";
import { RegisterActivitiesComponent } from "./crud-activities/register-activities/register-activities.component";
import { CustomersBySellerComponent } from "./export-excel/customers-by-seller/customers-by-seller.component";
import { PayPurchaseComponent } from "./crud-purchase/pay-purchase/pay-purchase.component";
import { FilterDPurchase } from "../../pipe/purchase-pipes/date.pipe";
import { CalculatePurchasesService } from "../services/calculate-purchases.service";
import { ValidPendingInvoicePipe } from "../../pipe/valid-pending-invoices.pipe";
import { CrudAccountingAccountsComponent } from "./crud-lists/crud-accounting-accounts/crud-accounting-accounts.component";
import { ModifyAccountingAccountsComponent } from './crud-lists/crud-accounting-accounts/modify-accounting-accounts/modify-accounting-accounts.component';
import { CrudCustomerSegmentComponent } from './crud-lists/crud-customer-segment/crud-customer-segment.component';
import { RefundsComponent } from './refunds/refunds.component';
import { RefundsListComponent } from './refunds/refunds-list/refunds-list.component';
import { RegisterTreasuryComponent } from './crud-treasury/register-treasury/register-treasury.component';
import { TransfersComponent } from './crud-transfers/transfers.component';
import { CrudWarehouseTransferComponent } from './crud-warehouse-transfer/crud-warehouse-transfer.component';
import { CrudWarehouseOutputAndEntryComponent } from './crud-warehouse-output-and-entry/crud-warehouse-output-and-entry.component';
import { CrudInventoryReclassificationComponent } from './crud-inventory-reclassification/crud-inventory-reclassification.component';
import { WarehouseOpeningsComponent } from './crud-article/warehouse-openings/warehouse-openings.component';
import { UpdateWarehouseOpeningsComponent } from './crud-article/warehouse-openings/update-warehouse-openings/update-warehouse-openings.component'; 
import { FlexLayoutModule } from '@angular/flex-layout';
import { ForgotPasswordComponent } from "./authentication/forgot-password/forgot-password.component";

@NgModule({
  declarations: [
    DialogComponent,
    RegisterComponent,
    ModifyComponent,
    RegisterUserComponent,
    RegisterCustomerComponent,
    ModifyCustomerComponent,
    ModifyUserComponent,
    RegisterArticleComponent,
    ModifyArticleComponent,
    DeliveryComponent,
    FilterComponent,
    DebitNoteComponent,
    CreditNoteComponent,
    VerDebitoComponent,
    VerCreditoComponent,
    AddCompany,
    GetAdressComponent,
    RegisterSellerComponent,
    ModifySellerComponent,
    CrudMailsComponent,
    CrudBanksComponent,
    CrudCashiersComponent,
    PaymentsComponent,
    ExportExcelComponent,
    ParsePipe,
    FixedPipe,
    ReducePipe,
    SelectCurrencyComponent,
    DetailsArticleComponent,
    CrudGroupsComponent,
    CrudPresentationsComponent,
    CrudWarehouseComponent,
    ExportSiigoComponent,
    DetailPaymentComponent,
    CrudMailsHomeComponent,
    ModifyProviderComponent,
    RegisterProviderComponent,
    ModifyPurchaseComponent,
    RegisterPurchaseComponent,
    RegistrationModeComponent,
    ModifyActivitiesComponent,
    RegisterActivitiesComponent,
    CustomersBySellerComponent,
    PayPurchaseComponent,
    CrudAccountingAccountsComponent,
    ModifyAccountingAccountsComponent,
    CrudCustomerSegmentComponent,
    RefundsComponent,
    RefundsListComponent,
    RegisterTreasuryComponent,
    TransfersComponent,
    CrudWarehouseTransferComponent,
    CrudWarehouseOutputAndEntryComponent,
    CrudInventoryReclassificationComponent,
    WarehouseOpeningsComponent,
    UpdateWarehouseOpeningsComponent,
  ],

  imports: [
    CommonModule,
    MaterialModule,
    RouterModule,
    ReactiveFormsModule,
    NgxSpinnerModule,
    FormsModule,
    MatIconModule,
    DxMapModule,
    TranslateModule,
    SortModule,
    StepperModule,
    DxTreeViewModule,
    DxDropDownBoxModule,
    DxDataGridModule,
    FlexLayoutModule,
    ForgotPasswordComponent
  ],
  exports: [DialogComponent],
  providers: [
    ExportSiigoService,
    CalculateSiigoService,
    CalculatePurchasesService,
    FilterSDSales,
    FilterSSales,
    FilterDSales,
    FilterDQuots,
    FilterDIQuots,
    FilterDPurchase,
    ValidPendingInvoicePipe
  ],
})
export class DialogModule {}

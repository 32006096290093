import { Injectable } from "@angular/core";
import { ISiigoRow } from "src/app/@interfaces/Siigo/rows.interface";
import { IAccountingAccounts } from "src/app/@interfaces/accountingAccounts.interface";
import { IMovementInventory } from "src/app/@interfaces/movement-inventory.interface";
import {
  IWarehouseOutputAndEntry,
  IWarehouseTransfer,
} from "src/app/@interfaces/warehouse.interface";
import { SetRowsService } from "./set-rows.service";
import { FunctionsSiigoService } from "./functions-siigo.service";
import { TransactionType } from "src/app/@enums/siigo/siigo-invoices";
import { IInventoryReclassification } from "src/app/@interfaces/inventoryReclassification.interface";
import { VoucherTypes } from "src/app/@enums/siigo/siigo-voucher";
import { IDateFormat } from "src/app/@interfaces/Siigo/format-date-siigo.interface";

@Injectable({
  providedIn: "root",
})
export class WarehouseCsvService {
  constructor(
    private setRowsService: SetRowsService,
    private functionsSiigoService: FunctionsSiigoService
  ) {}

  setWarehouseTransfers(
    transfers: IWarehouseTransfer[],
    dateToday: string,
    inventoryMovements: IMovementInventory[],
    firstCostAccountingAccounts: IAccountingAccounts
  ) {
    let collectionsArray: ISiigoRow[] = [];
    transfers.forEach((element) => {
      const formatCreatedDate = this.functionsSiigoService.getDateSeparated(
        element.date
      );
      const lastCost = this.functionsSiigoService.getLastCost(
        inventoryMovements, element.id_transfer!,
        element.article.codigo!, VoucherTypes.warehouseTransfer
      );
      const rows = this.setWarehouseTransferRows(
        element, firstCostAccountingAccounts, 
        lastCost, formatCreatedDate, dateToday
      ) 
      collectionsArray.push(rows.row1);
      collectionsArray.push(rows.row2);
    });
    return collectionsArray;
  }

  setWarehouseTransferRows(
    element: IWarehouseTransfer,
    firstCostAccountingAccounts: IAccountingAccounts,
    lastCost: number, formatCreatedDate: IDateFormat,
    dateToday: string
  ) {
    const row1 = this.setRowsService.createWarehouseTransfersRow(
      element, firstCostAccountingAccounts, TransactionType.Credit,
      lastCost, formatCreatedDate, dateToday
    );
    const row2 = { 
      ...row1, debitCredit: TransactionType.Debit, 
      warehouseCode: element.transferred.id_almacen.toString() 
    }
    return { row1: row1, row2: row2 }
  }

  setInventoryOutputsAndEntries(
    outputsAndEntries: IWarehouseOutputAndEntry[],
    dateToday: string,
    inventoryMovements: IMovementInventory[],
    firstCostAccountingAccounts: IAccountingAccounts,
    secondCostAccountingAccounts: IAccountingAccounts
  ) {
    let collectionsArray: ISiigoRow[] = [];
    outputsAndEntries.forEach((element) => {
      const formatCreatedDate = this.functionsSiigoService.getDateSeparated(element.date);
      const lastCost = this.functionsSiigoService.getLastCost(
        inventoryMovements, element.id_movement!,
        element.article.codigo!, VoucherTypes.warehouseOutput
      );
      const rows = this.setInventoryRows(
        element, formatCreatedDate, lastCost, dateToday,
        firstCostAccountingAccounts, secondCostAccountingAccounts
      )
      collectionsArray.push(rows.row1);
      collectionsArray.push(rows.row2);
    });
    return collectionsArray;
  }

  setInventoryRows(
    element: IWarehouseOutputAndEntry,
    formatCreatedDate: IDateFormat,
    lastCost: number, dateToday: string,
    firstCostAccountingAccounts: IAccountingAccounts,
    secondCostAccountingAccounts: IAccountingAccounts
  ) {
    const row1 = this.setRowsService.createWarehouseOutputRow(
      element,
      element.type === 1 ? TransactionType.Credit : TransactionType.Debit,
      formatCreatedDate, lastCost, dateToday,
      firstCostAccountingAccounts.total
    );
    const row2 = {
      ...row1,
      debitCredit: element.type === 1 ? TransactionType.Debit : TransactionType.Credit,
      accountingAccount: secondCostAccountingAccounts.total
    };
    return { row1: row1, row2: row2 }
  }

  setInventoryReclassifications(
    reclassifications: IInventoryReclassification[],
    dateToday: string,
    inventoryMovements: IMovementInventory[],
    firstCostAccountingAccounts: IAccountingAccounts
  ) {
    let collectionsArray: ISiigoRow[] = [];
    reclassifications.forEach((element) => {
      const formatCreatedDate = this.functionsSiigoService.getDateSeparated(element.date);
      const firstLastCost = this.functionsSiigoService.getLastCost(
        inventoryMovements, element.id_reclassification!,
        element.exitArticle.codigo!, VoucherTypes.inventoryReclassification
      );
      const secondLastCost = this.functionsSiigoService.getLastCost(
        inventoryMovements, element.id_reclassification!,
        element.entryArticle.codigo!, VoucherTypes.inventoryReclassification
      );
      const rows = this.createReclassificationRows(
        element, formatCreatedDate, firstLastCost,
        secondLastCost, dateToday, firstCostAccountingAccounts
      ) 
      collectionsArray.push(rows.row1);
      collectionsArray.push(rows.row2);
    });
    return collectionsArray;
  }

  createReclassificationRows(
    element: IInventoryReclassification,
    formatCreatedDate: IDateFormat,
    firstLastCost: number, 
    secondLastCost: number,
    dateToday: string,
    firstCostAccountingAccounts: IAccountingAccounts
  ) {
    const row1 = this.setRowsService.createReclassificationRow(
      element,
      TransactionType.Credit,
      formatCreatedDate, firstLastCost,
      "exitArticle", "exitWarehouse",
      dateToday,
      firstCostAccountingAccounts.total
    );
    const row2 = this.setRowsService.createReclassificationRow(
      element,
      TransactionType.Debit,
      formatCreatedDate, secondLastCost,
      "entryArticle", "entryWarehouse",
      dateToday,
      firstCostAccountingAccounts.total
    );
    return { row1: row1, row2: row2 }
  }
}

import { gql } from "apollo-angular";

export const WAREHOUSE_ARTICLE = gql`
  fragment WarehouseArticleObject on WarehouseArticle {
    id_warehouse_article
    id_article
    id_warehouse
    value
    quantity
    avaliable_quantity
    date
  }
`;

export const WAREHOUSE_OUTPUTS_ENTRIES_OBJECT_COUNT = gql`
  fragment WarehouseOutputsAndEntriesObject on WarehouseOutputEntriesCount {
    warehouseOutputEntries {
      id_movement
      type
      article {
        id_articulo
        codigo
        descripcion
        grupo
        unidad
        id_siigo
      }
      warehouse {
        id_almacen
        nombre
      }
      date
      amount
      cost
      description
      user {
        id
        name
      }
    }
    count
  }
`;

export const WAREHOUSE_OUTPUTS_ENTRIES_OBJECT = gql`
  fragment WarehouseOutputsAndEntriesObject on WarehouseOutputsAndEntries {
      id_movement
      type
      article {
        id_articulo
        codigo
        descripcion
        grupo
        unidad
        id_siigo
      }
      warehouse {
        id_almacen
        nombre
      }
      date
      amount
      cost
      description
      user {
        id
        name
      }
  }
`;

export const INVENTORY_RECLASSIFICATION_OBJECT_COUNT = gql`
  fragment InventoryReclassificationObject on InventoryReclassificationCount {
    count
    inventoryReclassification {
      id_reclassification
      exitArticle {
      id_articulo
      codigo
      descripcion
      grupo
      unidad
      id_siigo
    }
    entryArticle {
      id_articulo
      codigo
      descripcion
      grupo
      unidad
      id_siigo
    }
    exitWarehouse{
      id_almacen
      nombre
    }
    entryWarehouse{
      id_almacen
      nombre
    }
    date
    value
    user {
      id
      name
    }
    }
  }
`;

export const INVENTORY_RECLASSIFICATION_OBJECT = gql`
  fragment InventoryReclassificationObject on InventoryReclassification {
      id_reclassification
      exitArticle {
      id_articulo
      codigo
      descripcion
      grupo
      unidad
      id_siigo
    }
    entryArticle {
      id_articulo
      codigo
      descripcion
      grupo
      unidad
      id_siigo
    }
    exitWarehouse{
      id_almacen
      nombre
    }
    entryWarehouse{
      id_almacen
      nombre
    }
    date
    value
    user {
      id
      name
    }
  }
`;

export const WAREHOUSE_TRANSFER_OBJECT_COUNT = gql`
  fragment WarehouseTransferObject on WarehouseTransferCount {
    count
    transfers {
      id_transfer
      article {
        id_articulo
        codigo
        descripcion
        grupo
        unidad
        id_siigo
      }
      original {
        id_almacen
        nombre
      }
      transferred {
        id_almacen
        nombre
      }
      date
      value
      user {
        id
        name
      }
    }
  }
`

export const WAREHOUSE_TRANSFER_OBJECT = gql`
  fragment WarehouseTransferObject on WarehouseTransfer {
      id_transfer
      article {
        id_articulo
        codigo
        descripcion
        grupo
        unidad
        id_siigo
      }
      original {
        id_almacen
        nombre
      }
      transferred {
        id_almacen
        nombre
      }
      date
      value
      user {
        id
        name
      }
  }
`

export const TRANSFER_OBJECT = gql`
  fragment TransferObject on Transfer {
    id_transfer
    type
    date
    custom_value
    invoice_payment {
      identifier
      type
      date
      value
      factura {
        contfac {
          invoice
        }
        billyInvoice {
          invoice_number
        }
      }
    }
    purchase_payment{
      identifier
      type
      date 
      value
      purchaseInvoice {
        contpurchase{
          consecutive_invoice 
          written_invoice
        }
      }
    }
    original {
      id_payment
      type
      description
      accounting_account {
        id_account
        document_type
        total
      }
    }
    transferred {
      id_payment
      type
      description
      accounting_account {
        id_account
        document_type
        total
      }
    }
    id_correlative
  }
`

export const TRANSFER_OBJECT_COUNT = gql`
  fragment TransferObjectCount on TransferCount {
    count
    transfers {
      id_transfer
      type
      date
      custom_value
      invoice_payment {
        identifier
        type
        date
        value
        factura {
          contfac {
            invoice
          }
          billyInvoice {
            invoice_number
          }
        }
      }
      purchase_payment{
        identifier
        type
        date 
        value
        purchaseInvoice {
          contpurchase{
            consecutive_invoice 
            written_invoice
          }
        }
      }
      original {
        id_payment
        type
        description
        accounting_account {
          id_account
          document_type
          total
        }
      }
      transferred {
        id_payment
        type
        description
        accounting_account {
          id_account
          document_type
          total
        }
      }
      id_correlative
    }
  }
`

export const TREASURY_OBJECT_COUNT = gql`
  fragment TreasuryObjectCount on TreasuryCount {
    count
    treasury {
      id_treasury
      paymentType {
        id_payment
        type
        id_user
        description
        accounting_account {
          id_account
          document_type
          total
        }
      }
      customer {
        id_cliente
        doc_nit
        nombre
      }
      provider {
        id_provider
        doc_nit
        nombre
      }
      identifier
      type
      date
      value
      value_used
      id_ref
      id_correlative
    }
  }
`

export const TREASURY_OBJECT = gql`
  fragment TreasuryObject on Treasury {
      id_treasury
      paymentType {
        id_payment
        type
        id_user
        description
        accounting_account {
          id_account
          document_type
          total
        }
      }
      customer {
        id_cliente
        doc_nit
        nombre
      }
      provider {
        id_provider
        doc_nit
        nombre
      }
      identifier
      type
      date
      value
      value_used
      id_ref
      id_correlative
  }
`

export const VALID_REFUND = gql`
  fragment ValidRefundObject on Refund {
    id_refund
    num_invo
    refund_type
  }
`;

export const REFUND_OBJECT = gql`
  fragment RefundObject on Refund {
    id_refund
    num_invo
    refund_type
    concept
    total
    withholdingTax
    icaTax
    createAt
    dueDate
    refunds_values {
      id_value
      id_refund
      quantity
      price
      subtotal
      discount
      articulo{
        id_articulo
        codigo
        descripcion
        presentacion
        costo
        cantidad
        grupo
        unidad
        id_siigo
        almacen {
          id_almacen
          nombre
          estado
        }
        tax {
          id_tax
          type
          value
          id_second
        }
        type {
          id_type
          description
          id_second
          accounting_account {
            id_account
            document_type
            withholding
          }
        }
      }
      warehouse {
        id_almacen
        nombre
        estado
      }
    }
  }
`;

export const CUSTOMER_TYPE_OBJECT = gql`
  fragment CustomerTypeObject on CustomerType {
    id_customerType
    type
  }
`;

export const CONTRIBUTOR_TYPE_OBJECT = gql`
  fragment ContributorTypeObject on ContributorType {
    id_contributor
    description
  }
`;

export const CUSTOMER_SEGMENT_OBJECT = gql`
  fragment CustomerSegmentObject on CustomerSegment {
    id_segment
    nombre
  }
`;

export const ACCOUNTING_ACCOUNTS_OBJECT = gql`
  fragment AccountingAccountsObject on AccountingAccounts {
    id_account
    document_type
    inventory
    service
    tax
    withholding
    ica
    total
    date
  }
`

export const USER_SELLER_OBJECT = gql`
  fragment UserSellerObject on UserSeller {
    id_userSeller
    id_user
    id_seller
    seller{
      id_seller
      nombre
    }
  }
`;

export const COMPANY_TYPE_OBJECT = gql`
  fragment CompanyTypeObject on CompanyType {
    id_type
    name
    taxable_ica
  }
`;

export const CITIES_OBJECT = gql`
  fragment CitiesObject on Cities {
    id_city
    id_country
    countries {
      id_country
      name
      iso
      phone_code
    }
    name
    ica_single_rate
  }
`;

export const ACTIVITIES_CIIU_OBJECT = gql`
  fragment ActivitiesCiiuObject on ActivitiesCiiu {
    id_activity
    id_city
    cities {
      id_city
      id_country
      name
      ica_single_rate
    }
    ica_code
    activity_code
    activity_description
    actual_rate
  }
`;

export const TRIBUTARY_VALUES_OBJECT = gql`
  fragment TributaryValuesObject on TributaryValues {
    id_value
    id_city
    cities {
      id_city
      id_country
      name
      ica_single_rate
    }
    name
    type
    unit
    value
  }
`;

export const DECLARANT_TYPES_OBJECT = gql`
  fragment DeclarantTypesObject on DeclarantTypes {
    id_declarant
    id_regime
    regime_types {
      id_regime
      name
    }
    declarant_rate {
      id
      id_declarant
      id_type
      value
      uvt
    }
    name
    type
    value
  }
`;

export const REGIME_TYPES_OBJECT = gql`
  fragment RegimeTypesObject on RegimeTypes {
    id_regime
    name
  }
`;

export const CONTPURCHASE_OBJECT = gql`
  fragment ContPurchaseObject on ContPurchase {
    id_cont_purch
    consecutive_invoice
    written_invoice
  }
`;

export const PURCHASE_INVOICE_OBJECT = gql`
  fragment PurchaseInvoiceObject on PurchaseInvoice {
    id_invoice
    num_invo
    state
    purchases {
      id_purchases
      num_invo
      warehouse {
        id_almacen
        nombre
        estado
      }
      codigo
      quantity
      price
      discount
      subtotal
      total
      articulo {
        id_articulo
        codigo
        descripcion
        presentacion
        costo
        cantidad
        grupo
        unidad
        id_siigo
        almacen {
          id_almacen
          nombre
          estado
        }
        tax {
          id_tax
          type
          value
          id_second
        }
        type {
          id_type
          description
          id_second
          accounting_account {
            id_account
            document_type
            withholding
          }
        }
      }
    }
    tax
    withholdingTax
    icaTax
    customerCity
    providerCity
    id_provider
    provider {
      id_provider
      doc_nit
      nombre
      direccion
      telefono
      email
      city
      declarant_types {
        id_declarant
        name
        type
        value
        declarant_rate {
          id
          id_declarant
          id_type
          value
          uvt
        }
      }
      contributorType {
        id_contributor
        description
      }
      activity_code
    }
    contpurchase {
      id_cont_purch
      consecutive_invoice
      written_invoice
    }
    createdAt
    dueDate
    state_invoice
    tax_incl
    clause
    payment {
      id_payment
      type
      id_user
      description
    }
  }
`;

export const CREATE_PURCHASE_INVOICE_OBJECT = gql`
  fragment CreatePurchaseInvoiceObject on PurchaseInvoice {
    id_invoice
    num_invo
    purchases {
      id_purchases
      quantity
      price
      discount
      subtotal
      total
    }
    tax
    createdAt
    dueDate
  }
`;

export const REQ_PROVIDER_OBJECT_COUNT = gql`
  fragment RequestProviderObjCount on ProvidersCount {
    count
    providers {
      id_provider
      doc_nit
      types_docs {
        id_docs
        description
        id_second
        id_siigo
      }
      nombre
      direccion
      telefono
      email
      country {
        id_country
        name
        iso
        phone_code
      }
      city
      contributorType {
        id_contributor
        description
      }
      id_regime
      regime_types {
        id_regime
        name
      }
      id_declarant
      declarant_types {
        id_declarant
        name
        type
        value
        declarant_rate {
          id
          id_declarant
          id_type
          value
          uvt
        }
      }
      id_type
      company_type {
        id_type
        name
        taxable_ica
      }
      activity_code
      date
    }
  }
`;

export const REQ_PROVIDER_OBJECT = gql`
  fragment RequestProviderObj on RequestProvider {
      id_provider
      doc_nit
      types_docs {
        id_docs
        description
        id_second
        id_siigo
      }
      nombre
      direccion
      telefono
      email
      country {
        id_country
        name
        iso
        phone_code
      }
      city
      contributorType {
        id_contributor
        description
      }
      id_regime
      regime_types {
        id_regime
        name
      }
      id_declarant
      declarant_types {
        id_declarant
        name
        type
        value
        declarant_rate {
          id
          id_declarant
          id_type
          value
          uvt
        }
      }
      id_type
      company_type {
        id_type
        name
        taxable_ica
      }
      activity_code
      date
  }
`;

export const GRUPOART_OBJECT = gql`
  fragment GrupoArtObject on GrupoArt {
    id_grupo
    nombre
    presentations {
      id_type
      value
    }
  }
`;

export const PAYMENT_INVOICE_OBJECT = gql`
  fragment PayInvoiceObject on PayInvoice {
    id_payinvoice
    id_factura
    identifier
    type
    date
    value
    status
    p_vendor_date
    cancelled_date
    paymentDetails{
      type_payment
      id_ref
    }
    paymentType{
      id_payment
      type
      id_user
      description
      accounting_account {
        id_account
        document_type
        total
      }
    }
    factura{
      id_factura
      num_fac
      dueDate
      tax_incl
      tax
      cliente {
        id_cliente
        doc_nit
        nombre
        direccion
      }
      seller {
        id_seller
        doc_nit
        nombre
      }
      billyInvoice {
        id_primary
        id_fact
        id_billy
        invoice_number
        voucher
      }
      contfac {
        invoice
      }
      venta {
        cantidad
        precio
        descuento
        total
        articulo {
          grupoArt {
            id_grupo
            nombre
          }
          tax {
            value
          }
        }
      }
    }
    treasury {
      id_treasury
      date
      id_correlative
    }
    payment_description
    id_correlative
  }
`;

export const PAYMENT_PURCHASES_OBJECT = gql`
fragment PayPurchasesObject on PayPurchases{
  id_paypurchases
  id_invoice
  identifier
  type
  date
  value
  status
  p_vendor_date
  cancelled_date
  paymentDetails{
    type_payment
    id_ref
  }
  paymentType{
    id_payment
    type
    id_user
    description
    accounting_account {
      id_account
      document_type
      inventory
      service
      tax
      withholding
      ica
      total
    }
  }
  purchaseInvoice{
    id_invoice
    num_invo
    provider {
      id_provider
      doc_nit
      nombre
      direccion
      telefono
      email
      city
    }
    contpurchase{
      id_cont_purch
      consecutive_invoice 
      written_invoice
    }
    createdAt
    dueDate
    state_invoice
    tax_incl
  }
  treasury {
    id_treasury
    date
    paymentType {
      id_payment
      type
    }
    id_correlative
  }
  id_correlative
}
`;

export const PURCHASESBYSTATUS_OBJECT = gql`
fragment PurchasesByStatusObject on PurchaseInvoice {
  id_invoice
  num_invo
  state
  provider {
    id_provider
    doc_nit
    nombre
    direccion
    telefono
    email
    city
  }
  purchases{
    id_purchases
    num_invo
    warehouse {
      id_almacen
      nombre
      estado
    }
    codigo
    quantity
    price
    discount
    subtotal
    total
  }
  contpurchase{
    id_cont_purch
    consecutive_invoice 
    written_invoice
  }
  createdAt
  dueDate
  state_invoice
  tax_incl
  clause
}
`;

export const PAYMENTS_OBJECT = gql`
  fragment PaymentsObject on Payment {
    id_payment
    type
    description
    id_user
    date
  }
`;

export const MAILLIST_OBJECT = gql`
  fragment MailListObject on MailList {
    id_mails
    group_mails
    mail
    date
  }
`;

export const SELLER_OBJECT = gql`
  fragment SellerObject on Seller {
    id_seller
    doc_nit
    types_docs {
      id_docs
      description
      id_second
    }
    nombre
    email
    country {
      id_country
      name
      iso
      phone_code
    }
    date
  }
`;

export const DOCUMENTYPES_OBJECT = gql`
  fragment DocumentypesObject on DocTypes {
    id_docs
    description
    id_second
    id_siigo
  }
`;

export const TAXES_OBJECT = gql`
  fragment TaxesObject on Tax {
    id_tax
    type
    value
    id_second
  }
`;

export const TYPES_OBJECT = gql`
  fragment TypesObject on Type {
    id_type
    description
    id_second
  }
`;

export const UNITS_OBJECT = gql`
  fragment UnitsObject on Unit {
    id_unit
    description
    id_second
  }
`;

export const BILLY_OBJECT = gql`
  fragment BillyObject on BillyInvoice {
    id_primary
    id_fact
    id_billy
    invoice_number
  }
`;

export const BILLY_OBJECT_NOTES = gql`
  fragment BillyObjectNotes on BillyNotes {
    id_primary
    id_note
    id_billy
    note_number
  }
`;

export const CREATE_BILLY_OBJECT = gql`
  fragment BillyObjectC on BillyInvoice {
    id_fact
    id_billy
    invoice_number
  }
`;

export const CREATE_BILLY_OBJECT_NOTES = gql`
  fragment BillyObjectNotesC on BillyNotes {
    id_note
    id_billy
    note_number
  }
`;

export const GOAL_OBJECT = gql`
  fragment GoalObject on GraphsData {
    id_data
    goal
    date
  }
`;

export const CREATE_GOAL_OBJECT = gql`
  fragment CreateGoalObject on GraphsData {
    goal
    date
  }
`;

export const DRIVER_OBJECT = gql`
  fragment DriverObject on Chofer {
    id_chofer
    invoice
    chofer
    cin
    placa
    permiso_rasda
    destino
    peso_neto
    tasa_c
    son
    date
  }
`;

export const CREATE_DRIVER_OBJECT = gql`
  fragment CreateDriver on Chofer {
    invoice
    chofer
    cin
    placa
    permiso_rasda
    destino
    peso_neto
    tasa_c
    son
    date
  }
`;

export const QUOTATIONS_OBJECT = gql`
  fragment QuotationsObject on Factura {
    id_factura
    num_fac
    tax
    estado
    id_cliente
    createdAt
    dueDate
    delivery_date
    invoiced_date
    venta {
      id_venta
      codigo
      cantidad
      precio
      descuento
      subtotal
      total
      articulo {
        id_articulo
        codigo
        descripcion
        presentacion
        costo
        cantidad
        grupo
        unidad
        id_siigo
        tax {
          id_tax
          type
          value
          id_second
        }
        presentations {
          id_type
          value
        }
        grupoArt {
          id_grupo
          nombre
        }
        type {
          id_type
        }
      }
      almacen {
        id_almacen
        nombre
        estado
      }
    }
    cliente {
      id_cliente
      doc_nit
      nombre
      direccion
      telefono
      email
      delivery
      city
      coordinates
      id_seller
      id_customerType
      customer_type{
        id_customerType
      }
    }
    contfac {
      id_cont_fac
      invoice
      delivery
      quotation
      credit_note
      debit_note
    }
    id_seller
    seller {
      id_seller
      doc_nit
      nombre
      email
    }
    estado_factura
    tax_incl
    billyInvoice {
      id_primary
      id_fact
      id_billy
      invoice_number
      voucher
    }
    payment {
      id_payment
      type
      id_user
      description
    }
    positive_balance
    observations
  }
`;

export const SUBSCRIPTION_QUOT_OBJECT = gql`
  fragment SubscriptionQuotation on Factura {
    num_fac
    tax
    createdAt
    dueDate
  }
`;

export const SUBSCRIPTION_INV_OBJECT = gql`
  fragment SubscriptionInvoice on Inv {
    invoiceQ
  }
`;

export const NOTE_OBJECT = gql`
  fragment NoteObject on Notas {
    id_nota
    note_fact
    codigo
    tipo_nota
    concepto
    total
    createAt
    dueDate
    valor_notas {
      id_valor
      id_nota
      warehouse {
        id_almacen
        nombre
        estado
      }
      cantidad
      precio
      subtotal
      descuento
      articulo {
        id_articulo
        codigo
        descripcion
        presentacion
        grupo
        unidad
        id_type2
        id_siigo
        almacen {
          id_almacen
          nombre
          estado
        }
        tax {
          id_tax
          type
          value
          id_second
        }
        presentations {
          id_type
          value
        }
        grupoArt {
          id_grupo
          nombre
        }
        type {
          id_type
        }
      }
    }
    billyNotes {
      id_primary
      id_note
      id_billy
      note_number
    }
    invoice {
      id_factura
      tax
      tax_incl
      dueDate
      cliente {
        doc_nit
        nombre
      }
      seller{
        id_seller
        doc_nit
      }
      contfac {
        invoice
      }
      billyInvoice {
        invoice_number
        voucher
      }
    }
  }
`;

export const NOTE_VALID = gql`
  fragment NoteValid on validNote {
    id_nota
    note_fact
    tipo_nota
  }
`;

export const QUOTATIONBYSTATUS_OBJECT = gql`
  fragment QuotationByStatusObject on Factura {
    id_factura
    num_fac
    tax
    estado
    createdAt
    dueDate
    delivery_date
    invoiced_date
    venta {
      id_venta
      codigo
      cantidad
      precio
      descuento
      subtotal
      total
      almacen {
        id_almacen
        nombre
        estado
      }
      articulo {
        id_articulo
        codigo
        descripcion
        presentacion
        grupo
        unidad
        id_type2
        almacen {
          id_almacen
          nombre
          estado
        }
        tax {
          id_tax
          type
          value
          id_second
        }
        presentations {
          id_type
          value
        }
        grupoArt {
          id_grupo
          nombre
        }
        type {
          id_type
        }
      }
    }
    cliente {
      id_cliente
      doc_nit
      nombre
      direccion
      telefono
      email
      delivery
      city
      coordinates
      id_seller
      id_customerType
    }
    contfac {
      id_cont_fac
      invoice
      delivery
      quotation
      credit_note
      debit_note
    }
    id_seller
    seller {
      id_seller
      doc_nit
    }
    estado_factura
    tax_incl
    billyInvoice {
      id_primary
      id_fact
      id_billy
      invoice_number
    }
    payment {
      id_payment
      type
      id_user
      description
    }
  }
`;

export const CREATE_QUOTATION_OBJECT = gql`
  fragment CreateQuotationObject on Factura {
    id_factura
    num_fac
    venta {
      id_venta
      cantidad
      precio
      descuento
      subtotal
      total
    }
    tax
    estado
    createdAt
    dueDate
    positive_balance
  }
`;

export const UPDATE_STATUS_QUOTATION_OBJECT = gql`
  fragment updateStatusQObject on Factura {
    id_factura
    num_fac
  }
`;

export const UPDATE_STATUS_PURCHASE_OBJECT = gql`
  fragment updateStatusPObject on PurchaseInvoice {
    id_invoice
    num_invo
  }
`;

export const REQ_CUSTOMER_OBJECT_PROSPECT = gql`
  fragment RequestCustomerProspectObj on RequestCustomer {
    id_cliente
    doc_nit
    nombre
    direccion
    telefono
    email
    delivery
    country {
      id_country
      name
      iso
      phone_code
    }
    positive_balance
    date
  }
`;

export const REQ_CUSTOMER_OBJECT_COUNT = gql`
  fragment RequestCustomerObjCount on RequestCustomerCount {
    count
    customers {
    id_cliente
    doc_nit
    types_docs {
      id_docs
      description
      id_second
      id_siigo
    }
    nombre
    direccion
    telefono
    email
    delivery
    country {
      id_country
      name
      iso
      phone_code
    }
    city
    coordinates
    seller {
      id_seller
      doc_nit
      nombre
      email
    }
    contributorType {
      id_contributor
      description
    }
    customer_segment{
      id_segment
      nombre
    }
    positive_balance
    customer_type{
      id_customerType
      type
    }
    userSeller{
      id_userSeller
      id_user
      id_seller
      seller{
        id_seller
        nombre
      }
    }
    verification_digit
    date
    }
  }
`;

export const REQ_CUSTOMER_OBJECT = gql`
  fragment RequestCustomerObj on RequestCustomer {
    id_cliente
    doc_nit
    types_docs {
      id_docs
      description
      id_second
      id_siigo
    }
    nombre
    direccion
    telefono
    email
    delivery
    country {
      id_country
      name
      iso
      phone_code
    }
    city
    coordinates
    seller {
      id_seller
      doc_nit
      nombre
      email
    }
    contributorType {
      id_contributor
      description
    }
    customer_segment{
      id_segment
      nombre
    }
    positive_balance
    customer_type{
      id_customerType
      type
    }
    userSeller{
      id_userSeller
      id_user
      id_seller
      seller{
        id_seller
        nombre
      }
    }
    verification_digit
    date
  }
`;

//Articles Objects
export const ARTICLES_OBJECT_COUNT = gql`
  fragment ArticlesObjectCount on ArticlesCount {
    count
    articles {
      id_articulo
      codigo
      descripcion
      presentacion
      costo
      cantidad
      grupo
      grupoArt {
        id_grupo
        nombre
      }
      unidad
      almacen {
        id_almacen
        nombre
        estado
      }
      warehouse_values {
        id_warehouse_article
        id_article
        id_warehouse
        warehouse {
          id_almacen
          nombre
          estado
        }
        value
        quantity
        avaliable_quantity
      }
      tax {
        id_tax
        type
        value
        id_second
      }
      type {
        id_type
        description
        id_second
      }
      unit {
        id_unit
        description
        id_second
      }
      id_type2
      presentations {
        id_type
        value
      }
      cumulative_amount
      cumulative_value
      alternativeCodes
      id_siigo
      date
    }
    }
`;

export const ARTICLES_OBJECT = gql`
  fragment ArticlesObject on Articulos {
      id_articulo
      codigo
      descripcion
      presentacion
      costo
      cantidad
      grupo
      grupoArt {
        id_grupo
        nombre
      }
      unidad
      almacen {
        id_almacen
        nombre
        estado
      }
      warehouse_values {
        id_warehouse_article
        id_article
        id_warehouse
        warehouse {
          id_almacen
          nombre
          estado
        }
        value
        quantity
        avaliable_quantity
      }
      tax {
        id_tax
        type
        value
        id_second
      }
      type {
        id_type
        description
        id_second
      }
      unit {
        id_unit
        description
        id_second
      }
      id_type2
      presentations {
        id_type
        value
      }
      cumulative_amount
      cumulative_value
      alternativeCodes
      id_siigo
      date
    }
`;

export const WAREHOUSE_OBJECT = gql`
  fragment WarehouseObject on Almacen {
    id_almacen
    nombre
    estado
    date
  }
`;

export const TOKEN_USER_OBJECT = gql`
  fragment UserTokenObject on AuthPayload {
    token
    user{
      id
    }
  }
`;

export const USER_OBJECT = gql`
  fragment UsersObject on User {
    id
    document
    name
    email
    username
    status
    roles {
      Id_rol
      name
    }
    types_docs {
      id_docs
      description
      id_second
      id_siigo
    }
    companies {
      Id_company
      name
      tax_iva
      status
      address
      NIT
      external_electronic_invoicing
      internal_electronic_invoicing
      countries {
        id_country
        name
        iso
        phone_code
      }
    }
    prefixes {
      id
      id_company
      prefix
      voucher
    }
  }
`;

export const COMPANY_USER = gql`
  fragment CompanyUserObject on companyUser {
    id_company_user
    id_user
    id_company
  }
`;

export const ROL_OBJECT = gql`
  fragment RolObject on Rol {
    Id_rol
    name
    status
  }
`;

//Company Fragment
export const COMPANY_OBJECT = gql`
  fragment CompanyObject on Company {
    Id_company
    name
    tax_iva
    status
    address
    NIT
    countries {
      id_country
      name
      iso
      phone_code
    }
    external_electronic_invoicing
    internal_electronic_invoicing
    prefixes {
      id
      prefix
      voucher
    }
  }
`;

//Country fragment
export const COUNTRY_OBJECT = gql`
  fragment CountryObject on Country {
    id_country
    name
    iso
    iso_num
    phone_code
  }
`;

export const PRESENTATIONS_OBJECT = gql`
  fragment PresentationsObject on Presentations {
    id_type
    value
  }
`;

import { BillyErrorsService } from '../../@core/Billy/BillyErrors.service';
import { AlertsService } from './../../@shared/components/services/alerts.service';
import { Injectable } from "@angular/core";
import {
  HttpClient,
  HttpErrorResponse,
  HttpHeaders,
} from "@angular/common/http";
import { throwError } from "rxjs";
import { catchError, shareReplay } from "rxjs/operators";
import { map } from "rxjs/internal/operators/map";
import { CookieAuthService } from 'src/app/@shared/storage-variables/cookie-auth.service';

@Injectable({
  providedIn: "root",
})
export class BillyService {
  URL_BILLY = "https://api.billy.com.co/v1/";
  token: string = "";
  httpOptions = {};
  httpFormData = {};
  company: string | null = "";

  constructor(private http: HttpClient, private billyErrorsService: BillyErrorsService, private alertsService: AlertsService, private cookieAuthService: CookieAuthService) {
  }

  assignToken(company?: string) {
    const companyObject = this.cookieAuthService.getCompanyObject;
    const companyId = companyObject?.Id_company.toString()!;
    this.company = company ? company : companyId;
    switch (this.company) {
    case "7":
      this.token =
        "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpaWQiOiItTVAySDhfYi1OWkllVUNod0M5RSIsInNjcCI6eyJpbnYiOjN9LCJpYXQiOjE3MTU3MjE5NjEsImV4cCI6MTc0NzI1Nzk2MSwic3ViIjoiSENUelZCVVg3WlFkU1R3dG1hcGxtaHBWYlJ3MiIsImp0aSI6Ii1OeHN6cndBUTB6MUZIS2FlUXkyIn0.Q8esPLtFFHOUmvNow2Y6DmciRmmuYZMwUHV-PBAf6_k";
    break;
    case "9":
      this.token =
        "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpaWQiOiItTXhNN0tvMXZuUDlJeFQ2TXhnLSIsInNjcCI6eyJpbnYiOjN9LCJpYXQiOjE3MDk1ODQ3ODMsImV4cCI6MTc0MTEyMDc4Mywic3ViIjoiOWdZWWswT2lSVlBhUW5FdE43eWRRODZNaWF0MiIsImp0aSI6Ii1Oc0FCT1lTUE9ybHQwOFlQazhQIn0.RLIbxpo7qiP7YwG9tW5ba0d4-o3R1OtTigodFRJo5q8";
    break;
    default:
      this.token =
        "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpaWQiOiItTVAySDhfYi1OWkllVUNod0M5RSIsInNjcCI6eyJpbnYiOjN9LCJpYXQiOjE3MTU3MjE5NjEsImV4cCI6MTc0NzI1Nzk2MSwic3ViIjoiSENUelZCVVg3WlFkU1R3dG1hcGxtaHBWYlJ3MiIsImp0aSI6Ii1OeHN6cndBUTB6MUZIS2FlUXkyIn0.Q8esPLtFFHOUmvNow2Y6DmciRmmuYZMwUHV-PBAf6_k";
    break;
    }
    this.assignHttpConfig();
  }

  assignHttpConfig() {
    this.httpOptions = {
      headers: new HttpHeaders({
        Authorization: "Bearer" + this.token,
        "Content-Type": "application/json",
      }),
    };
    this.httpFormData = {
      headers: new HttpHeaders({
        Authorization: "Bearer" + this.token,
      }),
    };
  }

  handleError(error: HttpErrorResponse) {
    const commonErrorMessage = 'Something unexpected happened';
    if (error.status === 0 || error.status === 500) {
      console.log("An error occurred:", error.error);
      return this.alertsService.showErrorAlert(commonErrorMessage);
    } else {
      const errorFound = this.billyErrorsService.findErrorByStatus(error.status.toString(), error.error.errors[0].detail);
      console.log(
        `Backend returned code ${error.status}, body was: `,
        error.error,
        'Error detail:', errorFound
      );
      if (errorFound) {
        this.alertsService.showErrorAlert(errorFound.detail);
      } else {
        this.alertsService.showErrorAlert(commonErrorMessage, error.error.errors[0].detail);
      }
    }
    return throwError(
      () => new Error(commonErrorMessage)
    );
  }


  postInvoice(invoice: any) {
    return this.http
      .post<any>(this.URL_BILLY + `invoices`, invoice, this.httpOptions)
      .pipe(
        map((result: any) => {
          return result;
        }),
        shareReplay(1),
        catchError((error) => {
            this.handleError(error);
            return throwError(error);
          })
      );
  }

  getPayer(idNumber: string) {
    return this.http
      .get<any>(
        this.URL_BILLY + `payers/findByIdNumber?idNumber=` + idNumber,
        this.httpOptions
      )
      .pipe(
        map((result: any) => {
          return result;
        }),
        shareReplay(1),
        catchError((error) => {
            this.handleError(error);
            return throwError(error);
          })
      );
  }

  postCreditNote(credit_note: any) {
    return this.http
      .post<any>(this.URL_BILLY + `credit-notes`, credit_note, this.httpOptions)
      .pipe(
        map((result: any) => {
          return result;
        }),
        shareReplay(1),
        catchError((error) => {
            this.handleError(error);
            return throwError(error);
          })
      );
  }

  postItems(items: any) {
    return this.http
      .post<any>(this.URL_BILLY + `items`, items, this.httpOptions)
      .pipe(
        map((result: any) => {
          return result;
        }),
        shareReplay(1),
        catchError((error) => {
            this.handleError(error);
            return throwError(error);
          })
      );
  }

  postLegalPayers(payer: any) {
    return this.http
      .post<any>(
        this.URL_BILLY + `payers/institutions`,
        payer,
        this.httpFormData
      )
      .pipe(
        map((result: any) => {
          return result;
        }),
        shareReplay(1),
        catchError((error) => {
          return throwError(error);
        })
      );
  }

  postNaturalPayers(payer: any) {
    return this.http
      .post<any>(this.URL_BILLY + `payers/persons`, payer, this.httpFormData)
      .pipe(
        map((result: any) => {
          return result;
        }),
        shareReplay(1),
        catchError((error) => {
          return throwError(error);
        })
      );
  }

  getCities(id_iso: number) {
    return this.http
        .get<any>(this.URL_BILLY + `countries/${id_iso}/cities`, this.httpOptions)
        .pipe(
          map((result: any) => {
            return result;
          }),
          shareReplay(1),
          catchError((error) => {
            this.handleError(error);
            return throwError(error);
          })
        );
  }

  getInvoice(id_fact: string, company?: string) {
    if (company) { this.assignToken(company) };
    if (company) { this.assignHttpConfig() };
    return this.http
      .get<any>(this.URL_BILLY + `invoices/${id_fact}`, this.httpOptions)
      .pipe(
        map((result: any) => {
          return result;
        }),
        shareReplay(1),
        catchError((error) => {
          this.handleError(error);
          return throwError(error);
        })
      );
  }

  getCurrencies() {
    return this.http
      .get<any>(this.URL_BILLY + `currencies`, this.httpOptions)
      .pipe(
        map((result: any) => {
          return result;
        }),
        shareReplay(1),
        catchError((error) => {
            this.handleError(error);
            return throwError(error);
          })
      );
  }
}

import { Component, OnInit, OnDestroy } from '@angular/core';
import { IPaymentsList } from 'src/app/@interfaces/payments.interface';
import { startWith } from 'rxjs/operators';
import { Subject, Observable } from 'rxjs';
import { map } from "rxjs/internal/operators/map";
import { UntypedFormControl, Validators, UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { TransfersService } from './transfers.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { AlertsService } from '../../services/alerts.service';
import { PaymentsService } from '../crud-quotation/payments/payments.service';

@Component({
  selector: "app-transfers",
  templateUrl: "./transfers.component.html",
  styleUrls: ["./transfers.component.css"],
})
export class TransfersComponent implements OnInit, OnDestroy {
  dateForm: UntypedFormControl = this.fb.control(new Date(), Validators.required);
  paymentTypeForm: UntypedFormControl = this.fb.control("", Validators.required);
  customTransferForm:  UntypedFormGroup = this.fb.group({
    transferTo: ["", [Validators.required]],
    transferOf: ["", [Validators.required]],
    value: [0, [Validators.required, Validators.min(0.01), Validators.max(99999999.99)]],
  });
  filteredOptionsTransferTo: Observable<IPaymentsList[]> | undefined;
  filteredOptionsTransferOf: Observable<IPaymentsList[]> | undefined;
  paymentsList: IPaymentsList[] = [];
  unsubscribe$ = new Subject();
  constructor(
    private fb: UntypedFormBuilder,
    private transfersService: TransfersService,
    private spinnerService: NgxSpinnerService,
    private alertsService: AlertsService,
    private paymentsService: PaymentsService
  ) {}

  ngOnInit(): void {
    this.paymentsTypeData();
    this.filterInputs();
  }

  filterInputs() {
    this.filteredOptionsTransferTo = this.customTransferForm.get("transferTo")?.valueChanges.pipe(
      startWith(""),
      map((value) => this._filterP(value))
    );
    this.filteredOptionsTransferOf = this.customTransferForm.get("transferOf")?.valueChanges.pipe(
      startWith(""),
      map((value) => this._filterP(value))
    );
  }

  _filterP(value: string): IPaymentsList[] {
    const filterValueP = value.toString().toLowerCase();
    return this.paymentsList.filter((option) =>
      option.description.toLowerCase().toString().includes(filterValueP)
    );
  }

  displayFnPaymentType(payment: any) {
    return payment && payment ? payment.description : undefined;
  }

  registerCustom() {
    this.spinnerService.show();
    if (this.customTransferForm.invalid === true) {
      this.spinnerService.hide();
      this.alertsService.showErrorAlert("Please, fill in all the required fields!");
      return;
    }else {
      const date = this.dateForm.value;
      this.transfersService.createSimpleTransfer(
        "Custom",
        new Date(date).toDateString(),
        this.customTransferForm.value.value,
        parseInt(this.customTransferForm.value.transferOf.id_payment),
        parseInt(this.customTransferForm.value.transferTo.id_payment)
      ).then((confirm) => {
        this.spinnerService.hide();
        this.transfersService.validSuccessfullTransfers([confirm]);
      })
    }
  }

  async paymentsTypeData() {
    const cashiersList = await this.paymentsService.getCashierList();
    const banksList = await this.paymentsService.getBankList();
    this.paymentsList = cashiersList.concat(banksList);
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}

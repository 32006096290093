import { Component, OnInit, ViewChild } from "@angular/core";
import { IQuota } from "src/app//@interfaces/quotation.interface";
import {
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from "@angular/forms";
import { ICustomer } from "src/app/@interfaces/customer.interface";
import { CustomerService } from "../customer/customer.service";
import { NgxSpinnerService } from "ngx-spinner";
import { INotasGrap } from "../../@interfaces/notas.interface";
import { AnalysisService } from "./analysis.service";
import { AuthService } from "../../@shared/authentication/auth.service";
import { IArticles } from "../../@interfaces/article.interface";
import { InventoryService } from "../inventory/inventory.service";
import { debounceTime, takeUntil } from "rxjs/operators";
import { Subject } from "rxjs";
import { IPresentationTypes } from "src/app/@interfaces/presentationTypes";
import { ISeller } from "src/app/@interfaces/seller.interface";
import { SellersService } from "../sellers/sellers.service";
import {
  IResultProducts,
  IResultTabs,
  IResultInvCost,
} from "src/app/@interfaces/csv/csv.interface";
import { CsvService } from "../csv/csv.service";
import { DatePipe } from "@angular/common";
//Pipes
import { FilterCDISQuots } from "src/app/@shared/pipe/graphs-pipes/quotations/customer-date-item-seller.pipe";
import { FilterSQuots } from "src/app/@shared/pipe/graphs-pipes/quotations/seller.pipe";
import { FilterSIQuots } from "src/app/@shared/pipe/graphs-pipes/quotations/seller-item.pipe";
import { FilterSIDQuots } from "src/app/@shared/pipe/graphs-pipes/quotations/seller-item-date.pipe";
import { FilterSCQuots } from "src/app/@shared/pipe/graphs-pipes/quotations/seller-customer.pipe";
import { FilterSDCQuots } from "src/app/@shared/pipe/graphs-pipes/quotations/seller-date-customer.pipe";
import { FilterSICQuots } from "src/app/@shared/pipe/graphs-pipes/quotations/seller-item-customer.pipe";
import { FilterSDQuots } from "src/app/@shared/pipe/graphs-pipes/quotations/seller-date.pipe";
import { FilterCQuots } from "src/app/@shared/pipe/graphs-pipes/quotations/customer.pipe";
import { FilterCDQuots } from "src/app/@shared/pipe/graphs-pipes/quotations/customer-date.pipe";
import { FilterCIQuots } from "src/app/@shared/pipe/graphs-pipes/quotations/customer-item.pipe";
import { FilterIQuots } from "src/app/@shared/pipe/graphs-pipes/quotations/item.pipe";
import { FilterDIQuots } from "src/app/@shared/pipe/graphs-pipes/quotations/date-item.pipe";
import { FilterDQuots } from "src/app/@shared/pipe/graphs-pipes/quotations/date.pipe";
import { AnalysisCsvService } from "./analysis-csv.service";
import { EventEmitterService } from "src/app/@core/menu/emitterFuncions.service";
import { FilterBySearchService } from "../../@shared/components/services/filterBySearch.service";
import { FilterPTQuots } from "src/app/@shared/pipe/graphs-pipes/quotations/presentation.pipe";
import { FilterPTNotes } from "src/app/@shared/pipe/graphs-pipes/notes/presentation.pipe";
import { FilterINotes } from "src/app/@shared/pipe/graphs-pipes/notes/item.pipe";
import { GraphicQueriesService } from "src/app/@shared/components/services/graphic-queries.service";
import { CookieAuthService } from "src/app/@shared/storage-variables/cookie-auth.service";

@Component({
  selector: "app-analysis",
  templateUrl: "./analysis.component.html",
  styleUrls: ["./analysis.component.css"],
})
export class AnalysisComponent implements OnInit {
  range = new UntypedFormGroup({
    start: new UntypedFormControl(),
    end: new UntypedFormControl(),
  });
  date = new Date();
  years: number[] = [];
  presentationTypes: IPresentationTypes[] = [];

  ////////////////////////////////////////////
  registerForm: UntypedFormGroup = this.fb.group({
    customer: [, Validators.required],
    item: [, Validators.required],
    seller: [, Validators.required],
    presentation: [, Validators.required],
    year: [this.date.getFullYear(), Validators.required]
  });

  customerList: Array<ICustomer> = [];
  articleList: Array<IArticles> = [];
  sellerList: Array<ISeller> = [];

  orderCustomerQuots: Array<IQuota> = [];
  quotaList: Array<IQuota> = [];
  quotaListPrimary: Array<IQuota> = [];
  
  notas: Array<INotasGrap> = [];
  notasFilterTypes: Array<INotasGrap> = [];
  notasListPrimary: Array<INotasGrap> = [];

  months: number = 0;
  typePres: string = "All";

  Ctas_cobrar: Array<IResultTabs> = [];
  Cli_csv: Array<IResultProducts> = [];

  OriCtas_cobrar: Array<IResultTabs> = [];
  OriCli_csv: Array<IResultProducts> = [];

  OriCat_Art_csv: Array<IResultProducts> = [];
  Cat_Art_csv: Array<IResultProducts> = [];

  OriVal_Art_csv: Array<IResultProducts> = [];
  Val_Art_csv: Array<IResultProducts> = [];

  OriProduct_Days: Array<IResultProducts> = [];
  Product_Days: Array<IResultProducts> = [];

  OriProduct_Inven: Array<IResultInvCost> = [];
  Product_Inven: Array<IResultInvCost> = [];

  Dollar_customer_iva: Array<IResultProducts> = [];
  OriDollar_customer_iva: Array<IResultProducts> = [];
  month: number = 0;

  unsubscribe$ = new Subject();
  @ViewChild("tabs", { static: false }) tabs?: any;
  ///////////////Graphics Data/////////////////

  companyId = '';
  user = '';
  rol = '';

  constructor(
    private customers: CustomerService,
    private inventory: InventoryService,
    private fb: UntypedFormBuilder,
    private spinnerService: NgxSpinnerService,
    private analysisService: AnalysisService,
    private analysisCsvService: AnalysisCsvService,
    private filterCDISQuots: FilterCDISQuots,
    private filterSQuots: FilterSQuots,
    private filterSIQuots: FilterSIQuots,
    private filterSIDQuots: FilterSIDQuots,
    private filterSCQuots: FilterSCQuots,
    private filterSDCQuots: FilterSDCQuots,
    private filterSICQuots: FilterSICQuots,
    private filterSDQuots: FilterSDQuots,
    private filterCQuots: FilterCQuots,
    private filterCDQuots: FilterCDQuots,
    private filterCIQuots: FilterCIQuots,
    private filterIQuots: FilterIQuots,
    private filterDIQuots: FilterDIQuots,
    private filterDQuots: FilterDQuots,
    private filterPTQuots: FilterPTQuots,
    private filterPTNotes: FilterPTNotes,
    private filterINotes: FilterINotes,
    private seller: SellersService,
    private csv: CsvService,
    private filterSearchService: FilterBySearchService,
    private formtdate: DatePipe,
    private eventEmitterService: EventEmitterService,
    private graphicQueriesService: GraphicQueriesService,
    private authService: AuthService,
    private cookieAuthService: CookieAuthService
  ) {
    for (let i = this.date.getFullYear(); i >= this.date.getFullYear() - 10; i--) {
      this.years.push(i);
    }
  }

  async ngOnInit(): Promise<void> {
    await this.authService.checkAuthStatus();
    this.getAuthValues();
    this.spinner();
    this.data(this.date.getFullYear());
    this.dataFilters();
    this.getValuesFromHandleSearch();
    // this.dataCsv();
    this.toggleExpandCard();
    this.getEvent();
    this.handleSearch();
  }

  getAuthValues() {
    const company = this.cookieAuthService.getCompanyObject;
    this.companyId = company!.Id_company!.toString();
    this.user = this.cookieAuthService.getUserId!;
    this.rol = this.cookieAuthService.getRolId!;
  }

  dataCsv(dateIni?: string, dateFin?: string, codcli?: string, codart?: string, SellerdocNit?: string) {
    let token = "0d6f94e88c4d66b4e080f7adb7c8183b27e1546c";
    let tabla = "";
    let tabla2 = "";
    let tablaInven = "";
    let date3, date4, monthFilter;
    let date = new Date();
    if ((dateIni === null || dateIni === undefined) && (dateFin === null || dateFin === undefined)) {
      date3 = "2022-01-01";
      date4 = "2023-12-31";
      this.month = date.getMonth() + 1;
    } else {
      let x = dateIni?.split(" ");
      let y = dateFin?.split(" ");
      date3 = x![2];
      date4 = y![2];
      monthFilter = date4.split("-");
      this.month = parseInt(monthFilter![1]);
    }
    if (this.companyId === "6") {
      tabla = "top20CtasCobrarCaribe";
      tabla2 = "mvto_conta_caribe";
      tablaInven = "Valores_caribe";
    }
    if (this.companyId === "8") {
      tabla = "top20CtasCobrarCvp";
      tabla2 = "mvto_conta_cvp";
      tablaInven = "Valores_cvp";
    }
    this.csv
    .getTabs(token, tabla, "3")
    .pipe(takeUntil(this.unsubscribe$))
    .subscribe((result) => {
      if (SellerdocNit) {
        this.Ctas_cobrar = this.analysisCsvService.filterAccountReceivables(
          result,
          codcli!,
          SellerdocNit
        );
      } else {
        this.Ctas_cobrar = result;
      }
      this.OriCtas_cobrar = result;
    });
    this.csv
    .getTabs2(token, tabla2, "10", date3!, date4!)
    .pipe(takeUntil(this.unsubscribe$))
    .subscribe((result) => {
      if (codcli || codart || SellerdocNit) {
        this.Cli_csv = this.analysisCsvService.filterAmount(
          codcli!,
          result,
          codart!,
          SellerdocNit!
        );
      } else {
        this.Cli_csv = result;
      }
      this.OriCli_csv = result;
    });
    this.csv
    .getTabs2(token, tabla2, "8", date3!, date4!)
    .pipe(takeUntil(this.unsubscribe$))
    .subscribe((result) => {
      if (codcli || codart || SellerdocNit) {
        this.Cat_Art_csv = this.analysisCsvService.filterAmount(
          codcli!,
          result,
          codart!,
          SellerdocNit!
        );
      } else {
        this.Cat_Art_csv = result;
      }
      this.OriCat_Art_csv = result;
    });
    this.csv
    .getTabs2(token, tabla2, "9", date3!, date4!)
    .pipe(takeUntil(this.unsubscribe$))
    .subscribe((result) => {
      if (codcli || codart || SellerdocNit) {
        this.Val_Art_csv = this.analysisCsvService.filterAmount(
          codcli!,
          result,
          codart!,
          SellerdocNit!
        );
      } else {
        this.Val_Art_csv = result;
      }
      this.OriVal_Art_csv = result;
    });
    this.csv
    .getTabs2(token, tabla2, "11", date3!, date4!)
    .pipe(takeUntil(this.unsubscribe$))
    .subscribe((result) => {
      if (codcli || codart || SellerdocNit) {
        this.Product_Days = this.analysisCsvService.filterAmount(
          codcli!,
          result,
          codart!,
          SellerdocNit!
        );
      } else {
        this.Product_Days = result;
      }
      this.OriProduct_Days = result;
    });
    this.csv
    .getTabs(token, tablaInven, "1")
    .pipe(takeUntil(this.unsubscribe$))
    .subscribe((result) => {
      this.Product_Inven = result;
      this.OriProduct_Inven = result;
    });
    this.csv
    .getTabs2(token, tabla2, "12", date3!, date4!)
    .pipe(takeUntil(this.unsubscribe$))
    .subscribe((result) => {
      if (codcli || codart || SellerdocNit) {
        this.Dollar_customer_iva = this.analysisCsvService.filterAmount(
          codcli!,
          result,
          codart!,
          SellerdocNit!
        );
      } else {
        this.Dollar_customer_iva = result;
      }
      this.OriDollar_customer_iva = result;
    });
  }

  data(year: number) {
    const id_user = this.rol === "1" || this.rol === "3" ? null : parseInt(this.user!);
    const date = new Date(`${year}/12/31`)
    const dateRange = this.graphicQueriesService.getDatesRange(date, 0);
    
    this.graphicQueriesService
    .getAllAnalysisInvoices(dateRange.reducedDate, dateRange.currentDate, id_user)
    .pipe(takeUntil(this.unsubscribe$))
    .subscribe((result) => {
      this.quotaListPrimary = result.length === 0 ? this.analysisService.quotsNull() : result;
      this.quotaList = result.length === 0 ? this.analysisService.quotsNull() : result;
      this.orderCustomerQuots = result.length === 0 ? this.analysisService.quotsNull() : result;
      this.monthDiff(result.length === 0 ? this.analysisService.quotsNull() : result);
    });

    this.graphicQueriesService
    .getAllGraphicNotes()
    .pipe(takeUntil(this.unsubscribe$))
    .subscribe((result) => {
      this.notas = result.length === 0 ? this.analysisService.notesNull() : result;
      this.notasFilterTypes = result.length === 0 ? this.analysisService.notesNull() : result;
      this.notasListPrimary = result.length === 0 ? this.analysisService.notesNull() : result;
    });
  }

  dataFilters() {
    this.seller
    .listSellers()
    .pipe(takeUntil(this.unsubscribe$))
    .subscribe((result) => {
      this.sellerList = result;
    });

    this.inventory
    .getPresentations()
    .pipe(takeUntil(this.unsubscribe$))
    .subscribe((result) => {
      this.presentationTypes = result;
    });
  }

  getEvent() {
    this.eventEmitterService.invokeFirstComponentFunction
    .pipe(takeUntil(this.unsubscribe$))
    .subscribe((name: string) => {
      this.toggleExpandCard();
    });
  }

  toggleExpandCard() {
    setTimeout(() => {
      this.tabs!.realignInkBar();
    }, 500);
  }

  filterTypes(event: any, quots: IQuota[], notes: INotasGrap[]) {
    this.typePres = event.value.value;
    let filterQuots: IQuota[] = this.filterPTQuots.transform(quots, event.value.id_type);
    let filterNotes: INotasGrap[] = this.filterPTNotes.transform(notes, event.value.id_type);
    filterQuots = filterQuots.length === 0 ? this.analysisService.quotsNull() : filterQuots;
    filterNotes = filterNotes.length === 0 ? this.analysisService.notesNull() : filterNotes;

    this.orderCustomerQuots = filterQuots;
    this.quotaList = filterQuots;
    this.notas = filterNotes;
    this.notasFilterTypes = filterNotes;
  }

  getValuesFromHandleSearch() {
    if (this.rol === "1" || this.rol === "3") {
      this.registerForm
      .get("customer")
      ?.valueChanges.pipe(debounceTime(300))
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((value: any) => {
        if (!value.__typename) {
          this.customers
          .searchCustomer(value, null, null)
          .pipe(takeUntil(this.unsubscribe$))
          .subscribe((result: any) => {
            this.customerList = result.customers;
          });
        }
      });
    } else {
      this.registerForm
      .get("customer")
      ?.valueChanges.pipe(debounceTime(300))
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((value: any) => {
        if (!value.__typename) {
          this.customers
          .searchCustomerByUser(value, parseInt(this.user!), null, null)
          .pipe(takeUntil(this.unsubscribe$))
          .subscribe((result: any) => {
            this.customerList = result;
          });
        }
      });
    }
    this.registerForm
    .get("item")
    ?.valueChanges.pipe(debounceTime(300))
    .pipe(takeUntil(this.unsubscribe$))
    .subscribe((value: any) => {
      if (!value.__typename) {
        this.inventory
        .searchArticle(value, null, null, null)
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe((result: any) => {
          this.articleList = result.articles;
        });
      }
    });
  }

  updateCsv() {
    this.spinner();
    const start: Date = this.range.get("start")?.value;
    const end: Date = this.range.get("end")?.value;
    let date1 = this.formtdate.transform(start, "shortDate:  yyyy-MM-dd");
    let date2 = this.formtdate.transform(end, "shortDate:  yyyy-MM-dd");
    const customerObj = this.registerForm.get("customer")?.value;
    const articleObj = this.registerForm.get("item")?.value;
    const sellerObj = this.registerForm.get("seller")?.value;
    let docNit: string = "";
    let codArt: string = "";
    let SellerdocNit: string = "";
    if (customerObj) {
      docNit = customerObj.doc_nit;
    }
    if (articleObj) {
      codArt = articleObj.codigo;
    }
    if (sellerObj) {
      SellerdocNit = sellerObj.doc_nit;
    }
    if ((date1 && date2) || (date1 && date2 && (docNit || codArt || SellerdocNit))) {
      this.dataCsv(date1!, date2!, docNit, codArt, SellerdocNit);
    } else if (!(date1 && date2) && (docNit || codArt || SellerdocNit)) {
      this.Cli_csv = this.analysisCsvService.filterAmount(
        docNit,
        this.OriCli_csv,
        codArt,
        SellerdocNit
      );
      this.Ctas_cobrar = this.analysisCsvService.filterAccountReceivables(
        this.OriCtas_cobrar,
        docNit,
        SellerdocNit
      );
      this.Cat_Art_csv = this.analysisCsvService.filterAmount(
        docNit,
        this.OriCat_Art_csv,
        codArt,
        SellerdocNit
      );
      this.Val_Art_csv = this.analysisCsvService.filterAmount(
        docNit,
        this.OriVal_Art_csv,
        codArt,
        SellerdocNit
      );
      this.Product_Days = this.analysisCsvService.filterAmount(
        docNit,
        this.OriProduct_Days,
        codArt,
        SellerdocNit
      );
    }
  }

  resetCsv() {
    this.spinner();
    this.registerForm.get("customer")?.setValue("");
    this.registerForm.get("item")?.setValue("");
    this.registerForm.get("seller")?.setValue("");
    this.range.reset();
    this.dataCsv();
  }

  update(quots: IQuota[], notes: INotasGrap[]) {
    this.spinner();
    let start: Date | null = null;
    let end: Date | null = null;
    const customerObj = this.registerForm.get("customer")?.value;
    const articleObj = this.registerForm.get("item")?.value;
    const sellerObj = this.registerForm.get("seller")?.value;
    let id_cliente = null;
    let codigo = null;
    let id_seller = null;
    if (this.range.get("start")?.value !== null && this.range.get("end")?.value !== null) {
      start = this.setDatesFromForm(this.range.get("start")?.value);
      end = this.setDatesFromForm(this.range.get("end")?.value);
      end.setHours(23, 59, 59);
    }
    if (customerObj) {
      id_cliente = customerObj.id_cliente;
    }
    if (articleObj) {
      codigo = articleObj.codigo;
    }
    if (sellerObj) {
      id_seller = sellerObj.id_seller;
    }
    this.filterQuots(quots, notes, start!, end!, id_cliente, codigo, id_seller);
  }

  filterQuots(quots: IQuota[], notes: INotasGrap[], date1: Date, date2: Date, customer: string, item: string, seller: string) {
    this.filterNotes(notes, item);
    let filter: Array<IQuota> = [];
    //ALL
    if (customer !== null && date1 !== null && date2 !== null && item !== null && seller !== null) {
      filter = this.filterCDISQuots.transform(quots, date1, date2, customer, item, seller);
      if (filter.length === 0) {
        this.orderCustomerQuots = this.analysisService.quotsNull();
        this.monthDiff(this.analysisService.quotsNull());
      } else {
        this.orderCustomerQuots = filter;
        this.monthDiff(filter);
      }
    }
    //BY SELLER
    else if (seller !== null && item !== null && date1 !== null && date2 !== null) {
      filter = this.filterSIDQuots.transform(quots, date1, date2, item, seller);
      if (filter.length === 0) {
        this.orderCustomerQuots = this.analysisService.quotsNull();
        this.monthDiff(this.analysisService.quotsNull());
      } else {
        this.orderCustomerQuots = filter;
        this.monthDiff(filter);
      }
    } else if (seller !== null && date1 !== null && date2 !== null && customer !== null) {
      filter = this.filterSDCQuots.transform(quots, date1, date2, customer!, seller);
      if (filter.length === 0) {
        this.orderCustomerQuots = this.analysisService.quotsNull();
        this.monthDiff(this.analysisService.quotsNull());
      } else {
        this.orderCustomerQuots = filter;
        this.monthDiff(filter);
      }
    } else if (seller !== null && item !== null && customer !== null) {
      filter = this.filterSICQuots.transform(quots, customer, item, seller);
      if (filter.length === 0) {
        this.orderCustomerQuots = this.analysisService.quotsNull();
        this.monthDiff(this.analysisService.quotsNull());
      } else {
        this.orderCustomerQuots = filter;
        this.monthDiff(filter);
      }
    } else if (seller !== null && date1 !== null && date2 !== null) {
      filter = this.filterSDQuots.transform(quots, date1, date2, seller);
      if (filter.length === 0) {
        this.orderCustomerQuots = this.analysisService.quotsNull();
        this.monthDiff(this.analysisService.quotsNull());
      } else {
        this.orderCustomerQuots = filter;
        this.monthDiff(filter);
      }
    } else if (seller !== null && item !== null) {
      filter = this.filterSIQuots.transform(quots, item, seller);
      if (filter.length === 0) {
        this.orderCustomerQuots = this.analysisService.quotsNull();
        this.monthDiff(this.analysisService.quotsNull());
      } else {
        this.orderCustomerQuots = filter;
        this.monthDiff(filter);
      }
    } else if (seller !== null && customer !== null) {
      filter = this.filterSCQuots.transform(quots, customer, seller);
      if (filter.length === 0) {
        this.orderCustomerQuots = this.analysisService.quotsNull();
        this.monthDiff(this.analysisService.quotsNull());
      } else {
        this.orderCustomerQuots = filter;
        this.monthDiff(filter);
      }
    } else if (seller !== null) {
      filter = this.filterSQuots.transform(quots, seller);
      if (filter.length === 0) {
        this.orderCustomerQuots = this.analysisService.quotsNull();
        this.monthDiff(this.analysisService.quotsNull());
      } else {
        this.orderCustomerQuots = filter;
        this.monthDiff(filter);
      }
    } else if (customer !== null && date1 !== null && date2 !== null) {
      filter = this.filterCDQuots.transform(quots, date1, date2, customer);
      if (filter.length === 0) {
        this.orderCustomerQuots = this.analysisService.quotsNull();
        this.monthDiff(this.analysisService.quotsNull());
      } else {
        this.orderCustomerQuots = filter;
        this.monthDiff(filter);
      }
    } else if (customer !== null && item !== null) {
      filter = this.filterCIQuots.transform(quots, customer, item);
      if (filter.length === 0) {
        this.orderCustomerQuots = this.analysisService.quotsNull();
        this.monthDiff(this.analysisService.quotsNull());
      } else {
        this.orderCustomerQuots = filter;
        this.monthDiff(filter);
      }
    } else if (customer !== null) {
      filter = this.filterCQuots.transform(quots, customer);
      if (filter.length === 0) {
        this.orderCustomerQuots = this.analysisService.quotsNull();
        this.monthDiff(this.analysisService.quotsNull());
      } else {
        this.orderCustomerQuots = filter;
        this.monthDiff(filter);
      }
    }
    //BY CUSTOMER
    else if (date1 !== null && date2 !== null && item !== null) {
      filter = this.filterDIQuots.transform(quots, date1, date2, item);
      if (filter.length === 0) {
        this.orderCustomerQuots = this.analysisService.quotsNull();
        this.monthDiff(this.analysisService.quotsNull());
      } else {
        this.orderCustomerQuots = filter;
        this.monthDiff(filter);
      }
    }
    //BY ITEM
    else if (item !== null) {
      filter = this.filterIQuots.transform(quots, item);
      if (filter.length === 0) {
        this.orderCustomerQuots = this.analysisService.quotsNull();
        this.monthDiff(this.analysisService.quotsNull());
      } else {
        this.orderCustomerQuots = filter;
        this.monthDiff(filter);
      }
    }
    //BY DATE
    else if (date1 !== null && date2 !== null) {
      filter = this.filterDQuots.transform(quots, date1, date2);
      if (filter.length === 0) {
        this.orderCustomerQuots = this.analysisService.quotsNull();
        this.monthDiff(this.analysisService.quotsNull());
      } else {
        this.orderCustomerQuots = filter;
        this.monthDiff(filter);
      }
    } else {
      this.orderCustomerQuots = quots;
      this.monthDiff(quots);
    }
  }

  filterNotes(notes: INotasGrap[], item: string) {
    if (item) {
      const filter = this.filterINotes.transform(notes, item);
      if (filter.length === 0) {
        this.notas = this.analysisService.notesNull();
      } else {
        this.notas = filter;
      }
    }else {
      this.notas = notes;
    }
  }

  reset(quots: IQuota[], notes: INotasGrap[]) {
    this.spinner();
    this.orderCustomerQuots = quots;
    this.quotaList = quots;
    this.quotaListPrimary = quots;
    
    this.notas = notes;
    this.notasFilterTypes = notes;
    this.notasListPrimary = notes;
    this.typePres = "All";
    this.monthDiff(quots);
    this.clean();
  }

  clean() {
    this.range.reset();
    this.registerForm.get("customer")?.setValue("");
    this.registerForm.get("item")?.setValue("");
    this.registerForm.get("seller")?.setValue("");
    this.registerForm.get("presentation")?.setValue("");
  }

  handleSearch() {
    this.handleSearchCustomer();
    this.handleSearchSeller();
  }

  handleSearchCustomer() {
    let start: Date | null = null, end: Date | null = null;
    this.registerForm
    .get("customer")
    ?.valueChanges.pipe(debounceTime(300), takeUntil(this.unsubscribe$))
    .subscribe((customer) => {
      if (this.range.get("start")?.value && this.range.get("end")?.value) {
        start = this.setDatesFromForm(this.range.get("start")?.value);
        (end = this.setDatesFromForm(this.range.get("end")?.value)),
          end.setHours(23, 59, 59);
      }
      if (start && end && customer && !customer.__typename) {
        const array = this.filterSearchService.filterWithDate(
          this.quotaList,
          start,
          end,
          customer,
          "",
          ""
        );
        this.setValuesBySearch(array.quotArray);
      } else if (customer && !customer.__typename) {
        const array = this.filterSearchService.filterByCustomer(
          this.quotaList,
          customer
        );
        this.setValuesBySearch(array.quotArray);
      } else if (start && end) {
        const arrayQuots = this.filterDQuots.transform(
          this.quotaList,
          start,
          end
        );
        this.setValuesBySearch(arrayQuots);
      } else {
        this.setValuesBySearch(this.quotaList);
      }
    });
  }

  handleSearchSeller() {
    let start: Date | null = null, end: Date | null = null;
    this.registerForm
    .get("seller")
    ?.valueChanges.pipe(debounceTime(300), takeUntil(this.unsubscribe$))
    .subscribe((seller) => {
      if (this.range.get("start")?.value && this.range.get("end")?.value) {
        start = this.setDatesFromForm(this.range.get("start")?.value);
        (end = this.setDatesFromForm(this.range.get("end")?.value)),
          end.setHours(23, 59, 59);
      }
      if (start && end && seller && !seller.__typename) {
        const array = this.filterSearchService.filterWithDate(
          this.quotaList,
          start,
          end,
          "",
          "",
          seller
        );
        this.setValuesBySearch(array.quotArray);
      } else if (seller && !seller.__typename) {
        const array = this.filterSearchService.filterBySeller(
          this.quotaList,
          seller
        );
        this.setValuesBySearch(array.quotArray);
      } else if (start && end) {
        const arrayQuots = this.filterDQuots.transform(
          this.quotaList,
          start,
          end
        );
        this.setValuesBySearch(arrayQuots);
      } else {
        this.setValuesBySearch(this.quotaList);
      }
    });
  }

  setDatesFromForm(date: any) {
    return new Date(
      date.getFullYear().toString() +
      "-" +
      (date.getMonth() + 1).toString() +
      "-" +
      date.getDate().toString()
    );
  }

  setValuesBySearch(quotsArray: IQuota[]) {
    if (quotsArray.length <= 0) {
      this.orderCustomerQuots = this.analysisService.quotsNull();
      this.monthDiff(this.analysisService.quotsNull());
    } else {
      this.orderCustomerQuots = quotsArray;
      this.monthDiff(quotsArray);
    }
  }

  displayFnCustomer(customer: any) {
    return customer && customer ? customer.nombre : undefined;
  }

  displayFnArticle(item: any) {
    return item && item ? item.descripcion : undefined;
  }

  displayFnSeller(seller: any) {
    return seller && seller ? seller.nombre : undefined;
  }

  spinner(): void {
    this.spinnerService.show();
    setTimeout(() => {
      this.spinnerService.hide();
    }, 5000);
  }

  monthDiff(quotas: IQuota[]) {
    let arrayDates: string[] = [];
    let currentDate = new Date();
    quotas.forEach((data) => {
      if (data.estado === 4) {
        arrayDates.push(data.createdAt);
      }
    });
    const sortArray = arrayDates.sort();
    const months = this.calcDiff(new Date(sortArray[0]), currentDate) + 1;
    this.months = months;
  }

  calcDiff(d1: any, d2: any) {
    var months;
    months = (d2.getFullYear() - d1.getFullYear()) * 12;
    months -= d1.getMonth();
    months += d2.getMonth();
    return months <= 0 ? 0 : months;
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}

import { Input, ViewChild, OnDestroy } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { MediaMatcher } from '@angular/cdk/layout';
import { MatExpansionPanel } from '@angular/material/expansion'
import { UsersService } from 'src/app/@pages/users/users.service';
import { IUser } from 'src/app/@interfaces/user.interface';
import { AuthService } from 'src/app/@shared/authentication/auth.service';
import { TranslateService } from '@ngx-translate/core';
import { FlatTreeControl } from '@angular/cdk/tree';
import { MatTreeFlatDataSource, MatTreeFlattener } from '@angular/material/tree';
import { HomeComponent } from '../../@pages/home/home.component';
import { EventEmitterService } from './emitterFuncions.service';
import { Subject, Observable, combineLatest } from 'rxjs';
import { map, take, takeUntil } from 'rxjs/operators';
import { INode, FlatNode } from 'src/app/@interfaces/menuitem';
import { AuthStatus } from 'src/app/@enums/authentication/auth-status.enum';
import { CookieAuthService } from 'src/app/@shared/storage-variables/cookie-auth.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.css'],
  viewProviders: [MatExpansionPanel]
})

export class MenuComponent implements OnInit, OnDestroy {
  @Input() languages: string[] = [];
  showMenu: boolean = true;

  validatorSession = 0;
  access: boolean = false;
  me!: IUser;
  showSearch: boolean = true;
  mobileQuery?: MediaQueryList;
  currentCompany: string | null = null;
  companyName: string | null = null;
  @ViewChild(HomeComponent) home?: HomeComponent;

  TREE_DATA: INode[] = [];

  private _transformer = (node: INode, level: number) => {
    return {
      expandable: !!node.children && node.children.length > 0,
      name: node.name,
      icon: node.icon,
      route: node.route,
      level: level,
    };
  };
  treeControl = new FlatTreeControl<FlatNode>(
    node => node.level,
    node => node.expandable,
  );
  treeFlattener = new MatTreeFlattener(
    this._transformer,
    node => node.level,
    node => node.expandable,
    node => node.children,
  );
  dataSource = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener);
  hasChild = (_: number, node: FlatNode) => node.expandable;
  unsubscribe$ = new Subject();

  constructor(
    private translate: TranslateService, 
    private media: MediaMatcher, 
    private authService: AuthService, 
    private eventEmitterService: EventEmitterService,
    private cookieAuthService: CookieAuthService,
    private router: Router,
  ){
  }

  async ngOnInit(): Promise<void> {
    this.showMenu = await this.checkCurrentRoute();
    if (this.showMenu) {
      await this.authService.checkAuthStatus();
      combineLatest([
        this.authService.authStatus$,
        this.authService.currentUser$
      ]).pipe(takeUntil(this.unsubscribe$)).subscribe(([status, user]) => {
        if (status !== AuthStatus.notAuthenticated && user) {
          this.getAuthValues();
          this.me = user!;
          const [rol] = this.me?.roles;
          this.setRoleContent(rol.Id_rol.toString());
          this.access = status === AuthStatus.authenticated ? true : false;
          this.dataSource.data = this.TREE_DATA;
          this.mobileQuery = this.media.matchMedia('(max-width: 600px)');
        }
      })
    }
  }

  checkCurrentRoute() {
    return new Promise<boolean>((resolve, reject) => {
      this.router.events.pipe(take(1)).subscribe((event: any) => {
        const currentRoute = event.url;
        resolve(this.disableMenuByRoute(currentRoute))
      })
    })
  }
  
  disableMenuByRoute(currentRoute: string) {
    const splitRoute = currentRoute.split('/');
    switch (splitRoute[1]) {
      case 'password':
        return false;
      break
      default:
        return true;
    }
  }

  getAuthValues(): void {
    const company = this.cookieAuthService.getCompanyObject;
    this.companyName = company?.name!;
    this.currentCompany = company?.Id_company.toString()!;
  }

  resizeTabs() {
    this.eventEmitterService.onFirstComponentButtonClick();      
  }

  logOut() {
    this.access = false;
    this.currentCompany = null;
    this.authService.logOut();
  }

  changeLang(lang: string) {
    this.translate.use(lang).subscribe(result => {
    });
  }

  setRoleContent(id_role: string) {
    switch (id_role) {
      case '1':
        this.TREE_DATA = [
          { name: 'Home', icon: 'home', route: 'home' },
          { name: 'Select company', icon: 'autorenew', route: 'selectDB' },
          { name: 'Users', icon: 'person', route: 'user' },
          { name: 'Tributary Data', icon: 'gavel', route: 'tributary' },
          { name: 'Customers', icon: 'supervised_user_circle', route: 'customer' },
          { name: 'Providers', icon: 'supervisor_account', route: 'provider' },
          { name: 'Purchases', icon: 'add_shopping_cart', route: 'purchases' },
          { name: 'Accounts payable', icon: 'monetization_on', route: 'accountsPayable', children: [{ name: 'Reports', icon: 'list_alt', route: 'details_accounts_payable' }] },
          { name: 'Sales', icon: 'local_atm', route: 'sales', children: [{ name: 'Bill of lading', icon: 'list_alt', route: 'remittance' }] },
          { name: 'Sellers', icon: 'assignment_ind', route: 'sellers' },
          { name: 'Portfolio', icon: 'monetization_on', route: 'portfolio', children: [{ name: 'Reports', icon: 'list_alt', route: 'details_portfolio' }] },
          { name: 'Treasury', icon: 'account_balance', route: 'treasury' },
          { name: 'Inventory', icon: 'import_contacts', route: 'inventory', children: [{ name: 'Movements', icon: 'repeat', route: 'inventory_movements' }] },
          { name: 'Imports', icon: 'cloud_upload', route: 'imports' },
          { name: 'Reports', icon: 'cloud_download', route: 'reports' },
          { name: 'Analysis', icon: 'pie_chart_outline', route: 'analysis' },
        ];
      break;
      case '2':
        this.TREE_DATA = [
          { name: 'Home', icon: 'home', route: 'home' },
          { name: 'Select company', icon: 'autorenew', route: 'selectDB' },
          { name: 'Customers', icon: 'supervised_user_circle', route: 'customer' },
          { name: 'Sales', icon: 'local_atm', route: 'sales', children: [{ name: 'Bill of lading', icon: 'list_alt', route: 'remittance' }] },
          { name: 'Portfolio', icon: 'monetization_on', route: 'portfolio', children: [{ name: 'Reports', icon: 'list_alt', route: 'details_portfolio' }] },
          { name: 'Treasury', icon: 'account_balance', route: 'treasury' },
          { name: 'Analysis', icon: 'pie_chart_outline', route: 'analysis' }
        ];
      break;
      case '3':
        this.TREE_DATA = [
          { name: 'Home', icon: 'home', route: 'home' },
          { name: 'Select company', icon: 'autorenew', route: 'selectDB' },
          { name: 'Customers', icon: 'supervised_user_circle', route: 'customer' },
          { name: 'Providers', icon: 'supervisor_account', route: 'provider' },
          { name: 'Purchases', icon: 'assignment_ind', route: 'purchases' },
          { name: 'Accounts payable', icon: 'monetization_on', route: 'accountsPayable', children: [{ name: 'Reports', icon: 'list_alt', route: 'details_accounts_payable' }] },
          { name: 'Sellers', icon: 'assignment_ind', route: 'sellers' },
          { name: 'Sales', icon: 'local_atm', route: 'sales', children: [{ name: 'Bill of lading', icon: 'list_alt', route: 'remittance' }] },
          { name: 'Portfolio', icon: 'monetization_on', route: 'portfolio', children: [{ name: 'Reports', icon: 'list_alt', route: 'details_portfolio' }] },
          { name: 'Treasury', icon: 'account_balance', route: 'treasury' },
          { name: 'Inventory', icon: 'import_contacts', route: 'inventory', children: [{ name: 'Movements', icon: 'repeat', route: 'inventory_movements' }] },
          { name: 'Reports', icon: 'cloud_download', route: 'reports' },
          { name: 'Analysis', icon: 'pie_chart_outline', route: 'analysis' }
        ];
      break;
      case '4':
        this.TREE_DATA = [
          { name: 'Select company', icon: 'autorenew', route: 'selectDB' },
          { name: 'Providers', icon: 'supervisor_account', route: 'provider' },
          { name: 'Inventory', icon: 'import_contacts', route: 'inventory' },
        ];
      break;
    }
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { PurchasesRoutingModule } from './purchases-routing.module';
import { PurchasesComponent } from './purchases.component';
import { PurchaseCardModule } from '../../@shared/components/purchase-card/purchase-card.module';
import { PurchaseTabsModule } from '../../@shared/components/purchase-tabs/purchase-tabs.module';
import { StepperModule } from '../../@shared/components/stepper/stepper.module';
import { MaterialModule } from '../../material.module';
import { DialogModule } from '../../@shared/components/dialog/dialog.module';
import { ReactiveFormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { SearchModule } from '../../@shared/components/search/search.module';
import { NgxSpinnerModule } from 'ngx-spinner';
import { TranslateModule } from '@ngx-translate/core';
import { FilterInventoryToAccountingPipe } from 'src/app/@shared/pipe/pipes-csv/inventory-to-accounting.pipe';
import { FunctionsSiigoService } from 'src/app/@shared/components/services/functions-siigo.service';


@NgModule({
  declarations: [
    PurchasesComponent
  ],
  imports: [
    CommonModule,
    PurchasesRoutingModule,
    PurchaseCardModule,
    PurchaseTabsModule,
    StepperModule,
    MaterialModule,
    DialogModule,
    ReactiveFormsModule,
    MatIconModule,
    SearchModule,
    NgxSpinnerModule,
    TranslateModule
  ],
  providers: [
    FilterInventoryToAccountingPipe,
    FunctionsSiigoService
  ]
})
export class PurchasesModule { }

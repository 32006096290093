import { Component, OnInit, OnDestroy } from '@angular/core';
import { NgxQrcodeElementTypes, NgxQrcodeErrorCorrectionLevels } from '@techiediaries/ngx-qrcode';
import { IPurchaseQuery } from '../../@interfaces/purchases.interface';
import { Subject } from 'rxjs';
import { AuthService } from '../../@shared/authentication/auth.service';
import { PurchasesService } from '../purchases/purchases.service';
import { ActivatedRoute } from '@angular/router';
import { takeUntil } from 'rxjs/operators';
import { IPurchaseInvoice } from '../../@interfaces/purchaseInvoice.interface';
import { FunctionsSiigoService } from 'src/app/@shared/components/services/functions-siigo.service';
import { IStorageCompany } from 'src/app/@interfaces/company.interface';
import { CookieAuthService } from 'src/app/@shared/storage-variables/cookie-auth.service';

@Component({
  selector: 'app-details-purchases',
  templateUrl: './details-purchases.component.html',
  styleUrls: ['./details-purchases.component.css']
})
export class DetailsPurchasesComponent implements OnInit, OnDestroy {
  id_invoice: number = 0;
  subtotal: number = 0;
  taxCalc: number = 0;
  discount: number = 0;
  total: number = 0;
  withholdingTax: number = 0;
  icaTax: number = 0;
  tasaBCV: number | undefined;
  elementType = NgxQrcodeElementTypes.URL;
  correctionLevel = NgxQrcodeErrorCorrectionLevels.HIGH;
  value = '';
  purchaseInvoice = {} as IPurchaseInvoice;
  purchases: Array<IPurchaseQuery> = [];
  companyId = '';
  unsubscribe$ = new Subject();
  companyObject = {} as IStorageCompany;

  constructor(
    private router: ActivatedRoute, 
    private purchasesService: PurchasesService,
    private functionsSiigoService: FunctionsSiigoService,
    private authService: AuthService,
    private cookieAuthService: CookieAuthService
  ) { }

  async ngOnInit(): Promise<void> {
    await this.authService.checkAuthStatus();
    this.getAuthValues();
    this.setDocument();
  }

  getAuthValues() {
    this.companyObject = this.cookieAuthService.getCompanyObject!;
    this.companyId = this.companyObject.Id_company!.toString();
  }

  setDocument(){
    this.router.params.pipe(takeUntil(this.unsubscribe$)).subscribe((params) => {
      this.id_invoice = params.id_invoice;
      this.value = 'https://v1-insight.com/#/details_purchase' + this.id_invoice;
      this.purchasesService.getPurchaseInvoice(this.id_invoice).pipe(takeUntil(this.unsubscribe$)).subscribe((data) => {
        this.purchaseInvoice = data;
        this.withholdingTax = this.purchaseInvoice.withholdingTax!;
        this.icaTax = this.purchaseInvoice.icaTax!;
        this.purchases = this.purchaseInvoice.purchases;
        this.total = this.purchases[0].total;
        this.purchases.forEach(element => {
          let disco = 0;
          let subtotal = 0;
          let discountedSubtotal = 0;
          let calcTax = 0
          let tax = 0;
          const contributorID = parseInt(this.purchaseInvoice.provider[0].contributorType!.id_contributor.toString());
          if (this.purchaseInvoice.tax_incl === 'false') {
            disco = (element.discount / 100) * (element.quantity * element.price);
            if (element.articulo[0].tax![0].value === 0) {
              tax = this.functionsSiigoService.getContributorTax(contributorID, this.purchaseInvoice.tax / 100);
              subtotal = element.quantity * element.price;
              discountedSubtotal = subtotal - disco;
              calcTax = discountedSubtotal * tax;
            }else{
              tax = this.functionsSiigoService.getContributorTax(contributorID, element.articulo[0].tax![0].value);
              subtotal = element.quantity * element.price;
              discountedSubtotal = subtotal - disco;
              calcTax = discountedSubtotal * tax;
            }
          }else if(this.purchaseInvoice.tax_incl === 'true'){
            tax = this.functionsSiigoService.getContributorTax(contributorID, element.articulo[0].tax![0].value);
            subtotal = element.quantity * (element.price / (tax + 1));
            disco = (element.discount / 100) * subtotal;
            subtotal = subtotal - disco;
            calcTax = subtotal * tax;
          }
          this.taxCalc += calcTax;
          this.subtotal += subtotal;
          this.discount += disco;
        })
      })
    })
  }

  print() {
    window.print();
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}

<mat-dialog-content>
  <div class="row justify-content-md-center">
    <div class="col-md-auto">
      <form [formGroup]="dates">
        <mat-form-field class="example-full-width">
          <mat-label>{{ "Year" }}</mat-label>
          <input
            formControlName="year"
            type="number"
            min="1999"
            max="2999"
            matInput
          />
        </mat-form-field>
      </form>
    </div>
    <div class="col-md-auto">
      <a (click)="update()"
        ><button mat-raised-button class="Warn">
          {{ "Process" | translate }}
        </button></a
      >
    </div>
    <div class="col-md-auto">
      <a (click)="exportExcel()"
        ><button mat-raised-button class="Warn">
          {{ "Export" | translate }}
        </button></a
      >
    </div>
    <div class="col col-lg-2">
      <a (click)="closeDialog()"
        ><button mat-raised-button class="Warn">
          {{ "Close" | translate }}
        </button></a
      >
    </div>
  </div>
  <div hidden>
    <table class="table table-striped" id="tabSiigo">
      <thead>
        <tr>
          <td hidden colspan="122">{{companyName.toLocaleUpperCase()}}</td>
        </tr>
        <tr>
          <td hidden colspan="122">{{'MODEL FOR THE IMPORT OF ACCOUNTING TRANSACTIONS' | translate}}</td>
        </tr>
        <tr>
          <td hidden colspan="122">{{dateMainFile}}</td>
        </tr>
        <tr>
          <td hidden colspan="122"></td>
        </tr>
        <tr>
          <th>{{ "VOUCHER TYPE" | translate }} {{ "(REQUIRED)" | translate }}</th> <!-- A -->
          <th>{{ "VOUCHER CODE" | translate }}&nbsp;&nbsp;{{ "(REQUIRED)" | translate }}</th> <!-- B -->
          <th>{{ "DOCUMENT NUMBER" | translate }}</th> <!-- C -->
          <th>{{ "LEDGER ACCOUNT" | translate }}&nbsp;&nbsp;&nbsp;{{ "(REQUIRED)" | translate }}</th> <!-- D -->
          <th>{{ "DEBIT OR CREDIT" | translate }} {{ "(REQUIRED)" | translate }}</th> <!-- E -->
          <th>{{ "SEQUENCE VALUE" | translate }}&nbsp;&nbsp;&nbsp;{{ "(REQUIRED)" | translate }}</th> <!-- F -->
          <th>{{ "DOCUMENT YEAR" | translate }}</th> <!-- G -->
          <th>{{ "DOCUMENT MONTH" | translate }}</th> <!-- H -->
          <th>{{ "DOCUMENT DAY" | translate }}</th> <!-- I -->
          <th>{{ "VENDOR CODE" | translate }}</th> <!-- J -->
          <th hidden>{{ "CITY CODE" | translate }}</th> <!-- K -->
          <th hidden>{{ "ZONE CODE" | translate }}</th> <!-- L -->
          <th>{{ "SEQUENCE" | translate }}</th> <!-- M -->
          <th>{{ "COST CENTER" | translate }}</th> <!-- N -->
          <th hidden>{{ "COST SUBCENTER" | translate }}</th> <!-- O -->
          <th>{{ "NIT" | translate }}</th> <!-- P -->
          <th>{{ "BRANCH OFFICE" | translate }}</th> <!-- Q -->
          <th>{{ "SEQUENCE DESCRIPTION" | translate }}</th> <!-- R -->
          <th>{{ "CHECK NUMBER" | translate }}</th> <!-- S -->
          <th>{{ "VOIDED VOUCHER" | translate }}</th> <!-- T -->
          <th>{{ "RETURN REASON CODE" | translate }}</th> <!-- U -->
          <th>{{ "PAYMENT METHOD CODE" | translate }}</th> <!-- V -->
          <th hidden>{{ "VALUE OF CHARGE 1 OF THE SEQUENCE" | translate }}</th> <!-- W -->
          <th hidden>{{ "VALUE OF CHARGE 2 OF THE SEQUENCE" | translate }}</th> <!-- X -->
          <th hidden>{{ "SEQUENCE DISCOUNT 1 VALUE" | translate }}</th> <!-- Y -->
          <th hidden>{{ "SEQUENCE DISCOUNT 2 VALUE" | translate }}</th> <!-- Z -->
          <th hidden>{{ "SEQUENCE DISCOUNT 3 VALUE" | translate }}</th> <!-- AA -->
          <th hidden>{{ "ELECTRONIC INVOICE TO DEBIT/CREDIT" | translate }}</th> <!-- AB -->
          <th hidden>
            {{ "ELECTRONIC INVOICE NUMBER TO DEBIT/CREDIT" | translate }}
          </th> <!-- AC -->
          <th hidden>{{ "REFERENCE ORDER PREFIX" | translate }}</th> <!-- AD -->
          <th hidden>{{ "CONSECUTIVE ORDER" | translate }}</th> <!-- AE -->
          <th hidden>{{ "DELIVERY ORDER PREFIX" | translate }}</th> <!-- AF -->
          <th hidden>{{ "DELIVERY ORDER NUMBER" | translate }}</th> <!-- AG -->
          <th hidden>{{ "YEAR DELIVERY ORDER DATE" | translate }}</th> <!-- AH -->
          <th hidden>{{ "MONTH DELIVERY ORDER DATE" | translate }}</th> <!-- AI -->
          <th hidden>{{ "DAY DELIVERY ORDER DATE" | translate }}</th> <!-- AJ -->
          <th hidden>{{ "INCOME FOR THIRD PARTIES" | translate }}</th> <!-- AK -->
          <th>{{ "DOCUMENT UPDATE DATE" | translate }}</th> <!-- AL -->
          <th hidden>{{ "DOCUMENT UPDATE TIME" | translate }}</th> <!-- AM -->
          <th hidden>{{ "DELIVERY SCHEDULE PREFIX2" | translate }}</th> <!-- AN -->
          <th hidden>{{ "DELIVERY SCHEDULE NUMBER2" | translate }}</th> <!-- AO -->
          <th hidden>{{ "YEAR DELIVERY SCHEDULE DATE2" | translate }}</th> <!-- AP -->
          <th hidden>{{ "MONTH DELIVERY SCHEDULE DATE2" | translate }}</th> <!-- AQ -->
          <th hidden>{{ "DAY DELIVERY SCHEDULE DATE2" | translate }}</th> <!-- AR -->
          <th hidden>{{ "DELIVERY SCHEDULE PREFIX3" | translate }}</th> <!-- AS -->
          <th hidden>{{ "DELIVERY SCHEDULE NUMBER3" | translate }}</th> <!-- AT -->
          <th hidden>{{ "YEAR DELIVERY SCHEDULE DATE3" | translate }}</th> <!-- AU -->
          <th hidden>{{ "MONTH DELIVERY SCHEDULE DATE3" | translate }}</th> <!-- AV -->
          <th hidden>{{ "DAY DELIVERY SCHEDULE DATE3" | translate }}</th> <!-- AW -->
          <th hidden>{{ "DELIVERY SCHEDULE PREFIX4" | translate }}</th> <!-- AX -->
          <th hidden>{{ "DELIVERY SCHEDULE NUMBER4" | translate }}</th> <!-- AY -->
          <th hidden>{{ "YEAR DELIVERY SCHEDULE DATE4" | translate }}</th> <!-- AZ -->
          <th hidden>{{ "MONTH DELIVERY SCHEDULE DATE4" | translate }}</th> <!-- BA -->
          <th hidden>{{ "DAY DELIVERY SCHEDULE DATE4" | translate }}</th> <!-- BB -->
          <th hidden>{{ "DELIVERY SCHEDULE PREFIX5" | translate }}</th> <!-- BC -->
          <th hidden>{{ "DELIVERY SCHEDULE NUMBER5" | translate }}</th> <!-- BD -->
          <th hidden>{{ "YEAR DELIVERY SCHEDULE DATE5" | translate }}</th> <!-- BE -->
          <th hidden>{{ "MONTH DELIVERY SCHEDULE DATE5" | translate }}</th> <!-- BF -->
          <th hidden>{{ "DAY DELIVERY SCHEDULE DATE5" | translate }}</th> <!-- BG -->
          <th>{{ "SEQUENCE PERCENTAGE IVA" | translate }}</th> <!-- BH -->
          <th>{{ "SEQUENCE VALUE IVA" | translate }}</th> <!-- BI -->
          <th hidden>{{ "WITHHOLDING BASIS" | translate }}</th> <!-- BJ -->
          <th hidden>
            {{ "BASIS FOR ACCOUNTS MARKED AS RETAINED" | translate }}
          </th> <!-- BK -->
          <th>{{ "TAXABLE OR EXEMPT SEQUENCE" | translate }}</th> <!-- BL -->
          <th hidden>{{ "PERCENTAGE AIU" | translate }}</th> <!-- BM -->
          <th hidden>{{ "BASE IVA AIU" | translate }}</th> <!-- BN -->
          <th hidden>
            {{ "TOTAL VALUE IMPOCONSUMO OF THE SEQUENCE" | translate }}
          </th> <!-- BO -->
          <th>{{ "PRODUCT LINE" | translate }}</th> <!-- BP -->
          <th>{{ "PRODUCT GROUP" | translate }}</th> <!-- BQ -->
          <th>{{ "PRODUCT CODE" | translate }}</th> <!-- BR -->
          <th>{{ "PRODUCT QUANTITY" | translate }}</th> <!-- BS -->
          <th hidden>{{ "QUANTITY TWO" | translate }}</th> <!-- BT -->
          <th>{{ "WAREHOUSE CODE" | translate }}</th> <!-- BU -->
          <th hidden>{{ "LOCATION CODE" | translate }}</th> <!-- BV -->
          <th hidden>{{ "CONVERSION FACTOR AMOUNT" | translate }}</th> <!-- BW -->
          <th hidden>{{ "CONVERSION FACTOR OPERATOR" | translate }}</th> <!-- BX -->
          <th hidden>{{ "CONVERSION FACTOR VALUE" | translate }}</th> <!-- BY -->
          <th hidden>{{ "ASSET GROUP" | translate }}</th> <!-- BZ -->
          <th hidden>{{ "ACTIVE CODE" | translate }}</th> <!-- CA -->
          <th hidden>{{ "ADDITION OR IMPROVEMENT" | translate }}</th> <!-- CB -->
          <th hidden>
            {{
              "ADDITIONAL TIMES TO DEPRECIATE FOR ADDITION OR IMPROVEMENT"
                | translate
            }}
          </th> <!-- CC -->
          <th hidden>{{ "TIMES TO DEPRECIATE NIF" | translate }}</th> <!-- CD --> 
          <th hidden>{{ "SUPPLIER DOCUMENT NUMBER" | translate }}</th> <!-- CE -->
          <th hidden>{{ "VENDOR DOCUMENT PREFIX" | translate }}</th> <!-- CF -->
          <th hidden>{{ "SUPPLIER DOCUMENT YEAR" | translate }}</th> <!-- CG -->
          <th hidden>{{ "SUPPLIER DOCUMENT MONTH" | translate }}</th> <!-- CH -->
          <th hidden>{{ "SUPPLIER DOCUMENT DAY" | translate }}</th> <!-- CI -->
          <th hidden>{{ "ORDER DOCUMENT TYPE" | translate }}</th> <!-- CJ -->
          <th hidden>{{ "ORDER PROOF CODE" | translate }}</th> <!-- CK -->
          <th hidden>{{ "ORDER RECEIPT NUMBER" | translate }}</th> <!-- CL -->
          <th hidden>{{ "ORDER SEQUENCE" | translate }}</th> <!-- CM -->
          <th hidden>{{ "TYPE OF COIN ELABORATION" | translate }}</th> <!-- CN -->
          <th>{{ "TYPE AND CROSS CHECK" | translate }}</th> <!-- CO -->
          <th>{{ "CROSSING DOCUMENT NUMBER" | translate }}</th> <!-- CP -->
          <th>{{ "EXPIRATION NUMBER" | translate }}</th> <!-- CQ -->
          <th>{{ "CROSSING DOCUMENT EXPIRATION YEAR" | translate }}</th> <!-- CR -->
          <th>{{ "CROSSING DOCUMENT EXPIRATION MONTH" | translate }}</th> <!-- CS -->
          <th>{{ "CROSSING DOCUMENT EXPIRATION DAY" | translate }}</th> <!-- CT -->
          <th hidden>
            {{ "BOX NUMBER ASSOCIATED WITH THE VOUCHER" | translate }}
          </th> <!-- CU -->
          <th hidden>{{ "PAYROLL CONCEPT" | translate }}</th> <!-- CV -->
          <th hidden>{{ "QUANTITY (DAYS/HOURS. ETC)" | translate }}</th> <!-- CW -->
          <th hidden>{{ "PAYMENT TYPE" | translate }}</th> <!-- CX -->
          <th hidden>{{ "DESCRIPTION OF COMMENTS" | translate }}</th> <!-- CY -->
          <th hidden>{{ "LONG DESCRIPTION" | translate }}</th> <!-- CZ -->
          <th hidden>{{ "INCONTERM" | translate }}</th> <!-- DA -->
          <th hidden>{{ "EXPORT DESCRIPTION" | translate }}</th> <!-- DB -->
          <th hidden>{{ "MEAN OF TRANSPORTATION" | translate }}</th> <!-- DC -->
          <th hidden>{{ "COUNTRY OF ORIGIN" | translate }}</th> <!-- DD -->
          <th hidden>{{ "CITY OF ORIGIN" | translate }}</th> <!-- DE -->
          <th hidden>{{ "DESTINATION COUNTRY" | translate }}</th> <!-- DF -->
          <th hidden>{{ "DESTINATION CITY" | translate }}</th> <!-- DG -->
          <th hidden>{{ "NET WEIGHT" | translate }}</th> <!-- DH -->
          <th hidden>{{ "GROSS WEIGHT" | translate }}</th> <!-- DI -->
          <th hidden>{{ "NET UNIT OF MEASURE" | translate }}</th> <!-- DJ -->
          <th hidden>{{ "GROSS UNIT OF MEASURE" | translate }}</th> <!-- DK -->
          <th hidden>{{ "BLOCK BILLING CONCEPT" | translate }}</th> <!-- DL -->
          <th hidden>
            {{ "ESTABLISHED DATA (L=LOCAL O=OFFICE)" | translate }}
          </th> <!-- DM -->
          <th hidden>{{ "ESTABLISHMENT NUMBER" | translate }}</th> <!-- DN -->
          <th hidden>{{ "BILLING CONCEPT" | translate }}</th> <!-- DO -->
          <th hidden>{{ "GROUP OF THE PROPERTY" | translate }}</th> <!-- DP -->
          <th hidden>{{ "SUBGROUP OF THE PROPERTY" | translate }}</th> <!-- DQ -->
          <th hidden>{{ "NUMBER OF THE PROPERTY" | translate }}</th> <!-- DR -->
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let list of lista; let i = index">
          <td>
            {{ list.voucherType }} <!-- A -->
          </td>
          <td>
            {{ list.voucherCode }} <!-- B -->
          </td>
          <td>
            {{ list.documentNumber }} <!-- C -->
          </td>
          <td>
            {{ list.accountingAccount }} <!-- D -->
          </td>
          <td>
            {{ list.debitCredit }} <!-- E -->
          </td>
          <td>
            {{ list.total.toFixed(2) }} <!-- F -->
          </td>
          <td>
            {{ list.documentYear }} <!-- G -->
          </td>
          <td>
            {{ list.documentMonth }} <!-- H -->
          </td>
          <td>
            {{ list.documentDay }} <!-- I -->
          </td>
          <td>
            {{ list.sellerId }} <!-- J -->
          </td>
          <td hidden>
            {{ "" }} <!-- K -->
          </td>
          <td hidden>
            {{ "" }} <!-- L -->
          </td>
          <td>
            {{ i + 1 }} <!-- M -->
          </td>
          <td>
            {{ "1" }} <!-- N -->
          </td>
          <td hidden>
            {{ "" }} <!-- O -->
          </td>
          <td>
            {{ list.nit }} <!-- P -->
          </td>
          <td>
            {{ 0 }} <!-- Q -->
          </td>
          <td>
            {{ list.description }} <!-- R -->
          </td>
          <td>
            {{ 0 }} <!-- S -->
          </td>
          <td>
            {{ "N" }} <!-- T -->
          </td>
          <td>
            {{ 0 }} <!-- U -->
          </td>
          <td>
            {{ list.paymentMethod }} <!-- V -->
          </td>
          <td hidden>
            {{ "" }} <!-- W -->
          </td>
          <td hidden>
            {{ "" }} <!-- X -->
          </td>
          <td hidden>
            {{ "" }} <!-- Y -->
          </td>
          <td hidden>
            {{ "" }} <!-- Z -->
          </td>
          <td hidden>
            {{ "" }} <!-- AA -->
          </td>
          <td hidden>
            {{ "" }} <!-- AB -->
          </td>
          <td hidden>
            {{ "" }} <!-- AC -->
          </td>
          <td hidden> 
            {{ "" }} <!-- AD -->
          </td>
          <td hidden>
            {{ "" }} <!-- AE -->
          </td>
          <td hidden>
            {{ "" }} <!-- AF -->
          </td>
          <td hidden>
            {{ "" }} <!-- AG -->
          </td>
          <td hidden>
            {{ "" }} <!-- AH -->
          </td>
          <td hidden>
            {{ "" }} <!-- AI -->
          </td>
          <td hidden>
            {{ "" }} <!-- AJ -->
          </td>
          <td hidden>
            {{ "" }} <!-- AK -->
          </td>
          <td>
            {{ list.dateToDay }} <!-- AL -->
          </td>
          <td hidden>
            {{ "" }} <!-- AM -->
          </td>
          <td hidden>
            {{ "" }} <!-- AN -->
          </td>
          <td hidden>
            {{ "" }} <!-- AO -->
          </td>
          <td hidden>
            {{ "" }} <!-- AP -->
          </td>
          <td hidden>
            {{ "" }} <!-- AQ -->
          </td>
          <td hidden>
            {{ "" }} <!-- AR -->
          </td>
          <td hidden>
            {{ "" }} <!-- AS -->
          </td>
          <td hidden>
            {{ "" }} <!-- AT -->
          </td>
          <td hidden>
            {{ "" }} <!-- AU -->
          </td>
          <td hidden>
            {{ "" }} <!-- AV -->
          </td>
          <td hidden>
            {{ "" }} <!-- AW -->
          </td>
          <td hidden>
            {{ "" }} <!-- AX -->
          </td>
          <td hidden>
            {{ "" }} <!-- AY -->
          </td>
          <td hidden>
            {{ "" }} <!-- AZ -->
          </td>
          <td hidden>
            {{ "" }} <!-- BA -->
          </td>
          <td hidden>
            {{ "" }} <!-- BB -->
          </td>
          <td hidden>
            {{ "" }} <!-- BC -->
          </td>
          <td hidden>
            {{ "" }} <!-- BD -->
          </td>
          <td hidden>
            {{ "" }} <!-- BE -->
          </td>
          <td hidden>
            {{ "" }} <!-- BF -->
          </td>
          <td hidden>
            {{ "" }} <!-- BG -->
          </td>
          <td>
            {{ list.taxPercent }} <!-- BH -->
          </td>
          <td>
            {{ list.taxValue.toFixed(2) }} <!-- BI -->
          </td>
          <td hidden>
            {{ list.withholdingBase.toFixed(2) }} <!-- BJ -->
          </td>
          <td hidden>
            {{ "" }} <!-- BK -->
          </td>
          <td>
            {{ list.taxed }} <!-- BL -->
          </td>
          <td hidden>
            {{ "" }} <!-- BM -->
          </td>
          <td hidden>
            {{ "" }} <!-- BN -->
          </td>
          <td hidden>
            {{ "" }} <!-- BO -->
          </td>
          <td>
            {{ list.productLine }} <!-- BP -->
          </td>
          <td>
            {{ list.productGroup }} <!-- BQ -->
          </td>
          <td>
            {{ list.productCode }} <!-- BR -->
          </td>
          <td>
            {{ list.quantity }} <!-- BS -->
          </td>
          <td hidden>
            {{ "" }} <!-- BT -->
          </td>
          <td>
            {{ list.warehouseCode }} <!-- BU -->
          </td>
          <td hidden>
            {{ "" }} <!-- BV -->
          </td>
          <td hidden>
            {{ "" }} <!-- BW -->
          </td>
          <td hidden>
            {{ "" }} <!-- BX -->
          </td>
          <td hidden>
            {{ "" }} <!-- BY -->
          </td>
          <td hidden>
            {{ "" }} <!-- BZ -->
          </td>
          <td hidden>
            {{ "" }} <!-- CA -->
          </td>
          <td hidden>
            {{ "" }} <!-- CB -->
          </td>
          <td hidden>
            {{ "" }} <!-- CC -->
          </td>
          <td hidden>
            {{ "" }} <!-- CD -->
          </td>
          <td hidden>
            {{ list.providerObject.providerDocument }} <!-- CE -->
          </td>
          <td hidden>
            {{ list.providerObject.providerPrefix }} <!-- CF -->
          </td>
          <td hidden>
            {{ list.providerObject.providerDocumentYear }} <!-- CG -->
          </td>
          <td hidden>
            {{ list.providerObject.providerDocumentMonth }} <!-- CH -->
          </td>
          <td hidden>
            {{ list.providerObject.providerDocumentDay }} <!-- CI -->
          </td>
          <td hidden>
            {{ "" }} <!-- CJ -->
          </td>
          <td hidden>
            {{ "" }} <!-- CK -->
          </td>
          <td hidden>
            {{ "" }} <!-- CL -->
          </td>
          <td hidden>
            {{ "" }} <!-- CM -->
          </td>
          <td hidden>
            {{ "" }} <!-- CN -->
          </td>
          <td>
            {{ list.voucherTypeCross }} <!-- CO -->
          </td>
          <td>
            {{ list.documentNumberCross }} <!-- CP -->
          </td> 
          <td>
            {{ list.expirationNumber }} <!-- CQ -->
          </td>
          <td>
            {{ list.expirationYear }} <!-- CR -->
          </td>
          <td>
            {{ list.expirationMonth }} <!-- CS -->
          </td>
          <td>
            {{ list.expirationDay }} <!-- CT -->
          </td>
          <td hidden>
            {{ "" }} <!-- CU -->
          </td>
          <td hidden>
            {{ "" }} <!-- CV -->
          </td>
          <td hidden>
            {{ "" }} <!-- CW -->
          </td>
          <td hidden>
            {{ "" }} <!-- CX -->
          </td>
          <td hidden>
            {{ "" }} <!-- CY -->
          </td>
          <td hidden>
            {{ "" }} <!-- CZ -->
          </td>
          <td hidden>
            {{ "" }} <!-- DA -->
          </td>
          <td hidden>
            {{ list.exportDescription }} <!-- DB -->
          </td>
          <td hidden>
            {{ "" }} <!-- DC -->
          </td>
          <td hidden>
            {{ "" }} <!-- DD -->
          </td>
          <td hidden>
            {{ "" }} <!-- DE -->
          </td>
          <td hidden>
            {{ "" }} <!-- DF -->
          </td>
          <td hidden>
            {{ "" }} <!-- DG -->
          </td>
          <td hidden>
            {{ "" }} <!-- DH -->
          </td>
          <td hidden>
            {{ "" }} <!-- DI -->
          </td>
          <td hidden>
            {{ "" }} <!-- DJ -->
          </td>
          <td hidden>
            {{ "" }} <!-- DK -->
          </td>
          <td hidden>
            {{ "" }} <!-- DL -->
          </td>
          <td hidden>
            {{ "" }} <!-- DM -->
          </td>
          <td hidden>
            {{ "" }} <!-- DN -->
          </td>
          <td hidden>
            {{ "" }} <!-- DO -->
          </td>
          <td hidden>
            {{ "" }} <!-- DP -->
          </td>
          <td hidden>
            {{ "" }} <!-- DQ -->
          </td>
          <td hidden>
            {{ "" }} <!-- DR -->
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</mat-dialog-content>
<ngx-spinner
  type="ball-scale-multiple"
  template="<img src='../../../../../../assets/gif/V1-Insight-Icon-for-gif-2.gif' />"
  bdColor="rgba(51,51,51,0.8)"
>
  <p style="font-size: 20px; color: #ffffff">
    {{ "This may be take a few minutes, please wait..." | translate }}
  </p>
</ngx-spinner>


import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { take } from 'rxjs/operators';
import { QuotationsService } from 'src/app/@pages/quotations/quotations.service';
import { BillyService } from '../../../@pages/billyService/billy.service';
import { IQuota, IQuotation } from '../../../@interfaces/quotation.interface';
import { BillyPayer, IBillyInvoice, IBillyToGraphQ, IItemsInvoice } from 'src/app/@interfaces/Billy/billy.interface';
import { DialogComponent } from '../dialog/dialog.component';
import { IArticles } from 'src/app/@interfaces/article.interface';
import { AlertsService } from '../services/alerts.service';
import { ArticleCostService } from '../services/article-cost.service';
import { HttpErrorResponse } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class BillyInvoiceService {
  quota = {} as IQuota;
  billyInvoice = {} as IBillyInvoice;
  saveBillyIds?: IBillyToGraphQ;

  constructor(
    private spinnerService: NgxSpinnerService, 
    private translate: TranslateService, 
    private quotationService: QuotationsService, 
    public dialog: MatDialog, 
    private billyService: BillyService,
    private alertsService: AlertsService,
    private articleCostService: ArticleCostService
) { }

  sendDian(quotation: IQuotation) {
    if (quotation.billyInvoice![0].invoice_number !== "PENDING") {
        this.spinnerService.show();
        this.billyService.getInvoice(quotation.billyInvoice![0].id_billy).subscribe((data) => {
            this.spinnerService.hide();
            this.alertsService.showErrorAlert(
                this.translate.instant('This invoice has already been sent') + ', ' + this.translate.instant('status') + ': ',
                this.translate.instant(data.data.attributes.status),
                `<a href=${data.data.attributes.pdfUrl} target="_blank">${this.translate.instant('View invoice')}</a>`
            )
        }, ((error) => {
            this.spinnerService.hide();
            this.alertsService.showWarningAlert(
                'This invoice does not have an id associated with the electronic supplier, please refer to the number on your supplier`s website.'
            )
        }))
        return; 
    }
    this.alertsService.showWarningAlert(
        'You are about to send this invoice to DIAN, are you sure?.'
    ).then((result) => {
      if (result.isConfirmed) {
            this.dialog.open(DialogComponent, {
                data: { currency: true }
            })
            .afterClosed().subscribe((dialogResult) => {
                if (dialogResult === undefined || dialogResult === null) {
                    this.dialog.closeAll();
                    return;
                }
                else if(dialogResult === ''){
                    this.alertsService.showWarningAlert('No currency selected.');
                    return;
                }else {
                    this.spinnerService.show();
                    this.getQuotation(quotation.id_factura!).then((quota) => {
                        let document = '';
                        quota.cliente.forEach(item => {
                            document = item.doc_nit;
                        })
                        this.searchPayer(document).then((idPayer) => {
                            this.setInvoiceBilly(quota, idPayer, dialogResult.toString()).then((billyInvoice) => {
                                this.sendRequest(billyInvoice).then((saveBillyIds) => {
                                    this.saveBillyResponse(saveBillyIds, quota.id_factura!).then((completed) => {
                                        if (completed) {
                                            this.updateArticleQuantities(quotation);
                                            this.alertsService.refresh('/card', '/sales')
                                        }
                                    }).catch((error) => {
                                        this.billyErrorHandler(error);
                                    })
                                }).catch((error) => {
                                    this.billyErrorHandler(error);
                                });
                            }).catch((error) => {
                                this.billyErrorHandler(error);
                            });
                        }).catch((error) => {
                            this.billyErrorHandler(error);
                        });
                    }).catch((error) => {
                        this.billyErrorHandler(error);
                    });
                }
            })
        }
    })
  }

  billyErrorHandler(error: HttpErrorResponse) {
    this.spinnerService.hide();
    this.billyService.handleError(error);
  }

  searchPayer = async (document: string) => {
    return new Promise<string>((resolve ,reject) => {
        this.billyService.getPayer(document).subscribe((pagador) => {
            let payer = {} as BillyPayer;
            let idPayer = '';
            payer = pagador;
            payer.data.forEach((ele) =>{
            idPayer = ele.id;
            })
            resolve(idPayer)
        }, (error) => {
            if (error) {
                reject(new Error('Failed: looking for the payer'))
            }
        });
    })
  }

  getQuotation = async (id: number) => { 
    return new Promise<IQuota>((resolve, reject) => {
        this.quotationService.getQuotation(id).subscribe((items) => {
            const quot: IQuota = items;
            resolve(quot)
        });
    })
  }

  sendRequest = async (billyInvoice: IBillyInvoice) => {
    return new Promise<IBillyToGraphQ> ((resolve, reject) => {
        this.billyService.postInvoice(billyInvoice).pipe(take(1)).subscribe((element) => {
            resolve(element)
        }, (error) => {
            if (error) {
                reject(new Error('Failed: sending billy'));
            }
        });
    })
  }

  saveBillyResponse = async (saveBillyIds: IBillyToGraphQ, id: number) => {
    return new Promise<boolean> ((resolve, reject) => {
        const { data } = saveBillyIds!;
        const { attributes } = data;                
        this.quotationService.setBillyInvoice(
            id.toString(), data.id ? data.id : "TRIED TO SEND", attributes.invoiceNumber ? attributes.invoiceNumber : "PENDING",
            new Date().toISOString()
        ).subscribe((result) => {
            if (result) {
                this.spinnerService.hide();
                this.alertsService.showSuccessfullAlert(
                    'Invoice send to Billy with id',
                    'Good',
                    attributes.invoiceNumber
                ).then((result) => {
                    if (result.isConfirmed) {
                        resolve(result.isConfirmed)
                    }
                })
            }
        }, (error) => {
            if (error) {
                reject(new Error('Failed: saving billy reply'))
            }
        });
    })
  }

  async setInvoiceBilly(quota: IQuota, payer: string, currencies: string ){
    let invoice!: IBillyInvoice;
    let items: Array<IItemsInvoice> = [];
    let month = new Date(quota.dueDate).getMonth() + 1;
    let day = new Date(quota.dueDate).getDate();
    let year = new Date(quota.dueDate).getFullYear();
    let salesLength = quota.venta.length;
    let objItems: IItemsInvoice;
    let price = 0;
    let arrItems: IItemsInvoice[] = [];
    const observations = quota.observations
    const setInvoice = () => {
      return new Promise<IItemsInvoice[]> ((resolve, reject) => {
        quota.venta.forEach((item, index) => {
            let article: IArticles = item.articulo[0];
            if(quota.tax_incl === 'true'){
              if (item.descuento !== 0) {
                const priceWithoutTax = item.precio / (article.tax![0].value + 1);
                const descuento = ((item.descuento / 100) * priceWithoutTax);
                const finalPrice = priceWithoutTax - descuento;
                price = finalPrice;
              }else{
                price = item.precio / (article.tax![0].value + 1);
              };
            }else if(quota.tax_incl === 'false'){
              if (item.descuento !== 0) {
                let descuento = (item.descuento / 100) * item.precio;
                price = (item.precio - descuento);
              }else{
                price = item.precio;
              };
            }
            objItems = {
              "type": "item",
              "id": article.codigo!,
              "attributes":{
                "quantity": item.cantidad,
                "unitaryValue": price,
              }
            };
            items.push(objItems);
            if (items.length === salesLength) {
              resolve(items);
              if (items.length < salesLength) {
                reject(new Error('Failed: setting up the invoice'))
              }
            }
        });
      })
    }
    arrItems = await setInvoice();
    invoice = {
      "data": {
        "type": "invoice",
        "attributes":{
          "credit": false,
          "currency": currencies,
          "expiration": day + '/' + month + '/' + year,
          "items": arrItems,
          "payerId": payer,
          "usePayerAddress": true,
          "purchaseOrder": "",
           "observations": observations?observations:""
        }
      }
    }
    return invoice;
  }

  updateArticleQuantities(quotation: any) {
    const invoice = quotation as IQuota;
    invoice.venta.forEach((element) => {
        this.articleCostService.updateAvaliableQuantity(
            element.articulo![0].id_articulo,
            element.almacen![0].id_almacen,
            element.cantidad,
            2
        )
    });
  }
}

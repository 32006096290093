import { Component, Input, OnInit, OnDestroy } from "@angular/core";
import {
  UntypedFormControl,
  UntypedFormGroup,
  UntypedFormBuilder,
  Validators,
  UntypedFormArray,
} from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import { IArticles } from "src/app/@interfaces/article.interface";
import { ICustomer } from "src/app/@interfaces/customer.interface";
import { QuotationsService } from "src/app/@pages/quotations/quotations.service";
import { WarehouseService } from "src/app/@pages/warehouse/warehouse.service";
import { IWarehouse } from "src/app/@interfaces/warehouse.interface";
import Swal from "sweetalert2";
import { IVentaQuery } from "src/app/@interfaces/venta.interface";
import { NgxSpinnerService } from "ngx-spinner";
import { CustomerService } from "src/app/@pages/customer/customer.service";
import { DialogComponent } from "../../dialog.component";
import { ISeller } from "src/app/@interfaces/seller.interface";
import { SellersService } from "src/app/@pages/sellers/sellers.service";
import { TranslateService } from "@ngx-translate/core";
import { takeUntil } from "rxjs/operators";
import { Subject } from "rxjs";
import { ModifySalesService } from "./modify-sale.service";
import { IQuota } from "src/app/@interfaces/quotation.interface";
import { AlertsService } from "../../../services/alerts.service";
import { ErrorsService } from "../../../services/errors.service";
import { environment } from "src/environments/environment";
import { CookieAuthService } from "src/app/@shared/storage-variables/cookie-auth.service";

@Component({
  selector: "app-modify",
  templateUrl: "./modify.component.html",
  styleUrls: ["./modify.component.css"],
})
export class ModifyComponent implements OnInit, OnDestroy {
  @Input() id_factura: number = 0;
  sellerList: Array<ISeller> = [];
  totalQuot: number = 0;
  newArticle: UntypedFormControl = this.fb.control("", Validators.required);
  newCustomer: UntypedFormControl = this.fb.control("", Validators.required);
  registerForm: UntypedFormGroup = this.fb.group({
    itemRows: this.fb.array(
      [
        this.fb.group({
          ids: [],
          article: [, Validators.required],
          quantity: [, Validators.required],
          price: [, Validators.required],
          discount: [0, Validators.required],
          subtotal: [0],
          warehouse: ["", Validators.required],
        }),
      ],
      Validators.required
    ),
    tax_incl: false,
    tax: [, Validators.required],
    date: [, Validators.required],
    dueDate: [, Validators.required],
    seller: ["", [Validators.required]],
  });
  get rowsArr() {
    return this.registerForm.get("itemRows") as UntypedFormArray;
  }
  ventaIds: number[] = [];
  ventaIdsDelete: number[] = [];
  QuotationToModify = {} as IQuota;
  dateToday = new Date();
  Sales: Array<IVentaQuery> = [];
  ArticleList: Array<IArticles> = [];
  CustomersList: Array<ICustomer> = [];
  WarehouseList: Array<IWarehouse> = [];
  companyId = '';
  user = '';
  rol = '';
  articlesObj: Array<IArticles> = [];
  unsubscribe$ = new Subject();
  showDevelopWarning: boolean = false;

  constructor(
    private seller: SellersService,
    private spinnerService: NgxSpinnerService,
    public dialog: MatDialog,
    private fb: UntypedFormBuilder,
    public quotation: QuotationsService,
    public warehouseS: WarehouseService,
    private customers: CustomerService,
    private translate: TranslateService,
    private modifySalesService: ModifySalesService,
    private alertsService: AlertsService,
    private errorsService: ErrorsService,
    private cookieAuthService: CookieAuthService
  ) {}

  async ngOnInit(): Promise<void> {
    this.spinner();
    this.getAuthValues();
    this.QuotationToModify = await this.getQuotation();
    this.setQuotation(this.QuotationToModify);
    this.WarehouseList = await this.getWarehouses();
    this.getInputValues();
    this.setValuesByEnviroment();
  }

  getAuthValues() {
    const company = this.cookieAuthService.getCompanyObject;
    this.companyId = company!.Id_company!.toString();
    this.user = this.cookieAuthService.getUserId!;
    this.rol = this.cookieAuthService.getRolId!;
  }

  setValuesByEnviroment() {
    if(!environment.production) {
      this.showDevelopWarning = true;
    }
  }

  setQuotation(quotation: IQuota) {
    const { cliente } = quotation;
    this.Sales = quotation.venta;
    const [parameter] = this.Sales;
    const { total } = parameter;
    this.totalQuot = total;
    this.editQuotation(quotation, this.Sales, cliente);
  }

  getQuotation() {
    return new Promise<IQuota>((resolve, reject) => {
      this.quotation
        .getQuotation(this.id_factura)
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe((result) => {
          resolve(result);
        });
    });
  }

  getWarehouses() {
    return new Promise<IWarehouse[]>((resolve, reject) => {
      this.warehouseS
        .listWarehouses()
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe((result) => {
          resolve(result);
        });
    });
  }

  getInputValues() {
    if (this.rol === "1" || this.rol === "3") {
      this.newCustomer.valueChanges
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe((value: any) => {
          if (!value.__typename) {
            this.customers
              .searchCustomer(value, null, null)
              .pipe(takeUntil(this.unsubscribe$))
              .subscribe((result: any) => {
                this.CustomersList = result.customers;
              });
          }
        });
    } else {
      this.newCustomer.valueChanges
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe((value: any) => {
          if (!value.__typename) {
            this.customers
              .searchCustomerByUser(value, parseInt(this.user!), null, null)
              .pipe(takeUntil(this.unsubscribe$))
              .subscribe((result: any) => {
                this.CustomersList = result;
              });
          }
        });
    }
  }

  addNewRow() {
    if (this.registerForm.invalid) {
      return;
    }
    const newRow = this.fb.group({
      article: [, Validators.required],
      quantity: [, Validators.required],
      price: [, Validators.required],
      discount: [0, Validators.required],
      subtotal: [0, Validators.required],
      warehouse: ["", Validators.required],
    });
    this.rowsArr.push(newRow);
    this.calculation();
  }

  deleteRow(rowIndex: number, id_ventaD: number) {
    if (this.rowsArr.length > 1) {
      this.rowsArr.removeAt(rowIndex);
      this.ventaIdsDelete.push(parseInt(id_ventaD.toString()));
    } else if (this.rowsArr.length === 1) {
      this.alertsService.showWarningAlert("You should not remove the last row");
    }
    this.calculation();
  }

  editQuotation(
    quotation: IQuota,
    sales: IVentaQuery[],
    customer: ICustomer[]
  ) {
    let taxBool: boolean;
    if (quotation.tax_incl === "true") {
      taxBool = true;
    } else {
      taxBool = false;
    }
    this.registerForm.patchValue({
      tax: quotation.tax,
      date: quotation.createdAt,
      dueDate: quotation.dueDate,
      seller: quotation.seller![0],
      tax_incl: taxBool,
    });
    this.newCustomer.setValue(customer[0]);
    this.registerForm.setControl("itemRows", this.setVenta(sales));
    this.setSellers(quotation);
  }

  setSellers(quotation: IQuota) {
    this.seller
      .listSellers()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((result: any) => {
        const list = result.filter(
          (item: any) => item.nombre !== quotation.seller![0].nombre
        );
        this.sellerList = list;
      });
  }

  setVenta(saleSets: IVentaQuery[]): UntypedFormArray {
    const formArray = new UntypedFormArray([]);
    for (let index = 0; index < saleSets.length; index++) {
      formArray.push(
        this.fb.group({
          ids: saleSets[index].id_venta,
          article: saleSets[index].articulo[0],
          quantity: saleSets[index].cantidad,
          discount: saleSets[index].descuento,
          price: saleSets[index].precio,
          subtotal: saleSets[index].subtotal,
          warehouse: saleSets[index].almacen![0],
        })
      );
    }
    return formArray;
  }

  validRegister() {
    if (this.registerForm.invalid === true) {
      this.alertsService.showErrorAlert(
        "Please, fill in all the required fields!"
      );
      this.spinnerService.hide();
      return false;
    } else if (this.rowsArr.length > 11) {
      this.alertsService.showErrorAlert(
        "The quotation cannot have more than 11 items, please delete",
        " " + (this.rowsArr.length - 11)
      );
      this.spinnerService.hide();
      return false;
    } else if (this.errorsService.checkDuplicateArticles(this.registerForm)) {
      this.alertsService.showErrorAlert(
        "You cannot add the same item more than once on the same invoice"
      );
      this.spinnerService.hide();
      return false;
    } else {
      return true;
    }
  }

  validDeleteSales() {
    if (this.rowsArr.value.length === this.Sales.length) {
      for (let i = 0; i < this.ventaIdsDelete.length; i++) {
        this.ventaIdsDelete.pop();
      }
    }
  }

  update() {
    this.spinnerService.show();
    const formValidation = this.validRegister();
    if (formValidation) {
      this.calculation();
      const variables = this.modifySalesService.setQuotationData(
        this.registerForm,
        this.rowsArr,
        this.newCustomer
      );
      this.validDeleteSales();
      this.quotation
        .updateQuotation(
          this.id_factura,
          variables!.tax,
          variables!.cliente[0].id_cliente,
          variables!.venta[0].id_venta,
          this.ventaIdsDelete,
          variables!.venta[0].codigo,
          variables!.venta[0].cantidad,
          variables!.venta[0].precio,
          variables!.venta[0].descuento,
          variables!.venta[0].subtotal,
          variables!.venta[0].total,
          variables!.venta[0].id_almacen,
          variables!.createdAt,
          variables!.dueDate,
          variables!.seller![0].id_seller,
          variables!.tax_incl!,
          parseInt(this.user!)
        )
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe((result) => {
          if (result) {
            this.spinnerService.hide();
            this.alertsService
              .showSuccessfullAlert("Quotation has beed updated successfully")
              .then((result) => {
                if (result.isConfirmed) {
                  this.alertsService.refresh("/card", "/sales");
                }
              });
          } else {
            this.spinnerService.hide();
            Swal.fire(
              "Error",
              this.translate.instant("Something was wrong"),
              "error"
            );
          }
        });
    } else {
      return;
    }
  }

  calculation() {
    let priceCal;
    let quantityCal;
    let discountCal;
    let taxCal;
    let subsubtotal;

    for (let i = 0; i < this.rowsArr.length; i++) {
      if (this.registerForm.get("tax_incl")?.value === false) {
        if (this.rowsArr.value[i].article.tax[0].value === 0) {
          taxCal = this.registerForm.value.tax;
        } else {
          taxCal = this.rowsArr.value[i].article.tax[0].value;
        }
      } else {
        taxCal = 0;
      }
      priceCal = this.rowsArr.value[i].price;
      quantityCal = this.rowsArr.value[i].quantity;
      discountCal = this.rowsArr.value[i].discount;
      if (taxCal) {
        if (discountCal != 0) {
          subsubtotal = quantityCal * priceCal;
          discountCal = (discountCal / 100) * subsubtotal;
          subsubtotal = subsubtotal - discountCal;
          if (this.rowsArr.value[i].article.tax[0].value === 0) {
            taxCal = (taxCal / 100) * subsubtotal;
          } else {
            taxCal = taxCal * subsubtotal;
          }
          this.rowsArr.value[i].subtotal = subsubtotal + taxCal;
        } else {
          subsubtotal = quantityCal * priceCal;
          if (this.rowsArr.value[i].article.tax[0].value === 0) {
            taxCal = (taxCal / 100) * subsubtotal;
          } else {
            taxCal = taxCal * subsubtotal;
          }
          this.rowsArr.value[i].subtotal = priceCal * quantityCal + taxCal;
        }
      } else if (discountCal != 0) {
        discountCal = (discountCal / 100) * (priceCal * quantityCal);
        this.rowsArr.value[i].subtotal = priceCal * quantityCal - discountCal;
      } else {
        this.rowsArr.value[i].subtotal = priceCal * quantityCal;
      }
    }
    this.totalQuot = 0;
    for (let i = 0; i < this.rowsArr.length; i++) {
      this.totalQuot = this.totalQuot + this.rowsArr.value[i].subtotal;
    }
  }

  displayFnArticle(article: any) {
    return article && article
      ? article.descripcion +
          new String(` - (`) +
          article.tax[0].value +
          new String(`)`)
      : undefined;
  }

  displayFnCustomer(customer: any) {
    return customer && customer ? customer.nombre : undefined;
  }

  closeDialog() {
    this.dialog.closeAll();
  }

  spinner(): void {
    this.spinnerService.show();
    setTimeout(() => {
      this.spinnerService.hide();
    }, 3000);
  }

  openDialog(i: number) {
    let dialogRef = this.dialog.open(DialogComponent, {
      disableClose: true,
      data: {
        filtro: this.newArticle,
        currentWarehouse: this.rowsArr.at(i).get("warehouse")?.value.id_almacen,
      },
    });
    dialogRef
      .afterClosed()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((result) => {
        if (result === "") {
          return;
        }
        this.rowsArr.at(i).get("article")?.setValue(result);
        this.calculation();
      });
  }

  taxValid(tax: boolean) {
    return tax === true ? true : false;
  }

  compareWarehouse(item1: any, item2: any) {
    if (item1 === null || item2 === null) {
      return false;
    }
    return item1.id_almacen === item2.id_almacen;
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { MaterialModule } from 'src/app/material.module';
import { MatIconModule } from '@angular/material/icon';
import { TranslateModule } from '@ngx-translate/core';
import { ListComponent } from './list.component';
import { DxListModule, DxSelectBoxModule } from 'devextreme-angular';

@NgModule({
  declarations: [ListComponent],
  bootstrap: [ListComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MaterialModule,
    MatIconModule,
    TranslateModule,
    DxListModule,
    DxSelectBoxModule
  ],
  exports: [ListComponent]
})
export class ListModule { }

import { Injectable } from "@angular/core";
import { Apollo } from "apollo-angular";
import {
  COUNT_CUSTOMER,
  COUNT_CUSTOMER_BYSEARCH,
  FIND_COUNTRY,
  GET_ALL_CONTRIBUTOR_TYPES,
  GET_COUNTRY,
  GET_CUSTOMERS,
  GET_CUSTOMER_BYNIT,
  GET_CUSTOMER_SEGMENT,
  GET_CUSTOMER_TYPE,
  GET_SINGLE_CUSTOMER,
  SEARCH_CLIENT,
  SEARCH_CUSTOMER,
  SEARCH_PROSPECT,
} from "src/app/@graphql/operations/query";
import { ApiService } from "src/app/@graphql/services/api.service";
import { map } from "rxjs/internal/operators/map";
import {
  CREATE_CUSTOMER,
  CREATE_CUSTOMER_PROSPECTO,
  CREATE_CUSTOMER_SEGMENT,
  REMOVE_CUSTOMER_SEGMENT,
  UPDATE_CUSTOMER,
  UPDATE_DELIVERY,
  UPDATE_POSITIVE_BALANCE,
} from "src/app/@graphql/operations/mutation";
import {
  GET_CUSTOMERS_BY_USER_SELLER,
  SEARCH_CUSTOMER_BY_USER_SELLER,
} from "../../@graphql/operations/queryByUser";
import {
  COUNT_CUSTOMER_BYUSER,
  SEARCH_CUSTOMER_BYUSER,
} from "src/app/@graphql/operations/queryByUser";
import { CookieAuthService } from "src/app/@shared/storage-variables/cookie-auth.service";

@Injectable({
  providedIn: "root",
})
export class CustomerService extends ApiService {

  constructor(apollo: Apollo, private cookieAuthService: CookieAuthService) {
    super(apollo);
  }

  listContributorTypes() {
    const companyObject = this.cookieAuthService.getCompanyObject;
    const company = companyObject?.Id_company;
    return this.query(GET_ALL_CONTRIBUTOR_TYPES, { company }).pipe(
      map((result: any) => {
        return result.getAllContributorTypes;
      })
    );
  }

  countCustomerList(prospect: boolean) {
    const companyObject = this.cookieAuthService.getCompanyObject;
    const company = companyObject?.Id_company;
    return this.query(COUNT_CUSTOMER, { company, prospect }).pipe(
      map((result: any) => {
        return result.countCustomer;
      })
    );
  }

  countCustomerListBySearch(id_seller: number, prospect: boolean) {
    const companyObject = this.cookieAuthService.getCompanyObject;
    const company = companyObject?.Id_company;
    return this.query(COUNT_CUSTOMER_BYSEARCH, { company, id_seller, prospect }).pipe(
      map((result: any) => {
        return result.countCustomerBySearch;
      })
    );
  }

  searchClient(value: string, page: number | null, size: number | null) {
    const companyObject = this.cookieAuthService.getCompanyObject;
    const company = companyObject?.Id_company;
    return this.query(SEARCH_CLIENT, { company, value, page, size }).pipe(
      map((result: any) => {
        return result.searchClient;
      })
    );
  }

  searchCustomer(value: string, page: number | null, size: number | null) {
    const companyObject = this.cookieAuthService.getCompanyObject;
    const company = companyObject?.Id_company;
    return this.query(SEARCH_CUSTOMER, { company, value, page, size }).pipe(
      map((result: any) => {
        return result.searchCustomer;
      })
    );
  }

  searchProspect(value: string, page: number | null, size: number | null) {
    const companyObject = this.cookieAuthService.getCompanyObject;
    const company = companyObject?.Id_company;
    return this.query(SEARCH_PROSPECT, { company, value, page, size }).pipe(
      map((result: any) => {
        return result.searchProspect;
      })
    );
  }

  listCustomers(page?: number, size?: number, prospect?: boolean) {
    const prospects = prospect ? prospect : false;
    const companyObject = this.cookieAuthService.getCompanyObject;
    const company = companyObject?.Id_company;
    return this.query(GET_CUSTOMERS, { company, page, size, prospect: prospects }).pipe(
      map((result: any) => {
        return result.getAllCustomers;
      })
    );
  }
  // Customers By User

  countCustomerListByUser(id_user: number, prospect: boolean) {
    const companyObject = this.cookieAuthService.getCompanyObject;
    const company = companyObject?.Id_company;
    return this.query(COUNT_CUSTOMER_BYUSER, { company, id_user, prospect }).pipe(
      map((result: any) => {
        return result.countCustomerByUser;
      })
    );
  }

  searchCustomerByUser(value: string, id_user: number, page: number | null, size: number | null) {
    const companyObject = this.cookieAuthService.getCompanyObject;
    const company = companyObject?.Id_company;
    return this.query(SEARCH_CUSTOMER_BYUSER, { company, value, id_user, page, size }).pipe(
      map((result: any) => {
        return result.searchCustomerByUser;
      })
    );
  }
  searchCustomerByUserSeller(value: string, id_userSeller: number, page: number | null, size: number | null) {
    const companyObject = this.cookieAuthService.getCompanyObject;
    const company = companyObject?.Id_company;
    return this.query(SEARCH_CUSTOMER_BY_USER_SELLER, {
      company,
      value,
      id_userSeller,
      page,
      size
    }).pipe(
      map((result: any) => {
        return result.searchCustomerByUserSeller;
      })
    );
  }

  listCustomersByUserSeller(page: number, size: number, id_userSeller: number, prospect: boolean) {
    const companyObject = this.cookieAuthService.getCompanyObject;
    const company = companyObject?.Id_company;
    return this.query(GET_CUSTOMERS_BY_USER_SELLER, {
      company,
      page,
      size,
      id_userSeller,
      prospect
    }).pipe(
      map((result: any) => {
        return result.getAllCustomersByUserSeller;
      })
    );
  }

  listCountries() {
    const companyObject = this.cookieAuthService.getCompanyObject;
    const company = companyObject?.Id_company;
    return this.query(GET_COUNTRY, { company }).pipe(
      map((result: any) => {
        return result.getAllCountries;
      })
    );
  }

  findCountry(name: string) {
    const companyObject = this.cookieAuthService.getCompanyObject;
    const company = companyObject?.Id_company;
    return this.query(FIND_COUNTRY, { name, company }).pipe(
      map((result: any) => {
        return result.findCountry;
      })
    );
  }

  getCustomer(id_customer: number) {
    const companyObject = this.cookieAuthService.getCompanyObject;
    const company = companyObject?.Id_company;
    return this.query(GET_SINGLE_CUSTOMER, {
      id_cliente: id_customer,
      company: company,
    }).pipe(
      map((result: any) => {
        return result.getSingleCustomer;
      })
    );
  }

  getCustomerByNit(document: string) {
    const companyObject = this.cookieAuthService.getCompanyObject;
    const company = companyObject?.Id_company;
    return this.query(GET_CUSTOMER_BYNIT, { document, company }).pipe(
      map((result: any) => {
        return result.getCustomerByNit;
      })
    );
  }

  registerCustomer(
    doc_nit: string,
    id_docs: number,
    nombre: string,
    direccion: string,
    telefono: string,
    email: string,
    id_country: number,
    city: string,
    coordinates: string,
    RUT: string,
    id_contributor: number,
    id_seller: number,
    id_user: number,
    id_segment: number,
    positive_balance: number,
    id_customerType: number,
    id_userSeller: number,
    verification_digit?: number
  ) {
    const companyObject = this.cookieAuthService.getCompanyObject;
    const company = companyObject?.Id_company;
    return this.mutation(CREATE_CUSTOMER, {
      doc_nit,
      id_docs,
      nombre,
      direccion,
      telefono,
      email,
      id_country,
      city,
      coordinates,
      RUT,
      id_contributor,
      id_seller,
      company,
      id_user,
      id_segment,
      positive_balance,
      id_customerType,
      id_userSeller,
      verification_digit,
    }).pipe(
      map((result: any) => {
        return result.createCustomer;
      })
    );
  }
  registerCustomerProspecto(
    doc_nit: string | null,
    id_docs: number | null,
    nombre: string | null,
    direccion: string | null,
    telefono: string | null,
    email: string | null,
    id_country: number | null,
    city: string | null,
    coordinates: string | null,
    RUT: string | null,
    id_contributor: number | null,
    id_seller: number | null,
    id_user: number | null,
    id_segment: number | null,
    positive_balance: number | null,
    id_customerType: number | null,
    id_userSeller: number | null,
    verification_digit?: number | null,
    observations?: string | null
  ) {
    const companyObject = this.cookieAuthService.getCompanyObject;
    const company = companyObject?.Id_company;
    return this.mutation(CREATE_CUSTOMER_PROSPECTO, {
      doc_nit,
      id_docs,
      nombre,
      direccion,
      telefono,
      email,
      id_country,
      city,
      coordinates,
      RUT,
      id_contributor,
      id_seller,
      company,
      id_user,
      id_segment,
      positive_balance,
      id_customerType,
      id_userSeller,
      verification_digit,
      observations
    }).pipe(
      map((result: any) => {
        return result.createCustomerProspecto;
      })
    );
  }

  updateCustomer(
    id_cliente: number,
    doc_nit: string,
    id_docs: number | null,
    nombre: string,
    direccion: string,
    telefono: string,
    email: string,
    id_country: number,
    city: string,
    coordinates: string,
    RUT: string,
    id_contributor: number | null,
    id_seller: number,
    id_segment: number | null,
    id_customerType: number,
    id_userSeller: number,
    verification_digit?: number
  ) {
    const companyObject = this.cookieAuthService.getCompanyObject;
    const company = companyObject?.Id_company;
    return this.mutation(UPDATE_CUSTOMER, {
      id_cliente,
      doc_nit,
      id_docs,
      nombre,
      direccion,
      telefono,
      email,
      id_country,
      city,
      coordinates,
      RUT,
      id_contributor,
      id_seller,
      id_segment,
      id_customerType,
      id_userSeller,
      verification_digit,
      company,
    }).pipe(
      map((result: any) => {
        return result.updateCustomer;
      })
    );
  }

  updateDelivery(id_customer: number, delivery: string) {
    const companyObject = this.cookieAuthService.getCompanyObject;
    const company = companyObject?.Id_company;
    return this.mutation(UPDATE_DELIVERY, {
      id_cliente: id_customer,
      delivery: delivery,
      company: company,
    }).pipe(
      map((result: any) => {
        return result.updateDelivery;
      })
    );
  }

  updatePositiveBalance(id_customer: number, positiveBalance: number) {
    const companyObject = this.cookieAuthService.getCompanyObject;
    const company = companyObject?.Id_company;
    return this.mutation(UPDATE_POSITIVE_BALANCE, {
      id_cliente: id_customer,
      positive_balance: positiveBalance,
      company: company,
    }).pipe(
      map((result: any) => {
        return result.updatePositiveBalance;
      })
    );
  }

  listCustomerSegment() {
    const companyObject = this.cookieAuthService.getCompanyObject;
    const company = companyObject?.Id_company;
    return this.query(GET_CUSTOMER_SEGMENT, { company }).pipe(
      map((result: any) => {
        return result.getAllCustomerSegments;
      })
    );
  }

  createCustomerSegment(nombre: string) {
    const companyObject = this.cookieAuthService.getCompanyObject;
    const company = companyObject?.Id_company;
    return this.mutation(CREATE_CUSTOMER_SEGMENT, { nombre, company }).pipe(
      map((result: any) => {
        return result.createCustomerSegments;
      })
    );
  }

  removeCustomerSegment(id: number) {
    const companyObject = this.cookieAuthService.getCompanyObject;
    const company = companyObject?.Id_company;
    return this.mutation(REMOVE_CUSTOMER_SEGMENT, { id, company }).pipe(
      map((result: any) => {
        return result.removeCustomerSegments;
      })
    );
  }

  listCustomerType() {
    const companyObject = this.cookieAuthService.getCompanyObject;
    const company = companyObject?.Id_company;
    return this.query(GET_CUSTOMER_TYPE, { company }).pipe(
      map((result: any) => {
        return result.getAllCustomerTypes;
      })
    );
  }

  calculateVerificationDigit(id: string) {
    let x: number;
    let y: number;
    let vpri = new Array(16);
    let z = id.length;

    vpri[1] = 3;
    vpri[2] = 7;
    vpri[3] = 13;
    vpri[4] = 17;
    vpri[5] = 19;
    vpri[6] = 23;
    vpri[7] = 29;
    vpri[8] = 37;
    vpri[9] = 41;
    vpri[10] = 43;
    vpri[11] = 47;
    vpri[12] = 53;
    vpri[13] = 59;
    vpri[14] = 67;
    vpri[15] = 71;

    x = 0;
    y = 0;
    for (var i = 0; i < z; i++) {
      y = parseInt(id.substr(i, 1));
      x += y * vpri[z - i];
    }

    y = x % 11;

    const digit = y > 1 ? 11 - y : y;

    if (digit) {
      return digit;
    } else {
      return 0;
    }
  }
}
import { Injectable, inject } from '@angular/core';
import { DocumentNode } from '@apollo/client';
import { Apollo, QueryRef } from 'apollo-angular';
import { of } from 'rxjs';
import { map } from 'rxjs/internal/operators/map'
import { catchError, share } from 'rxjs/operators';
import { AlertsService } from 'src/app/@shared/components/services/alerts.service';

@Injectable({
  providedIn: 'root'
})
export class ApiService {
  private queryRefs: { [key: string]: QueryRef<any> } = {};

  constructor(private apollo: Apollo) { }

  protected query(query: DocumentNode, variables: object = {}, context: object = {}) {
    const queryKey = JSON.stringify({ query });
    let queryRef = this.queryRefs[queryKey];
    
      queryRef = this.apollo.watchQuery({
        query,
        variables,
        context,
        fetchPolicy: 'network-only',
      })

      this.queryRefs[queryKey] = queryRef;


    return queryRef.valueChanges.pipe(map((result) => result.data), share());
  }
  
  protected mutation(mutation: DocumentNode, variables: object = {}, context: object = {}) {
    return this.apollo.mutate({
      mutation,
      variables,
      context,
    }).pipe(map((result) => {
      return result.data;
    }));
  }

  protected subscription(subscription: DocumentNode, variables: object = {}, context: object = {}) {
    return this.apollo.subscribe({
      query: subscription,
      variables,
      context
    }).pipe(map((result: any) => {
      return result.data;
    }))
  }

  protected setVariables(query: DocumentNode, variables: object = {}) {
    const queryKey = JSON.stringify({ query });
    const queryRef = this.queryRefs[queryKey];

    if (queryRef) {
      queryRef.setVariables(variables);
    } else {
      throw new Error('Query reference not found.');
    }
  }
}

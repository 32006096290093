import { Injectable } from "@angular/core";
import { IPurchaseInvoice } from "src/app/@interfaces/purchaseInvoice.interface";
import { SetRowsService } from "./set-rows.service";
import { IPurchaseQuery } from "src/app/@interfaces/purchases.interface";
import { IArticles } from "src/app/@interfaces/article.interface";
import { CalculateSiigoService } from "./calculate-siigo.service";
import { IAccountingAccounts } from "src/app/@interfaces/accountingAccounts.interface";
import { IRefundsSingle, IRefundsValuesSingle } from "src/app/@interfaces/refunds.interface";
import { FunctionsSiigoService } from "./functions-siigo.service";
import { ISiigoRow } from "src/app/@interfaces/Siigo/rows.interface";
import { CrossVoucherTypes } from "src/app/@enums/siigo/siigo-crossvoucher";
import { TransactionType } from "src/app/@enums/siigo/siigo-invoices";
import { ISiigoPurchaseValues } from "src/app/@interfaces/Siigo/siigo-purchases.interface";
import { ISiigoRefund, ISiigoRefundValues } from "src/app/@interfaces/Siigo/siigo-refunds.interface";
import { RegisterPurchaseService } from '../dialog/crud-purchase/register-purchase/register-purchase.service';

@Injectable({
  providedIn: "root",
})
export class CalculatePurchasesService {
  constructor(
    private setRowsService: SetRowsService,
    private calculateSiigoService: CalculateSiigoService,
    private functionsSiigoService: FunctionsSiigoService,
    private registerPurchaseService: RegisterPurchaseService
  ) {}

  calculate(
    dateToday: string, 
    purchases: IPurchaseInvoice[], 
    allRefunds: IRefundsSingle[],
    accountingAccounts: IAccountingAccounts, 
    refundAccountingAccounts: IAccountingAccounts
  ) {
    let list: ISiigoRow[] = [], refunds: ISiigoRow[] = [];
    for (const purchase of purchases) {
      const withholdingAccount = this.validPurchaseType(purchase);
      const unstructuredData = this.separatePurchaseData(purchase);
      const rows = this.setPurchaseRows(
        accountingAccounts, unstructuredData, purchase, dateToday, withholdingAccount!
      )
      const purchasesSiigo = this.setPurchases(
        this.createPurchaeValuesObject(
          purchase, accountingAccounts, 
          unstructuredData, dateToday
        )
      );
      list = list.concat(this.setPurchasesOnList(
        purchasesSiigo, rows.row1, rows.row2, 
        rows.row3, rows.row4, accountingAccounts
      ))
      refunds = refunds.concat(this.setRefund(
        this.createRefundObject(
          purchase, unstructuredData,
          dateToday, refundAccountingAccounts,
          allRefunds
        )
      ))
    }
    list = list.concat(refunds);
    return list;
  }

  validPurchaseType(purchase: IPurchaseInvoice) {
    const typesArticleArray = purchase.purchases
    .map((element) => 
      element.articulo[0].type![0].id_type
    );
    const groupArray = this.registerPurchaseService.reduceArray(typesArticleArray);
    const type = groupArray.length === 1 ? groupArray[0] : 5;
    const getAccount = purchase.purchases
    .filter((item) => 
      item.articulo[0].type![0].id_type === type
    ).map((item) => 
      item.articulo[0].type![0].accounting_account?.withholding
    );
    return getAccount[0];
  }

  setPurchaseRows(
    accountingAccounts: IAccountingAccounts,
    unstructuredData: any, purchase: IPurchaseInvoice,
    dateToday: string, withholdingAccount: string
  ) {
    const mainRow = this.setRowsService.createPurchaseRow(
      unstructuredData, purchase, dateToday
    )
    const crossRows = this.createPurchaseCrossRows(unstructuredData);
    const row1 = {
      ...mainRow, accountingAccount: accountingAccounts.total,
      debitCredit: TransactionType.Credit, total: unstructuredData.total,
      ...crossRows
    }
    const row2 = {
      ...mainRow, accountingAccount: accountingAccounts.tax,
      debitCredit: TransactionType.Debit, total: 0,
    }
    const row3 = {
      ...mainRow, accountingAccount: withholdingAccount ? withholdingAccount : accountingAccounts.withholding,
      debitCredit: TransactionType.Credit, total: purchase.withholdingTax!,
    }
    const row4 = {
      ...mainRow, accountingAccount: accountingAccounts.ica,
      debitCredit: TransactionType.Credit, total: purchase.icaTax!,
    }
    return { row1: row1, row2: row2, row3: row3, row4: row4 }
  }

  setPurchasesOnList(
    purchasesSiigo: ISiigoRow[],
    row1: ISiigoRow, row2: ISiigoRow,
    row3: ISiigoRow, row4: ISiigoRow,
    accountingAccounts: IAccountingAccounts
  ) {
    let list: ISiigoRow[] = [];
    const values = this.getValuesFromSiigoPurchases(
      purchasesSiigo, list, accountingAccounts
    )
    row2.total = values.totalTax;
    row3.withholdingBase = values.subtotals;
    row4.withholdingBase = values.subtotals;
    list.push(row1);
    list.push(row2);
    list.push(row3);
    list.push(row4);
    return list;
  }

  getValuesFromSiigoPurchases(
    purchasesSiigo: ISiigoRow[], list: ISiigoRow[],
    accountingAccounts: IAccountingAccounts
  ) {
    let totalTax = 0; let subtotals = 0;
    purchasesSiigo.forEach((element) => {
      list.push(element);
      totalTax += element.taxValue;
      if (
        element.accountingAccount === accountingAccounts.inventory || 
        element.accountingAccount === accountingAccounts.service
      ) {
        subtotals += element.total;
      }
    });
    return { totalTax: totalTax, subtotals: subtotals }
  }

  createRefundObject(
    purchase: IPurchaseInvoice,
    unstructuredData: any,
    dateToday: string,
    refundAccountingAccounts: IAccountingAccounts,
    allRefunds: IRefundsSingle[]
  ): ISiigoRefund {
    return {
      purchaseId: purchase.id_invoice!,
      numberDocument: unstructuredData.numberDocument!,
      finalDate: unstructuredData.finalDate,
      documentNit: unstructuredData.documentNit,
      name: purchase.provider[0].nombre,
      dateToday: dateToday,
      isTaxable: unstructuredData.isTaxable,
      purchaseTax: unstructuredData.purchaseTax,
      refundAccountingAccounts: refundAccountingAccounts,
      contributorType: unstructuredData.contributorType,
      refunds: allRefunds,
      providerObject: unstructuredData.providerObject
    }
  }

  createPurchaseCrossRows(unstructuredData: any) {
    //Only for row1
    return {
      voucherTypeCross: CrossVoucherTypes.purchases,
      documentNumberCross: unstructuredData.providerObject.providerDocument,
      expirationNumber: "1",
      expirationYear: unstructuredData.finalDate.year,
      expirationMonth: unstructuredData.finalDate.month,
      expirationDay: unstructuredData.finalDate.day,
    }
  }

  separatePurchaseData(purchase: IPurchaseInvoice) {
    const numberDocument = purchase.contpurchase![0].consecutive_invoice?.replace(/\D+/g, "");
    const total = purchase.purchases[0].total - purchase.withholdingTax! - purchase.icaTax!;
    const finalDate = this.functionsSiigoService.getDateSeparated(purchase.createdAt);
    const documentNit = purchase.provider[0].doc_nit!.split('-')[0];
    const contributorType = parseInt(purchase.provider[0].contributorType!.id_contributor.toString());
    const isTaxable = this.functionsSiigoService.validTaxes(purchase.withholdingTax!,purchase.icaTax!);
    const purchaseTax = purchase.tax;
    const providerObject = this.functionsSiigoService.setProviderObject(purchase, finalDate);
    return {
      numberDocument: numberDocument,
      total: total,
      finalDate: finalDate,
      documentNit: documentNit,
      contributorType: contributorType,
      isTaxable: isTaxable,
      purchaseTax: purchaseTax,
      providerObject: providerObject
    }
  }

  createPurchaeValuesObject(
    purchase: IPurchaseInvoice,
    accountingAccounts: IAccountingAccounts,
    unstructuredData: any,
    dateToday: string
  ): ISiigoPurchaseValues {
    return {
      purchases: purchase.purchases,
      inventoryAccount: accountingAccounts.inventory,
      serviceAccount: accountingAccounts.service,
      numberDocument: unstructuredData.numberDocument!,
      purchaseYear: unstructuredData.finalDate.year,
      purchaseMonth: unstructuredData.finalDate.month,
      purchaseDay: unstructuredData.finalDate.day,
      documentNit: unstructuredData.documentNit!,
      dateToday: dateToday,
      isTaxable: unstructuredData.isTaxable,
      purchaseTax: unstructuredData.purchaseTax,
      contributorType: unstructuredData.contributorType,
      providerObject: unstructuredData.providerObject
    }
  }

  setPurchases(purchaseValues: ISiigoPurchaseValues) {
    let arrItems: ISiigoRow[] = [];
    for (const purchase of purchaseValues.purchases) {
      const unstructuredData = this.separatePurchaseValueData(
        purchase, purchaseValues
      );
      const row = this.setRowsService.createPurchaseValuesRow(
        purchaseValues, purchase, unstructuredData, purchaseValues.providerObject
      )
      arrItems.push(row);
    };
    return arrItems;
  }

  getPurchasePrices(
    article: IArticles, purchase: IPurchaseQuery,
    purchaseValues: ISiigoPurchaseValues
  ) {
    const taxes = this.calculatePurchaseTax(article, purchaseValues);
    const price = this.calculateSiigoService.calculateTax(
      "false",
      purchase.discount,
      purchase.price,
      taxes.percentIvaFloat
    );
    return {
      percentIvaFloat: taxes.percentIvaFloat,
      percentIvaInt: taxes.percentIvaInt,
      price: price
    }
  }

  calculatePurchaseTax(article: IArticles, purchaseValues: ISiigoPurchaseValues) {
    let percentIvaFloat = 0, percentIvaInt = 0;
    if (article.tax!.length >= 0) {
      article.tax!.forEach((tax) => {
        const taxValue = tax.value > 0 ? tax.value : purchaseValues.purchaseTax;                                              
        percentIvaFloat = this.functionsSiigoService.getContributorTax(
          purchaseValues.contributorType, taxValue + 1
        );
        percentIvaInt = this.functionsSiigoService.getContributorTax(
          purchaseValues.contributorType, taxValue * 100
        );
      });
    }
    return { percentIvaFloat: percentIvaFloat, percentIvaInt: percentIvaInt }
  }

  getPurchasesAccountingAccount(
    article: IArticles, purchaseValues: ISiigoPurchaseValues
  ) {
    return parseInt(article.type![0].id_type.toString()) !== 5
      ? purchaseValues.serviceAccount
      : purchaseValues.inventoryAccount;
  }

  separatePurchaseValueData(
    purchase: IPurchaseQuery, purchaseValues: ISiigoPurchaseValues
  ) {
    const article: IArticles = purchase.articulo[0];
    const pricesAndTax = this.getPurchasePrices(article, purchase, purchaseValues);
    const accountingAccount = this.getPurchasesAccountingAccount(
      article, purchaseValues
    )
    const subtotal = purchase.quantity * pricesAndTax.price;
    const totalTax = subtotal * (pricesAndTax.percentIvaInt / 100)
    return { 
      article: article,
      pricesAndTax: pricesAndTax,
      accountingAccount: accountingAccount,
      subtotal: subtotal,
      totalTax: totalTax
    }
  }

  setRefund(refundObject: ISiigoRefund) {
    let list: ISiigoRow[] = [];
    const filterRefund = refundObject.refunds.filter(
      item => item.num_invo.toString() === refundObject.purchaseId.toString()
    );
    for (const element of filterRefund) {
      const withholdingAccount = this.validRefundType(element);
      const unstructuredData =  this.separateRefundData(element);
      const rows = this.setRefundRows(
        unstructuredData, refundObject, element, withholdingAccount!
      )
      const refundValues = this.setRefunds(
        this.createRefundValuesObject(
          element, refundObject, unstructuredData
        )
      );
      list = list.concat(this.setRefundsOnList(
        refundValues, refundObject, rows
      ))
    }
    return list;
  }

  validRefundType(refund: IRefundsSingle) {
    const typesArticleArray = refund.refunds_values
    .map((element) => 
      element.articulo![0].type![0].id_type
    );
    const groupArray = this.registerPurchaseService.reduceArray(typesArticleArray);
    const type = groupArray.length === 1 ? groupArray[0] : 5;
    const getAccount = refund.refunds_values
    .filter((item) => 
      item.articulo![0].type![0].id_type === type
    ).map((item) => 
      item.articulo![0].type![0].accounting_account?.withholding
    );
    return getAccount[0];
  }
  
  setRefundRows(
    unstructuredData: any, 
    refundObject: ISiigoRefund,
    element: IRefundsSingle,
    withholdingAccount: string,
  ) {
    const mainRow = this.setRowsService.createRefundRow(
      unstructuredData, refundObject
    )
    const crossRows = this.createRefundCrossRows(
      refundObject
    )
    const row1 = {
      ...mainRow, accountingAccount: refundObject.refundAccountingAccounts.total,
      debitCredit: TransactionType.Debit, total: unstructuredData.total,
      ...crossRows
    }
    const row2 = {
      ...mainRow, accountingAccount: refundObject.refundAccountingAccounts.tax,
      debitCredit: TransactionType.Credit, total: 0,
    }
    const row3 = {
      ...mainRow, accountingAccount: withholdingAccount ? withholdingAccount : refundObject.refundAccountingAccounts.withholding,
      debitCredit: TransactionType.Debit, total: element.withholdingTax,
    }
    const row4 = {
      ...mainRow, accountingAccount: refundObject.refundAccountingAccounts.ica,
      debitCredit: TransactionType.Debit, total: element.icaTax,
    }
    return { row1: row1, row2: row2, row3: row3, row4: row4 }
  }

  setRefundsOnList(
    refundValues: ISiigoRow[], 
    refundObject: ISiigoRefund, 
    rows: any
  ) {
    let list: ISiigoRow[] = [];
    const values = this.getValuesFromSiigoRefunds(
      refundValues, list, refundObject
    )
    rows.row2.total = values.totalTax;
    rows.row3.withholdingBase = values.subtotals;
    rows.row4.withholdingBase = values.subtotals;
    list.push(rows.row1);
    list.push(rows.row2);
    list.push(rows.row3);
    list.push(rows.row4);
    return list;
  }

  getValuesFromSiigoRefunds(
    refundValues: ISiigoRow[], 
    list: ISiigoRow[],
    refundObject: ISiigoRefund,
  ) {
    let totalTax = 0, subtotals = 0;
    refundValues.forEach((element) => {
      list.push(element);
      totalTax += element.taxValue;
      if (
        element.accountingAccount === refundObject.refundAccountingAccounts.inventory || 
        element.accountingAccount === refundObject.refundAccountingAccounts.service
      ) {
        subtotals += element.total;
      }
    });
    return { totalTax: totalTax, subtotals: subtotals }
  }

  createRefundCrossRows(refundObject: ISiigoRefund) {
    return {
      voucherTypeCross: CrossVoucherTypes.purchases,
      documentNumberCross: refundObject.providerObject.providerDocument,
      expirationNumber: "1",
      expirationYear: refundObject.finalDate.year,
      expirationMonth: refundObject.finalDate.month,
      expirationDay: refundObject.finalDate.day,
    }
  }

  separateRefundData(element: IRefundsSingle) {
    const parsedDate = this.functionsSiigoService.getDateSeparated(
      element.createAt!
    );
    const numberDocument = element.id_refund.toString();
    const total = element.total * - 1;
    return { 
      parsedDate: parsedDate, 
      numberDocument: numberDocument,
      total: total
    }
  }

  setRefunds(refundValues: ISiigoRefundValues){
    let arrItems: ISiigoRow[] = [];
    for (const refund of refundValues.refunds) {
      const unstructuredData = this.separateRefundValueData(
        refund, refundValues
      )
      const accountingAccount = this.getRefundAccounitngAccount(
        unstructuredData.article, refundValues
      )
      const row = this.setRowsService.createRefundValueRow(
        refundValues, accountingAccount, 
        unstructuredData, refund.warehouse.id_almacen
      )
      arrItems.push(row);
    };
    return arrItems;
  }

  separateRefundValueData(
    refund: IRefundsValuesSingle,
    refundValues: ISiigoRefundValues
  ) {
    const article: IArticles = refund.articulo![0];
    const accountingAccount = this.getRefundAccounitngAccount(
      article, refundValues
    )
    const quantity = this.functionsSiigoService.validNegatives(
      refund.quantity
    );
    const unitaryPrice = this.functionsSiigoService.validNegatives(
      refund.price
    );
    const priceAndTax = this.getRefundPrices(
      article, refund, refundValues, unitaryPrice
    )
    const subtotal = quantity * priceAndTax.price;
    const totalTax = subtotal * (priceAndTax.percentIvaInt / 100);
    return {
      article: article,
      accountingAccount: accountingAccount,
      quantity: quantity,
      priceAndTax: priceAndTax,
      subtotal: subtotal,
      totalTax: totalTax
    }
  }

  createRefundValuesObject(
    element: IRefundsSingle,
    refundObject: ISiigoRefund,
    unstructuredData: any
  ): ISiigoRefundValues {
    return {
      refunds: element.refunds_values,
      inventoryAccount: refundObject.refundAccountingAccounts.inventory,
      serviceAccount: refundObject.refundAccountingAccounts.service,
      numberDocument: unstructuredData.numberDocument!,
      purchaseYear: unstructuredData.parsedDate.year,
      purchaseMonth: unstructuredData.parsedDate.month,
      purchaseDay: unstructuredData.parsedDate.day,
      documentNit: refundObject.documentNit!,
      dateToday: refundObject.dateToday,
      isTaxable: refundObject.isTaxable,
      purchaseTax: refundObject.purchaseTax,
      contributorType: refundObject.contributorType,
      providerObject: refundObject.providerObject
    }
  }

  calculateRefundTax(article: IArticles, refundValues: ISiigoRefundValues) {
    let percentIvaFloat = 0, percentIvaInt = 0;
    if (article.tax!.length >= 0) {
      article.tax!.forEach((tax) => {
        const taxValue = tax.value > 0 ? tax.value : refundValues.purchaseTax;                                              
        percentIvaFloat = this.functionsSiigoService.getContributorTax(
          refundValues.contributorType, taxValue + 1
        );
        percentIvaInt = this.functionsSiigoService.getContributorTax(
          refundValues.contributorType, taxValue * 100
        );
      });
    }
    return { percentIvaFloat: percentIvaFloat, percentIvaInt: percentIvaInt }
  }

  getRefundPrices(
    article: IArticles, refund: IRefundsValuesSingle,
    refundValues: ISiigoRefundValues, unitaryPrice: number
  ) {
    const taxes = this.calculateRefundTax(article, refundValues);
    const price = this.calculateSiigoService.calculateTax(
      "false",
      refund.discount,
      unitaryPrice,
      taxes.percentIvaFloat
    );
    return {
      percentIvaFloat: taxes.percentIvaFloat,
      percentIvaInt: taxes.percentIvaInt,
      price: price
    }
  }

  getRefundAccounitngAccount(
    article: IArticles, refundValues: ISiigoRefundValues
  ) {
    return parseInt(article.type![0].id_type.toString()) !== 5
      ? refundValues.serviceAccount
      : refundValues.inventoryAccount;
  }
}

import { Component, OnInit, OnDestroy } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { NgxSpinnerService } from "ngx-spinner";
import { IArticles } from "src/app/@interfaces/article.interface";
import { IGrupoArt } from "src/app/@interfaces/grupoart.interface";
import { IPresentationTypes } from "src/app/@interfaces/presentationTypes";
import { AuthService } from "src/app/@shared/authentication/auth.service";
import { DialogComponent } from "src/app/@shared/components/dialog/dialog.component";
import { InventoryService } from "./inventory.service";
import { IWarehouse } from "../../@interfaces/warehouse.interface";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { ArticleCostService } from "src/app/@shared/components/services/article-cost.service";
import { CalculateSiigoService } from "src/app/@shared/components/services/calculate-siigo.service";
import { ReportsService } from "../reports/reports.service";
import { InventoryMovementsService } from "src/app/@shared/components/services/inventory-movement.service";
import { TranslateService } from "@ngx-translate/core";
import { AlertsService } from '../../@shared/components/services/alerts.service';
import { CookieAuthService } from "src/app/@shared/storage-variables/cookie-auth.service";

@Component({
  selector: "app-inventory",
  templateUrl: "./inventory.component.html",
  styleUrls: ["./inventory.component.css"],
})
export class InventoryComponent implements OnInit, OnDestroy {
  inventoryList: Array<IArticles> = [];
  groupsList: Array<IGrupoArt> = [];
  presentationList: Array<IPresentationTypes> = [];
  warehouseList: Array<IWarehouse> = [];
  length = 0;
  companyId = '';
  disableButton = false;
  orderType: string = "ASC";
  orderField: string = "id_articulo";
  user = '';
  rol = '';
  unsubscribe$ = new Subject();
  constructor(
    private spinnerService: NgxSpinnerService,
    private inventory: InventoryService,
    private dialog: MatDialog,
    private articleCostService: ArticleCostService,
    private calculateSiigoService: CalculateSiigoService,
    private reportsService: ReportsService,
    private invetoryMovement: InventoryMovementsService,
    private translate: TranslateService,
    private alertsService: AlertsService,
    private authService: AuthService,
    private cookieAuthService: CookieAuthService
  ) {}

  async ngOnInit(): Promise<void> {
    this.spinnerService.show();
    await this.authService.checkAuthStatus();
    this.getAuthValues();
    this.getInventory().pipe(takeUntil(this.unsubscribe$))
    .subscribe((result) => {
      this.inventoryList = result.articles;
      this.length = result.count;
      this.spinnerService.hide();
    });
    this.validButtons();
  }

  getAuthValues() {
    const company = this.cookieAuthService.getCompanyObject;
    this.companyId = company!.Id_company!.toString();
    this.user = this.cookieAuthService.getUserId!;
    this.rol = this.cookieAuthService.getRolId!;
  }

  validButtons() {
    if (this.rol === "1") {
      this.disableButton = false;
    } else {
      this.disableButton = true;
    }
  }

  openDialog() {
    this.dialog.open(DialogComponent, {
      data: { registerArticle: true },
    });
  }

  openDialogGroups(registerGroups: boolean, listGroups: IGrupoArt[]) {
    this.dialog.open(DialogComponent, {
      data: { registerGroups, listGroups },
    });
  }

  openDialogPresen(registerPresen: boolean, listPresen: IPresentationTypes[]) {
    this.dialog.open(DialogComponent, {
      data: { registerPresen, listPresen },
    });
  }

  openDialogWare(registerWare: boolean, listWare: IWarehouse[]) {
    this.dialog.open(DialogComponent, {
      data: { registerWare, listWare },
    });
  }

  async updateCosts() {
    const warehouseID = await this.calculateSiigoService.getWarehouseId();
    const year = await this.reportsService.getYear();
    const updateReady = await this.updateArticleQuantitesTo0();
    if(year && updateReady) {
      this.alertsService.showProgressAlert(this.translate.instant('Starting.......'));
      const invoices = await this.calculateSiigoService.getAllInvoices(year);
      const purchases = await this.calculateSiigoService.getAllPurchases(year);
      const refunds = await this.calculateSiigoService.getAllRefunds(year);
      const notes = await this.calculateSiigoService.getAllNotes(year);
      const warehouseTransfers = await this.calculateSiigoService.getAllWarehouseTransfers(year);
      const warehouseOutputsAndEntries = await this.calculateSiigoService.getAllWarehouseOutputsAndEntries(year);
      const inventoryReclassifications = await this.calculateSiigoService.getAllInventoryReclassifications(year);
      this.invetoryMovement
      .inventoryMovement(
        invoices, purchases, refunds, notes, warehouseTransfers, 
        warehouseOutputsAndEntries, inventoryReclassifications, warehouseID, true
      ).then((result) => {
        this.articleCostService.onUpdateCompleted();
      }); 
    }
  }

  getInventory() {
    return this.inventory
    .listArticles(0, 15, this.orderField, this.orderType)
  }

  updateArticleQuantitesTo0() {
    return new Promise<boolean>((resolve, reject) => {
      this.inventory.updateArticleQuantitesTo0()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((result) => {
        resolve(result)
      })
    })
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}

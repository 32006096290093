import { Injectable } from "@angular/core";
import { ITopCustomerNotes } from "src/app/@interfaces/Analysis/dollar-per-client.interface";
import { INotasGrap } from "src/app/@interfaces/notas.interface";
import { CalculateAnalysis } from './calculate-analysis.service';

@Injectable({
  providedIn: "root",
})
export class ProcessNotesService {
  constructor(private calculateAnalysis: CalculateAnalysis) {}

  extractNoteValues(notes: INotasGrap[], compareNumbers: any[], propertyToUse: string) {
    let arrayItemsByNote: string[] = [];
    let arrayInitial: ITopCustomerNotes[] = [];
    compareNumbers.forEach((number) => {
      notes.forEach(note => {
        note.valor_notas.forEach((value) => {
          const id_invoice = this.isNumberOrObject(number, "invoice_id");
          const id_note = this.isNumberOrObject(number, "note_id");
          if (value.id_nota! === parseInt(id_note.toString())) {
            const { codigo, descripcion, presentacion } = value.articulo[0];
            const group = value.articulo[0].grupoArt![0].nombre;
            const itemToUse = this.validIdentificator(propertyToUse, group, descripcion, codigo!);
            arrayItemsByNote.push(itemToUse!);
            if (Math.sign(value.cantidad) === -1 || Math.sign(value.cantidad) === -0 || Math.sign(value.precio) === -1 || Math.sign(value.precio) === -0) {
              const subtotal = value.cantidad * (value.precio * -1) - (value.descuento / 100) * (value.cantidad * (value.precio * -1));
              arrayInitial.push({
                fact: id_invoice,
                nota: id_note,
                articulo: itemToUse!,
                grupo: group!.toString(),
                cantidad: value.cantidad,
                litros: parseFloat(presentacion!),
                subtotal: subtotal
              });
            }
            if (Math.sign(value.cantidad) === 1 || Math.sign(value.cantidad) === 0 || Math.sign(value.precio) === 1 || Math.sign(value.precio) === 0) {
              const subtotal = value.cantidad * value.precio - (value.descuento / 100) * (value.cantidad * value.precio);
              arrayInitial.push({
                fact: id_invoice,
                nota: id_note,
                articulo: itemToUse!,
                grupo: group!.toString(),
                cantidad: value.cantidad,
                litros: parseFloat(presentacion!),
                subtotal: subtotal
              });
            }
          }
        });
      });
    });
    return { arrayItemsByNote: arrayItemsByNote, arrayInitial: arrayInitial }
  }

  validIdentificator(propertyToUse: string, group: string, description: string, code: string) {
    if (propertyToUse === "descripcion") {
      return description;
    }else if(propertyToUse === "codigo") {
      return code;
    }else {
      return group;
    }
  }

  isNumberOrObject(item: any, property: string) {
    if (typeof item === 'number') {
      return item;
    } else if (typeof item === 'object') {
      return item[property];
    } else {
      return 'N/A';
    }
  }

  getNotesByDescription(notes: INotasGrap[], compareNumbers: number[]) {
    const noteValues = this.extractNoteValues(notes, compareNumbers, "descripcion");
    let filteredNotes = this.calculateAnalysis.reduceArrays(noteValues.arrayItemsByNote);
    const arrayGroupValues = this.createArray(filteredNotes, noteValues.arrayInitial, true);
    return arrayGroupValues;
  }

  getNotesPerCode(notes: INotasGrap[], compareNumbers: any[]) {
    const noteValues = this.extractNoteValues(notes, compareNumbers, "codigo");
    const filteredNotes = this.calculateAnalysis.reduceArrays(noteValues.arrayItemsByNote);
    const arrayGroupValues = this.createArray(filteredNotes, noteValues.arrayInitial, true);
    return arrayGroupValues;
  }

  getNotesPerMaps(notes: INotasGrap[], compareNumbers: any[]) {
    const noteValues = this.extractNoteValues(notes, compareNumbers, "codigo");
    const filteredNotes = this.calculateAnalysis.reduceArrays(noteValues.arrayItemsByNote);
    const arrayGroupValues = this.createSingleArray(filteredNotes, noteValues.arrayInitial);
    return arrayGroupValues;
  }

  getNotesPerGroup(notes: INotasGrap[], compareNumbers: number[]) {
    const noteValues = this.extractNoteValues(notes, compareNumbers, "grupo");
    const filteredNotes = this.calculateAnalysis.reduceArrays(noteValues.arrayItemsByNote);
    const arrayGroupValues = this.createArray(filteredNotes, noteValues.arrayInitial, false);
    return arrayGroupValues;
  }

  createArray(filteredNotes: string[], arrayInitial: ITopCustomerNotes[], accumulateQuantities: boolean) {
    let arrayAgrupateValues: ITopCustomerNotes[] = [];
    filteredNotes.forEach((item) => {
      let filterByItem = arrayInitial.filter((dato) => dato.articulo === item);
      const agrupateValues = filterByItem.reduce((acc, element) => {
        accumulateQuantities ? 
          acc.cantidad += element.cantidad : 
          acc.cantidad = element.cantidad;
        accumulateQuantities ? 
          acc.litros = acc.cantidad * element.litros : 
          acc.litros += acc.cantidad * element.litros;
        acc.subtotal += element.subtotal;
        acc.articulo = element.articulo;
        acc.grupo = element.grupo!;
        acc.fact = element.fact;
        acc.nota = element.nota;
        return acc;
      }, {
        fact: 0,
        nota: 0,
        articulo: '',
        grupo: '',
        cantidad: 0,
        litros: 0,
        subtotal: 0
      });
      arrayAgrupateValues.push(agrupateValues);
    });
    return arrayAgrupateValues;
  }

  createSingleArray(filteredNotes: string[], arrayInitial: ITopCustomerNotes[]) {
    let arrayAgrupateValues: ITopCustomerNotes[] = [];
    filteredNotes.forEach((item) => {
      let sumaQuan = 0;
      let sumaSubts = 0;
      let liter = 0;
      let prod = "";
      let fact = 0;
      let filterByItem = arrayInitial.filter((dato) => dato.articulo === item);
      filterByItem.forEach((element) => {
        sumaQuan = element.cantidad;
        sumaSubts = element.subtotal;
        liter = sumaQuan * parseFloat(element.litros.toString());
        prod = element.articulo;
        fact = element.fact;
        arrayAgrupateValues.push({
          fact: fact,
          nota: element.nota,
          articulo: prod,
          cantidad: sumaQuan,
          litros: liter,
          subtotal: sumaSubts,
        });
      });
    });
    return arrayAgrupateValues;
  }
}

import { Injectable } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import * as pdfMake from "pdfmake/build/pdfmake";
import { IGraphQToBilly } from "src/app/@interfaces/Billy/billy.interface";
import { IContFact } from "src/app/@interfaces/contfac.interface";
import { IQuota } from "src/app/@interfaces/quotation.interface";
import { IVentaQuery } from "src/app/@interfaces/venta.interface";
import { ImageConverseService } from "src/app/@shared/components/services/imageConverse.service";

@Injectable({
  providedIn: "root",
})
export class GenerateDetailsPDFService {
  constructor(
    private imageConverseService: ImageConverseService,
    private translate: TranslateService
  ) {}

  getLogoUrl(company: string) {
    let imgUrl = "";
    switch (company) {
      case "1":
        imgUrl = "../../../assets/img/v1rotateBlack.png";
        break;
      case "2":
        imgUrl = "../../../assets/img/Ibexports.png";
        break;
      case "4":
        imgUrl = "../../../assets/img/Jens.png";
        break;
      case "5":
        imgUrl = "../../../assets/img/letrasVersa.png";
        break;
      case "6":
        imgUrl = "../../../assets/img/Parabricentro.png";
        break;
      case "7":
        imgUrl = "../../../assets/img/v1rotateBlack.png";
        break;
      case "8":
        imgUrl = "../../../assets/img/Parabricentro.png";
        break;
      case "9":
        imgUrl = "../../../assets/img/v1rotateBlack.png";
        break;
    }
    return imgUrl;
  }

  async generateInvoicePDF(
    invoiceObject: IQuota,
    invoiceCount: IContFact,
    sales: IVentaQuery[],
    subtotal: number,
    taxCalculated: number,
    discount: number,
    total: number,
    qrUrl: string,
    company: string
  ) {
    const invoiceDate = this.getInvoiceDate(invoiceObject);
    const items = this.getSales(
      sales,
      invoiceObject.tax,
      invoiceObject.tax_incl!
    );
    const pdfDefinition: any = {
      content: [
        {
          style: "tableData",
          table: {
            widths: ["auto", "*", "auto", "auto"],
            body: [
              [
                {
                  image: await this.imageConverseService.getBase64ImageFromURL(
                    this.getLogoUrl(company)
                  ),
                  width: 250,
                  height: 60,
                  rowSpan: 3,
                  border: [false, false, false, false],
                  alignment: "center",
                },
                {
                  rowSpan: 3,
                  text: "",
                  border: [false, false, false, false],
                  alignment: "center",
                },
                {
                  rowSpan: 3,
                  text: this.getInvoiceType(invoiceCount, invoiceObject),
                  border: [false, false, false, false],
                  alignment: "center",
                },
                {
                  rowSpan: 3,
                  text: `${this.translate.instant("Electronic Invoice")}\n ${
                    invoiceObject.billyInvoice![0]?.invoice_number ||
                    this.translate.instant("PENDING")
                  }`,
                  border: [false, false, false, false],
                  alignment: "center",
                },
              ],
              ["", "", "", ""],
              ["", "", "", ""],
            ],
          },
        },
        {
          style: "tableData",
          color: "#444",
          table: {
            widths: ["auto", "*", "auto", "auto", "auto", "auto"],
            headerRows: 2,
            body: [
              [
                {
                  style: "header",
                  border: [true, true, true, true],
                  text: this.translate.instant("Name"),
                },
                {
                  style: "textStyle",
                  border: [true, true, true, true],
                  text: `${invoiceObject.cliente[0].nombre}`,
                },
                {
                  style: "header",
                  border: [true, true, true, true],
                  text: this.translate.instant("Email"),
                },
                {
                  style: "textStyle",
                  border: [true, true, true, true],
                  text: `${invoiceObject.cliente[0].email}`,
                },
                {
                  style: "header",
                  text:
                    this.translate.instant("Date") +
                    "/" +
                    this.translate.instant("Due Date"),
                  colSpan: 2,
                },
                "",
              ],
              [
                {
                  style: "header",
                  border: [true, true, true, true],
                  text: this.translate.instant("Address"),
                },
                {
                  style: "textStyle",
                  border: [true, true, true, true],
                  text: `${invoiceObject.cliente[0].direccion}`,
                },
                {
                  style: "header",
                  border: [true, true, true, true],
                  text: this.translate.instant("Phone"),
                },
                {
                  style: "textStyle",
                  border: [true, true, true, true],
                  text: `${invoiceObject.cliente[0].telefono}`,
                },
                { style: "textStyle", text: `${invoiceDate}`, colSpan: 2 },
                "",
              ],
              [
                {
                  style: "header",
                  border: [true, true, true, true],
                  text: this.translate.instant("Seller"),
                },
                {
                  style: "textStyle",
                  border: [true, true, true, true],
                  text: `${invoiceObject.seller![0].nombre}`,
                },
                {
                  style: "header",
                  border: [true, true, true, true],
                  text: "",
                  colSpan: 2,
                },
                "",
                {
                  style: "textStyle",
                  text: `${invoiceObject.dueDate}`,
                  colSpan: 2,
                },
                "",
              ],
            ],
          },
          layout: {
            hLineColor: function (i: any, node: any) {
              return "grey";
            },
            vLineColor: function (i: any, node: any) {
              return "grey";
            },
          },
        },
        {
          style: "tableData",
          table: {
            widths: ["auto", "*", "auto", "auto", "auto"],
            body: items,
          },
          layout: {
            hLineColor: function (i: any, node: any) {
              return "grey";
            },
            vLineColor: function (i: any, node: any) {
              return "grey";
            },
          },
        },
        {
          style: "tableData",
          table: {
            widths: [70, "auto"],
            body: [
              [
                {
                  style: "header",
                  border: [true, true, true, true],
                  text: this.translate.instant("Subtotal"),
                },
                {
                  border: [true, true, true, true],
                  style: "" + "textStyle",
                  text: "$" + `${subtotal.toFixed(2)}`,
                },
              ],
              [
                {
                  style: "header",
                  border: [true, true, true, true],
                  text: this.translate.instant("Tax"),
                },
                {
                  border: [true, true, true, true],
                  style: "" + "textStyle",
                  text: "$" + `${taxCalculated.toFixed(2)}`,
                },
              ],
              [
                {
                  style: "header",
                  border: [true, true, true, true],
                  text: this.translate.instant("Discount"),
                },
                {
                  border: [true, true, true, true],
                  style: "" + "textStyle",
                  text: "$" + `${discount.toFixed(2)}`,
                },
              ],
              [
                {
                  style: "header",
                  border: [true, true, true, true],
                  text: this.translate.instant("Total"),
                },
                {
                  border: [true, true, true, true],
                  style: "" + "textStyle",
                  text: "$" + `${total.toFixed(2)}`,
                },
              ],
            ],
          },
          layout: {
            hLineColor: function (i: any, node: any) {
              return "grey";
            },
            vLineColor: function (i: any, node: any) {
              return "grey";
            },
          },
        },
        {
          style: "tableData",
          table: {
            widths: ["*"],
            body: [
              [
                {
                  border: [true, true, true, true],
                  style: "header",
                  text: this.translate.instant("Observations"),
                  alignment: "center",
                },
              ],
              [
                {
                  border: [true, true, true, true],
                  style: "textStyle",
                  text: invoiceObject.observations || "N/A",
                },
              ],
            ],
          },
          layout: {
            hLineColor: function (i: any, node: any) {
              return "grey";
            },
            vLineColor: function (i: any, node: any) {
              return "grey";
            },
          },
        },
      ],
      footer: (currentPage: any, pageCount: any) => {
        return {
          columns: [
            {
              qr: qrUrl,
              fit: 130,
              alignment: "left",
              margin: [40, 0, 0, 20],
            },
            {
              text: `Page ${currentPage} of ${pageCount}`,
              alignment: "right",
              margin: [0, 105, 40, 0],
            },
          ],
          margin: [10, 0],
        };
      },
      styles: this.getStyles(),
      pageMargins: [40, 20, 40, 150],
    };
    const pdf = pdfMake.createPdf(pdfDefinition);
    pdf.open({}, window);
  }

  getInvoiceDate(invoiceObject: IQuota) {
    let date = "";
    switch (invoiceObject.estado) {
      case 1:
        date = invoiceObject.createdAt;
        break;
      case 2:
        date = invoiceObject.createdAt;
        break;
      case 3:
        date = invoiceObject.delivery_date!;
        break;
      case 4:
        date = invoiceObject.invoiced_date!;
        break;
      default:
        date = "N/A";
        break;
    }
    return date;
  }

  getInvoiceType(invoiceCount: IContFact, invoiceObject: IQuota) {
    let text = "";
    switch (invoiceObject.estado) {
      case 1:
        text = `${this.translate.instant("Quotation/Order")}\n ${
          invoiceCount.quotation
        }`;
        break;
      case 2:
        text = `${this.translate.instant("Reject")}`;
        break;
      case 3:
        text = `${this.translate.instant("Delivery")}\n ${
          invoiceCount.delivery
        }`;
        break;
      case 4:
        text = `${this.translate.instant("Invoice")}\n ${invoiceCount.invoice}`;
        break;
      default:
        text = "N/A";
        break;
    }
    return text;
  }

  getSales(sales: IVentaQuery[], invoiceTax: number, taxIncluded: string) {
    let array: any[] = [];
    sales.forEach((sale, index) => {
      const obj1 = {
        border: [true, true, true, true],
        style: "textStyle",
        text: `${index + 1}`,
      };
      const obj2 = {
        border: [true, true, true, true],
        style: "textStyle",
        text: this.getArticleDescription(invoiceTax, taxIncluded, sale),
      };
      const obj3 = {
        border: [true, true, true, true],
        style: "textStyle",
        text: `${sale.cantidad}`,
      };
      const obj4 = {
        border: [true, true, true, true],
        style: "" + "textStyle",
        text: "$" + `${sale.precio}`,
      };
      const obj5 = {
        border: [true, true, true, true],
        style: "textStyle",
        text: "$" + `${sale.subtotal}`,
      };
      array.push([obj1, obj2, obj3, obj4, obj5]);
    });
    const headers = this.getSaleHeaders(taxIncluded);
    array.unshift(headers.header);
    return array;
  }

  getArticleDescription(
    invoiceTax: number,
    taxIncluded: string,
    sale: IVentaQuery
  ) {
    const initialDescription =
      sale.articulo[0].descripcion + " " + this.translate.instant("Tax");
    if (taxIncluded === "true") {
      return (
        initialDescription + " (" + sale.articulo[0].tax![0].value + ") " + "%"
      );
    } else {
      if (sale.articulo[0].tax![0].value === 0) {
        return initialDescription + " (" + invoiceTax + ") " + "%";
      } else {
        return (
          initialDescription +
          " (" +
          sale.articulo[0].tax![0].value +
          ") " +
          "%"
        );
      }
    }
  }

  getSaleHeaders(taxIncluded: string) {
    return {
      header: [
        {
          border: [true, true, true, true],
          text: this.translate.instant("Item"),
          style: "tableHeader",
          alignment: "center",
        },
        {
          border: [true, true, true, true],
          text: this.translate.instant("Description"),
          style: "tableHeader",
          alignment: "center",
        },
        {
          border: [true, true, true, true],
          text: this.translate.instant("Quantity"),
          style: "tableHeader",
          alignment: "center",
        },
        {
          border: [true, true, true, true],
          text: this.getPriceHeader(taxIncluded),
          style: "tableHeader",
          alignment: "center",
        },
        {
          border: [true, true, true, true],
          text: this.translate.instant("Subtotal"),
          style: "tableHeader",
          alignment: "center",
        },
      ],
    };
  }

  getPriceHeader(taxIncluded: string) {
    if (taxIncluded === "true") {
      return (
        this.translate.instant("Price") + " + " + this.translate.instant("Tax")
      );
    } else {
      return this.translate.instant("Price");
    }
  }

  getStyles() {
    return {
      header: {
        fontSize: 10,
        bold: true,
        color: "black",
      },
      subheader: {
        fontSize: 16,
        bold: true,
        margin: [0, 10, 0, 5],
      },
      tableData: {
        margin: [0, 5, 0, 15],
      },
      tableHeader: {
        bold: true,
        fontSize: 10,
        color: "black",
      },
      textStyle: {
        fontSize: 10,
        color: "#363636",
      },
    };
  }
}

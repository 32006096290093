import { Component, OnInit } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { Subject } from "rxjs";
import { IMovementInventory } from "src/app/@interfaces/movement-inventory.interface";
import { CustomerService } from "src/app/@pages/customer/customer.service";
import { InventoryService } from "src/app/@pages/inventory/inventory.service";
import { QuotationsService } from "src/app/@pages/quotations/quotations.service";
import { ReportsService } from "../../reports.service";
import { NgxSpinnerService } from "ngx-spinner";
import { InventoryMovementsService } from "src/app/@shared/components/services/inventory-movement.service";
import { CalculateSiigoService } from "src/app/@shared/components/services/calculate-siigo.service";
import { takeUntil } from "rxjs/operators";
import { DialogComponent } from "src/app/@shared/components/dialog/dialog.component";
import { IQuota } from "src/app/@interfaces/quotation.interface";
import { IArticles } from "src/app/@interfaces/article.interface";
import { ICustomer } from "src/app/@interfaces/customer.interface";
import * as XLSX from "xlsx";
import { IStorageCompany } from "src/app/@interfaces/company.interface";
import { CookieAuthService } from "src/app/@shared/storage-variables/cookie-auth.service";
import { FunctionsSiigoService } from "src/app/@shared/components/services/functions-siigo.service";

@Component({
  selector: "app-financial-reports",
  templateUrl: "./financial-reports.component.html",
  styleUrls: ["./financial-reports.component.css"],
})
export class FinancialReportsComponent implements OnInit {
  reportList: Array<any> = [
    "Dian Customers",
    "Dian Products",
    "Quotations",
    "Deliverys",
    "Invoices",
    "Customers",
    "Siigo Third Parties",
    "Siigo Products",
    "Accounting Movements",
    "Inventory Movement",
  ];
  unsubscribe$ = new Subject();
  companyId = '';
  companyObject = {} as IStorageCompany;
  companyName = '';
  colspanHeaderSiigo = 0;
  modelName = "";
  inventoryMovementArray: IMovementInventory[] = [];
  year = new Date().getFullYear();
  constructor(
    private inventory: InventoryService,
    private customers: CustomerService,
    private quotations: QuotationsService,
    private dialog: MatDialog,
    private reportsService: ReportsService,
    private invetoryMovement: InventoryMovementsService,
    private spinner: NgxSpinnerService,
    private calculateSiigoService: CalculateSiigoService,
    private cookieAuthService: CookieAuthService,
    private functionsSiigoService: FunctionsSiigoService
  ) {}

  ngOnInit(): void {
    this.getAuthValues();
  }

  getAuthValues() {
    this.companyObject = this.cookieAuthService.getCompanyObject!;
    this.companyId = this.companyObject.Id_company!.toString();
    this.companyName = this.companyObject.name;
  }

  async select(report: string) {
    if (report === "Dian Customers") {
      this.spinner.show();
      this.customers.listCustomers(0, undefined)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((result: any) => {
            const list: Array<ICustomer> = result.customers;
            const customerListFilter = list.filter(
              (list) => list.customer_type?.type != "Prospecto"
            );
            this.arrayCustomers(customerListFilter);
          });
    }
    if (report === "Dian Products") {
      this.spinner.show();
          this.inventory
            .listArticles(null, null, "id_articulo", "ASC")
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe((result: any) => {
              const list: Array<IArticles> = result.articles;
              this.arryProducts(list);
            });
    }
    if (report === "Quotations") {
      this.spinner.show();
      this.quotations
        .listQuotationsByStatus1()
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe((result: any) => {
          const quotationList: Array<IQuota> = result;
          this.dialog.open(DialogComponent, {
            data: { quotationList },
          });
        });
    }
    if (report === "Deliverys") {
      this.spinner.show();
      this.quotations
        .listQuotationsByStatus3()
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe((result: any) => {
          const quotationList: Array<IQuota> = result;
          this.dialog.open(DialogComponent, {
            data: { quotationList },
          });
        });
    }
    if (report === "Invoices") {
      this.spinner.show();
      this.quotations
        .listQuotationsByStatus4()
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe((result: any) => {
          const quotationList: Array<IQuota> = result;
          this.dialog.open(DialogComponent, {
            data: { quotationList },
          });
        });
    }
    if (report === "Customers") {
      this.spinner.show();
      this.customers.listCustomers(0, undefined)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((result: any) => {
            const customersList: Array<ICustomer> = result.customers;
            this.dialog.open(DialogComponent, {
              data: { customersList },
            });
          });
    }
    if (report === "Siigo Third Parties") {
      this.spinner.show();
      this.colspanHeaderSiigo = 76;
      this.modelName = "MODEL THIRD PARTY";
      this.customers.listCustomers(0, undefined)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((result: any) => {
            const customerList: Array<ICustomer> = result.customers;
            const customerListFilter = customerList.filter(
              (list) => list.customer_type?.type != "Prospecto"
            );
            this.siigoThirdParties(customerListFilter);
          });
    }
    if (report === "Siigo Products") {
      this.spinner.show();
      this.colspanHeaderSiigo = 92;
      this.modelName = "MODEL PRODUCTS";
          this.inventory
            .listArticles(null, null, "id_articulo", "ASC")
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe((result: any) => {
              const list: Array<IArticles> = result.articles;
              this.arraySiigoProducts(list);
            });
    }
    if (report === "Accounting Movements") {
      this.dialog.open(DialogComponent, {
        data: { invoicesList: true },
      });
    }
    if (report === "Inventory Movement") {
      this.spinner.show();
      this.colspanHeaderSiigo = 32;
      this.modelName = "INVENTORY MOVEMENTS";
      const warehouseID = await this.calculateSiigoService.getWarehouseId();
      const year = await this.reportsService.getYear();
      if (year) {
        const invoices = await this.calculateSiigoService.getAllInvoices(year);
        const purchases = await this.calculateSiigoService.getAllPurchases(
          year
        );
        const refunds = await this.calculateSiigoService.getAllRefunds(year);
        const notes = await this.calculateSiigoService.getAllNotes(year);
        const warehouseTransfers =
          await this.calculateSiigoService.getAllWarehouseTransfers(year);
        const warehouseOutputsAndEntries =
          await this.calculateSiigoService.getAllWarehouseOutputsAndEntries(
            year
          );
        const inventoryReclassifications =
          await this.calculateSiigoService.getAllInventoryReclassifications(
            year
          );
        invoices.length <= 0 ? this.spinner.hide() : this.spinner.show();
        this.invetoryMovement
          .inventoryMovement(
            invoices,
            purchases,
            refunds,
            notes,
            warehouseTransfers,
            warehouseOutputsAndEntries,
            inventoryReclassifications,
            warehouseID
          )
          .then((result) => {
            this.inventoryMovementArray = result;
            this.exportExcel(
              [],
              "InventoryMovements.xlsx",
              true,
              true,
              "inventoryMovements"
            );
          });
      } else {
        this.spinner.hide();
      }
    }
  }

  arryProducts(list: any): void {
    let array: any[] = [];
    let name = "Items.xlsx";
    for (let i = 0; i < list.length; i++) {
      let vacio = "";
      let imp = "";
      let { codigo, descripcion, tax, type, unit, costo } = list[i];
      let { id_type } = type[0];
      let { id_tax, value } = tax[0];
      let { id_second } = unit[0];

      if (
        id_tax === "1" ||
        id_tax === "2" ||
        id_tax === "3" ||
        id_tax === "4"
      ) {
        imp = "IVA";
      }
      if (id_tax === "5" || id_tax === "6" || id_tax === "7") {
        imp = "INC";
      }
      value = value * 100 + "%";
      array.push({
        Identificador: codigo,
        Descripcion: descripcion,
        Impuestos: imp,
        Valor: value,
        tipos: id_type,
        NoDiligenciar: vacio,
        CodUnitario: id_second,
        NoDiligencia: vacio,
        ValorUnitario: parseInt(costo),
      });
    }
    this.exportExcel(array, name, false, false);
  }

  arrayCustomers(list: any): void {
    let data2: any[] = [];
    const name = "Customers.xlsx";
    for (let i = 0; i < list.length; i++) {
      let { types_docs, doc_nit, nombre, email, direccion, city } = list[i];
      let { id_second } = types_docs[0];
      if (
        id_second === "11" ||
        id_second === "12" ||
        id_second === "13" ||
        id_second === "21" ||
        id_second === "22" ||
        id_second === "41" ||
        id_second === "42" ||
        id_second === "91"
      ) {
        data2.push({
          Tipo: id_second,
          Id: doc_nit,
          Nombre: nombre,
          Apellido: nombre,
          email: email,
          Direccion: direccion,
          CityCode: city,
        });
      }
    }
    this.exportExcel(data2, name, false, false);
  }

  exportExcel(
    list: any[],
    name: string,
    siigo: boolean,
    fromTable: boolean,
    tableName?: string
  ) {
    const workBook: XLSX.WorkBook = XLSX.utils.book_new();
    let mainSheet: XLSX.WorkSheet;
    if (siigo) {
      if (fromTable) {
        setTimeout(() => {
          const table = document.getElementById(tableName!);
          mainSheet = XLSX.utils.table_to_sheet(table);
          this.reportsService.export(workBook, mainSheet, name);
        }, 1);
      } else {
        const table = document.getElementById("header");
        mainSheet = XLSX.utils.table_to_sheet(table);
        XLSX.utils.sheet_add_json(mainSheet, list, { origin: -1 });
        this.reportsService.export(workBook, mainSheet, name);
      }
    } else {
      mainSheet = XLSX.utils.json_to_sheet(list);
      this.reportsService.export(workBook, mainSheet, name);
    }
  }

  async siigoThirdParties(customerlist: any[]) {
    const arrayCustomers =
      this.reportsService.createArrayThirdParties(customerlist);
    const arrayProviders = await this.reportsService.siigoProviders();
    const arrayThirdParties = arrayCustomers.concat(arrayProviders);
    const name = "Terceros.xlsx";
    this.exportExcel(arrayThirdParties, name, true, false);
  }

  arraySiigoProducts(list: IArticles[]): void {
    let data2: any[] = [];
    const name = "Products.xlsx";
    for (let i = 0; i < list.length; i++) {
      let { id_articulo, descripcion, codigo, costo, tax, grupoArt, id_siigo, unidad, date, unit } =
        list[i];
      let { value } = tax![0];
      let { id_grupo } = grupoArt![0];
      const siigoIdSplit = this.calculateSiigoService.splitSiigoID(id_siigo!);
      const formatedDate = this.functionsSiigoService.getDateSeparated(date!);
      data2.push({
        "LÍNEA (OBLIGATORIO)": id_siigo ? siigoIdSplit?.line : 700,
        "GRUPO  (OBLIGATORIO)": id_siigo ? siigoIdSplit?.group : id_grupo,
        "PRODUCTO  (OBLIGATORIO)": id_siigo ? siigoIdSplit?.articleId : id_articulo,
        DESCRIPCIÓN: descripcion,
        "REFERENCIA DE FÁBRICA": codigo,
        "CÓDIGO DE BARRAS": codigo,
        "PRECIO 1": costo,
        "PRECIO 2": 0,
        "PRECIO 3": 0,
        "PRECIO 4": 0,
        "PRECIO 5": 0,
        "PRECIO 6": 0,
        "PRECIO 7": 0,
        "PRECIO 8": 0,
        "PRECIO 9": 0,
        "PRECIO 10": 0,
        "PRECIO 11": 0,
        "PRECIO 12": 0,
        "IVA INCLUIDO": "S",
        "PORCENTAJE DE IVA": value * 100,
        "EXISTENCIA MÁXIMA": "",
        "EXISTENCIA MÍNIMA": "",
        "TIEMPO DE REPOSICIÓN": "",
        "COSTO DE COMPRA": "",
        UNIDAD: unidad,
        "CÓDIGO DE PRODUCTO EQUIVALENTE": "",
        "DESCRIPCIÓN PRODUCTO EQUIVALENTE": "",
        MARCA: "",
        "MANEJA FACTOR DE CONVERSIÓN": "",
        "NOMBRE FACTOR DE CONVERSIÓN 1": "",
        "NOMBRE FACTOR DE CONVERSIÓN 2": "",
        "NOMBRE FACTOR DE CONVERSIÓN 3": "",
        "NOMBRE FACTOR DE CONVERSIÓN 4": "",
        "NOMBRE FACTOR DE CONVERSIÓN 5": "",
        "OPERANDO 1": "",
        "OPERANDO 2": "",
        "OPERANDO 3": "",
        "OPERANDO 4": "",
        "OPERANDO 5": "",
        "FACTOR 1": "",
        "FACTOR 2": "",
        "FACTOR 3": "",
        "FACTOR 4": "",
        "FACTOR 5": "",
        TARIFA: value > 0 ? "3" : "1",
        UBICACIÓN: "",
        "DISCRIMINAR IVA EN LA IMPRESIÓN": "",
        ACTIVO: "",
        PESO: "",
        "ENVIAR A ISIIGO": "",
        "NIT PROVEEDOR 1": "",
        "NIT PROVEEDOR 2": "",
        "NIT PROVEEDOR 3": "",
        "NIT PROVEEDOR 4": "",
        "SUCURSAL PROVEEDOR 1": "",
        "SUCURSAL PROVEEDOR 2": "",
        "SUCURSAL PROVEEDOR 3": "",
        "SUCURSAL PROVEEDOR 4": "",
        "AÑO ULTIMA COMPRA 1": formatedDate.year,
        "MES ULTIMA COMPRA 1": formatedDate.month,
        "DÍA ULTIMA COMPRA 1": formatedDate.day,
        "AÑO ULTIMA COMPRA 2": "",
        "MES ULTIMA COMPRA 2": "",
        "DÍA ULTIMA COMPRA 2": "",
        "AÑO ULTIMA COMPRA 3": "",
        "MES ULTIMA COMPRA 3": "",
        "DÍA ULTIMA COMPRA 3": "",
        "AÑO ULTIMA COMPRA 4": "",
        "MES ULTIMA COMPRA 4": "",
        "DÍA ULTIMA COMPRA 4": "",
        "VALOR ÚLTIMA COMPRA 1": "",
        "VALOR ÚLTIMA COMPRA 2": "",
        "VALOR ÚLTIMA COMPRA 3": "",
        "VALOR ÚLTIMA COMPRA 4": "",
        "DESCRIPCIÓN LARGA": "",
        "MANEJA PESO EN CAJERO": "",
        "MANEJA MÁXIMOS Y MÍNIMOS PARA PRODUCTOS": "",
        "COSTO UNITARIO": "",
        MODELO: "",
        "UNIDAD DE MEDIDA FACTURA ELECTRÓNICA": unit![0].id_second,
        "CÓDIGO ARANCELARIO": "",
        "UNIDAD MEDIDA DE CONVERSIÓN 1 FACTURA ELECTRÓNICA": "",
        "UNIDAD MEDIDA DE CONVERSIÓN 2 FACTURA ELECTRÓNICA": "",
        "UNIDAD MEDIDA DE CONVERSIÓN 3 FACTURA ELECTRÓNICA": "",
        "UNIDAD MEDIDA DE CONVERSIÓN 4 FACTURA ELECTRÓNICA": "",
        "UNIDAD MEDIDA DE CONVERSIÓN 5 FACTURA ELECTRÓNICA": "",
        "APLICA IMPOCONSUMO BOLSA PLASTICA FACTURA ELECTRÓN": "",
      });
    }
    this.exportExcel(data2, name, true, false);
  }

  openDialog() {
    this.dialog.open(DialogComponent, {
      data: { accountingAccounts: true },
    });
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}

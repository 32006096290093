import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DetailsRefundsComponent } from './details-refunds.component';
import { DetailsRefundsRoutingModule } from './details-refunds-routing.module';
import { MaterialModule } from 'src/app/material.module';
import { NgxQRCodeModule } from '@techiediaries/ngx-qrcode';
import { NgxPrintModule } from 'ngx-print';
import { MatIconModule } from '@angular/material/icon';
import { DialogModule } from '@angular/cdk/dialog';
import { TranslateModule } from '@ngx-translate/core';
import { FilterInventoryToAccountingPipe } from 'src/app/@shared/pipe/pipes-csv/inventory-to-accounting.pipe';
import { FunctionsSiigoService } from 'src/app/@shared/components/services/functions-siigo.service';



@NgModule({
  declarations: [DetailsRefundsComponent],
  imports: [
    CommonModule,
    DetailsRefundsRoutingModule,
    MaterialModule,
    NgxQRCodeModule,
    NgxPrintModule,
    MatIconModule, 
    DialogModule,
    TranslateModule
  ],
  providers: [
    FilterInventoryToAccountingPipe,
    FunctionsSiigoService
  ]
})
export class DetailsRefundsModule { }

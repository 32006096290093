import { Component, OnInit, OnDestroy, Input, OnChanges, SimpleChanges } from "@angular/core";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { MatDialog } from "@angular/material/dialog";
import { DialogComponent } from "../dialog/dialog.component";
import { IPurchasesInvoices } from "../../../@interfaces/purchaseInvoice.interface";
import { PurchasesService } from "../../../@pages/purchases/purchases.service";
import { PortfolioService } from "src/app/@pages/portfolio/portfolio.service";
import { CreatePDFPurchasesService } from "../services/createPDFpurchase.service";
import { AccountsPayableService } from "src/app/@pages/accounts-payable/accounts-payable.service";
import { IPurchasePayments } from "../../../@interfaces/payments.interface";
import { TCreatedPdf } from "pdfmake/build/pdfmake";
import { IRefunds } from "src/app/@interfaces/refunds.interface";
import { AlertsService } from '../services/alerts.service';
import * as $ from 'jquery';
import { IPurchaseQuery } from "src/app/@interfaces/purchases.interface";
import { ArticleCostService } from "../services/article-cost.service";
import { CookieAuthService } from "../../storage-variables/cookie-auth.service";
import { IStorageCompany } from "src/app/@interfaces/company.interface";

@Component({
  selector: "app-purchase-card",
  templateUrl: "./purchase-card.component.html",
  styleUrls: ["./purchase-card.component.css"],
})
export class PurchaseCardComponent implements OnInit, OnDestroy, OnChanges {
  @Input() refunds: IRefunds[] = [];
  @Input() purchaseInvoice = {} as IPurchasesInvoices;
  @Input() cardType = 0;
  companyId = '';
  companyName = '';
  companyObject = {} as IStorageCompany;
  unsubscribe$ = new Subject();
  invoicePayments: Array<IPurchasePayments> = [];
  totalPaid: number = 0;
  haveRefund: boolean = true;

  constructor(
    private purchaseService: PurchasesService,
    public dialog: MatDialog,
    private portfolio: PortfolioService,
    public createPDFService: CreatePDFPurchasesService,
    private accountPayService: AccountsPayableService,
    private alertsService: AlertsService,
    private articleCostService: ArticleCostService,
    private cookieAuthService: CookieAuthService
  ) {}

  ngOnInit(): void {
    this.getAuthValues()
    this.portfolio
    .getPaymenPurchase(parseInt(this.purchaseInvoice.id_invoice!.toString()))
    .pipe(takeUntil(this.unsubscribe$))
    .subscribe((result) => {
      this.invoicePayments = result;
      this.totalPaid = 0;
      this.invoicePayments.forEach((element) => {
        this.totalPaid += element.value;
      });
    });
  }

  getAuthValues() {
    this.companyObject = this.cookieAuthService.getCompanyObject!;
    this.companyId = this.companyObject.Id_company!.toString();
    this.companyName = this.companyObject.name;
  }

  ngOnChanges(changes: SimpleChanges): void {
    for (let item in changes) {
      if (item === "refunds") {
        const values: Array<IRefunds> = changes[item].currentValue;
        if (values.length > 0) {
          this.validRefunds(values);
        }
      }
    }
  }

  validRefunds(refunds: IRefunds[]) {
    refunds.forEach(element => {
      if (this.purchaseInvoice.id_invoice?.toString() === element.num_invo.toString()) {
        this.haveRefund = false;
      }
    });
  }

  openDialog() {
    if (this.purchaseInvoice.state === 1) {
      this.dialog.open(DialogComponent, {
        data: { id_purchaseInvoice: this.purchaseInvoice.id_invoice },
      }); 
    } else {
      this.alertsService.showErrorAlert("You cannot edit a purchase in this state")
    }
  }

  configPurchases(
    identify_invoice: number, configInvoicePurchase: boolean, 
    invoicePayments: IPurchasePayments[], idProvider: number) 
  {
    this.dialog.open(DialogComponent, {
      disableClose: true,
      data: { identify_invoice, configInvoicePurchase, invoicePayments, idProvider },
    })
    .afterClosed()
    .pipe(takeUntil(this.unsubscribe$))
    .subscribe(async (dialogClosed) => {
      if (dialogClosed) {
        if (dialogClosed.sended === true) {
          const getPdf = await this.generateReport(false);
          getPdf?.getBase64((data) => {
            this.accountPayService
            .sendMailPurchases(parseInt(this.purchaseInvoice.id_invoice!.toString()), data)
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe((res) => {});
          });
        }
        if (dialogClosed.refresh === true) {
          this.alertsService.refresh("/card", "/accountsPayable");
        }
      } else {
        return;
      }
    });
  }

  generateReport(openReport: boolean) {
    return new Promise<TCreatedPdf>((resolve, reject) => {
      const imageElement = $("#img").attr("src");
      this.purchaseService
      .getPurchaseInvoice(parseInt(this.purchaseInvoice.id_invoice!.toString()))
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(async (result) => {
        this.purchaseInvoice = result;
        const paymentsDescriptions: string[] = this.purchaseInvoice.payment!.map(
          (payment) => payment.description
        );
        const getPdf = await this.createPDFService.createPDF(
          openReport,
          "Cancelled",
          this.purchaseInvoice,
          imageElement,
          paymentsDescriptions.join(", ")
        );
        resolve(getPdf!);
      });
    })
  }

  cancelPurchase(id: number, purchases: any[]) {
    this.alertsService.showConfirmAlert("Do you want to void this purchase?")
    .then((confirm) => {
      if (confirm.isConfirmed) {
        this.purchaseService
        .cancelPurchase(id)
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe((result) => {
          this.updateArticleQuantities(purchases);
          this.alertsService.showSuccessfullAlert("Purchase void")
          .then((successfullConfirm) => {
            if (successfullConfirm.isConfirmed) {
              this.alertsService.refresh("/card", "/purchases");
            }
          });
        },(error) => {
          this.alertsService.showErrorAlert("Something was wrong");
        })
      }
    })
  }

  updateArticleQuantities(purchases: IPurchaseQuery[]) {
    purchases.forEach(element => {
      this.articleCostService.updateAvaliableQuantity(
        element.articulo[0].id_articulo,
        element.warehouse.id_almacen,
        element.quantity,
        2
      )
    });
  }

  refundsDialog(purchase: IPurchasesInvoices) {
    this.dialog.open(DialogComponent, {
      data: { refunds: true, purchase: purchase },
    });
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}

import { Injectable } from "@angular/core";
import { IArticles } from "src/app/@interfaces/article.interface";
import { INotasGrap } from "src/app/@interfaces/notas.interface";
import { IValoresSingle } from "../../../../@interfaces/valores.interface";
import { SetRowsService } from "../../services/set-rows.service";
import { CalculateSiigoService } from "../../services/calculate-siigo.service";
import { IAccountingAccounts } from "src/app/@interfaces/accountingAccounts.interface";
import { FunctionsSiigoService } from "../../services/functions-siigo.service";
import { ISiigoRow } from "src/app/@interfaces/Siigo/rows.interface";
import { ISiigoSales } from "src/app/@interfaces/Siigo/siigo-sales.interface";
import { IVentaQuery } from "src/app/@interfaces/venta.interface";
import { TransactionType } from "src/app/@enums/siigo/siigo-invoices";
import { IDateFormat } from "src/app/@interfaces/Siigo/format-date-siigo.interface";
import { VoucherTypes } from "src/app/@enums/siigo/siigo-voucher";
import { ISiigoNote, ISiigoNoteValues } from "src/app/@interfaces/Siigo/siigo-notes.interface";

@Injectable({
  providedIn: "root",
})
export class ExportSiigoService {
  constructor(
    private setRowsService: SetRowsService,
    private calculateSiigoService: CalculateSiigoService,
    private functionsSiigoService: FunctionsSiigoService
  ) {}

  //Notes

  setNote(notesData: ISiigoNote, noteType: string) {
    const filterNotes = this.filterNotes(notesData.notes, noteType);
    let notesArray: ISiigoRow[] = [];
    for (const element of filterNotes) {
      const parsedDate = this.functionsSiigoService.getDateSeparated(element.createAt!);
      const total = noteType === "Credito" ? element.total * -1 : element.total;
      const electronicNumber = element.billyNotes![0].note_number.replace(/\D+/g, "");
      const documentNumber = electronicNumber ? electronicNumber : element.id_nota!.toString();
      const invoice = element.invoice;
      const dueParsedDate = this.functionsSiigoService.getDateSeparated(invoice?.dueDate!);
      const invoiceElectronicNumber = invoice!.billyInvoice![0].invoice_number?.replace(/\D+/g, "");
      const invoiceNumber = invoice!.id_factura?.toString().replace(/\D+/g, "");
      const invoiceDocumentNumber = invoiceElectronicNumber ? invoiceElectronicNumber : invoiceNumber;
      const voucherNumber = invoice!.billyInvoice![0].voucher;
      const notesRow = this.createNoteRow(
        voucherNumber, documentNumber, notesData.noteAccountingAccounts, 
        total, parsedDate, dueParsedDate, invoice!.seller![0].id_seller, 
        invoice!.cliente[0].doc_nit.split("-")[0], 
        notesData.dateToDay, invoice!.cliente[0].nombre,
        invoiceDocumentNumber!
      )
      const notesSiigo = this.setNoteValues(
        this.createNoteValuesObject(
          notesData, element, documentNumber, parsedDate
        )
      );
      notesArray = notesArray.concat(
        this.setNotesOnArray(notesRow, notesSiigo, notesData.noteAccountingAccounts)
      )
    }
    return notesArray;
  }

  filterNotes(notes: INotasGrap[], typeNote: string) {
    return notes.filter(
      item => item.tipo_nota.toString() === typeNote.toString()
    );
  }

  createNoteRow(
    voucherNumber: number, documentNumber: string, noteAccountingAccounts: IAccountingAccounts,
    total: number, parsedDate: IDateFormat, dueParsedDate: IDateFormat,
    idSeller: number, documentNit: string,
    dateToday: string, name: string, invoiceDocument: string
  ) {
    const mainRow = this.setRowsService.createInvoiceRow(
      VoucherTypes.notes, voucherNumber, documentNumber,
      "PENDING", total, parsedDate, dueParsedDate,
      idSeller, documentNit,dateToday, name, "PENDING",
    )
    return this.setNoteRows(mainRow, noteAccountingAccounts, invoiceDocument);
  }

  setNoteRows(
    mainRow: ISiigoRow, noteAccountingAccounts: 
    IAccountingAccounts, invoiceDocument: string
  ) {
    const row1: ISiigoRow = {
      ...mainRow, accountingAccount: noteAccountingAccounts.total, 
      debitCredit: TransactionType.Credit, paymentMethod: 1, 
      documentNumberCross: invoiceDocument
    }
    const crossDataVoided = this.crossDataVoided();
    const row2: ISiigoRow = {
      ...mainRow, accountingAccount: noteAccountingAccounts.tax, 
      debitCredit: TransactionType.Debit, ...crossDataVoided
    }
    const row3: ISiigoRow = {
      ...mainRow, accountingAccount: noteAccountingAccounts.withholding, 
      debitCredit: TransactionType.Credit, description: "AUTORRETENCION",
      ...crossDataVoided
    }
    const row4: ISiigoRow = {
      ...mainRow, accountingAccount: noteAccountingAccounts.ica, 
      debitCredit: TransactionType.Debit, description: "AUTORRETENCION",
      ...crossDataVoided
    }
    return { row1: row1, row2: row2, row3: row3, row4: row4 }
  }

  crossDataVoided() {
    return {
      voucherTypeCross: '', documentNumberCross: '',
      expirationYear: '', expirationMonth: '', expirationDay: ''
    }
  }

  setNotesOnArray(
    notesRow: any, notesSiigo: ISiigoRow[], 
    noteAccountingAccounts: IAccountingAccounts
  ) {
    let notesArray: ISiigoRow[] = [], taxAmount = 0, withholdingAmount = 0;
    notesSiigo.forEach((element) => {
      notesArray.push(element);
      if (element.accountingAccount === noteAccountingAccounts.inventory || element.accountingAccount === noteAccountingAccounts.service) {
        taxAmount += parseFloat(element.taxValue.toFixed(2));
        withholdingAmount += parseFloat(element.total.toFixed(2)); 
      }
    });
    notesRow.row2.total = taxAmount;
    notesRow.row3.total = withholdingAmount * 0.0055;
    notesRow.row4.total = withholdingAmount * 0.0055;
    notesArray.push(notesRow.row1);
    notesArray.push(notesRow.row2);
    notesArray.push(notesRow.row3);
    notesArray.push(notesRow.row4);
    return notesArray;
  }

  //Note Values

  createNoteValuesObject(
    notesData: ISiigoNote, element: INotasGrap,
    documentNumber: string, parsedDate: IDateFormat
  ): ISiigoNoteValues {
    return {
      invoiceId: element.invoice?.id_factura!,
      inventoryMovements: notesData.inventoryMovements,
      values: element.valor_notas,
      voucherNumber: 1,
      numberDocument: documentNumber,
      invoiceYear: parsedDate.year,
      invoiceMonth: parsedDate.month,
      invoiceDay: parsedDate.day,
      sellerId: element.invoice!.seller![0].id_seller,
      documentNit: element.invoice!.cliente[0].doc_nit.split("-")[0],
      dateToDay: notesData.dateToDay,
      taxIncluded: element.invoice!.tax_incl!,
      invoiceTax: element.invoice!.tax,
      inventoryAccount: notesData.noteAccountingAccounts.inventory,
      serviceAccount: notesData.noteAccountingAccounts.service,
      firstCostAccountingAccounts: notesData.firstCostAccountingAccounts, 
      secondCostAccountingAccounts: notesData.secondCostAccountingAccounts
    }
  }

  setNoteValues(noteValuesData: ISiigoNoteValues) {
    let arrItems: ISiigoRow[] = []
    for (const value of noteValuesData.values) {
      const article: IArticles = value.articulo[0];
      const unitaryPrice = this.functionsSiigoService.validNegatives(value.precio);
      const quantity = this.functionsSiigoService.validNegatives(value.cantidad);
      const accountingAccount = this.getAccountingAccount(article, noteValuesData);
      const cost = this.functionsSiigoService.getCosts(
        noteValuesData.invoiceId, article.codigo!, noteValuesData.inventoryMovements, "F"
      );
      const valuesObject = this.getPriceAndTax(article, noteValuesData, value, unitaryPrice);
      const subtotal = quantity * valuesObject.price;
      const totalTax = (quantity * valuesObject.price) * (valuesObject.percentIvaInt / 100);
      const salesRow = this.createNoteValuesRow(
        noteValuesData, article, value, accountingAccount, 
        subtotal, totalTax, valuesObject, cost, quantity
      )
      arrItems.push(salesRow.row1);
      arrItems.push(salesRow.row2);
      arrItems.push(salesRow.row3);
    };
    return arrItems;
  }

  createNoteValuesRow(
    itemObject: ISiigoNoteValues, article: IArticles, 
    noteValue: IValoresSingle, accountingAccount: string, 
    subtotal: number, totalTax: number, valuesObject: any, 
    cost: number, quantity: number
  ) {
    const mainRow = this.setRowsService.createSaleRow(
      itemObject, article, quantity, noteValue.warehouse.id_almacen
    )
    const crossDataVoided = this.crossDataVoided();
    const row1: ISiigoRow = {
      ...mainRow, accountingAccount: accountingAccount,
      debitCredit: TransactionType.Debit, total: subtotal,
      taxPercent: valuesObject.percentIvaInt, taxValue: totalTax,
      ...crossDataVoided
    }
    const row2: ISiigoRow = {
      ...mainRow, accountingAccount: itemObject.firstCostAccountingAccounts.total,
      debitCredit: TransactionType.Debit, total: cost, ...crossDataVoided
    }
    const row3: ISiigoRow = {
      ...mainRow, accountingAccount: itemObject.secondCostAccountingAccounts.total,
      debitCredit: TransactionType.Credit, total: cost, ...crossDataVoided
    }
    return { row1: row1, row2: row2, row3: row3 }
  }

  //Sales

  setSales(salesObject: ISiigoSales) {
    let arrItems: ISiigoRow[] = [];
    for (const sale of salesObject.sales) {
      const article: IArticles = sale.articulo[0];
      const accountingAccount = this.getAccountingAccount(article, salesObject);
      const cost = this.functionsSiigoService.getCosts(
        salesObject.id_invoice, article.codigo!, salesObject.inventoryMovements, "F"
      );
      const valuesObject = this.getPriceAndTax(article, salesObject, sale, sale.precio);
      const subtotal = sale.cantidad * valuesObject.price;
      const totalTax = (sale.cantidad * valuesObject.price) * (valuesObject.percentIvaInt / 100);
      const salesRow = this.createSalesRow(
        salesObject, article, sale, accountingAccount, 
        subtotal, totalTax, valuesObject, cost, sale.almacen[0].id_almacen
      )
      arrItems.push(salesRow.row1);
      arrItems.push(salesRow.row2);
      arrItems.push(salesRow.row3);
    }
    return arrItems;
  }

  createSalesRow(
    itemObject: ISiigoSales | ISiigoNoteValues, article: IArticles, 
    sale: IVentaQuery | IValoresSingle, accountingAccount: string, 
    subtotal: number, totalTax: number, valuesObject: any, cost: number,
    warehouse: number
  ) {
    const mainRow = this.setRowsService.createSaleRow(
      itemObject, article, sale.cantidad, warehouse
    )
    const row1: ISiigoRow = {
      ...mainRow, accountingAccount: accountingAccount,
      debitCredit: TransactionType.Credit, total: subtotal,
      taxPercent: valuesObject.percentIvaInt, taxValue: totalTax
    }
    const row2: ISiigoRow = {
      ...mainRow, accountingAccount: itemObject.firstCostAccountingAccounts.total,
      debitCredit: TransactionType.Credit, total: cost
    }
    const row3: ISiigoRow = {
      ...mainRow, accountingAccount: itemObject.secondCostAccountingAccounts.total,
      debitCredit: TransactionType.Debit, total: cost
    }
    return { row1: row1, row2: row2, row3: row3 }
  }

  //Get values

  getPriceAndTax(
    article: IArticles, itemObject: ISiigoSales | ISiigoNoteValues, 
    sale: IVentaQuery | IValoresSingle, unitaryPrice: number
  ) {
    let percentIvaFloat = 0, percentIvaInt = 0;
    if (article.tax!.length >= 0) {
      article.tax!.forEach((tax) => {
        const taxValue = tax.value > 0 ? tax.value : itemObject.invoiceTax;
        percentIvaFloat = taxValue + 1;
        percentIvaInt = taxValue * 100;
      });
    }
    const price = this.calculateSiigoService.calculateTax(
      itemObject.taxIncluded,
      sale.descuento,
      unitaryPrice,
      percentIvaFloat
    );
    return { price: price, percentIvaFloat: percentIvaFloat, percentIvaInt: percentIvaInt }
  }

  getAccountingAccount(article: IArticles, salesObject: ISiigoSales | ISiigoNoteValues) {
    return parseInt(article.type![0].id_type.toString()) !== 5
      ? salesObject.serviceAccount
      : salesObject.inventoryAccount;
  }
}

import { Injectable } from "@angular/core";
import { FormArray, UntypedFormGroup } from "@angular/forms";
@Injectable({
  providedIn: "root",
})
export class ErrorsService {
  constructor() {}

  getErrorFromJson(error: any) {
    const jsonString = JSON.stringify(error);
    return JSON.parse(jsonString);
  }

  checkDuplicateArticles(registerForm: UntypedFormGroup): boolean {
    const itemRows = registerForm.get("itemRows") as FormArray;
    const articles = itemRows.value.map((row: any) => row.article.id_articulo);
    const uniqueArticles = new Set(articles);
    const duplicate = articles.length !== uniqueArticles.size;
    if (duplicate) {
      return true;
    } else {
      return false;
    }
  }
}

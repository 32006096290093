import { Component, Input, OnInit, OnDestroy } from "@angular/core";
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from "@angular/forms";
import { TranslateService } from "@ngx-translate/core";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import {
  IInvoicePayments,
  IPaymentsList,
} from "src/app/@interfaces/payments.interface";
import { PortfolioService } from "src/app/@pages/portfolio/portfolio.service";
import Swal, { SweetAlertResult } from "sweetalert2";
import {
  IQuota,
  IQuotation,
} from "../../../../../@interfaces/quotation.interface";
import { QuotationsService } from "../../../../../@pages/quotations/quotations.service";
import { ValidatorsService } from "../../../../validator/validators.service";
import { NgxSpinnerService } from "ngx-spinner";
import { CreatePDFService } from "../../../services/createPDF.service";
import { PaymentsService } from "./payments.service";
import { ITreasury } from "src/app/@interfaces/treasury.interface";
import { MatCheckboxChange } from "@angular/material/checkbox";
import { CookieAuthService } from "src/app/@shared/storage-variables/cookie-auth.service";

@Component({
  selector: "app-payments",
  templateUrl: "./payments.component.html",
  styleUrls: ["./payments.component.css"],
})
export class PaymentsComponent implements OnInit, OnDestroy {
  typesForm: UntypedFormGroup = this.fb.group({
    type: [0, [Validators.required]],
    payment: ["", [Validators.required]],
  });
  paymentsForm: UntypedFormGroup = this.fb.group({
    types: ["", [Validators.required]],
    paymentType: ["", [Validators.required]],
    value: [0,[ Validators.required, Validators.pattern(this.validatorS.quantityPattern)]],
    date: ["", [Validators.required]],
    id_ref: [""],
    positive_balance: [false, [Validators.required]],
    advancePayment: [""],
  });
  @Input() id_factura: number = 0;
  @Input() id_customer: number = 0;
  listBanks: Array<IPaymentsList> = [];
  listCashiers: Array<IPaymentsList> = [];
  advancePayments: ITreasury[] = [];
  @Input() invoicePayments: Array<IInvoicePayments> = [];
  totalQuot: number = 0;
  paidQuot: number = 0;
  missingPaid: number = 0;
  totalPositivebalance: number = 0;
  sended: boolean = false;
  refresh: boolean = false;
  user = '';
  rol = '';
  quotationObj = {} as IQuota;
  quotation = {} as IQuotation;
  positive_balance_value: number = 0;
  selectedCheckBoxIndex: number = -1
  paymentsObj = [
    { value: 1, viewValue: "Cash" },
    { value: 2, viewValue: "Current account consignment" },
    { value: 3, viewValue: "Savings account consignment" },
  ];
  typesObj = [
    { value: "Credit", viewValue: "Credit" },
    { value: "Cancellation", viewValue: "Cancellation" },
  ];
  unsubscribe$ = new Subject();

  constructor(
    private validatorS: ValidatorsService,
    private translate: TranslateService,
    private portfolio: PortfolioService,
    private fb: UntypedFormBuilder,
    private quotationService: QuotationsService,
    private spinnerService: NgxSpinnerService,
    private createPDFService: CreatePDFService,
    private paymentsService: PaymentsService,
    private cookieAuthService: CookieAuthService
  ) {}

  async ngOnInit(): Promise<void> {
    this.getAuthValues();
    const creditValues = await this.paymentsService.getCreditValues(
      this.id_factura
    );
    const debitValues = await this.paymentsService.getDebitsValues(
      this.id_factura
    );
    const invoice = await this.paymentsService.getInvoice(this.id_factura);
    this.quotationObj = invoice;
    this.quotation = invoice;
    this.totalQuot += this.quotationObj.venta[0].total;
    this.totalQuot -= creditValues;
    this.totalQuot += debitValues;
    this.invoicePayments.forEach((element) => {
      this.paidQuot += element.value;
    });
    this.listsData();
    this.positive_balance_value = await this.paymentsService.getPositiveBalance(this.id_customer);
    this.updateTotalPaidAndPositiveBalance();
  }

  getAuthValues() {
    this.user = this.cookieAuthService.getUserId!;
    this.rol = this.cookieAuthService.getRolId!;
  }

  async listsData() {
    this.listCashiers = await this.paymentsService.getCashierList();
    this.listBanks = await this.paymentsService.getBankList();
    this.getAdvancePayments();
  }

  async getAdvancePayments() {
    const paymentsIDS = this.quotationObj.payment?.map((result) => result.id_payment);
    this.advancePayments = await this.paymentsService.getAdvancePayments(paymentsIDS!, this.id_customer, null, "Customers");
  }

  setAdvancePayment(payment: ITreasury, index: number, event: MatCheckboxChange) {
    if (event.checked) {
      this.selectedCheckBoxIndex = index;
      const paymentType = this.quotationObj.payment?.filter((result) => result.id_payment === payment.paymentType.id_payment);
      this.paymentsForm.patchValue({
        paymentType: paymentType![0],
        value: this.paymentsService.getTotalWithAdvancePayment(this.missingPaid, this.calculateAvaliable(payment.value, payment.value_used)),
        date: new Date(payment.date),
        id_ref: payment.id_ref,
        positive_balance: false,
        advancePayment: payment
      }); 
    } else {
      this.resetPaymentsForm();
    }
  }

  resetPaymentsForm() {
    if (this.selectedCheckBoxIndex !== -1) {
      this.selectedCheckBoxIndex = -1;
      this.paymentsForm.patchValue({
        paymentType: "",
        value: "",
        date: "",
        id_ref: "" ,
        advancePayment: ""
      }); 
    }
  }

  updateStatusInvoice(id: number, p_vendor: boolean, cancelled: boolean) {
    this.changePaymentStatus(id, p_vendor, cancelled);
  }

  changePaymentStatus(id: number, p_vendor: boolean, cancelled: boolean) {
    Swal.fire({
      title: this.translate.instant("Are you sure?"),
      text: this.translate.instant("You won not be able to revert this!"),
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#ff5400",
      cancelButtonColor: "rgb(0, 0, 0)",
      confirmButtonText: "Confirm",
    }).then((result) => {
      if (result.isConfirmed) {
        this.updateStatus(id, p_vendor, cancelled);
      }
    });
  }

  updateStatus(id: number, p_vendor: boolean, cancelled: boolean) {
    this.portfolio
    .updateStatusPayment(id, p_vendor, cancelled)
    .subscribe((result) => {
      if (result) {
        this.portfolio
        .getPaymenInvoice(this.id_factura)
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe((result) => {
          this.invoicePayments = result;
        });
        Swal.fire(
          this.translate.instant("OK"),
          this.translate.instant("Status has been changed succesfully."),
          "success"
        ).then((data) => {
          this.afterUpdateStatus(data, cancelled);
        });
      }
    });
  }

  afterUpdateStatus(data: SweetAlertResult<any>, cancelled: boolean) {
    if (data.isConfirmed) {
      if (parseFloat(this.paidQuot.toFixed(2)) < parseFloat(this.totalQuot.toFixed(2))) {
        return;
      }
      if (!this.quotationObj.payment) {
        return;
      }
      if (cancelled === true) {
        this.validPayments(this.invoicePayments);
      } else {
        return;
      }
    }
  }

  validPayments(invoicePayments: IInvoicePayments[]) {
    const valid = this.paymentsService.validCompletePayments(invoicePayments);
    if (valid === true) {
      this.changeStatus();
    } else {
      return;
    }
  }

  async changeStatus() {
    this.spinnerService.show();
    const imageElement = $("#img").attr("src");
    this.quotationService
    .getQuotation(this.quotation.id_factura!)
    .pipe(takeUntil(this.unsubscribe$))
    .subscribe(async (result) => {
      this.afterChangeStatus(imageElement, result);
    });
  }

  async afterChangeStatus(imageElement: string | undefined, result: any) {
    this.quotation = result;
    const paymentsDescriptions: string[] = this.quotationObj.payment!.map(
      (payment) => payment.description
    );
    const getPdf = await this.createPDFService.createPDF(
      false,
      "Cancelled",
      this.quotation,
      imageElement,
      paymentsDescriptions.join(", ")
    );
    getPdf?.getBase64((data) => {
      this.quotationService
      .updateStatusInvoice(this.id_factura, false, true, data)
      .subscribe((result) => {
        this.afterUpdateStatusInvoice(result);
      });
    });
  }

  afterUpdateStatusInvoice(result: any) {
    if (result) {
      this.spinnerService.hide();
      Swal.fire(
        this.translate.instant("Cancelled"),
        this.translate.instant("Status has been changed succesfully."),
        "success"
      );
      this.refresh = true;
    } else {
      Swal.fire(
        this.translate.instant("Something was wrong"),
        this.translate.instant("error trying to update the quotation"),
        "error"
      );
    }
  }

  createPayment() {
    const value = parseFloat(this.paymentsForm.get("value")?.value);
    const advancePayment = this.paymentsForm.get("advancePayment")?.value;
    const valuePositiveBalance: boolean = this.paymentsForm.get("positive_balance")?.value;
    const descriptionPayment = this.paymentsService.getDescriptionPayment(valuePositiveBalance, this.paymentsForm);
    const advancePaymentValidations = advancePayment ? this.paymentsService.advancePaymentValidations(advancePayment, value) : true;
    const validations = this.paymentsService.createValidations(
      this.paymentsForm, value, this.positive_balance_value, 
      valuePositiveBalance, this.missingPaid, this.quotationObj.payment!
    )
    if (validations && advancePaymentValidations) {
      this.portfolio
      .createPaymentInvoice(
        this.id_factura,
        this.paymentsForm.get("paymentType")?.value.id_payment,
        this.paymentsForm.get("types")?.value,
        new Date(this.paymentsForm.get("date")?.value).toDateString(),
        value,
        this.paymentsForm.get("id_ref")?.value,
        descriptionPayment
      )
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((result) => {
        if (result) {
          Swal.fire({
            title: this.translate.instant("Created"),
            text: this.translate.instant("Payment created successfully!"),
            icon: "success",
            showCancelButton: false,
            confirmButtonColor: "#f44336",
            confirmButtonText: "Ok",
          }).then((data) => {
            this.afterCreatePayment(data, valuePositiveBalance, value, result, advancePayment);
          });
        } else {
          Swal.fire(
            this.translate.instant("Something was wrong"),
            this.translate.instant("Failed"),
            "error"
          );
        }
      });
    }else {
      return;
    }
  }

  afterCreatePayment(data: SweetAlertResult<any>, valuePositiveBalance: boolean, value: number, result: IInvoicePayments, advancePayment: ITreasury) {
    if (data.isConfirmed) {
      if (valuePositiveBalance) {
        this.updatePositiveBalanceByCustomer(this.id_customer, this.positive_balance_value - value);
      }
      if (advancePayment) {
        this.paymentsService.relateAdvancePayment("Set", "Invoice", result.id_payinvoice, advancePayment.id_treasury, parseFloat(value.toString())).then((ok) => {
          this.getAdvancePayments();
          this.resetPaymentsForm();
        }).catch((error) => {
          console.error(error)
        });
      }
      this.paidQuot += result.value;
      this.handlePositiveBalanceUpdate(value);
      this.updateTotalPaidAndPositiveBalance();
      this.updatePayments();
      this.sended = true;
      this.refresh = true;
    }
  }

  handlePositiveBalanceUpdate(value: number) {
    if (value > this.missingPaid && this.missingPaid !== 0) {
      const valueUpdate = value - this.missingPaid;
      this.updatePositiveBalanceByCustomer(
        this.id_customer,
        this.positive_balance_value + valueUpdate
      );
      this.paymentsService.getPositiveBalance(this.id_customer);
    } else if (value > this.missingPaid && this.missingPaid === 0) {
      this.updatePositiveBalanceByCustomer(
        this.id_customer,
        this.positive_balance_value + value
      );
      this.paymentsService.getPositiveBalance(this.id_customer);
    }
  }

  updateTotalPaidAndPositiveBalance() {
    if ((this.totalQuot - this.paidQuot) < 0) {
      this.missingPaid = 0;
      this.totalPositivebalance = this.paidQuot - this.totalQuot;
    } else {
      this.missingPaid = this.totalQuot - this.paidQuot;
      this.totalPositivebalance = 0;
    }
  }

  confirmDelete(id: number, paymentDelete: IInvoicePayments | null, type: boolean) {
    Swal.fire({
      title: this.translate.instant("Are you sure?"),
      text: this.translate.instant("You won not be able to revert this!"),
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#ff5400",
      cancelButtonColor: "rgb(0, 0, 0)",
      confirmButtonText: "Confirm",
    }).then((result) => {
      if (result.isConfirmed) {
        if (type) {
          this.removePaymentType(id);
        } else {
          this.removePayment(id, paymentDelete!);
        }
      }
    });
  }

  updatePositiveBalanceByCustomer(id_customer: number, positiveBalance: number) {
    this.paymentsService.updatePositiveBalanceByCustomer(id_customer, positiveBalance).then(async (result) => {
      if (result) {
        this.positive_balance_value = await this.paymentsService.getPositiveBalance(
          this.id_customer
        );
      }
    })
  }

  updatePositiveBalanceByInvoice(id_factura: number, positiveBalance: number) {
    this.paymentsService.updatePositiveBalanceByInvoice(id_factura, positiveBalance);
  }

  removePaymentType(id: number) {
    const filterPayments = this.invoicePayments.filter(
      (item) => item.paymentType[0].id_payment === id
    );
    const ids_delete = filterPayments.map((item) =>
      parseInt(item.id_payinvoice.toString())
    );
    const trasury_delete = filterPayments.map((item) => {
      return {
        id_payinvoice: parseInt(item.id_payinvoice.toString()),
        id_treasury: parseInt(item.treasury[0]?.id_treasury.toString()),
        value: item.value
      }
    })
    const values_delete = filterPayments.map((item) =>
      parseFloat(item.value.toString())
    );
    this.portfolio
    .removeInvoicePays(this.id_factura, id, ids_delete)
    .pipe(takeUntil(this.unsubscribe$))
    .subscribe((result) => {
      Swal.fire(
        this.translate.instant("Good"),
        this.translate.instant("Payment removed"),
        "success"
      ).then((data) => {
        this.afterRemovePaymentType(values_delete, data, trasury_delete);
      });
    },(error) => {
      Swal.fire(
        "Error",
        this.translate.instant("Something was wrong") + error,
        "error"
      );
    });
  }

  afterRemovePaymentType(values_delete: number[], data: SweetAlertResult<any>, trasury_delete: any[]) {
    if (data.isConfirmed) {
      if (values_delete.length > 0) {
        values_delete.forEach((value) => {
          this.paidQuot -= value;
          this.missingPaid += value;
        });
      }
      if (trasury_delete.length > 0) {
        trasury_delete.forEach(value => {
          if (value.id_treasury) {
            this.paymentsService.relateAdvancePayment("Remove", "Invoice", value.id_payinvoice, value.id_treasury, parseFloat(value.value.toString())).then((ok) => {
              this.getAdvancePayments();
            }).catch((error) => {
              console.error(error)
            }); 
          }
        });
      }
      this.updatePayments();
      this.updateInvoice();
      this.refresh = true;
    }
  }

  removePayment(id: number, paymentDelete: IInvoicePayments) {
    this.portfolio
    .removePaymentInvoice(id)
    .pipe(takeUntil(this.unsubscribe$))
    .subscribe((result) => {
      if (!result) {
        Swal.fire(
          "Error",
          this.translate.instant("Something was wrong"),
          "error"
        );
      } else {
        Swal.fire(
          this.translate.instant("Good"),
          this.translate.instant("Payment removed"),
          "success"
        ).then((data) => {
          this.afterRemovePayment(result, data, paymentDelete);
        });
      }
    });
  }

  afterRemovePayment(result: any, data: SweetAlertResult<any>, paymentDelete: IInvoicePayments) {
    if (data.isConfirmed) {
      if (paymentDelete.treasury.length > 0) {
        this.paymentsService.relateAdvancePayment("Remove", "Invoice", paymentDelete.id_payinvoice, paymentDelete.treasury[0].id_treasury, parseFloat(paymentDelete.value.toString())).then((ok) => {
          this.getAdvancePayments();
        }).catch((error) => {
          console.error(error)
        });  
      }
      this.paidQuot -= result.value;
      this.deletePositiveBalance(paymentDelete);
      this.updateTotalPaidAndPositiveBalance();
      this.updatePayments();
      this.refresh = true;
    }
  }

  deletePositiveBalance(paymentDelete: IInvoicePayments) {
    const valueDelete = paymentDelete.value - this.totalPositivebalance;
    if (paymentDelete.payment_description === "positive_balance") {
      this.updatePositiveBalanceByCustomer(
        this.id_customer,
        this.positive_balance_value + paymentDelete.value
      );
    }
    if (valueDelete < 0 && paymentDelete.payment_description !== "positive_balance") {
      this.updatePositiveBalanceByCustomer(
        this.id_customer,
        this.positive_balance_value - paymentDelete.value
      );
    }
    if ((valueDelete >= 0) && paymentDelete.payment_description !== "positive_balance") {
      this.updatePositiveBalanceByCustomer(
        this.id_customer,
        this.positive_balance_value - this.totalPositivebalance
      );
    }
  }

  updatePayments() {
    this.portfolio
    .getPaymenInvoice(this.id_factura)
    .pipe(takeUntil(this.unsubscribe$))
    .subscribe((result) => {
      this.invoicePayments = result;
    });
  }

  updateInvoice() {
    this.quotationService
    .getQuotation(this.id_factura)
    .pipe(takeUntil(this.unsubscribe$))
    .subscribe((invoice) => {
      this.quotationObj = invoice;
      this.getAdvancePayments();
    });
  }

  addPaymentType() {
    if (this.typesForm.invalid === true) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: this.translate.instant("Type Invalid, Please Check!"),
      });
      return;
    }
    this.portfolio
    .assingInvoicePays(
      this.id_factura,
      this.typesForm.get("payment")?.value,
      this.typesForm.get("type")?.value
    )
    .pipe(takeUntil(this.unsubscribe$))
    .subscribe((result) => {
      if (result) {
        Swal.fire({
          title: this.translate.instant("Created"),
          text: this.translate.instant("Payment added successfully!"),
          icon: "success",
          showCancelButton: false,
          confirmButtonColor: "#f44336",
          confirmButtonText: "Ok",
        }).then((data) => {
          this.afterAddPaymentType(data);
        });
      } else {
        Swal.fire(
          this.translate.instant("Something was wrong"),
          this.translate.instant("Failed"),
          "error"
        );
      }
    });
  }

  afterAddPaymentType(data: SweetAlertResult<any>) {
    if (data.isConfirmed) {
      this.updateInvoice();
      this.refresh = true;
    }
  }

  textValid(text: string) {
    return (
      this.typesForm.get(text)?.invalid && this.typesForm.get(text)?.touched
    );
  }

  textValid2(text: string) {
    return (
      this.paymentsForm.get(text)?.invalid &&
      this.paymentsForm.get(text)?.touched
    );
  }

  calculateAvaliable(total: number, used: number) {
    return parseFloat((total - used).toFixed(2));
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}

import { Component, Input, OnInit, OnDestroy } from "@angular/core";
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from "@angular/forms";
import { QuotationsService } from "src/app/@pages/quotations/quotations.service";
import Swal from "sweetalert2";
import { CustomerService } from "../../../../../@pages/customer/customer.service";
import { Router } from "@angular/router";
import { IQuotation } from "src/app/@interfaces/quotation.interface";
import { TranslateService } from "@ngx-translate/core";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { AlertsService } from '../../../services/alerts.service';
import { CookieAuthService } from "src/app/@shared/storage-variables/cookie-auth.service";

@Component({
  selector: "app-delivery",
  templateUrl: "./delivery.component.html",
  styleUrls: ["./delivery.component.css"],
})
export class DeliveryComponent implements OnInit, OnDestroy {
  deliveryTxt: string = "";
  @Input() dataD: { id_customer: number; id_quotation: number, isProspective: boolean } = {
    id_quotation: 0,
    id_customer: 0,
    isProspective: false
  };

  registerForm: UntypedFormGroup = this.fb.group({
    invoice: [this.dataD.id_quotation, Validators.required],
    chofer: [, Validators.required],
    cin: [, Validators.required],
    placa: [, Validators.required],
    permiso_rasda: [, Validators.required],
    destino: [, Validators.required],
    peso_neto: [, Validators.required],
    tasa_c: [0.0, Validators.required],
    son: [, Validators.required],
  });

  companyId = '';

  quotation = {} as IQuotation;
  dollars: number = 0;
  calcDevise: number = 0;
  unsubscribe$ = new Subject();

  constructor(
    private customerS: CustomerService,
    private quotationS: QuotationsService,
    private router: Router,
    private fb: UntypedFormBuilder,
    private translate: TranslateService,
    private alertsService: AlertsService,
    private cookieAuthService: CookieAuthService
  ) {}

  ngOnInit() {
    this.getAuthValues();
    this.quotationS
    .getQuotation(this.dataD.id_quotation)
    .pipe(takeUntil(this.unsubscribe$))
    .subscribe((result) => {
      this.quotation = result;
      const { venta } = this.quotation;
      for (let z = 0; z < venta.length; z++) {
        this.dollars =
          this.dollars + parseFloat(venta[z].subtotal.toString());
      }
      this.registerForm.patchValue({
        invoice: result.num_fac,
      });
    });
  }

  getAuthValues() {
    const company = this.cookieAuthService.getCompanyObject;
    this.companyId = company!.Id_company!.toString();
  }

  update() {
    if (this.registerForm.invalid === true) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: this.translate.instant(
          "Please, fill in all the required fields!"
        ),
      });
      return;
    }
    this.quotationS
    .createDriver(
      this.registerForm.value.invoice,
      this.registerForm.value.chofer,
      this.registerForm.value.cin,
      this.registerForm.value.placa,
      this.registerForm.value.permiso_rasda,
      this.registerForm.value.destino,
      this.registerForm.value.peso_neto,
      parseFloat(this.registerForm.value.tasa_c),
      this.registerForm.value.son
    )
    .pipe(takeUntil(this.unsubscribe$))
    .subscribe((res) => {
      if (res != null) {
        this.customerS
        .updateDelivery(
          parseInt(this.dataD.id_customer.toString()),
          this.deliveryTxt
        )
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe((result) => {
          if (result) {
            const deliveryDate = new Date().toISOString();
            this.quotationS
            .updateStatus(
              this.dataD.id_quotation,
              false,
              true,
              deliveryDate,
              false,
              "",
              false,
              1
            )
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe((result) => {
              if (result) {
                Swal.fire(
                  this.translate.instant("Good"),
                  this.translate.instant("Quotation has beed updated successfully"),
                  "success"
                ).then((result) => {
                  if (result.isConfirmed) {
                    this.router
                    .navigateByUrl("/card", {
                      skipLocationChange: true,
                    })
                    .then(() => {
                      this.router.navigate(["/sales"]);
                    });
                  }
                });
              } else {
                Swal.fire(
                  "Error",
                  this.translate.instant("Something was wrong"),
                  "error"
                );
              }
            });
          }
        });
      }
    });
  }

  validation(action: number) {
    if(this.dataD.isProspective) {
      this.alertsService.showErrorAlert("You cannot change the status because the customer is a prospect!");
    }else {
      action === 1 ? this.save() : this.update();
    }
  }

  save() {
    this.customerS
    .updateDelivery(
      parseInt(this.dataD.id_customer.toString()),
      this.deliveryTxt
    )
    .pipe(takeUntil(this.unsubscribe$))
    .subscribe((result) => {
      if (result) {
        const deliveryDate = new Date().toISOString();
        this.quotationS
        .updateStatus(
          this.dataD.id_quotation,
          false,
          true,
          deliveryDate,
          false,
          "",
          false,
          1
        )
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe((result) => {
          if (result) {
            Swal.fire(
              this.translate.instant("Good"),
              this.translate.instant(
                "Quotation has beed updated successfully"
              ),
              "success"
            ).then((result) => {
              if (result.isConfirmed) {
                this.router
                .navigateByUrl("/card", { skipLocationChange: true })
                .then(() => {
                  this.router.navigate(["/sales"]);
                });
              }
            });
          } else {
            Swal.fire(
              "Error",
              this.translate.instant("Something was wrong"),
              "error"
            );
          }
        });
      }
    });
  }

  calculation() {
    let dollar = this.dollars;
    let tasa = parseFloat(this.registerForm.value.tasa_c);
    this.calcDevise = dollar * tasa;
  }

  textValid(text: string, index?: number) {
    return (
      this.registerForm.get(text)?.invalid &&
      this.registerForm.get(text)?.touched
    );
  }

  ngOnDestroy(): void {
    // this.unsubscribe$.next();
    // this.unsubscribe$.complete();
  }
}

import { Component, OnChanges, Input, SimpleChanges, OnInit } from "@angular/core";
import { IQuota } from "src/app/@interfaces/quotation.interface";
import { DataItem } from "../../components/stacked-bar/stacked-bar.service";
import { IStorageCompany } from "src/app/@interfaces/company.interface";
import { CookieAuthService } from "src/app/@shared/storage-variables/cookie-auth.service";

@Component({
  selector: "app-sales-per-seller",
  templateUrl: "./sales-per-seller.component.html",
  styleUrls: ["./sales-per-seller.component.css"],
})
export class SalesPerSellerComponent implements OnChanges {
  @Input() quotToday: Array<IQuota> = [];
  quots: Array<IQuota> = [];
  data: DataItem[] = [];
  companyObject = {} as IStorageCompany;

  constructor(private cookieAuthService: CookieAuthService) {}

  ngOnChanges(changes: SimpleChanges): void {
    this.getAuthValues();
    for (let item in changes) {
      if (item === "quotToday") {
        const values: Array<IQuota> = changes[item].currentValue;
        if (values.length > 0) {
          this.quots = values;
        }
      }
      if (this.quots.length > 0) {
        this.calcQuotTodayV1(this.quots);
      }
    }
  }

  getAuthValues() {
    this.companyObject = this.cookieAuthService.getCompanyObject!;
  }

  calcQuotTodayV1(invoices: IQuota[]) {
    let filterInvoices: IQuota[] = []
    if (this.companyObject.external_electronic_invoicing === "true" || this.companyObject.internal_electronic_invoicing === "true") {
      filterInvoices = invoices.filter((data) => data.estado === 4 && data.billyInvoice![0].invoice_number !== "PENDING");
    } else {
      filterInvoices = invoices.filter((data) => data.estado === 4);
    }
    let arrayCant1: string[] = [];
    let arraySeller: string[] = [];
    let data: DataItem[] = [];
    let dataSellers: DataItem[] = [];
    let dataObj = {} as DataItem;
    const date = new Date();
    const month = date.getMonth() + 1;
    const year = date.getFullYear();
    let created;

    filterInvoices.forEach((element) => {
      if (element.estado === 1 || element.estado === 3 || element.estado === 4) {
        //Quotations
        created = new Date(element.createdAt);
        if (created.getMonth() + 1 === month && created.getFullYear() === year) {
          arrayCant1.push(
            new Date(created).getFullYear() +
              "/" +
              (new Date(created).getMonth() + 1) +
              "/" +
              new Date(created).getDate()
          );
          dataSellers.push({
            arg: element.seller![0].nombre,
            val: 0,
            parentID:
              new Date(created).getFullYear() +
              "/" +
              (new Date(created).getMonth() + 1) +
              "/" +
              new Date(created).getDate(),
          });
          arraySeller.push(element.seller![0].nombre);
        }
      }
    });

    let elemento = [];
    let repetidos = [];
    let contador = 1;

    for (let index = 0; index < arrayCant1.length; index++) {
      if (arrayCant1[index + 1] === arrayCant1[index]) {
        contador++;
      } else {
        elemento.push(arrayCant1[index]);
        repetidos.push(contador);
        contador = 1;
      }
    }
    for (let g = 0; g < elemento.length; g++) {
      data.push({ arg: elemento[g], val: repetidos[g], parentID: "" });
    }

    let groupSeller = arraySeller.filter((item, index) => {
      return arraySeller.indexOf(item) === index;
    });
    elemento.forEach((date) => {
      groupSeller.forEach((seller) => {
        let arg = "";
        let val = 0;
        let parentID = "";
        let filter = dataSellers.filter(
          (dato) => dato.arg === seller && dato.parentID === date
        );
        if (filter.length > 0) {
          filter.forEach((element) => {
            arg = element.arg;
            val = val + 1;
            parentID = element.parentID;
          });
          dataObj = {
            arg: arg,
            val: val,
            parentID: parentID,
          };
          data.push(dataObj);
        }
      });
    });
    this.data = data;
  }
}

import { Component, OnInit } from '@angular/core';
import { NgxQrcodeElementTypes, NgxQrcodeErrorCorrectionLevels } from '@techiediaries/ngx-qrcode';
import { IContPurchase } from 'src/app/@interfaces/contpurch.interface';
import { IPurchaseInvoice } from 'src/app/@interfaces/purchaseInvoice.interface';
import { IRefundsSingle, IRefundsValuesSingle } from 'src/app/@interfaces/refunds.interface';
import { Subject } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { PurchasesService } from '../purchases/purchases.service';
import { AuthService } from 'src/app/@shared/authentication/auth.service';
import { takeUntil } from 'rxjs/operators';
import { FunctionsSiigoService } from 'src/app/@shared/components/services/functions-siigo.service';
import { CookieAuthService } from 'src/app/@shared/storage-variables/cookie-auth.service';

@Component({
  selector: 'app-details-refunds',
  templateUrl: './details-refunds.component.html',
  styleUrls: ['./details-refunds.component.css']
})
export class DetailsRefundsComponent implements OnInit {
  id_refund: number = 0;
  id_invoice: number = 0;
  subtotal : number = 0;
  taxCalculated: number = 0;
  discount: number = 0;
  total: number = 0;
  elementType = NgxQrcodeElementTypes.URL;
  correctionLevel = NgxQrcodeErrorCorrectionLevels.HIGH;
  value = '';
  purchaseInvoice = {} as IPurchaseInvoice;
  refundObject = {} as IRefundsSingle;
  values: IRefundsValuesSingle[] = [];
  contpurchase = {} as IContPurchase;
  companyId = '';
  unsubscribe$ = new Subject();

  constructor(
    private router: ActivatedRoute, 
    private purchasesService: PurchasesService,
    private functionsSiigoService: FunctionsSiigoService,
    private authService: AuthService,
    private cookieAuthService: CookieAuthService
  ) { }

  async ngOnInit(): Promise<void> {
    await this.authService.checkAuthStatus();
    this.getAuthValues();
    this.setDocument();
  }

  getAuthValues() {
    const company = this.cookieAuthService.getCompanyObject;
    this.companyId = company!.Id_company!.toString();
  }

  setDocument(){
    this.router.params.pipe(takeUntil(this.unsubscribe$)).subscribe((params) => {
      this.id_invoice = params.id_invoice;
      this.id_refund = params.id_refund;
      this.value = 'https://v1-insight.com/#/details_refunds/' + this.id_invoice + '/' + this.id_refund;
      this.purchasesService.getPurchaseInvoice(this.id_invoice).pipe(takeUntil(this.unsubscribe$)).subscribe((data) => {
        this.purchaseInvoice = data;
        this.contpurchase = this.purchaseInvoice.contpurchase![0];
        this.purchasesService.getSingleRefund(parseInt(this.id_refund.toString())).pipe(takeUntil(this.unsubscribe$)).subscribe((result) => {
          this.refundObject = result;
          this.total -= this.refundObject.icaTax;
          this.total -= this.refundObject.withholdingTax;
          this.values = this.refundObject.refunds_values!;
          this.values.forEach(element => {
            let disco = 0;
            let subtotal = 0;
            let discountedSubtotal = 0;
            let subCalc = 0;
            let calcTax = 0
            let tax = 0;
            const contributorID = parseInt(this.purchaseInvoice.provider[0].contributorType!.id_contributor.toString());
            if (this.purchaseInvoice.tax_incl === 'false') {
              disco = (element.discount / 100) * (element.quantity * element.price);
              if (element.articulo![0].tax![0].value === 0) {
                tax = this.functionsSiigoService.getContributorTax(contributorID, this.purchaseInvoice.tax / 100);
                subtotal = element.quantity * element.price;
                discountedSubtotal = subtotal - disco;
                calcTax = discountedSubtotal * tax;
                subCalc = discountedSubtotal + calcTax;
              } else {
                tax = this.functionsSiigoService.getContributorTax(contributorID, element.articulo![0].tax![0].value);
                subtotal = element.quantity * element.price;
                discountedSubtotal = subtotal - disco;
                calcTax = discountedSubtotal * tax;
                subCalc = discountedSubtotal + calcTax;
              }
            } else if (this.purchaseInvoice.tax_incl === 'true') {
              tax = this.functionsSiigoService.getContributorTax(contributorID, element.articulo![0].tax![0].value);
              subtotal = element.quantity * (element.price / (tax + 1));
              disco = (element.discount / 100) * subtotal;
              subtotal = subtotal - disco;
              calcTax = subtotal * tax;
              subCalc = (subtotal + calcTax);
            }
            this.taxCalculated += calcTax;
            this.subtotal += subtotal;
            this.discount += disco;
            this.total += Math.abs(subCalc);  
          })
        })
      })
    })
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  print() {
    window.print();
  }
}

import { Injectable } from "@angular/core";
import { Subject } from "rxjs";
import { IPurchaseInvoice } from "src/app/@interfaces/purchaseInvoice.interface";
import { IArticles } from "src/app/@interfaces/article.interface";
import { IQuota } from "src/app/@interfaces/quotation.interface";
import { INotasGrap } from "src/app/@interfaces/notas.interface";
import { IMovementInventory } from "src/app/@interfaces/movement-inventory.interface";
import { CalculateSiigoService } from "./calculate-siigo.service";
import { IRefundsSingle } from "src/app/@interfaces/refunds.interface";
import { FunctionsSiigoService } from "./functions-siigo.service";
import { IWarehouseTransfer } from "src/app/@interfaces/warehouse.interface";
import { SetRowsService } from "./set-rows.service";
import { IWarehouseOutputAndEntry } from "src/app/@interfaces/warehouse.interface";
import { IInventoryReclassification } from "src/app/@interfaces/inventoryReclassification.interface";
import { VoucherTypes } from "src/app/@enums/siigo/siigo-voucher";
import { ArticleCostService } from "./article-cost.service";
import { IWarehouseArticle } from "src/app/@interfaces/warehouseArticle.interface";
import { ICreateMovementEntry } from "src/app/@interfaces/Siigo/create-movement-entry.interface";
import { IPurchaseQuery } from "src/app/@interfaces/purchases.interface";

@Injectable({
  providedIn: "root",
})
export class InventoryMovementsService {
  unsubscribe$ = new Subject();
  constructor(
    private calculateSiigoService: CalculateSiigoService,
    private functionsSiigoService: FunctionsSiigoService,
    private setRowsService: SetRowsService,
    private articleCostService: ArticleCostService
  ) {}

  async inventoryMovement(
    listInvoices: IQuota[],
    listPurchases: IPurchaseInvoice[],
    listRefunds: IRefundsSingle[],
    listNotes: INotasGrap[],
    warehouseTransfers: IWarehouseTransfer[],
    warehouseOutputsAndEntries: IWarehouseOutputAndEntry[],
    inventoryReclassifications: IInventoryReclassification[],
    warehouseID: number,
    updateOnDatabases?: boolean
  ) {
    const listProducts = await this.calculateSiigoService.getProducts();
    const listProductsPurchases = this.listProductPurchases(listPurchases);
    const listProductsInvoices = this.listProductInvoices(listInvoices);
    const listProductNotes = this.listProductNotes(listNotes);
    const listProductRefunds = this.listProductRefunds(listRefunds);
    const listProductWarehouseTransfers =
      this.listWarehouseTransfer(warehouseTransfers);
    const listProductWarehouseOutputsAndEntries =
      this.listProductWarehouseOutputsAndEntries(warehouseOutputsAndEntries);
    const listProductInventoryReclassifications =
      this.listProductInventoryReclassifications(inventoryReclassifications);
    const concatedLists = listProductsPurchases.concat(
      listProductsInvoices,
      listProductNotes,
      listProductRefunds,
      listProductWarehouseTransfers,
      listProductWarehouseOutputsAndEntries,
      listProductInventoryReclassifications
    );
    const firstMovements = await this.filterProducts(
      listProducts,
      concatedLists,
      [],
      false,
      warehouseID
    );
    const secondMovements = await this.filterProducts(
      listProducts,
      concatedLists,
      firstMovements,
      updateOnDatabases!,
      warehouseID
    );
    return updateOnDatabases ? [] : secondMovements;
  }

  async filterProducts(
    listProducts: IArticles[],
    concatedLists: IMovementInventory[],
    firstMovements: IMovementInventory[],
    updateOnDatabases: boolean,
    warehouseID: number
  ) {
    let inventoryMovements: IMovementInventory[] = [];
    for (const product of listProducts) {
      let finalList: IMovementInventory[] = [];
      finalList = finalList.concat(this.filterArray(concatedLists, product));
      finalList = finalList.concat(this.getOpening(product, warehouseID));
      if (finalList.length > 0) {
        const calculatedList = await this.getListAndUpdate(
          finalList,
          firstMovements,
          updateOnDatabases
        );
        inventoryMovements = inventoryMovements.concat(calculatedList);
      }
    }
    return inventoryMovements;
  }

  filterArray(array: IMovementInventory[], product: IArticles) {
    return array.filter((item) => item.reference === product.codigo);
  }

  async getListAndUpdate(
    finalList: IMovementInventory[],
    firstMovements: IMovementInventory[],
    updateOnDatabases: boolean
  ) {
    const orderFinalList = this.orderByDate(finalList);
    const calculatedList = this.calculate(orderFinalList, firstMovements);
    if (updateOnDatabases) {
      const validation = this.validUpdate(calculatedList);
      if (validation) {
        const lastCostByArticle = calculatedList[calculatedList.length - 1];
        await this.articleCostService.updateArticleCost(
          lastCostByArticle.reference,
          lastCostByArticle.acc_quantity,
          lastCostByArticle.unitary
        );
        await this.updateArticleQuantities(calculatedList);
      }
    }
    return calculatedList;
  }

  validUpdate(calculatedList: IMovementInventory[]) {
    const allDocumentsStartWithOpening = calculatedList.every((item) =>
      item.document.startsWith("OPENING")
    );
    if (allDocumentsStartWithOpening) {
      const allQuantitiesIs0 = calculatedList.every(
        (item) => item.acc_quantity === 0
      );
      const allUnitaryIs0 = calculatedList.every((item) => item.unitary === 0);
      if (allQuantitiesIs0 && allUnitaryIs0) {
        return false;
      } else {
        return true;
      }
    } else {
      return true;
    }
  }

  async updateArticleQuantities(finalList: IMovementInventory[]) {
    const warehouseQuantities = this.getWarehouseQuantities(finalList);
    await this.updateArticleQuantitiesByWarehouse(warehouseQuantities);
  }

  getWarehouseQuantities(
    finalList: IMovementInventory[]
  ): IMovementInventory[] {
    const mapping = new Map<number, IMovementInventory>();
    for (const element of finalList) {
      if (mapping.has(element.warehouse)) {
        const exists = mapping.get(element.warehouse)!;
        exists.entries += element.entries;
        exists.exits += element.exits;
        mapping.set(element.warehouse, exists);
      } else {
        mapping.set(element.warehouse, { ...element });
      }
    }
    return Array.from(mapping.values());
  }

  async updateArticleQuantitiesByWarehouse(
    warehouseQuantities: IMovementInventory[]
  ) {
    for (const element of warehouseQuantities) {
      const quantity = element.entries - element.exits;
      await this.articleCostService.updateQuantityFromCodeArray(
        [element.reference],
        [element.warehouse],
        [quantity],
        4
      );
    }
  }

  calculate(
    finalList: IMovementInventory[],
    firstMovements: IMovementInventory[]
  ) {
    let acc_quantity = 0,
      acc_value = 0,
      unitary = 0;
    for (let [index, finalListItem] of finalList.entries()) {
      const document_type = finalListItem.document[0];
      acc_quantity += finalListItem.quantity;
      finalListItem.acc_quantity = acc_quantity;
      unitary = this.calculateUnitaryCost(
        finalList,
        index,
        document_type,
        firstMovements
      );
      finalListItem.value = unitary * finalListItem.quantity;
      finalListItem = this.calculateValues(
        finalList,
        index,
        document_type,
        unitary
      );
      acc_value += finalListItem.value;
      finalListItem.acc_value = acc_value;
    }
    return finalList;
  }

  calculateValues(
    finalList: IMovementInventory[],
    index: number,
    document_type: string,
    unitary: number
  ) {
    finalList[index].exits_values =
      document_type === "F"
        ? unitary * finalList[index].exits
        : finalList[index].exits_values;
    finalList[index].exits_values =
      document_type === "J" && finalList[index].exits > 0
        ? unitary * finalList[index].exits
        : finalList[index].exits_values;
    finalList[index].entries_values =
      document_type === "J" && finalList[index].entries > 0
        ? unitary * finalList[index].entries
        : finalList[index].entries_values;
    finalList[index].unitary = Math.abs(unitary);
    finalList[index].exits_values =
      document_type === "U"
        ? unitary * finalList[index].exits
        : finalList[index].exits_values;
    return finalList[index];
  }

  calculateUnitaryCost(
    finalList: IMovementInventory[],
    index: number,
    document_type: string,
    firstMovements: IMovementInventory[]
  ) {
    let cost = 0;
    const invoice = finalList[index].id_invoice;
    const article = finalList[index].reference;
    const crossArticle = finalList[index].cross_article;
    switch (document_type) {
      case "U":
        cost = this.calculationCrossDocuments(
          "P",
          finalList,
          cost,
          invoice!,
          article
        );
        break;
      case "P":
        cost = finalList[index].entries_values / finalList[index].entries;
        break;
      case "O":
        cost = finalList[index].entries_values / finalList[index].entries;
        break;
      case "J":
        cost = this.calculationCrossDocuments(
          "F",
          finalList,
          cost,
          invoice!,
          article
        );
        break;
      case "F":
        cost = this.calculationOnCaseF(index, finalList, cost);
        break;
      case "H":
        cost = this.calculationOnCaseH(index, finalList, cost);
        break;
      case "T":
        cost = this.getLastUnitary(index, finalList);
        break;
      case "L":
        cost = this.calculationOnCaseL(
          firstMovements,
          finalList,
          index,
          invoice!,
          crossArticle!
        );
        break;
      default:
        cost = 0;
        break;
    }
    return Number.isNaN(cost) || !Number.isFinite(cost) ? 0 : Math.abs(cost);
  }

  calculationCrossDocuments(
    document: string,
    finalList: IMovementInventory[],
    cost: number,
    invoice: number,
    article: string
  ) {
    const filterList = finalList.filter(
      (item) =>
        item.id_invoice?.toString() === invoice?.toString() &&
        item.reference.toString() === article.toString() &&
        item.document[0] === document
    );
    for (const element of filterList) {
      cost = element.unitary;
    }
    return cost;
  }

  calculationOnCaseH(
    index: number,
    finalList: IMovementInventory[],
    cost: number
  ) {
    if (finalList[index].unitary) {
      cost = finalList[index].unitary;
    } else {
      cost = this.getLastUnitary(index, finalList);
    }
    return cost;
  }

  calculationOnCaseF(
    index: number,
    finalList: IMovementInventory[],
    cost: number
  ) {
    if (index > 0) {
      let lastValue = 0,
        lastQuantity = 0,
        lastUnitary = 0;
      for (let i = index - 1; i >= 0; i--) {
        lastValue = finalList[i].acc_value;
        lastQuantity = finalList[i].acc_quantity;
        lastUnitary = finalList[i].unitary;
        break;
      }
      cost = lastValue / lastQuantity;
      cost = this.calculateCost(cost, lastValue, lastUnitary, lastQuantity);
    }
    return cost;
  }

  calculationOnCaseL(
    firstMovements: IMovementInventory[],
    finalList: IMovementInventory[],
    index: number,
    invoice: number,
    crossArticle: string
  ) {
    if (
      Math.sign(finalList[index].quantity) === 1 &&
      firstMovements.length > 0
    ) {
      const unitaryByOtherItem =
        firstMovements
          .filter(
            (item) =>
              item.id_invoice?.toString() === invoice?.toString() &&
              item.reference.toString() === crossArticle?.toString() &&
              item.document[0] === "L"
          )
          .map((element) => element.unitary)
          .shift() || 0;
      if (unitaryByOtherItem <= 0) {
        return this.getLastUnitary(index, finalList);
      } else {
        return unitaryByOtherItem;
      }
    } else {
      return this.getLastUnitary(index, finalList);
    }
  }

  getLastUnitary(index: number, finalList: IMovementInventory[]) {
    if (index > 0) {
      let lastUnitary = 0;
      for (let i = index - 1; i >= 0; i--) {
        if (finalList[i].unitary > 0) {
          lastUnitary = finalList[i].unitary;
          break;
        }
      }
      return lastUnitary;
    } else {
      return 0;
    }
  }

  calculateCost(
    cost: number,
    lastValue: number,
    lastUnitary: number,
    lastQuantity: number
  ) {
    if (lastQuantity > 0 && lastValue > 0) {
      return cost;
    } else {
      return lastUnitary;
    }
  }

  orderByDate(finalList: IMovementInventory[]) {
    finalList.sort((a, b) => {
      if (new Date(a.date).getTime() > new Date(b.date).getTime()) {
        return 1;
      }
      if (new Date(a.date).getTime() < new Date(b.date).getTime()) {
        return -1;
      }
      return 0;
    });
    return finalList;
  }

  calculatePurchasePrice(
    purchases: IPurchaseQuery,
    purchase: IPurchaseInvoice
  ) {
    const article: IArticles = purchases.articulo[0];
    const contributor = parseInt(
      purchase.provider[0].contributorType!.id_contributor.toString()
    );
    let percentIvaFloat = 0;
    for (const tax of article.tax!) {
      const taxValue = tax.value > 0 ? tax.value : purchase.tax;
      percentIvaFloat = this.functionsSiigoService.getContributorTax(
        contributor,
        taxValue + 1
      );
    }
    const price = this.calculateSiigoService.calculateTax(
      "false",
      purchases.discount,
      purchases.price,
      percentIvaFloat
    );
    return price;
  }

  listProductPurchases(listPurchases: IPurchaseInvoice[]) {
    let productPurchase: IMovementInventory[] = [];
    for (const purchase of listPurchases) {
      for (const purchases of purchase.purchases) {
        const numberDocument =
          purchase.contpurchase![0].consecutive_invoice?.replace(/\D+/g, "");
        const price = this.calculatePurchasePrice(purchases, purchase);
        const dataObject: ICreateMovementEntry = {
          element: purchases,
          warehouseId: purchases.warehouse.id_almacen,
          entries: purchases.quantity,
          entries_values: price * purchases.quantity,
          exits: 0,
          documentType: VoucherTypes.purchases,
          articleProperty: "articulo",
          numberDocument: numberDocument,
          idProperty: "id_invoice",
          mainId: purchase.id_invoice,
          date: purchase.createdAt,
        };
        productPurchase.push(
          this.setRowsService.createMovementEntryFromArticleArray(dataObject)
        );
      }
    }
    return productPurchase;
  }

  listProductNotes(listNotes: INotasGrap[]) {
    let productNotes: IMovementInventory[] = [];
    for (const note of listNotes) {
      const electronicNumber = note.billyNotes![0].note_number.replace(
        /\D+/g,
        ""
      );
      const numberDocument = electronicNumber
        ? electronicNumber
        : note.id_nota?.toString();
      for (const value of note.valor_notas) {
        const dataObject: ICreateMovementEntry = {
          element: value,
          warehouseId: value.warehouse.id_almacen,
          entries: note.tipo_nota === "Credito" ? value.cantidad * -1 : 0,
          exits: note.tipo_nota === "Debito" ? value.cantidad : 0,
          documentType: VoucherTypes.notes,
          articleProperty: "articulo",
          numberDocument: numberDocument,
          idProperty: "id_nota",
          mainId: note.note_fact,
          date: note.createAt,
        };
        productNotes.push(
          this.setRowsService.createMovementEntryFromArticleArray(dataObject)
        );
      }
    }
    return productNotes;
  }

  listProductInvoices(listInvoices: IQuota[]) {
    let productInvoices: IMovementInventory[] = [];
    for (const invoice of listInvoices) {
      for (const sales of invoice.venta) {
        const electronicNumber =
          invoice.billyInvoice![0].invoice_number?.replace(/\D+/g, "");
        const invoiceNumber = invoice.contfac![0].invoice.replace(/\D+/g, "");
        const numberDocument = electronicNumber
          ? electronicNumber
          : invoiceNumber;
        const dataObject: ICreateMovementEntry = {
          element: sales,
          warehouseId: sales.almacen![0].id_almacen,
          entries: 0,
          exits: sales.cantidad,
          documentType: VoucherTypes.invoices,
          articleProperty: "articulo",
          numberDocument: numberDocument,
          idProperty: "id_factura",
          mainId: invoice.id_factura,
          date: invoice.createdAt,
        };
        productInvoices.push(
          this.setRowsService.createMovementEntryFromArticleArray(dataObject)
        );
      }
    }
    return productInvoices;
  }

  listProductRefunds(refunds: IRefundsSingle[]) {
    let productRefunds: IMovementInventory[] = [];
    for (const refund of refunds) {
      for (const value of refund.refunds_values) {
        const dataObject: ICreateMovementEntry = {
          element: value,
          warehouseId: value.warehouse.id_almacen!,
          entries: 0,
          exits: Math.abs(value.quantity),
          documentType: VoucherTypes.refunds,
          articleProperty: "articulo",
          idProperty: "id_refund",
          mainId: refund.num_invo,
          date: refund.createAt,
          numberDocument: refund.id_refund.toString(),
        };
        productRefunds.push(
          this.setRowsService.createMovementEntryFromArticleArray(dataObject)
        );
      }
    }
    return productRefunds;
  }

  getOpeningValues(article: IArticles, warehouseID: number) {
    let openingValues: IWarehouseArticle[] = [];
    if (warehouseID) {
      openingValues.push(
        article.warehouse_values?.find(
          (item) => item.id_warehouse === warehouseID
        )!
      );
    } else {
      openingValues = article.warehouse_values!;
    }
    return openingValues;
  }

  getOpening(article: IArticles, warehouseID: number) {
    let opening: IMovementInventory[] = [];
    const openingValues = this.getOpeningValues(article, warehouseID);
    for (const element of openingValues) {
      const dataObject: ICreateMovementEntry = {
        element: { article: article, id: 1 },
        warehouseId: element ? element?.id_warehouse! : 1,
        entries: element ? element?.quantity! : 0,
        entries_values: element ? element?.value! : 0,
        exits: 0,
        documentType: "OPENING",
        articleProperty: "article",
        idProperty: "id",
        crossArtile: "",
        date: new Date(2000, 0, 1).toISOString(),
      };
      opening.push(this.setRowsService.createMovementEntry(dataObject));
    }
    return opening;
  }

  listWarehouseTransfer(transfers: IWarehouseTransfer[]) {
    let warehouseTransfers: IMovementInventory[] = [];
    for (const element of transfers) {
      const dataObject: ICreateMovementEntry = {
        element: element,
        warehouseId: 0,
        entries: 0,
        exits: 0,
        documentType: VoucherTypes.warehouseTransfer,
        articleProperty: "article",
        idProperty: "id_transfer",
      };
      warehouseTransfers.push(
        this.setRowsService.createMovementEntry({
          ...dataObject,
          entries: element.value,
          warehouseId: element.transferred.id_almacen,
        })
      );
      warehouseTransfers.push(
        this.setRowsService.createMovementEntry({
          ...dataObject,
          exits: element.value,
          warehouseId: element.original.id_almacen,
        })
      );
    }
    return warehouseTransfers;
  }

  listProductWarehouseOutputsAndEntries(
    outputsAndEntries: IWarehouseOutputAndEntry[]
  ) {
    let productOutputsAndEntries: IMovementInventory[] = [];
    for (const item of outputsAndEntries) {
      const dataObject: ICreateMovementEntry = {
        element: item,
        warehouseId: item.warehouse.id_almacen,
        entries: item.type === 2 ? item.amount : 0,
        exits: item.type === 1 ? item.amount : 0,
        documentType: VoucherTypes.warehouseOutput,
        articleProperty: "article",
        idProperty: "id_movement",
      };
      productOutputsAndEntries.push({
        ...this.setRowsService.createMovementEntry(dataObject),
        unitary: item.cost,
      });
    }
    return productOutputsAndEntries;
  }

  listProductInventoryReclassifications(
    reclassifications: IInventoryReclassification[]
  ) {
    let productReclassifications: IMovementInventory[] = [];
    for (const element of reclassifications) {
      productReclassifications.push(
        this.setRowsService.createReclassificationEntry(
          element,
          element.exitWarehouse.id_almacen!,
          0,
          element.value,
          VoucherTypes.inventoryReclassification,
          "exitArticle",
          "id_reclassification",
          element.exitArticle.codigo!
        )
      );
      productReclassifications.push(
        this.setRowsService.createReclassificationEntry(
          element,
          element.entryWarehouse.id_almacen!,
          element.value,
          0,
          VoucherTypes.inventoryReclassification,
          "entryArticle",
          "id_reclassification",
          element.exitArticle.codigo!
        )
      );
    }
    return productReclassifications;
  }
}

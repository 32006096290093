import { VoucherTypes } from '../../../@enums/siigo/siigo-voucher';
import { Injectable } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import {
  IInvoicePayments,
  IPurchasePayments,
} from "src/app/@interfaces/payments.interface";
import { SetRowsService } from "./set-rows.service";
import { ITransfers } from "src/app/@interfaces/transfers.interface";
import { ITreasury } from "src/app/@interfaces/treasury.interface";
import { FunctionsSiigoService } from "./functions-siigo.service";
import { IAccountingAccounts } from "src/app/@interfaces/accountingAccounts.interface";
import { ISiigoRow } from "src/app/@interfaces/Siigo/rows.interface";
import { IDateFormat } from "src/app/@interfaces/Siigo/format-date-siigo.interface";
import { CrossVoucherTypes } from "src/app/@enums/siigo/siigo-crossvoucher";
import { TransactionType } from "src/app/@enums/siigo/siigo-invoices";

@Injectable({
  providedIn: "root",
})
export class CollectionsService {
  constructor(
    private translate: TranslateService,
    private setRowsService: SetRowsService,
    private functionsSiigoService: FunctionsSiigoService
  ) {}

  setCollections(
    paymentsList: IInvoicePayments[],
    dateToday: string,
    accountingAccounts: IAccountingAccounts
  ) {
    let collectionsArray: ISiigoRow[] = [];
    paymentsList.filter(item => item.status === 3).forEach((element) => {
      const unstructuredData = this.separeDataFromCollections(element);
      const descriptions = this.getCollectionDescriptions(element);
      const mainRows = this.setMainCollectionRows(element, unstructuredData, dateToday);
      const rows = this.setCollectionRows(
        mainRows.mainRow, mainRows.crossRows, accountingAccounts,
        descriptions, unstructuredData, element
      ) 
      collectionsArray.push(rows.row1);
      collectionsArray.push(rows.row2);
    });
    return collectionsArray;
  }

  setCollectionRows(
    mainRow: ISiigoRow, crossRows: any, 
    accountingAccounts: IAccountingAccounts,
    descriptions: any, unstructuredData: any,
    element: IInvoicePayments
  ) {
    const row1 = { 
      ...mainRow, ...crossRows.row1, debitCredit: TransactionType.Credit,
      accountingAccount: accountingAccounts.total, description: descriptions.row1
    }
    const row2 = { 
      ...mainRow, ...crossRows.row2, debitCredit: TransactionType.Debit,
      accountingAccount: 
        this.getSecondAccoutingAccount(unstructuredData.advancePayment!, accountingAccounts, element),
      description: descriptions.row2
    }
    return { row1: row1, row2: row2 }
  }

  setMainCollectionRows(element: IInvoicePayments, unstructuredData: any, dateToday: string) {
    const mainRow = this.setRowsService.createCollectionsRow(
      element, unstructuredData.formatCreatedDate, dateToday
    )
    const crossRows = this.getCollectionsCrossData(
      unstructuredData.advancePayment!, unstructuredData.advancePaymentDate!,
      unstructuredData.formatInvoiceDueDate, unstructuredData.invoiceDocument,
      unstructuredData.voucherNumber
    )
    return { mainRow: mainRow, crossRows: crossRows }
  }

  getSecondAccoutingAccount(
    advancePayment: ITreasury, 
    accountingAccounts: IAccountingAccounts,
    element: IInvoicePayments
  ) {
    return advancePayment ? accountingAccounts.total : 
    element.paymentType[0].accounting_account!.total
  }

  separeDataFromCollections(element: IInvoicePayments) {
    const electronicNumber = element.factura.billyInvoice![0].invoice_number?.replace(/\D+/g, "");
    const invoiceNumber = element.factura.contfac![0].invoice.replace(/\D+/g, "");
    const voucherNumber = element.factura.billyInvoice![0].voucher;
    const invoiceDocument = electronicNumber ? electronicNumber : invoiceNumber;
    const formatCreatedDate = this.functionsSiigoService.getDateSeparated(element.date);
    const formatInvoiceDueDate = this.functionsSiigoService.getDateSeparated(element.factura.dueDate);
    const advancePayment = element.treasury.length > 0 ? element.treasury[0] : null;
    const advancePaymentDate = advancePayment ? this.functionsSiigoService.getDateSeparated(advancePayment?.date!) : null;
    return { 
      invoiceDocument: invoiceDocument, 
      formatCreatedDate: formatCreatedDate, 
      formatInvoiceDueDate: formatInvoiceDueDate,
      advancePayment: advancePayment,
      advancePaymentDate: advancePaymentDate,
      voucherNumber: voucherNumber
    }
  }

  getCollectionDescriptions(element: IInvoicePayments) {
    return { 
      row1: 
        element.type === "Cancellation" ? 
        this.translate.instant("CANCEL INVOICE") : 
        this.translate.instant("PAY INVOICE"),
      row2: element.factura.cliente[0].nombre!,
    }
  }

  getCollectionsCrossData(
    advancePayment: ITreasury, advancePaymentDate: IDateFormat,
    formatInvoiceDueDate: IDateFormat, invoiceDocument: string,
    voucherNumber: number
  ) {
    return {
      row1: {
        voucherTypeCross: CrossVoucherTypes.invoices + voucherNumber,
        documentNumberCross: invoiceDocument,
        expirationNumber: '1',
        expirationYear: formatInvoiceDueDate.year,
        expirationMonth: formatInvoiceDueDate.month,
        expirationDay: formatInvoiceDueDate.day,
      },
      row2: {
        voucherTypeCross: advancePayment ? CrossVoucherTypes.receivables : '',
        documentNumberCross: advancePayment ? advancePayment?.id_correlative.toString() : '',
        expirationNumber: advancePayment ? "1" : '',
        expirationYear: advancePayment ? advancePaymentDate!.year : '',
        expirationMonth: advancePayment ? advancePaymentDate!.month : '',
        expirationDay: advancePayment ? advancePaymentDate!.day : '',
      }
    }
  }

  setAccountsPayable(
    paymentsList: IPurchasePayments[],
    dateToday: string,
    purchaseAccountingAccounts: IAccountingAccounts
  ) {
    let collectionsArray: ISiigoRow[] = [];
    paymentsList.forEach((element) => {
      const descriptions = this.getAccountsPayableDescriptions(element);
      const unstructuredData = this.separeDataFromAccountsPayable(
        element, purchaseAccountingAccounts
      );
      const rows = this.setAccountsPayableRow(
        unstructuredData, element, dateToday, descriptions, purchaseAccountingAccounts
      )
      collectionsArray.push(rows.row1);
      collectionsArray.push(rows.row2);
    });
    return collectionsArray;
  }

  setAccountsPayableRow(
    unstructuredData: any, element: IPurchasePayments,
    dateToday: string, descriptions: any, 
    purchaseAccountingAccounts: IAccountingAccounts 
  ) {
    const mainRow = this.setRowsService.createAccountsPayableRow(
      unstructuredData, element, dateToday
    )
    const crossRows = this.getAccountsPayableCrossData(unstructuredData)
    const row1 = { 
      ...mainRow, ...crossRows.row1, debitCredit: TransactionType.Debit,
      accountingAccount: purchaseAccountingAccounts.total, 
      description: descriptions.row1
    }
    const row2 = { 
      ...mainRow, ...crossRows.row2, debitCredit: TransactionType.Credit,
      accountingAccount: unstructuredData.secondAccountingAccount, 
      description: descriptions.row2
    }
    return { row1: row1, row2: row2 }
  }

  separeDataFromAccountsPayable(element: IPurchasePayments, purchaseAccountingAccounts: IAccountingAccounts) {
    const purchaseDocument = element.purchaseInvoice.contpurchase![0].consecutive_invoice?.replace(/\D+/g, "");
    const formatCreatedDate = this.functionsSiigoService.getDateSeparated(element.date);
    const formatPurchaseDueDate = this.functionsSiigoService.getDateSeparated(element.purchaseInvoice.dueDate);
    const formatPurchaseCreatedDate = this.functionsSiigoService.getDateSeparated(element.purchaseInvoice.createdAt);
    const voucherType = this.functionsSiigoService.getVoucherType(element.paymentType[0].type)
    const advancePayment = element.treasury.length > 0 ? element.treasury[0] : null;
    const advancePaymentDate = advancePayment ? this.functionsSiigoService.getDateSeparated(advancePayment?.date!) : null;
    const advancePaymentVoucher = advancePayment ? this.functionsSiigoService.getVoucherType(advancePayment!.paymentType.type) : null;
    const secondAccountingAccount = advancePayment ? purchaseAccountingAccounts.total : element.paymentType[0].accounting_account!.total;
    const providerObject = this.functionsSiigoService.setProviderObject(element.purchaseInvoice, formatPurchaseCreatedDate);
    return {
      purchaseDocument: purchaseDocument,
      formatCreatedDate: formatCreatedDate,
      formatPurchaseDueDate: formatPurchaseDueDate,
      voucherType: voucherType,
      advancePayment: advancePayment,
      advancePaymentDate: advancePaymentDate,
      advancePaymentVoucher: advancePaymentVoucher,
      secondAccountingAccount: secondAccountingAccount,
      providerObject: providerObject
    }
  }

  getAccountsPayableDescriptions(element: IPurchasePayments) {
    return { 
      row1: 
        element.type === "Cancellation" ? 
        this.translate.instant("CANCEL INVOICE") : 
        this.translate.instant("PAY INVOICE"),
      row2: element.purchaseInvoice.provider[0].nombre!,
    }
  }

  getAccountsPayableCrossData(unstructuredData: any) {
    return {
      row1: {
        voucherTypeCross: CrossVoucherTypes.purchases,
        documentNumberCross: unstructuredData.purchaseDocument,
        expirationNumber: '1',
        expirationYear: unstructuredData.formatPurchaseDueDate.year,
        expirationMonth: unstructuredData.formatPurchaseDueDate.month,
        expirationDay: unstructuredData.formatPurchaseDueDate.day,
      },
      row2: {
        voucherTypeCross: unstructuredData.advancePayment ? unstructuredData.advancePaymentVoucher + "-001" : '',
        documentNumberCross: unstructuredData.advancePayment ? unstructuredData.advancePayment?.id_correlative.toString() : '',
        expirationNumber: unstructuredData.advancePayment ? "1" : '',
        expirationYear: unstructuredData.advancePayment ? unstructuredData.advancePaymentDate!.year : '',
        expirationMonth: unstructuredData.advancePayment ? unstructuredData.advancePaymentDate!.month : '',
        expirationDay: unstructuredData.advancePayment ?unstructuredData. advancePaymentDate!.day : '',
      }
    }
  }

  setTransfers(
    transfers: ITransfers[],
    dateToday: string,
  ) {
    let collectionsArray: ISiigoRow[] = [];
    transfers.forEach((element) => {
        const unstructuredData = this.separeDataFromTransfers(element);
        const mainRow = this.setRowsService.createTansferRow(
          unstructuredData, element, dateToday
        )
        const row1 = { 
          ...mainRow, debitCredit: TransactionType.Credit,
          accountingAccount: unstructuredData.accountingAccounts.row1
        }
        const row2 = { 
          ...mainRow, debitCredit: TransactionType.Debit,
          accountingAccount: unstructuredData.accountingAccounts.row2
        }
        collectionsArray.push(row1);
        collectionsArray.push(row2);
    });
    return collectionsArray;
  }

  separeDataFromTransfers(element: ITransfers) {
    const formatCreatedDate = this.functionsSiigoService.getDateSeparated(element.date);
    const voucherType = this.functionsSiigoService.getVoucherType(element.original.type);
    const voucherTransferred = this.functionsSiigoService.getVoucherType(element.transferred.type);
    const transferDescription = this.functionsSiigoService.getTransferDescription(voucherTransferred, formatCreatedDate.day + "-" + formatCreatedDate.month + "-" + formatCreatedDate.year);
    const transferValue = this.functionsSiigoService.getTransferValue(element);
    const accountingAccounts = this.getTransferAccountingAccounts(element);
    return {
      formatCreatedDate: formatCreatedDate,
      voucherType: voucherType,
      voucherTransferred: voucherTransferred,
      transferDescription: transferDescription,
      transferValue: transferValue,
      accountingAccounts: accountingAccounts
    }
  }

  getTransferAccountingAccounts(element: ITransfers) {
    return {
      row1: element.original.accounting_account!.total,
      row2: element.transferred.accounting_account!.total,
    }
  }

  setAdvancePayments(
    advancePayments: ITreasury[],
    dateToday: string,
    purchaseAccountingAccounts: IAccountingAccounts
  ) {
    let collectionsArray: ISiigoRow[] = [];
    advancePayments.forEach(element => {
      const unstructuredData = this.separeDataFromAdvancePayment(
        element, purchaseAccountingAccounts
      );
      const mainRow = this.setRowsService.createAdvancePaymentsRow(
        unstructuredData, element, dateToday
      )
      const row1 = { 
        ...mainRow, debitCredit: unstructuredData.transactionTypes.row1,
        accountingAccount: unstructuredData.accountingAccount.row1 
      }
      const row2 = { 
        ...mainRow, debitCredit: unstructuredData.transactionTypes.row2,
        accountingAccount: unstructuredData.accountingAccount.row2,
        description: unstructuredData.name
      }
      collectionsArray.push(row1);
      collectionsArray.push(row2);
    });
    return collectionsArray;
  }

  validAdvancePaymentsVoucher(element: ITreasury, voucherType: string) {
    return element.type === "Customers" ? VoucherTypes.receivables : voucherType
  }

  getAdvancePaymentAccountingAccount(
    element: ITreasury, purchaseAccountingAccounts: IAccountingAccounts
  ) {
    return {
      row1: element.type === "Customers" ? "1305050100" : purchaseAccountingAccounts.total,
      row2: element.paymentType.accounting_account!.total,
    }
  }

  getAdvancePaymentTansaccionType(element: ITreasury){
    return {
      row1: element.type === "Customers" ? TransactionType.Credit : TransactionType.Debit,
      row2: element.type === "Customers" ? TransactionType.Debit : TransactionType.Credit,
    }
  }

  separeDataFromAdvancePayment(
    element: ITreasury, purchaseAccountingAccounts: IAccountingAccounts
  ) {
    const formatCreatedDate = this.functionsSiigoService.getDateSeparated(element.date);
    const voucherType =  this.functionsSiigoService.getVoucherType(element.paymentType.type);
    const document = this.functionsSiigoService.getDocumentType(element.type, element.customer, element.provider);
    const name = this.functionsSiigoService.getName(element.type, element.customer, element.provider);
    const transferDescription = this.functionsSiigoService.getAdvancePaymentDescription(element.type);
    const voucher = this.validAdvancePaymentsVoucher(element, voucherType);
    const accountingAccount = this.getAdvancePaymentAccountingAccount(element, purchaseAccountingAccounts);
    const transactionTypes = this.getAdvancePaymentTansaccionType(element);
    return {
      formatCreatedDate: formatCreatedDate,
      voucherType: voucherType,
      document: document,
      name: name,
      transferDescription: transferDescription,
      voucher: voucher,
      accountingAccount: accountingAccount,
      transactionTypes: transactionTypes
    }
  }
}

import { Injectable, OnDestroy } from "@angular/core";
import { IPurchasesInvoices } from "../../../../../@interfaces/purchaseInvoice.interface";
import {
  UntypedFormGroup,
  UntypedFormArray,
  UntypedFormControl,
} from "@angular/forms";
import Swal from "sweetalert2";
import { TranslateService } from "@ngx-translate/core";
import { PurchasesService } from "../../../../../@pages/purchases/purchases.service";
import { ITributaryValues } from "../../../../../@interfaces/tributary.interface";
import { takeUntil } from "rxjs/operators";
import { Subject } from "rxjs";
import { ConverseFilesService } from "../../../services/converse-files.service";
import { TributaryService } from "../../../../../@pages/tributary/tributary.service";
import { IActivitiesCiiu } from "../../../../../@interfaces/activitiesciiu.interface";
import { ICities } from "../../../../../@interfaces/cities.interface";
import { NgxSpinnerService } from "ngx-spinner";
import { IProvider } from "src/app/@interfaces/provider.interface";
import { CookieAuthService } from "src/app/@shared/storage-variables/cookie-auth.service";

@Injectable({
  providedIn: "root",
})
export class RegisterPurchaseService implements OnDestroy {
  unsubscribe$ = new Subject();
  citiesList: ICities[] = [];
  constructor(
    private translate: TranslateService,
    private purchasesService: PurchasesService,
    private converseFilesService: ConverseFilesService,
    private tributaryService: TributaryService,
    private spinnerService: NgxSpinnerService,
    private cookieAuthService: CookieAuthService
  ) {}

  setQuotationData(purchaseForm: UntypedFormGroup, rowsArr: UntypedFormArray, newProvider: UntypedFormControl) {
    let setPurchaseInvoice = {} as IPurchasesInvoices;
    var total: number = 0;
    setPurchaseInvoice.tax = purchaseForm.value.tax;
    setPurchaseInvoice.provider = [{ nombre: "" }];
    setPurchaseInvoice.provider[0].id_provider = parseInt(
      newProvider.value.id_provider
    );
    setPurchaseInvoice.createdAt = purchaseForm.value.date;
    setPurchaseInvoice.dueDate = purchaseForm.value.dueDate;
    setPurchaseInvoice.tax_incl = new Boolean(
      purchaseForm.value.tax_include
    ).toString();
    setPurchaseInvoice.clause = new Boolean(
      purchaseForm.value.clause
    ).toString();
    setPurchaseInvoice.contpurchase = [{ written_invoice: "" }];
    setPurchaseInvoice.contpurchase![0].written_invoice =
      purchaseForm.value.written_invoice;
    for (let x = 0; x < rowsArr.value.length; x++) {
      total = total + parseFloat(rowsArr.value[x].subtotal);
    }
    setPurchaseInvoice.purchases = [
      {
        id_purchases: [],
        id_warehouse: [],
        codigo: [],
        quantity: [],
        price: [],
        discount: [],
        subtotal: [],
        total: [],
        id_article: [],
      },
    ];
    for (let index = 0; index < rowsArr.value.length; index++) {
      this.validItems(rowsArr.value[index].article.codigo);
      setPurchaseInvoice.purchases[0].id_warehouse.push(
        parseInt(rowsArr.value[index].warehouse)
      );
      setPurchaseInvoice.purchases[0].codigo.push(
        rowsArr.value[index].article.codigo,
      );
      setPurchaseInvoice.purchases[0].quantity.push(
        parseFloat(rowsArr.value[index].quantity)
      );
      setPurchaseInvoice.purchases[0].price.push(
        parseFloat(rowsArr.value[index].price)
      );
      setPurchaseInvoice.purchases[0].discount.push(
        parseFloat(rowsArr.value[index].discount)
      );
      setPurchaseInvoice.purchases[0].subtotal.push(
        parseFloat(rowsArr.value[index].subtotal)
      );
      setPurchaseInvoice.purchases[0].total.push(total);
      setPurchaseInvoice.purchases[0].id_article.push(
        rowsArr.value[index].article.id_articulo,
      );
    }
    return setPurchaseInvoice;
  }

  validItems(item: any) {
    if (!item) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: this.translate.instant("Some of the items do not exist"),
      });
      this.spinnerService.hide();
      return;
    }
  }

  getRateValues(
    uvtValue: number, 
    formArray: UntypedFormArray, 
    newProvider: UntypedFormControl
  ) {
    const purchaseType = this.validPurchaseType(formArray);
    const providerObject: IProvider = newProvider.value;
    const rate = providerObject.declarant_types![0].declarant_rate.filter((item) => 
      item.id_type.toString() === purchaseType.toString()
    )
    const uvtRate = uvtValue * rate[0].uvt;
    const rateValue = rate[0].value;
    return {
      uvtRate: uvtRate,
      rateValue: rateValue,
      purchaseType: purchaseType
    }
  }

  calculationWithholdingTax(
    newProvider: UntypedFormControl, totalPurchase: number, 
    uvtValue: number, formArray: UntypedFormArray, clause: boolean
  ) {
    let withholdingTax = 0;
    const rateValues = this.getRateValues(uvtValue, formArray, newProvider);
    if (totalPurchase >= rateValues.uvtRate) {
      if (newProvider.value.declarant_types) {
        withholdingTax = totalPurchase * (rateValues.rateValue / 100);
      }
    }
    const validClause = this.validClause(rateValues.purchaseType, clause);
    return validClause ? 0 : withholdingTax;
  }

  validClause(purchaseType: number, clause: boolean) {
    return (
      parseInt(purchaseType.toString()) === 1 || 
      parseInt(purchaseType.toString()) === 3) && clause 
      ? true : false
  }

  async converseCities(customerCity: string, providerCity: string) {
    const validCustomerCity = this.converseFilesService.validText(customerCity);
    const validProviderCity = this.converseFilesService.validText(providerCity);
    let parsedCustomerCity = "";
    let parsedProviderCity = "";
    if (validCustomerCity) {
      parsedCustomerCity = await this.converseFilesService.converseUTF8(
        this.converseFilesService.removeAccents(customerCity)
      );
    } else {
      parsedCustomerCity = await this.converseFilesService.converseUTF8(
        customerCity
      );
    }
    if (validProviderCity) {
      parsedProviderCity = await this.converseFilesService.converseUTF8(
        this.converseFilesService.removeAccents(providerCity)
      );
    } else {
      parsedProviderCity = await this.converseFilesService.converseUTF8(
        providerCity
      );
    }
    return {
      parsedCustomerCity: parsedCustomerCity.trim(),
      parsedProviderCity: parsedProviderCity.trim(),
    };
  }

  async calculationIcaTax(
    customerCity: string, providerCity: string, 
    newProvider: UntypedFormControl, totalPurchase: number, 
    uvtValue: number, formArray: UntypedFormArray
  ) {
    const citiesParsed = await this.converseCities(customerCity, providerCity);
    const parsedCustomerCity = citiesParsed.parsedCustomerCity;
    const parsedProviderCity = citiesParsed.parsedProviderCity;
    const rateValues = this.getRateValues(uvtValue, formArray, newProvider);
    let icaTax = 0,
      city = "";
    if (totalPurchase >= rateValues.uvtRate) {
      if (newProvider.value.declarant_types) {
        if (newProvider.value.declarant_types[0].id_declarant === 1 || newProvider.value.declarant_types[0].id_declarant === 2) {
          city = customerCity;
          icaTax = 0;
        } else {
          if (parsedCustomerCity.toLocaleLowerCase() !== parsedProviderCity.toLocaleLowerCase()) {
            city = customerCity;
            icaTax = 0;
          } else {
            const rate = await this.getRate(
              parsedCustomerCity,
              newProvider.value.activity_code
            );
            city = rate.city;
            icaTax = this.calculateIca(totalPurchase, rate.actual_rate);
          }
        }
      }
    } else {
      city = customerCity;
      icaTax = 0;
    }
    return { icaTax: icaTax, city: city };
  }

  calculateIca(totalPurchase: number, rate: number) {
    let ica = 0;
    const multiply = totalPurchase * rate;
    ica = multiply / 1000;
    return ica;
  }

  getRate(city: string, activityCode: string) {
    return new Promise<any>((resolve, reject) => {
      this.tributaryService
      .getActivity(activityCode, city)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((result: IActivitiesCiiu[]) => {
        if (result.length > 0) {
          if (result[0].actual_rate) {
            resolve({ actual_rate: result[0].actual_rate, city: city });
          } else {
            resolve(0);
          }
        }
      },async (error) => {
        const getIfError = await this.getRateIfError(activityCode);
        resolve(getIfError);
      });
    });
  }

  getCityFromSwal(citiesArray: ICities[], activityCode: string) {
    return new Promise<string>((resolve, reject) => {
      let options = {} as any;
      citiesArray.map((o) => {
        options[o.name] = o.name;
      });
      Swal.fire({
        title:
          this.translate.instant(
            `No economic activity was found for activity`
          ) +
          " " +
          activityCode +
          " " +
          this.translate.instant(
            `in the city of purchase, please select one to search again.`
          ),
        input: "select",
        inputOptions: options,
        inputPlaceholder: this.translate.instant("Select a city"),
        showCancelButton: true,
      }).then((result) => {
        resolve(result.value);
      });
    });
  }

  getCityWritten(activityCode: string) {
    const companyObject = this.cookieAuthService.getCompanyObject;
    return new Promise<string>((resolve, reject) => {
      const country_id = companyObject!.countries[0].id_country;
      this.tributaryService
      .getCitiesByCompany(parseInt(country_id!.toString()))
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(async (result) => {
        this.citiesList = result;
        const city = await this.getCityFromSwal(
          this.citiesList,
          activityCode
        );
        resolve(city);
      });
    });
  }

  getRateIfError(activityCode: string) {
    return new Promise<any>(async (resolve, reject) => {
      const city = await this.getCityWritten(activityCode);
      this.tributaryService
      .getActivity(activityCode, city)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((result: IActivitiesCiiu[]) => {
        if (result.length > 0) {
          if (result[0].actual_rate) {
            resolve({ actual_rate: result[0].actual_rate, city: city });
          } else {
            resolve(0);
          }
        }
      },(error) => {
        Swal.fire(
          "Error",
          this.translate.instant(`Make sure that the economic activity`) +
            " " +
            activityCode +
            " " +
            this.translate.instant(
              `is registered for the city of purchase.`
            ),
          "error"
        );
        reject(error);
      });
    });
  }

  getInvoiceCities(invoice: any) {
    const customerCity =
      invoice.Invoice["cac:AccountingCustomerParty"]["cac:Party"][
        "cac:PhysicalLocation"
      ]["cac:Address"]["cbc:CityName"]["cbc:CityName"];
    const providerCity =
      invoice.Invoice["cac:AccountingSupplierParty"]["cac:Party"][
        "cac:PhysicalLocation"
      ]["cac:Address"]["cbc:CityName"]["cbc:CityName"];
    return { customerCity: customerCity, providerCity: providerCity };
  }

  getTaxBase() {
    return new Promise<ITributaryValues[]>(async (resolve, reject) => {
      resolve(await this.getTributaryValues(1));
    });
  }

  getTributaryValues(id_value: number) {
    return new Promise<ITributaryValues[]>((resolve, reject) => {
      this.purchasesService
      .getSingleTributaryValues(id_value)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((result: ITributaryValues[]) => {
        resolve(result);
      },(error) => {
        reject(error);
      });
    });
  }

  validPurchaseType(formArray: UntypedFormArray) {
    const typesArticleArray = formArray.value
    .filter((element: any) => element.article && element.article.type)
    .map((element: any) => element.article.type[0].id_type);
    const groupArray = this.reduceArray(typesArticleArray);
    return groupArray.length === 1 ? groupArray[0] : 5;
  }

  reduceArray(array: number[]) {
    return array.filter((item, index) => {
      return array.indexOf(item) === index;
    });
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}

export enum CrossVoucherTypes {
  invoices = "F-00",
  notes = "J-001",
  purchases = "P-004",
  refunds = "U-001",
  receivables = "R-001",
  bank = "G-001",
  cashier = "N-001",
  warehouseTransfer = "T-001",
}

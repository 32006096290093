import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { NgxQRCodeModule } from '@techiediaries/ngx-qrcode';
import { NgxPrintModule } from 'ngx-print';
import { HomeRoutingModule } from './home-routing.module';
import { HomeComponent } from './home.component';
import { DialogModule } from 'src/app/@shared/components/dialog/dialog.module';
import { MatIconModule } from '@angular/material/icon';
import { ChartsModule } from 'ng2-charts';
import { MaterialModule } from '../../material.module';
import { QuotTodayComponent } from './graph-v1/quot-today/quot-today.component';
import { BarrasComponent } from './components/barras/barras.component';
import { LineComponent } from './components/line/line.component';
import { DxButtonModule, DxCircularGaugeModule, DxSelectBoxModule, DxLinearGaugeModule, DxProgressBarModule, DxMapModule, DxVectorMapModule, DxCheckBoxModule, DxChartModule, DxDataGridModule, DxGanttModule, DxBulletModule } from 'devextreme-angular'
import { MeterComponent } from './components/meter/meter.component';
import { BarIndicatorComponent } from './components/bar-indicator/bar-indicator.component';
import { AverageIndicatorComponent } from './components/average-indicator/average-indicator.component';
import { SalesGrowthComponent } from './graph-v1/sales-growth/sales-growth.component';
import { SalesTargetComponent } from './graph-v1/sales-target/sales-target.component';
import { AverageSalesComponent } from './graph-v1/average-sales/average-sales.component';
import { GraphV1Component } from './graph-v1/graph-v1.component';
import { MapsComponent } from './components/maps/maps.component';
import { SalesToDateComponent } from './graph-v1/sales-to-date/sales-to-date.component';
import { SalesByRegionComponent } from './graph-v1/sales-by-region/sales-by-region.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { NgxSpinnerModule } from 'ngx-spinner';
import { SortModule } from '../../directives/sortDirective/sort.module';
import { MultiplePaneComponent } from './components/multiple-pane/multiple-pane.component';
import { SalesPerSellerComponent } from './graph-v1/sales-per-sellr/sales-per-seller.component';
import { StackedBarComponent } from './components/stacked-bar/stacked-bar.component';
import { FilterPresentationSales } from 'src/app/@shared/pipe/graphs-pipes/sales/presentation-type.pipe';
import { BulletComponent } from './components/bullet/bullet.component';
import { FilterSSales } from '../../@shared/pipe/graphs-pipes/sales/seller.pipe';
import { FilterSQuots } from '../../@shared/pipe/graphs-pipes/quotations/seller.pipe';
import { FilterFullQuots } from '../../@shared/pipe/graphs-pipes/quotations/full-quotation.pipe';
import { FilterFullSales } from '../../@shared/pipe/graphs-pipes/sales/full-sales.pipe';
import { FilterBySearchService } from '../../@shared/components/services/filterBySearch.service';
import { ProcessNotesService } from '../../@shared/components/services/process-notes.service';
import { FilterFullDateQuots } from '../../@shared/pipe/graphs-pipes/quotations/full-date-quotation.pipe';
import { FilterFullDateSales } from '../../@shared/pipe/graphs-pipes/sales/full-date-sales.pipe';
import { ImageConverseService } from '../../@shared/components/services/imageConverse.service';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { FilterIQuots } from 'src/app/@shared/pipe/graphs-pipes/quotations/item.pipe';
import { FilterPTQuots } from 'src/app/@shared/pipe/graphs-pipes/quotations/presentation.pipe';
import { FilterPTNotes } from 'src/app/@shared/pipe/graphs-pipes/notes/presentation.pipe';
import { FlexLayoutModule } from '@angular/flex-layout';

@NgModule({
  declarations: [
    HomeComponent, 
    QuotTodayComponent, 
    BarrasComponent, 
    LineComponent, 
    MeterComponent, 
    BarIndicatorComponent, 
    AverageIndicatorComponent, 
    SalesGrowthComponent, 
    SalesTargetComponent, 
    AverageSalesComponent, 
    MapsComponent, 
    SalesToDateComponent, 
    SalesByRegionComponent, 
    GraphV1Component, 
    MultiplePaneComponent, 
    SalesPerSellerComponent,
    StackedBarComponent,
    BulletComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    HomeRoutingModule,
    DialogModule,
    NgxQRCodeModule,
    NgxPrintModule,
    MatIconModule,
    MaterialModule,
    ChartsModule,
    DxButtonModule,
    DxCircularGaugeModule, 
    DxCircularGaugeModule,
    DxSelectBoxModule,
    DxLinearGaugeModule,
    DxProgressBarModule, 
    DxVectorMapModule, 
    DxMapModule,
    DxCheckBoxModule,
    FormsModule,
    TranslateModule,
    NgxSpinnerModule,
    SortModule,
    DxChartModule,
    DxGanttModule,
    DxBulletModule,
    CarouselModule,
    FlexLayoutModule
  ],
  providers: [
    FilterPresentationSales,
    FilterSSales,
    FilterSQuots,
    FilterFullQuots,
    FilterFullSales,
    FilterFullDateQuots,
    FilterFullDateSales,
    FilterBySearchService,
    FilterIQuots,
    FilterPTQuots,
    FilterPTNotes,
    ProcessNotesService,
    ImageConverseService
  ]
})
export class HomeModule { }



import { Component, OnInit, OnDestroy, ChangeDetectionStrategy } from "@angular/core";
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from "@angular/forms";
import { ICountry } from "src/app/@interfaces/country.interface";
import { CustomerService } from "src/app/@pages/customer/customer.service";
import { ValidatorsService } from "src/app/@shared/validator/validators.service";
import { MatDialog } from "@angular/material/dialog";
import { DialogComponent } from "../../dialog.component";
import {
  BillyPayerNatural,
  BillyPayerLegal,
  BillyCities,
} from "../../../../../@interfaces/Billy/billy.interface";
import { IDocumenType } from "../../../../../@interfaces/authentication/documenType";
import { NgxSpinnerService } from "ngx-spinner";
import { TranslateService } from "@ngx-translate/core";
import { BillyPayerService } from "./billyPayers.service";
import { Observable, Subject, of } from "rxjs";
import { takeUntil, first, catchError, startWith, map } from "rxjs/operators";
import { ConverseFilesService } from "../../../services/converse-files.service";
import { ICustomerSegment } from "src/app/@interfaces/customerSegment.interface";
import { IContributorType } from "src/app/@interfaces/contributorTypes.interface";
import { ICustomerType } from "src/app/@interfaces/customerType.interface";
import { UsersService } from "src/app/@pages/users/users.service";
import { IUserSeller } from "src/app/@interfaces/userSeller.interface";
import { ICustomer } from "src/app/@interfaces/customer.interface";
import { AlertsService } from '../../../services/alerts.service';
import { CookieAuthService } from "src/app/@shared/storage-variables/cookie-auth.service";
import { IStorageCompany } from "src/app/@interfaces/company.interface";
import { CitiesFormService } from "../../../services/cities-form.service";

@Component({
  selector: "app-register-customer",
  templateUrl: "./register-customer.component.html",
  styleUrls: ["./register-customer.component.css"],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class RegisterCustomerComponent implements OnInit, OnDestroy {
  cityList: string[] = [];
  filterCities$: Observable<string[]> = new Observable();
  billyCities = {} as BillyCities;
  countryList: Array<ICountry> = [];
  countrySelected: string = "";
  sellerLists: Array<IUserSeller> = [];
  customerSegmentList: Array<ICustomerSegment> = [];
  customerTypeList: Array<ICustomerType> = [];
  documenTypeList: Array<IDocumenType> = [];
  contributorTypeList: Array<IContributorType> = [];
  files: any;
  customerForm: UntypedFormGroup = this.fb.group({
    cust_type: ["", [Validators.required]],
    doc_nit: ["", [Validators.required, Validators.pattern(this.validatorS.documentPattern)]],
    dv: [
      "",
      [
        Validators.required,
        Validators.pattern(this.validatorS.documentPattern),
        Validators.maxLength(1),
      ],
    ],
    types_docs: ["", [Validators.required]],
    name: ["", [Validators.required, Validators.maxLength(60)]],
    address: ["", [Validators.required, Validators.maxLength(100)]],
    phone: [
      "",
      [
        Validators.required,
        Validators.pattern(this.validatorS.documentPattern),
        Validators.maxLength(15),
      ],
    ],
    email: [
      "",
      [
        Validators.required,
        Validators.pattern(this.validatorS.emailPattern),
        Validators.maxLength(50),
      ],
    ],
    country: ["", Validators.required],
    city: ["", Validators.required],
    citySelect: [""],
    coordinates: ["", Validators.required],
    RUT: [],
    contributorType: ["", [Validators.required]],
    seller: ["", [Validators.required]],
    segment: ["", [Validators.required]],
    observations: [null, ],
  });

  companyId = '';
  companyObject = {} as IStorageCompany;
  user = '';
  rol = '';
  userSeller = '';
  billyPayerLegal = {} as BillyPayerLegal;
  billyPayerNatural = {} as BillyPayerNatural;
  customerList: ICustomer[] = [];
  customerExistsName: string = '';
  unsubscribe$ = new Subject();
  selectedType: string = "";
  clientErrorMessage: string = '';
  initialClientFormValues?: { [key: string]: string};

  constructor(
    private billyPayerService: BillyPayerService,
    private translate: TranslateService,
    private spinnerService: NgxSpinnerService,
    public dialog: MatDialog,
    private fb: UntypedFormBuilder,
    private validatorS: ValidatorsService,
    private customer: CustomerService,
    private converseFilesService: ConverseFilesService,
    private userService: UsersService,
    private alertsService: AlertsService,
    private cookieAuthService: CookieAuthService,
    private citiesFormService: CitiesFormService
  ) {}

  async ngOnInit() {
    this.getAuthValues();
    this.initialClientFormValues = this.customerForm.value;
    this.loadData();
    this.onFormChange();
    this.searchCustomer();
    this.citiesFromStore();
  }

  citiesFromStore() {
    this.citiesFormService.setInitialCitiesFromStore();
    this.customerForm.get('country')?.valueChanges.subscribe((result) => {
      this.spinnerService.show();
      this.setCity(result)
    })
    this.filterCities$ = this.customerForm.get('citySelect')!.valueChanges.pipe(
      startWith(""),
      map((value) => this.citiesFormService.filterCities(value, this.cityList))
    );
  }

  getAuthValues() {
    this.companyObject = this.cookieAuthService.getCompanyObject!;
    this.companyId = this.companyObject!.Id_company!.toString();
    this.user = this.cookieAuthService.getUserId!;
    this.rol = this.cookieAuthService.getRolId!;
    this.userSeller = this.cookieAuthService.getUserSellerId!;
  }

  loadData() {
    this.customer
    .listCountries()
    .pipe(takeUntil(this.unsubscribe$))
    .subscribe((result) => {
      this.countryList = result;
    });

    this.userService
    .listDocumenTypes()
    .pipe(takeUntil(this.unsubscribe$))
    .subscribe((result) => {
      this.documenTypeList = result;
    });

    this.customer
    .listCustomerSegment()
    .pipe(takeUntil(this.unsubscribe$))
    .subscribe((result: any) => {
      this.customerSegmentList = result;
    });

    this.customer
    .listContributorTypes()
    .pipe(takeUntil(this.unsubscribe$))
    .subscribe((result: any) => {
      this.contributorTypeList = result;
    });

    this.customer
    .listCustomerType()
    .pipe(takeUntil(this.unsubscribe$))
    .subscribe((result: any) => {
      this.customerTypeList = result;
    });

    this.userService
    .getAllSellerUser()
    .pipe(takeUntil(this.unsubscribe$))
    .subscribe((result: any) => {
      this.sellerLists = result;
    });
  }

  onFormChange() {
    this.customerForm.get("doc_nit")!.valueChanges.subscribe((newValue) => {
      if(newValue) {
        this.customerForm
        .get("dv")
        ?.setValue(
          this.customer.calculateVerificationDigit(newValue.toString())
        );
      }
    });
  }

  searchCustomer() {
    this.customerForm.get("name")!.valueChanges.subscribe(async (newValue) => {
      if (!newValue.__typename) {
        this.customerExistsName = '';
        this.customerList = await this.searchQuery(newValue);
      }
    });
  }

  searchQuery(value: string) {
    return new Promise<ICustomer[]>((resolve, reject) => {
      this.customer
      .searchCustomer(value, null, null)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((result) => {
        resolve(result.customers)
      }) 
    })
  }

  onSelectCustomer(event: any) {
    this.alertsService.showConfirmAlert('This client already exist. Do you want to modify?').then((res) => {
      if (res.isConfirmed) {
        return this.dialogModifyCustomer(true, event.id_cliente, event.customer_type.id_customerType)
      }
        this.customerForm.reset(this.initialClientFormValues);
    })
    this.customerForm.get("doc_nit")?.setValue(event.doc_nit);
  }

  setErrorAlertMessage() {
    this.customerForm.markAllAsTouched();
    if (this.customerForm.invalid === true) {
      this.alertsService.showErrorAlert("Please, fill in all the required fields!");
      return false;
    } else if (this.customerForm.value.cust_type.type === "Legal" && this.customerForm.value.RUT === null && (this.companyObject.external_electronic_invoicing === "true")) {
      this.alertsService.showErrorAlert("For legal persons you must send the RUT!");
      return false;
    } else if (this.customerForm.value.cust_type.type === "Natural" && (this.customerForm.value.types_docs.id_docs === "1" || this.customerForm.value.types_docs.id_docs === "2")) {
      this.alertsService.showErrorAlert("For natural persons it cannot have NIT!");
      return false;
    } else if (this.customerForm.value.cust_type.type === "Legal" && !this.customerForm.value.dv && (this.companyObject.external_electronic_invoicing === "true" || this.companyObject.internal_electronic_invoicing === "true")) {
      this.alertsService.showErrorAlert("For legal persons the document must have the verification digit separated by a dash!");
      return false;
    } else {
      return true;
    }
  }

  register() {
    if (this.customerForm.value.cust_type.type === "Prospecto") {
      this.IsClientProspect();
    } else if (this.customerForm.value.cust_type.type != "Prospecto") {
      const validations = this.setErrorAlertMessage();
      if (!validations) {
       return;
      } else {
        if (this.companyObject.external_electronic_invoicing === "true") {
          this.spinnerService.show();
          if (this.customerForm.value.cust_type.type === "Legal") {
            this.isCustomerLegal();
          }
          if (this.customerForm.value.cust_type.type === "Natural") {
            this.isCustomerNatural();
          }
        } else {
          this.spinnerService.show();
          this.saveOnDataBase();
        }
      }
    }else {
      this.spinnerService.hide();
    }
  }

  async IsClientProspect() {
    this.customerForm.markAsUntouched();
    const validations = await this.prospectValidation();
    if (!validations) {
      return;
    } else {
      this.saveOnDataBaseProspecto();
    }
  }

  async prospectValidation() {
    if (!this.customerForm.value.name || !this.customerForm.value.seller || !this.customerForm.value.country || !this.customerForm.value.citySelect) {
      this.customerForm.get("name")?.markAsTouched();
      this.customerForm.get("seller")?.markAsTouched();
      this.customerForm.get("country")?.markAsTouched();
      this.customerForm.get("citySelect")?.markAsTouched();
      this.clientErrorMessage = "Please, fill in all the required fields, name, country, city and seller!";
      return false;
    } else if (this.customerForm.value.name.__typename) {
      this.clientErrorMessage = "This client already exist, it is not possible to register";
      return false;
    }else {
      const searchPossibleCustomer = await this.searchQuery(this.customerForm.value.name);
      const filterResult = searchPossibleCustomer.filter(
        customer => 
        customer.nombre.toLocaleUpperCase().trim() === this.customerForm.value.name.toLocaleUpperCase().trim()
      )
      if (filterResult.length > 0) {
        this.clientErrorMessage = "This client already exist, it is not possible to register";
        return false;
      }else {
        return true;
      }
    }
  }

  isCustomerLegal() {
    this.billyPayerService
    .sendDianLegal(this.customerForm, this.files)
    .then((result) => {
      if (result) {
        this.spinnerService.show();
        this.saveOnDataBase();
      }
    })
    .catch((error) => {
      if (error.error.errors[0].detail === "An office already exists in this city") {
        this.spinnerService.show();
        this.saveOnDataBase();
      } else {
        console.log(error);
      }
    });
  }

  isCustomerNatural() {
    this.billyPayerService
    .sendDianNatural(this.customerForm)
    .then((result) => {
      if (result) {
        this.spinnerService.show();
        this.saveOnDataBase();
      }
    })
    .catch((error) => {
      if (error.error.errors[0].detail === "The idNumber is already registered") {
        this.spinnerService.show();
        this.saveOnDataBase();
      } else {
        console.log(error);
      }
    });
  }

  saveOnDataBase() {
    const coords = this.customerForm.value.coordinates;
    const verification_digit = this.customerForm.get('dv')?.getRawValue();
    this.customer
    .registerCustomer(
      this.customerForm.value.doc_nit,
      parseInt(this.customerForm.value.types_docs.id_docs),
      this.customerForm.value.name,
      this.customerForm.value.address,
      this.customerForm.value.phone,
      this.customerForm.value.email,
      parseInt(this.customerForm.value.country.id_country),
      this.customerForm.value.city,
      coords,
      "Nothing",
      parseInt(this.customerForm.value.contributorType.id_contributor),
      parseInt(this.customerForm.value.seller.seller.id_seller),
      parseInt(this.user!),
      parseInt(this.customerForm.value.segment.id_segment),
      0,
      parseInt(this.customerForm.value.cust_type.id_customerType),
      parseInt(this.customerForm.value.seller.id_userSeller),
      parseInt(verification_digit)
    )
    .pipe(catchError(error => {
      if (error.graphQLErrors) {
        const [graphQLError] = error.graphQLErrors
        this.clientErrorMessage = this.translate.instant(graphQLError.message);
        this.spinnerService.hide();
        return of(error.graphQLErrors)
      }
      this.spinnerService.hide();
      return of(error)
    }),takeUntil(this.unsubscribe$))
    .subscribe((result) => {
      if (result) {
        this.spinnerService.hide();
        this.alertsService.showSuccessfullAlert("Client created successfully", "Created")
        this.dialog.closeAll();
      }
    });
  }

  saveOnDataBaseProspecto() {
    const coords = this.customerForm.value.coordinates;
    const verification_digit = this.customerForm.get('dv')?.getRawValue();
    this.customer
    .registerCustomerProspecto(
      this.customerForm.value.doc_nit || null,
      parseInt(this.customerForm.value.types_docs.id_docs),
      this.customerForm.value.name,
      this.customerForm.value.address,
      this.customerForm.value.phone,
      this.customerForm.value.email,
      parseInt(this.customerForm.value.country.id_country),
      this.customerForm.value.city,
      coords,
      "Nothing",
      parseInt(this.customerForm.value.contributorType.id_contributor),
      parseInt(this.customerForm.value.seller.seller.id_seller),
      parseInt(this.user!),
      parseInt(this.customerForm.value.segment.id_segment),
      0,
      parseInt(this.customerForm.value.cust_type.id_customerType),
      parseInt(this.customerForm.value.seller.id_userSeller),
      parseInt(verification_digit),
      this.customerForm.get('observations')?.value
    )
    .pipe(takeUntil(this.unsubscribe$))
    .subscribe((result) => {
      if (result) {
        this.spinnerService.hide();
        this.alertsService.showSuccessfullAlert("Prospect client created successfully", "Created")
        this.dialog.closeAll();
      }
    });
  }

  setValid() {
    if (this.customerForm.value.cust_type.type === "Legal") {
      this.customerForm.controls.RUT.setValidators([Validators.required]);
    }
    if (this.customerForm.value.cust_type.type === "Natural") {
      this.customerForm.controls.RUT.clearValidators();
    }
  }

  textValid(text: string) {
    return (
      this.customerForm.get(text)?.invalid &&
      this.customerForm.get(text)?.touched
    );
  }

  openDialog() {
    let dialogRef = this.dialog.open(DialogComponent, {
      disableClose: true,
      data: { validAdress: true },
    });
    dialogRef
    .afterClosed()
    .pipe(takeUntil(this.unsubscribe$))
    .subscribe((result) => {
      if (result === "") {
        return;
      }
      this.customerForm.get("coordinates")?.setValue(result.coordenada);
    });
  }

  dialogModifyCustomer(modifyCustomer: boolean, id_customer: number, id_customer_type: string): void {
    const isProspect = id_customer_type === '3' ? true : false;
    this.dialog.open(DialogComponent, {
      data: { modifyCustomer, id_customer, isProspect },
    }).afterClosed().pipe(first()).subscribe((res) => {
      if (res) {
        this.dialog.closeAll();
      }
    });
  }

  async setCity(country: any) {
    this.countrySelected = country.iso_num;
    if (this.countrySelected === "170") {
      this.billyCities = await this.citiesFormService.getBillyCities(this.countrySelected);
      this.spinnerService.hide();
    } else {
      this.cityList = await this.citiesFormService.getCityList(country);
      this.spinnerService.hide();
    }
  }

  getCitySelect() {
    if (this.customerForm.value.country.iso_num === "170") {
      this.customerForm
      .get("city")
      ?.setValue(this.customerForm.value.citySelect?.attributes.name);
    } else {
      this.customerForm
      .get("city")
      ?.setValue(this.customerForm.value.citySelect);
    }
  }

  async getFile(event: any) {
    const fileTransformed = await this.converseFilesService
    .transformFile(event, "application/pdf", false)
    .catch((err) => {
      if (err) {
        console.error(err);
        this.customerForm.get("PDF")?.reset();
      }
    });
    this.files = fileTransformed;
  }

  spinner(): void {
    this.spinnerService.show();
    setTimeout(() => {
      this.spinnerService.hide();
    }, 3000);
  }

  updateSelectedType() {
    this.selectedType = this.customerForm.value.types_docs.id_docs;
    if ((this.selectedType === "1" || this.selectedType === "2") && (this.companyObject.external_electronic_invoicing === "true" || this.companyObject.internal_electronic_invoicing === "true")) {
      this.customerForm.get("dv")?.enable();
    } else {
      this.customerForm.get("dv")?.disable();
    }
  }

  displayFnCustomer(customer: any) {
    return customer && customer ? customer.nombre : undefined;
  }

  displayFnCity(city: any) {
    return city && city ? city : undefined;
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}

import { gql } from "apollo-angular";
import { ACCOUNTING_ACCOUNTS_OBJECT, ARTICLES_OBJECT, INVENTORY_RECLASSIFICATION_OBJECT, NOTE_OBJECT, PAYMENT_INVOICE_OBJECT, PAYMENT_PURCHASES_OBJECT, PURCHASE_INVOICE_OBJECT, QUOTATIONBYSTATUS_OBJECT, QUOTATIONS_OBJECT, REFUND_OBJECT, TRANSFER_OBJECT, TREASURY_OBJECT, WAREHOUSE_OUTPUTS_ENTRIES_OBJECT, WAREHOUSE_TRANSFER_OBJECT } from "./fragment";

export const GETALL_CSV_INVENTORY_RECLASSIFICATIONS = gql`
  query getAllCsvInventoryReclassifications($year: Int, $company: String) {
    getAllCsvInventoryReclassifications(year: $year, company: $company) {
      ...InventoryReclassificationObject
    }
  }
  ${INVENTORY_RECLASSIFICATION_OBJECT}
`;

export const GETALL_CSV_WAREHOUSE_OUTPUTS_ENTRIES = gql`
  query getAllCsvWarehouseOutputsAndEntries($year: Int, $company: String) {
    getAllCsvWarehouseOutputsAndEntries(year: $year, company: $company) {
      ...WarehouseOutputsAndEntriesObject
    }
  }
  ${WAREHOUSE_OUTPUTS_ENTRIES_OBJECT}
`;

export const GETALL_CSV_PURCHASES = gql`
query getAllCsvPurchases($year: Int, $company: String) {
  getAllCsvPurchases(year: $year, company: $company) {
    ...PurchaseInvoiceObject
  }
}
${PURCHASE_INVOICE_OBJECT}
`;

export const GETALL_CSV_REFUNDS = gql`
query getAllCsvRefunds($year: Int, $company: String) {
  getAllCsvRefunds(year: $year, company: $company) {
    ...RefundObject
  }
}
${REFUND_OBJECT}
`;

export const GET_ALLPAYMENTS_WITHINVOICE = gql`
  query getAllPaymentsWithInvoice($year: Int, $company: String) {
    getAllPaymentsWithInvoice(year: $year, company: $company) {
      ...PayInvoiceObject
    }
  }
  ${PAYMENT_INVOICE_OBJECT}
`;

export const GETALL_PAYMENTS_WITH_PURCHASE = gql`
  query getAllPaymentsWithPurchase($year: Int, $company: String) {
    getAllPaymentsWithPurchase(year: $year, company: $company) {
      ...PayPurchasesObject
    }
  }
  ${PAYMENT_PURCHASES_OBJECT}
`;

export const GETALL_CSV_TRANSFERS = gql`
query getAllCsvTransfers($year: Int, $company: String) {
  getAllCsvTransfers(year: $year, company: $company) {
    ...TransferObject
  }
}
${TRANSFER_OBJECT}
`;

export const GETALL_CSV_ADVENCEPAYMENTS = gql`
query getAllCsvAdvencePayments($year: Int, $company: String) {
  getAllCsvAdvencePayments(year: $year, company: $company) {
    ...TreasuryObject
  }
}
${TREASURY_OBJECT}
`;

export const GETALL_CSV_WAREHOUSETRANSFERS = gql`
query getAllCsvWarehouseTransfers($year: Int, $company: String) {
  getAllCsvWarehouseTransfers(year: $year, company: $company) {
    ...WarehouseTransferObject
  }
}
${WAREHOUSE_TRANSFER_OBJECT}
`;

export const GETALL_CSV_ARTICLES = gql`
query getAllCsvArticles($company: String) {
  getAllCsvArticles(company: $company) {
    ...ArticlesObject
  }
}
${ARTICLES_OBJECT}
`;

export const GET_CSV_INVOICES = gql`
query getCsvInvoices($year: Int, $company: String) {
  getCsvInvoices(year: $year, company: $company) {
    ...QuotationsObject
  }
}
${QUOTATIONS_OBJECT}
`;
 
export const GETALL_CSV_NOTES = gql`
query getAllCsvNotes($year: Int, $company: String) {
  getAllCsvNotes(year: $year, company: $company) {
    ...NoteObject
  }
}
${NOTE_OBJECT}
`;

export const GET_ACCOUNTING_ACCOUNT = gql`
  query getAccountingAccount($document_type: String, $company: String) {
    getAccountingAccount(document_type: $document_type, company: $company) {
      ...AccountingAccountsObject
    }
  }
  ${ACCOUNTING_ACCOUNTS_OBJECT}
`;
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TabsComponent } from './tabs.component';
import { MaterialModule } from 'src/app/material.module';
import { StepperModule } from '../stepper/stepper.module';
import { CardModule } from '../card/card.module';
import { FormsModule } from '@angular/forms'
import { SearchModule } from '../search/search.module';
import { NgxPaginationModule } from 'ngx-pagination';
import { DialogModule } from '../dialog/dialog.module';
import { FilterQuotPipe } from '../../pipe/filter-quot.pipe';
import { MatIconModule } from '@angular/material/icon';
import { TranslateModule } from '@ngx-translate/core';
import { NgxSpinnerModule } from 'ngx-spinner';

@NgModule({
    declarations: [
        TabsComponent,
        FilterQuotPipe
    ],
    imports: [
        CommonModule,
        MaterialModule,
        StepperModule,
        CardModule,
        FormsModule,
        SearchModule,
        NgxPaginationModule,
        DialogModule,
        MatIconModule,
        TranslateModule,
        NgxSpinnerModule
    ],
    providers: [
        FilterQuotPipe
    ],
    exports: [TabsComponent]
})
export class TabsModule { }

import { Injectable } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { IDateFormat } from "src/app/@interfaces/Siigo/format-date-siigo.interface";
import { ICustomer } from "src/app/@interfaces/customer.interface";
import { IMovementInventory } from "src/app/@interfaces/movement-inventory.interface";
import { IProvider } from "src/app/@interfaces/provider.interface";
import { IPurchaseInvoice } from "src/app/@interfaces/purchaseInvoice.interface";
import { ITransfers } from "src/app/@interfaces/transfers.interface";
import { IVentaQuery } from "src/app/@interfaces/venta.interface";
import { ISiigoProvider, ISiigoRow } from "src/app/@interfaces/Siigo/rows.interface";
import { IAccountingAccounts } from "src/app/@interfaces/accountingAccounts.interface";

@Injectable({
  providedIn: "root",
})
export class FunctionsSiigoService {
  constructor(
    private translate: TranslateService
  ) {}

  getAdvancePaymentDescription(type: string) {
    if (type === "Customers") {
      return this.translate.instant("CUSTOMER ADVANCE PAYMENT");
    } else {
      return this.translate.instant("PROVIDER ADVANCE PAYMENT");
    }
  }

  getDocumentType(type: string, customer: ICustomer, provider: IProvider) {
    if (type === "Customers") {
      return customer.doc_nit ? customer.doc_nit.split("-")[0] : '';
    } else {
      return provider.doc_nit ? provider.doc_nit!.split("-")[0] : '';
    }
  }

  getName(type: string, customer: ICustomer, provider: IProvider) {
    if (type === "Customers") {
      return customer.nombre;
    } else {
      return provider.nombre;
    }
  }

  getTransferDescription(voucherType: string, date: string) {
    return voucherType === "G"
      ? this.translate.instant("CONSIGNMENT") + " " + date
      : this.translate.instant("TRANSFER TO CASHIER") + " " + date;
  }

  getTransferValue(transfer: ITransfers) {
    let total = 0;
    if (transfer.invoice_payment) {
      total = transfer.invoice_payment.value;
    } else if (transfer.purchase_payment) {
      total = transfer.purchase_payment.value;
    } else {
      total = transfer.custom_value;
    }
    return total;
  }

  getVoucherType(type: string) {
    return type === "Bank" ? "G" : "N";
  }

  validTaxes(withholdingTax: number, icaTax: number): string {
    return withholdingTax === 0 && icaTax === 0 ? "S" : "N";
  }

  getDateSeparated(createdAt: string, invoiced_date?: string): IDateFormat {
    const date = invoiced_date ? new Date(invoiced_date!) : new Date(createdAt);
    const day = date.getDate();
    const month = date.getMonth() + 1;
    const year = date.getFullYear();
    return { day: day.toString(), month: month.toString(), year: year.toString() }
  }

  getContributorTax(id_contributor: number, value: number) {
    return id_contributor === 7 ? 0 : value;
  }

  validNegatives(number: number) {
    let result = 0;
    if (Math.sign(number) === -1) {
      result = number * -1;
    } else if (Math.sign(number) === 1) {
      result = number;
    }
    return result;
  }

  getCosts(id_invoice: number, article: string, inventoryMovements: IMovementInventory[], type: string) {
    let cost = 0;
    const matchingMovement = inventoryMovements.find(element => {
      return element.id_invoice?.toString() === id_invoice.toString() &&
      element.reference === article &&
      element.document.startsWith(type)
    })
    cost = matchingMovement?.unitary! * (Math.abs(matchingMovement?.quantity!))
    return cost ? cost : 0;
  }

  getLastCost(inventoryMovements: IMovementInventory[], invoice: number, article: string, documentType: string) {
    return this.filterInventoryToAccountingPipe(inventoryMovements, invoice, article, documentType);
  }

  filterInventoryToAccountingPipe(
    inventoryMovements: IMovementInventory[],
    invoice: number,
    article: string,
    documentType: string
  ): number {
    return inventoryMovements
      .filter((item) =>
        item.id_invoice?.toString() === invoice?.toString() &&
        item.reference.toString() === article!.toString() &&
        item.document[0] === documentType
      )
      .map(element => element.unitary).shift() || 0
  }

  addDayToDate(date: Date, days: number) {
    let initDate = new Date(date);
    initDate.setDate(initDate.getDate() + days);
    return initDate;
  }

  amountSales(sales: IVentaQuery[]) {
    let sum = 0;
    sales.forEach((element) => {
      sum += element.cantidad;
    });
    return sum;
  }

  getPurchasesLength(purchase: IPurchaseInvoice[]) {
    let length = 0;
    purchase.forEach((element) => {
      length = length + element.purchases.length;
    });
    return length;
  }

  setProviderObject(purchase: IPurchaseInvoice, finalDate: IDateFormat): ISiigoProvider {
    const splitNumber = this.splitPurchaseNumber(purchase.contpurchase![0].written_invoice);
    const providerDocument = splitNumber[1];
    const providerPrefix = splitNumber[0];
    const providerDocumentYear = finalDate.year
    const providerDocumentMonth = finalDate.month
    const providerDocumentDay = finalDate.day
    return {
      providerDocument: providerDocument,
      providerPrefix: providerPrefix,
      providerDocumentYear: providerDocumentYear,
      providerDocumentMonth: providerDocumentMonth,
      providerDocumentDay: providerDocumentDay
    }
  }

  splitPurchaseNumber(written_invoice: string) {
    if(written_invoice.includes('-')) {
      return written_invoice.split('-');
    }else if(written_invoice.includes(' ')) {
      return written_invoice.split(' ');
    }else {
      const letters = written_invoice.replace(/\d/g, '');
      const numbers = written_invoice.replace(/\D/g, '');
      return [letters, numbers];
    }
  }

  getAccountsOrder(
    saleAccountingAccounts: IAccountingAccounts, 
    firstCostAccountingAccounts: IAccountingAccounts, 
    secondCostAccountingAccounts: IAccountingAccounts
  ) {
    const articleAccount = saleAccountingAccounts.inventory;
    const serviceAccount = saleAccountingAccounts.service;
    const totalInvoiceAccount = saleAccountingAccounts.total;
    const secondCostAccount = firstCostAccountingAccounts.total;
    const firstCostAccount = secondCostAccountingAccounts.total;
    const taxAccount = saleAccountingAccounts.tax;
    const withholdingAccount = saleAccountingAccounts.withholding;
    const icaAccount = saleAccountingAccounts.ica;
    return [
      [articleAccount, serviceAccount],
      totalInvoiceAccount,
      secondCostAccount,
      firstCostAccount,
      taxAccount,
      withholdingAccount,
      icaAccount
    ]
  }

  orderInvoiceRows(invoiceRows: ISiigoRow[], orderSaleAccounts: (string | string[])[]) {
    return invoiceRows.sort((a, b) => {
      const getOrderIndex = (account: string) => {
        for (let i = 0; i < orderSaleAccounts.length; i++) {
          if (Array.isArray(orderSaleAccounts[i])) {
            if (orderSaleAccounts[i].includes(account)) {
              return i;
            }
          } else {
            if (orderSaleAccounts[i] === account) {
              return i;
            }
          }
        }
        return -1;
      };
      return getOrderIndex(a.accountingAccount) - getOrderIndex(b.accountingAccount);
    });
  }
}

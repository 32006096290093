import { Component, Input, OnInit, OnDestroy, ChangeDetectionStrategy } from "@angular/core";
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from "@angular/forms";
import { MatDialog, MatDialogRef } from "@angular/material/dialog";
import { ICountry } from "src/app/@interfaces/country.interface";
import { ICustomer } from "src/app/@interfaces/customer.interface";
import { CustomerService } from "src/app/@pages/customer/customer.service";
import { ValidatorsService } from "src/app/@shared/validator/validators.service";
import { DialogComponent } from "../../dialog.component";
import { IDocumenType } from "src/app/@interfaces/authentication/documenType";
import { BillyCities } from "src/app/@interfaces/Billy/billy.interface";
import { NgxSpinnerService } from "ngx-spinner";
import { Observable, Subject, of } from "rxjs";
import { takeUntil, catchError, startWith, map } from 'rxjs/operators';
import { ICustomerSegment } from "src/app/@interfaces/customerSegment.interface";
import { IContributorType } from "src/app/@interfaces/contributorTypes.interface";
import { ICustomerType } from "src/app/@interfaces/customerType.interface";
import { BillyPayerService } from "../register-customer/billyPayers.service";
import { ConverseFilesService } from "../../../services/converse-files.service";
import { UsersService } from "src/app/@pages/users/users.service";
import { IUserSeller } from "src/app/@interfaces/userSeller.interface";
import { AlertsService } from "../../../services/alerts.service";
import { TranslateService } from "@ngx-translate/core";
import { CookieAuthService } from "src/app/@shared/storage-variables/cookie-auth.service";
import { CitiesFormService } from "../../../services/cities-form.service";

@Component({
  selector: "app-modify-customer",
  templateUrl: "./modify-customer.component.html",
  styleUrls: ["./modify-customer.component.css"],
})
export class ModifyCustomerComponent implements OnInit, OnDestroy {
  @Input() id_customer: number = 0;
  cityList: string[] = [];
  filterCities$: Observable<string[]> = new Observable();
  billyCities = {} as BillyCities;
  countryList: Array<ICountry> = [];
  sellerLists: Array<IUserSeller> = [];
  documenTypeList: Array<IDocumenType> = [];
  customerModify = {} as ICustomer;
  customerSegmentList: Array<ICustomerSegment> = [];
  contributorTypeList: Array<IContributorType> = [];
  customerTypeList: Array<ICustomerType> = [];
  wasCreated: boolean = false;
  customerForm: UntypedFormGroup = this.fb.group({
    cust_type: ["", [Validators.required]],
    doc_nit: ["", [Validators.required, Validators.pattern(this.validatorS.documentPattern)]],
    dv: [
      "",
      [
        Validators.required,
        Validators.pattern(this.validatorS.documentPattern),
        Validators.maxLength(1),
      ],
    ],
    types_docs: ["", [Validators.required]],
    name: ["", [Validators.required, Validators.maxLength(60)]],
    address: ["", [Validators.required, Validators.maxLength(100)]],
    phone: [
      "",
      [
        Validators.required,
        Validators.pattern(this.validatorS.documentPattern),
        Validators.maxLength(15),
      ],
    ],
    email: [
      "",
      [
        Validators.required,
        Validators.pattern(this.validatorS.emailPattern),
        Validators.maxLength(50),
      ],
    ],
    country: ["", Validators.required],
    city: ["", Validators.required],
    citySelect: [""],
    coordinates: ["", Validators.required],
    RUT: [],
    contributorType: ["", [Validators.required]],
    seller: ["", [Validators.required]],
    segment: ["", [Validators.required]],
  });

  files: any;
  companyId = '';
  userSeller = '';
  unsubscribe$ = new Subject();
  selectedType: string = "";
  useElectronicBilling: boolean = false;
  clientErrorMessage: string = '';

  constructor(
    private spinnerService: NgxSpinnerService,
    private fb: UntypedFormBuilder,
    private validatorS: ValidatorsService,
    private customer: CustomerService,
    private dialog: MatDialog,
    private billyPayerService: BillyPayerService,
    private converseFilesService: ConverseFilesService,
    private userService: UsersService,
    private alertsService: AlertsService,
    public dialogRef: MatDialogRef<ModifyCustomerComponent>,
    private translate: TranslateService,
    private cookieAuthService: CookieAuthService,
    private citiesFormService: CitiesFormService
  ) {}

  async ngOnInit() {
    this.getAuthValues();
    this.loadData();
    this.citiesFromStore();
  }

  citiesFromStore() {
    this.citiesFormService.setInitialCitiesFromStore();
    this.customerForm.get('country')?.valueChanges.subscribe((result) => {
      this.spinnerService.show();
      this.setCity(result)
    })
    this.filterCities$ = this.customerForm.get('citySelect')!.valueChanges.pipe(
      startWith(""),
      map((value) => this.citiesFormService.filterCities(value, this.cityList))
    );
  }

  getAuthValues() {
    const company = this.cookieAuthService.getCompanyObject;
    this.companyId = company!.Id_company!.toString();
    this.userSeller = this.cookieAuthService.getUserSellerId!;
  }

  loadData() {
    this.userService
    .getAllSellerUser()
    .pipe(takeUntil(this.unsubscribe$))
    .subscribe((result: any) => {
      this.sellerLists = result;
    });

    this.customer
    .getCustomer(this.id_customer)
    .pipe(takeUntil(this.unsubscribe$))
    .subscribe((result) => {
      this.customerModify = result;
      this.setCustomer(this.customerModify);
    });

    this.customer
    .listCountries()
    .pipe(takeUntil(this.unsubscribe$))
    .subscribe((result) => {
      this.countryList = result;
    });

    this.userService
    .listDocumenTypes()
    .pipe(takeUntil(this.unsubscribe$))
    .subscribe((result) => {
      this.documenTypeList = result;
    });

    this.customer
    .listCustomerSegment()
    .pipe(takeUntil(this.unsubscribe$))
    .subscribe((result: any) => {
      this.customerSegmentList = result;
    });

    this.customer
    .listContributorTypes()
    .pipe(takeUntil(this.unsubscribe$))
    .subscribe((result: any) => {
      this.contributorTypeList = result;
    });

    this.customer
    .listCustomerType()
    .pipe(takeUntil(this.unsubscribe$))
    .subscribe((result: any) => {
      this.customerTypeList = result;
    });

    this.customerForm.get("doc_nit")!.valueChanges.subscribe((newValue) => {
      if (newValue) {
        this.customerForm
        .get("dv")
        ?.setValue(
          this.customer.calculateVerificationDigit(newValue.toString())
        );
      }
    });

    this.useElectronicBilling = (this.companyId === '7' || this.companyId === '9') ? true : false;
  }

  setCustomer(customerSets: ICustomer) {
    this.customerForm.patchValue({
      doc_nit: customerSets.doc_nit,
      types_docs: customerSets.types_docs![0],
      name: customerSets.nombre,
      email: customerSets.email,
      address: customerSets.direccion,
      phone: customerSets.telefono,
      country: customerSets.country[0],
      coordinates: customerSets.coordinates,
      contributorType: customerSets.contributorType,
      seller: customerSets.userSeller,
      segment: customerSets.customer_segment![0],
      cust_type: customerSets.customer_type,
      dv: customerSets.verification_digit,
    });
    this.setCity(true, customerSets.city);
    this.updateSelectedType();
    this.verifyCreation(parseInt(customerSets.customer_type!.id_customerType.toString()));
  }

  verifyCreation(type: number) {
    this.wasCreated = type === 1 || type === 2;
  }

  updateCustomer() {
    const coords = this.customerForm.value.coordinates;
    const verification_digit = this.customerForm.get('dv')?.getRawValue();
    this.customer
    .updateCustomer(
      this.id_customer,
      this.customerForm.value.doc_nit,
      parseInt(this.customerForm.value.types_docs?.id_docs) || null,
      this.customerForm.value.name,
      this.customerForm.value.address,
      this.customerForm.value.phone,
      this.customerForm.value.email,
      parseInt(this.customerForm.value.country.id_country),
      this.customerForm.value.city,
      coords,
      "FILE NO EXISTS TO MODIFY",
      parseInt(this.customerForm.value.contributorType?.id_contributor) || null,
      parseInt(this.customerForm.value.seller.seller.id_seller),
      parseInt(this.customerForm.value.segment?.id_segment) || null,
      parseInt(this.customerForm.value.cust_type.id_customerType),
      parseInt(this.customerForm.value.seller.id_userSeller),
      parseInt(verification_digit)
    )
    .pipe(catchError(error => {
      if (error.graphQLErrors) {
        const [graphQLError] = error.graphQLErrors
        this.clientErrorMessage = graphQLError.message;
        this.spinnerService.hide();
        return of(error.graphQLErrors)
      }
      this.spinnerService.hide();
      return of(error)
    }),takeUntil(this.unsubscribe$))
    .subscribe((result) => {
      if (result) {
        this.spinnerService.hide();
        this.afterUpdateCustomer();
      }
    });
  }

  afterUpdateCustomer() {
    this.alertsService.showSuccessfullAlert("Customer updated successfully")
    .then((result) => {
      if (result.isConfirmed) {
        this.dialogRef.close(true);
      }
    });
  }

  generalValidations(type: string) {
    if (type == 'Prospecto') {
      return true;
    } else {
      if (this.customerForm.invalid === true) {
        this.clientErrorMessage = this.translate.instant("There are required fields. Please fill them");
        return false;
      } else if (this.customerForm.value.cust_type.type === "Legal" && !this.customerForm.value.RUT && this.useElectronicBilling && !this.wasCreated) {
        this.clientErrorMessage = this.translate.instant("For legal people you should add the RUT document");
        return false;
      } else if(this.customerForm.value.cust_type.type === "Legal" && !this.customerForm.value.dv && this.useElectronicBilling) {
        this.clientErrorMessage = this.translate.instant("For legal people the document should have the verification digit separated by a dash");
        return false;
      } else if (this.customerForm.value.cust_type.type === "Natural" && (this.customerForm.value.types_docs.id_docs === "1" || this.customerForm.value.types_docs.id_docs === "2")) {
        this.clientErrorMessage = this.translate.instant("You cannot set NIT For natural people");
        return false;
      } else {
        return true;
      }
    }
  }

  ifUpdateProspect() {
    if (!this.customerForm.value.name || !this.customerForm.value.seller || !this.customerForm.value.country || !this.customerForm.value.citySelect) {
      this.customerForm.get("name")?.markAsTouched();
      this.customerForm.get("seller")?.markAsTouched();
      this.customerForm.get("country")?.markAsTouched();
      this.customerForm.get("citySelect")?.markAsTouched();
      this.spinnerService.hide();
      this.alertsService.showWarningAlert("Company name, country, city and seller are required fields");
      return;
    } else {
      this.updateCustomer();
    }
  }

  validUpdate() {
    this.spinnerService.show();
    const initialValidations = this.generalValidations(this.customerForm.value.cust_type.type);
    if (!initialValidations) {
      return;
    }else {
      if (this.customerForm.value.cust_type.type === "Prospecto") {
        this.ifUpdateProspect();
      } else if (this.customerForm.value.cust_type.type != "Prospecto" && !this.wasCreated && this.useElectronicBilling) {
        this.customerForm.markAllAsTouched();
        this.spinnerService.show();
        if (this.customerForm.value.cust_type.type === "Legal") {
          this.sendLegalPayer();
        }
        if (this.customerForm.value.cust_type.type === "Natural") {
          this.sendNaturalPayer();
        }
      } else {
        this.updateCustomer();
      }
    }
  }

  verifyPayerSent() {
    const payerType = this.customerForm.value.cust_type.type;
    const validations = this.generalValidations('');
    const rutValidation = this.resendValidation();
    if (!validations || !rutValidation) {
      return
    }else {
      this.spinnerService.hide();
      switch(payerType) {
        case "Prospecto":
          this.alertsService.showErrorAlert("This customer is a prospect still!");
        break;
        case "Legal":
          this.sendLegalPayer();
        break;
        case "Natural":
          this.sendNaturalPayer();
        break;
      }
    }
  }

  resendValidation() {
    if(this.customerForm.value.cust_type.type === "Legal" && !this.customerForm.value.RUT && this.useElectronicBilling) {
      this.spinnerService.hide();
      this.alertsService.showErrorAlert("For legal persons you must send the RUT!");
      return false;
    }else{
      return true;
    }
  }

  sendLegalPayer() {
    this.billyPayerService
    .sendDianLegal(this.customerForm, this.files)
    .then((result) => {
      if (result) {
        this.updateCustomer();
      }
    })
    .catch((error) => {
      if (error.error.errors[0].detail === "An office already exists in this city") {
        this.updateCustomer();
      } else {
        this.alertsService.showErrorAlert(error.error.errors[0].detail)
        console.log(error);
      }
    });
  }

  sendNaturalPayer() {
    this.billyPayerService
    .sendDianNatural(this.customerForm)
    .then((result) => {
      if (result) {
        this.updateCustomer();
      }
    })
    .catch((error) => {
      if (error.error.errors[0].detail === "The idNumber is already registered") {
        this.updateCustomer();
      } else {
        this.alertsService.showErrorAlert(error.error.errors[0].detail)
        console.log(error);
      }
    });
  }

  closeDialog() {
    this.dialog.closeAll();
  }

  textValid(text: string) {
    return (
      this.customerForm.get(text)?.invalid &&
      this.customerForm.get(text)?.touched
    );
  }

  openDialog() {
    let dialogRef = this.dialog.open(DialogComponent, {
      disableClose: true,
      data: { validAdress: true },
    });
    dialogRef
    .afterClosed()
    .pipe(takeUntil(this.unsubscribe$))
    .subscribe((result) => {
      if (result === "") {
        return;
      }
      this.customerForm.get("coordinates")?.setValue(result.coordenada);
    });
  }

  async setCity(setcities: boolean, citySelected?: string) {
    const country = this.customerForm.value.country;
    if (country.name === "Colombia") {
      this.billyCities = await this.citiesFormService.getBillyCities('170');
      if (setcities) {
        this.searchCityByBilly(citySelected!, this.billyCities);
      }
      this.spinnerService.hide();
    } else {
      this.customerForm.patchValue({
        city: citySelected,
        citySelect: citySelected,
      });
      this.cityList = await this.citiesFormService.getCityList(country);
      this.spinnerService.hide();
    }
  }

  getCitySelect() {
    if (this.customerForm.value.country.name === "Colombia") {
      this.customerForm
      .get("city")
      ?.setValue(this.customerForm.value.citySelect.attributes.name);
    } else {
      this.customerForm
      .get("city")
      ?.setValue(this.customerForm.value.citySelect);
    }
  }

  searchCityByBilly(citySelected: string, cities: BillyCities) {
    const city = cities.data.find(
      (item: any) => item.attributes.name === citySelected
    );
    let cityData: any = {};
    if (city) {
      cityData.attributes = city.attributes;
      cityData.id = city.id;
      cityData.type = city.type;
      this.customerForm.patchValue({
        city: cityData.attributes.name,
        citySelect: cityData,
      });
    }
  }

  compareDocType(item1: any, item2: any) {
    if (item1 === null || item2 === null) {
      return false;
    }
    return item1?.id_docs === item2?.id_docs;
  }

  compareCountry(item1: any, item2: any) {
    if (item1 === null || item2 === null) {
      return false;
    }
    return item1?.id_country === item2?.id_country;
  }

  compareSeller(item1: any, item2: any) {
    if (!item1 || !item2) {
      return false;
    }
    return item1?.id_seller === item2?.id_seller;
  }

  compareSegment(item1: any, item2: any) {
    if (item1 === null || item2 === null) {
      return false;
    }
    return item1?.id_segment === item2?.id_segment;
  }

  compareCustomerType(item1: any, item2: any) {
    if (item1 === null || item2 === null) {
      return false;
    }
    return item1.id_customerType === item2.id_customerType;
  }

  compareContributor(item1: any, item2: any) {
    if (item1 === null || item2 === null) {
      return false;
    }
    return item1.id_contributor === item2.id_contributor;
  }

  compareCityBilly(item1: any, item2: any) {
    if (!item1 || !item2) {
      return false;
    }
    return item1.attributes.name === item2.attributes.name;
  }

  compareCity(item1: any, item2: any) {
    if (!item1 || !item2) {
      return false;
    }
    return item1 === item2;
  }

  async getFile(event: any) {
    const fileTransformed = await this.converseFilesService
    .transformFile(event, "application/pdf", false)
    .catch((err) => {
      if (err) {
        console.error(err);
        this.customerForm.get("PDF")?.reset();
      }
    });
    this.files = fileTransformed;
  }

  updateSelectedType() {
    this.selectedType = this.customerForm.value.types_docs?.id_docs;
    if ((this.selectedType === "1" || this.selectedType === "2") && this.useElectronicBilling) {
      this.customerForm.get("dv")?.enable();
    } else {
      this.customerForm.get("dv")?.disable();
    }
  }

  displayFnCity(city: any) {
    return city && city ? city : undefined;
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}

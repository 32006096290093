import { Injectable } from "@angular/core";
import { UntypedFormGroup } from "@angular/forms";
import { TranslateService } from "@ngx-translate/core";
import { NgxSpinnerService } from "ngx-spinner";
import {
  BillyPayerLegal,
  BillyPayerNatural,
} from "src/app/@interfaces/Billy/billy.interface";
import { BillyService } from "src/app/@pages/billyService/billy.service";
import Swal from "sweetalert2";

@Injectable({
  providedIn: "root",
})
export class BillyPayerService {
  billyPayerLegal = {} as BillyPayerLegal;
  billyPayerNatural = {} as BillyPayerNatural;
  constructor(
    private translate: TranslateService,
    private spinnerService: NgxSpinnerService,
    private billyService: BillyService,
  ) {}


  sendDianLegal(customerForm: UntypedFormGroup, files: any) {
    return new Promise<boolean>((resolve, reject) => {
      const formData = new FormData();
      this.billyPayerLegal = this.setBillyPayerLegal(customerForm, files);
      formData.append("address", this.billyPayerLegal.address);
      formData.append("cityId", this.billyPayerLegal.cityId);
      formData.append("email", this.billyPayerLegal.email.toString());
      formData.append("headquarter", new Boolean(this.billyPayerLegal.headquarter).toString());
      formData.append("idNumber", this.billyPayerLegal.idNumber);
      formData.append("idType", this.billyPayerLegal.idType.toString());
      formData.append("name", this.billyPayerLegal.name);
      formData.append("phone", this.billyPayerLegal.phone);
      formData.append("rut", this.billyPayerLegal.rut);
      this.billyService.postLegalPayers(formData).subscribe(
        (result) => {
          if (result) {
            Swal.fire(
              this.translate.instant("Good"),
              this.translate.instant("Customer send to Billy"),
              "success"
            ).then((result) => {
              if (result.isConfirmed) {
                this.spinnerService.hide();
                resolve(result.isConfirmed);
              }
            });
          }
        },
        (error) => {
          this.spinnerService.hide();
          this.billyService.handleError(error);
        }
      );
    });
  }

  sendDianNatural(customerForm: UntypedFormGroup) {
    return new Promise<boolean>((resolve, reject) => {
      const formData = new FormData();
      this.billyPayerNatural = this.setBillyPayerNatural(customerForm);
      formData.append("address", this.billyPayerNatural.address);
      formData.append("cityId", this.billyPayerNatural.cityId);
      formData.append("email", this.billyPayerNatural.email.toString());
      formData.append("idNumber", this.billyPayerNatural.idNumber);
      formData.append("idType", this.billyPayerNatural.idType.toString());
      formData.append("lastname", this.billyPayerNatural.lastname);
      formData.append("name", this.billyPayerNatural.name);
      this.billyService.postNaturalPayers(formData).subscribe(
        (result) => {
          if (result) {
            Swal.fire(
              this.translate.instant("Good"),
              this.translate.instant("Customer send to Billy"),
              "success"
            ).then((result) => {
              if (result.isConfirmed) {
                this.spinnerService.hide();
                resolve(result.isConfirmed);
              }
            });
          }
        },
        (error) => {
          this.spinnerService.hide();
          this.billyService.handleError(error);
        }
      );
    });
  }

  setBillyPayerLegal(form: UntypedFormGroup, files: any) {
    let payer = {} as BillyPayerLegal;
    payer = {
      address: form.value.address,
      cityId: form.value.citySelect.id,
      email: form.value.email,
      headquarter: true,
      idNumber: form.value.doc_nit + "-" + form.value.dv,
      idType: parseInt(form.value.types_docs.id_second),
      name: form.value.name,
      phone: form.value.phone,
      rut: files!,
    };
    return payer;
  }

  setBillyPayerNatural(form: UntypedFormGroup) {
    let payer = {} as BillyPayerNatural;
    let name = "";
    let lastName = "";
    let nameParts: string[] = form.value.name.split(" ");
    if (nameParts.length === 1 || nameParts.length > 4) {
      Swal.fire(
        this.translate.instant("Error"),
        this.translate.instant("Name invald, must have name and last name"),
        "error"
      ).then((result) => {
        if (result.isConfirmed) {
          return;
        }
      });
    } else if (nameParts.length === 2) {
      name = nameParts[0];
      lastName = nameParts[1];
    } else if (nameParts.length === 3) {
      name = nameParts[0];
      lastName = nameParts[1] + " " + nameParts[2];
    } else if (nameParts.length === 4) {
      name = nameParts[0] + " " + nameParts[1];
      lastName = nameParts[2] + " " + nameParts[3];
    }
    payer = {
      address: form.value.address,
      cityId: form.value.citySelect.id,
      email: form.value.email,
      idNumber: form.value.doc_nit,
      idType: parseInt(form.value.types_docs.id_second),
      name: name,
      lastname: lastName,
    };
    return payer;
  }
}

import { NgModule } from '@angular/core';
import { CommonModule, DatePipe, DecimalPipe } from '@angular/common';

import { AnalysisRoutingModule } from './analysis-routing.module';
import { AnalysisComponent } from './analysis.component';
import { MatIconModule } from '@angular/material/icon';
import { BarChartComponent } from './components/bar-chart/bar-chart.component';
import { MaterialModule } from 'src/app/material.module';
import { ChartsModule } from 'ng2-charts';
import { GrapV1Component } from './pages/grap-v1/grap-v1.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { NgxSpinnerModule } from 'ngx-spinner';
import { DollarsPerClientGraphComponent } from './pages/grap-v1/dollars-per-client-graph/dollars-per-client-graph.component';
import { LiterPerClientGraphComponent } from './pages/grap-v1/liter-per-client-graph/liter-per-client-graph.component';
import { LiterPerItemGraphComponent } from './pages/grap-v1/liter-per-item-graph/liter-per-item-graph.component';
import { TranslateModule } from '@ngx-translate/core';
import { LiterPerMonthGraphComponent } from './pages/grap-v1/liter-per-month-graph/liter-per-month-graph.component';
import { LiterPerGroupGraphComponent } from './pages/grap-v1/liter-per-group-graph/liter-per-group-graph.component';
import { PieComponent } from './components/pie/pie.component';
import { DxChartModule, DxCheckBoxModule, DxSelectBoxModule, DxTreeMapModule, DxButtonModule, DxPopoverModule } from 'devextreme-angular';
import { DrillDownComponent } from './components/drill-down/drill-down.component';
import { DollarsCliGraphCsvComponent } from './pages/csv/dollars-customer-graph/dollars-customer-graph.component';
import { DrillItemsGraphComponent } from './pages/grap-v1/drill-items-graph/drill-items-graph.component';
import { CsvComponent } from './pages/csv/csv.component';
import { AccountReceivablesGraphComponent } from './pages/csv/account-receivables-graph/account-receivables-graph.component';
import { StackedBarComponent } from './components/stacked-bar/stacked-bar.component';
import { FilterCustomerCsvPipe } from 'src/app/@shared/pipe/pipes-csv/filter-customer-csv.pipe';
import { MultiplePaneComponent } from './components/multiple-pane/multiple-pane.component';

import { FilterCDISSales } from 'src/app/@shared/pipe/graphs-pipes/sales/customer-date-item-seller.pipe';
import { FilterSSales } from 'src/app/@shared/pipe/graphs-pipes/sales/seller.pipe';
import { FilterSISales } from 'src/app/@shared/pipe/graphs-pipes/sales/seller-item.pipe';
import { FilterSIDSales } from 'src/app/@shared/pipe/graphs-pipes/sales/seller-item-date.pipe';
import { FilterSCSales } from 'src/app/@shared/pipe/graphs-pipes/sales/seller-customer.pipe'; 
import { FilterSDCSales } from 'src/app/@shared/pipe/graphs-pipes/sales/seller-date-customer.pipe';
import { FilterSICSales } from 'src/app/@shared/pipe/graphs-pipes/sales/seller-item-customer.pipe';
import { FilterSDSales } from 'src/app/@shared/pipe/graphs-pipes/sales/seller-date.pipe';
import { FilterCSales } from 'src/app/@shared/pipe/graphs-pipes/sales/customer.pipe';
import { FilterCDSales } from 'src/app/@shared/pipe/graphs-pipes/sales/customer-date.pipe';
import { FilterCISales } from 'src/app/@shared/pipe/graphs-pipes/sales/customer-item.pipe';
import { FilterISales } from 'src/app/@shared/pipe/graphs-pipes/sales/item.pipe';
import { FilterDISales } from 'src/app/@shared/pipe/graphs-pipes/sales/date-item.pipe';
import { FilterDSales } from 'src/app/@shared/pipe/graphs-pipes/sales/date.pipe';

import { FilterCDISQuots } from 'src/app/@shared/pipe/graphs-pipes/quotations/customer-date-item-seller.pipe';
import { FilterSQuots } from 'src/app/@shared/pipe/graphs-pipes/quotations/seller.pipe';
import { FilterSIQuots } from 'src/app/@shared/pipe/graphs-pipes/quotations/seller-item.pipe';
import { FilterSIDQuots } from 'src/app/@shared/pipe/graphs-pipes/quotations/seller-item-date.pipe';
import { FilterSCQuots } from 'src/app/@shared/pipe/graphs-pipes/quotations/seller-customer.pipe';
import { FilterSDCQuots } from 'src/app/@shared/pipe/graphs-pipes/quotations/seller-date-customer.pipe';
import { FilterSICQuots } from 'src/app/@shared/pipe/graphs-pipes/quotations/seller-item-customer.pipe';
import { FilterSDQuots } from 'src/app/@shared/pipe/graphs-pipes/quotations/seller-date.pipe';
import { FilterCQuots } from 'src/app/@shared/pipe/graphs-pipes/quotations/customer.pipe';
import { FilterCDQuots } from 'src/app/@shared/pipe/graphs-pipes/quotations/customer-date.pipe';
import { FilterCIQuots } from 'src/app/@shared/pipe/graphs-pipes/quotations/customer-item.pipe';
import { FilterIQuots } from 'src/app/@shared/pipe/graphs-pipes/quotations/item.pipe';
import { FilterDIQuots } from 'src/app/@shared/pipe/graphs-pipes/quotations/date-item.pipe';
import { FilterDQuots } from 'src/app/@shared/pipe/graphs-pipes/quotations/date.pipe';
import { FilterPresentationSales } from 'src/app/@shared/pipe/graphs-pipes/sales/presentation-type.pipe';
import { ProductAmountGraphComponent } from './pages/csv/product-amount-graph/product-amount-graph.component';
import { ProductPriceGraphComponent } from './pages/csv/product-price-graph/product-price-graph.component';
import { FilterCustomerCsvPipeAmount } from 'src/app/@shared/pipe/pipes-csv/filter-customer-csv-amount.pipe';
import { FilterArticleCsvPipe } from 'src/app/@shared/pipe/pipes-csv/filter-article-csv.pipe';
import { FilterSellerCsvPipe } from 'src/app/@shared/pipe/pipes-csv/filter-seller-csv.pipe';
import { FilterCustomerArticleCsvPipe } from 'src/app/@shared/pipe/pipes-csv/filter-customer-article-csv.pipe';
import { FilterCustomerSellerCsvPipe } from 'src/app/@shared/pipe/pipes-csv/filter-customer-seller-csv.pipe';
import { FilterArticleSellerCsvPipe } from 'src/app/@shared/pipe/pipes-csv/filter-article-seller-csv.pipe';
import { FilterCustArticleSellerCsvPipe } from 'src/app/@shared/pipe/pipes-csv/filter-cust-article-seller-csv.pipe';
import { ProductDaysGraphComponent } from './pages/csv/product-days-graph/product-days-graph.component';
import { AnalysisCsvService } from './analysis-csv.service';
import { FilterSellerCsvAccountReceivablesPipe } from 'src/app/@shared/pipe/pipes-csv/account-receivables/filter-seller.pipe';
import { FilterCustomerSellerCsvAccountPipe } from 'src/app/@shared/pipe/pipes-csv/account-receivables/filter-customer-seller.pipe';
import { FilterFullSales } from '../../@shared/pipe/graphs-pipes/sales/full-sales.pipe';
import { FilterFullQuots } from '../../@shared/pipe/graphs-pipes/quotations/full-quotation.pipe';
import { FilterBySearchService } from '../../@shared/components/services/filterBySearch.service';
import { FilterFullDateSales } from '../../@shared/pipe/graphs-pipes/sales/full-date-sales.pipe';
import { FilterFullDateQuots } from '../../@shared/pipe/graphs-pipes/quotations/full-date-quotation.pipe';
import { ProcessNotesService } from '../../@shared/components/services/process-notes.service';
import { StreetDaysClientGraphComponent } from './pages/csv/street-days-client-graph/street-days-client-graph.component';
import { FilterPTQuots } from 'src/app/@shared/pipe/graphs-pipes/quotations/presentation.pipe';
import { FilterINotes } from 'src/app/@shared/pipe/graphs-pipes/notes/item.pipe';
import { FilterPTNotes } from 'src/app/@shared/pipe/graphs-pipes/notes/presentation.pipe';
import { FlexLayoutModule } from '@angular/flex-layout';

@NgModule({
  declarations: [ 
    AnalysisComponent, 
    BarChartComponent, 
    GrapV1Component, 
    DollarsPerClientGraphComponent, 
    LiterPerClientGraphComponent, 
    LiterPerItemGraphComponent, 
    LiterPerMonthGraphComponent,
    LiterPerGroupGraphComponent,
    PieComponent,
    DrillDownComponent,
    DrillItemsGraphComponent,
    DollarsCliGraphCsvComponent,
    CsvComponent,
    AccountReceivablesGraphComponent,
    StackedBarComponent,
    MultiplePaneComponent,
    ProductAmountGraphComponent,
    ProductPriceGraphComponent,
    ProductDaysGraphComponent,
    StreetDaysClientGraphComponent
  ],
  imports: [
    AnalysisRoutingModule,
    CommonModule,
    MaterialModule,
    RouterModule,
    ReactiveFormsModule,
    NgxSpinnerModule,
    FormsModule,
    MatIconModule, 
    ChartsModule,
    TranslateModule,
    DxChartModule,
    DxSelectBoxModule,
    DxCheckBoxModule,
    DxTreeMapModule,
    DxButtonModule,
    DxPopoverModule,
    FlexLayoutModule
  ],
  providers: [
    AnalysisCsvService,
    FilterBySearchService,
    ProcessNotesService,
    DecimalPipe,
    FilterCustomerCsvPipe,
    FilterCustomerCsvPipeAmount,
    FilterArticleCsvPipe,
    FilterSellerCsvPipe,
    FilterCustomerArticleCsvPipe,
    FilterCustomerSellerCsvPipe,
    FilterArticleSellerCsvPipe,
    FilterCustArticleSellerCsvPipe,
    FilterSellerCsvAccountReceivablesPipe,
    FilterCustomerSellerCsvAccountPipe,
    DatePipe,
    DecimalPipe,
    FilterPresentationSales,
    FilterFullSales,
    FilterFullDateSales,
    FilterCDISSales,
    FilterSSales,
    FilterSISales,
    FilterSIDSales,
    FilterSCSales,
    FilterSDCSales,
    FilterSICSales,
    FilterSDSales,
    FilterCSales,
    FilterCDSales,
    FilterCISales,
    FilterISales,
    FilterDISales,
    FilterDSales,
    
    FilterFullQuots,
    FilterFullDateQuots,
    FilterCDISQuots,
    FilterSQuots,
    FilterSIQuots,
    FilterSIDQuots,
    FilterSCQuots,
    FilterSDCQuots,
    FilterSICQuots,
    FilterSDQuots,
    FilterCQuots,
    FilterCDQuots,
    FilterCIQuots,
    FilterIQuots,
    FilterDIQuots,
    FilterDQuots,
    FilterPTQuots,
    FilterINotes,
    FilterPTNotes
  ], 
  bootstrap: [AnalysisComponent]
})
export class AnalysisModule { }

import { Injectable, OnDestroy } from '@angular/core';
import { INote } from "src/app/@interfaces/details/note.interface";
import { IPurchasePayments } from "src/app/@interfaces/payments.interface";
import { IRefunds } from "src/app/@interfaces/refunds.interface";
import { AccountsPayableService } from '../accounts-payable/accounts-payable.service';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { PurchasesService } from '../purchases/purchases.service';
import { IPurchaseInvoice } from 'src/app/@interfaces/purchaseInvoice.interface';

@Injectable({
  providedIn: "root",
})
export class DetailsAccountsPayableService implements OnDestroy {
  unsubscribe$ = new Subject();
  constructor(
    private accountsPayableService: AccountsPayableService,
    private purchasesService: PurchasesService
  ) {}

  processRefunds(refunds: IRefunds[], purchases: number[]) {
    let finalRefund: INote[] = [];
    purchases.forEach((purchase) => {
      refunds.forEach((refund) => {
        if (refund.num_invo.toString() === purchase.toString()) {
          finalRefund.push({
            fact: purchase.toString(),
            code:
              "RF" +
              "-" +
              refund.id_refund?.toString(),
            total: refund.total,
          });
        }
      });
    });
    return finalRefund;
  }

  getAllPayments(year: number) {
    return new Promise<IPurchasePayments[]>((resolve, reject) => {
      this.accountsPayableService
      .getAllApPurchasePayments(year)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((result) => {
        resolve(result);
      })
    })
  }

  getAllRefunds(year: number) {
    return new Promise<IRefunds[]>((resolve, reject) => {
      this.accountsPayableService
      .getAllApRefunds(year)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((result) => {
        resolve(result);
      })
    })
  }

  getAllPurchases(year: number) {
    return new Promise<IPurchaseInvoice[]>((resolve, reject) => {
      this.accountsPayableService
      .getAllApPurchases(year)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((result) => {
        resolve(result);
      })
    })
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}

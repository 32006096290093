import { Component, Input, OnInit, OnDestroy } from "@angular/core";
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import { ICompany } from "src/app/@interfaces/company.interface";
import { IRol } from "src/app/@interfaces/rol.interface";
import { IUser } from "src/app/@interfaces/user.interface";
import { CompanyService } from "src/app/@pages/company/company.service";
import { UsersService } from "src/app/@pages/users/users.service";
import { ValidatorsService } from "src/app/@shared/validator/validators.service";
import { DialogComponent } from "../../../dialog.component";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { IDocumenType } from "src/app/@interfaces/authentication/documenType";
import { CookieAuthService } from "src/app/@shared/storage-variables/cookie-auth.service";
import { AlertsService } from '../../../../services/alerts.service';

@Component({
  selector: "app-modify-user",
  templateUrl: "./modify-user.component.html",
  styleUrls: ["./modify-user.component.css"],
})
export class ModifyUserComponent implements OnInit, OnDestroy {
  validator: boolean = false;
  @Input() id_user: number = 0;
  rolList: Array<IRol> = [];
  companyList: Array<ICompany> = [];
  userModify = {} as IUser;
  documenTypeList: Array<IDocumenType> = [];
  userForm: UntypedFormGroup = this.fb.group({
    name: ["",[Validators.required, Validators.pattern(this.validatorS.nombrePattern)],],
    username: ["", Validators.required],
    types_docs: ["", [Validators.required]],
    document: [
      "",
      [
        Validators.required,
        Validators.pattern(this.validatorS.documentPattern),
      ],
    ],
    email: [
      "",
      [Validators.required, Validators.pattern(this.validatorS.emailPattern)],
    ],
    rol: [5, Validators.required],
  });
  companyId = '';
  unsubscribe$ = new Subject();
  constructor(
    private fb: UntypedFormBuilder,
    private validatorS: ValidatorsService,
    private userS: UsersService,
    private companyS: CompanyService,
    private dialog: MatDialog,
    private typeDoc: UsersService,
    private cookieAuthService: CookieAuthService,
    private alertsService: AlertsService
  ) {}

  ngOnInit(): void {
    this.getAuthValues()
    this.userS
    .getUser(this.id_user)
    .pipe(takeUntil(this.unsubscribe$))
    .subscribe((result) => {
      this.userModify = result;
      this.setUser(this.userModify);
    });
    this.userS
    .getRoles()
    .pipe(takeUntil(this.unsubscribe$))
    .subscribe((result) => {
      this.rolList = result;
    });
    this.companyS
    .getAllCompanies()
    .pipe(takeUntil(this.unsubscribe$))
    .subscribe((result) => {
      this.companyList = result;
    });

    this.typeDoc
    .listDocumenTypes()
    .pipe(takeUntil(this.unsubscribe$))
    .subscribe((result) => {
      this.documenTypeList = result;
    });
  }

  getAuthValues() {
    const company = this.cookieAuthService.getCompanyObject;
    this.companyId = company!.Id_company!.toString();
  }

  update() {
    this.userForm.markAllAsTouched();
    if (this.userForm.invalid === true) {
      this.alertsService.showErrorAlert("Please, fill in all the required fields!");
      return;
    }
    this.userS
    .updateUser(
      this.id_user,
      this.userForm.value.document,
      this.userForm.value.name,
      this.userForm.value.email,
      this.userForm.value.username,
      parseInt(this.userForm.value.rol.Id_rol),
      1,
      parseInt(this.userForm.value.types_docs.id_docs),
    )
    .pipe(takeUntil(this.unsubscribe$))
    .subscribe((result) => {
      if (result) {
        this.alertsService.showSuccessfullAlert(
          this.userForm.value.name + " ",
          "Good",
          "updated successfully"
        ).then((result) => {
          this.alertsService.refresh("/table", "/user");
        });
      } else {
        this.alertsService.showErrorAlert("something was wrong");
      }
    });
  }

  setUser(usetSets: IUser) {
    this.userForm.patchValue({
      name: usetSets.name,
      username: usetSets.username,
      types_docs: usetSets.types_docs![0],
      document: usetSets.document,
      email: usetSets.email,
      rol: usetSets.roles[0],
    });
  }

  closeDialog() {
    this.dialog.closeAll();
  }

  textValid(text: string) {
    return this.userForm.get(text)?.invalid && this.userForm.get(text)?.touched;
  }

  dialogAddCompany(addCompanies: boolean, id_user: number) {
    let valuesForm = this.userForm.value;
    this.dialog.open(DialogComponent, {
      data: { addCompanies, id_user, valuesForm },
    });
  }

  compareRol(item1: any, item2: any) {
    if (item1 === null || item2 === null) {
      return false;
    }
    return item1.Id_rol === item2.Id_rol;
  }

  compareDocType(item1: any, item2: any) {
    if (item1 === null || item2 === null) {
      return false;
    }
    return item1.id_docs === item2.id_docs;
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}

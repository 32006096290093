import {
  Component,
  OnChanges,
  Input,
  SimpleChanges,
  OnInit
} from "@angular/core";
import { ChartDataSets } from "chart.js";
import { Label, MultiDataSet, SingleDataSet } from "ng2-charts";
import { IStorageCompany } from "src/app/@interfaces/company.interface";
import { IInitPerGroupData, ILiterPerGroupData } from "src/app/@interfaces/Analysis/liter-per-group.interface";
import { CalculateAnalysis } from '../../../../../@shared/components/services/calculate-analysis.service';
import { ITopCustomerNotes } from "src/app/@interfaces/Analysis/dollar-per-client.interface";
import { CookieAuthService } from "src/app/@shared/storage-variables/cookie-auth.service";

@Component({
  selector: "app-liter-per-group-graph",
  templateUrl: "./liter-per-group-graph.component.html",
  styleUrls: ["./liter-per-group-graph.component.css"],
})
export class LiterPerGroupGraphComponent implements OnChanges {
  @Input() typePres: string = "All";
  @Input() typeGraph: string = "";
  @Input() literPerGroupData = {} as ILiterPerGroupData;
  @Input() notesByGroup: ITopCustomerNotes[] = [];

  literPerGroupDataToUse = {} as ILiterPerGroupData;
  notesByGroupToUse: ITopCustomerNotes[] = [];

  dataTopSalesLabel: Label[] = [];
  dataTopSales: SingleDataSet = [];
  multidataSales: MultiDataSet = [];
  barChartDataSets3: ChartDataSets[] = [
    {
      label: "Values",
      backgroundColor: "#0C3871",
      hoverBackgroundColor: "#0F4D9D",
    },
  ];
  companyObject = {} as IStorageCompany;

  constructor(
    private calculateAnalysis: CalculateAnalysis,
    private cookieAuthService: CookieAuthService
  ) {
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.getAuthValues();
    for (let item in changes) {
      if (item === "literPerGroupData") {
        const values: ILiterPerGroupData = changes[item].currentValue;
        if (values) {
          this.literPerGroupDataToUse = values;
        }
      }
      if (item === "notesByGroup") {
        const note: Array<ITopCustomerNotes> = changes[item].currentValue;
        if (note.length > 0) {
          this.notesByGroupToUse = note;
        }
      }
      if (this.literPerGroupDataToUse.initialData || (this.notesByGroupToUse.length > 0)) {
        this.topCalculation(this.literPerGroupDataToUse, this.notesByGroupToUse);
      }
    }
  }

  getAuthValues() {
    this.companyObject = this.cookieAuthService.getCompanyObject!;
  }

  topCalculation(literPerGroupData: ILiterPerGroupData, notesByGroup: ITopCustomerNotes[]) {
    const reduceArticles = this.calculateAnalysis.reduceArrays(literPerGroupData.articlesArray);
    const arrayFiltered = this.calculation(reduceArticles, literPerGroupData.initialData, notesByGroup);
    const sortArray = this.calculateAnalysis.constructFinalArray(arrayFiltered, "liters", "item", 9999, true);
    this.dataTopSalesLabel = sortArray.chartLabels;
    this.dataTopSales = sortArray.chartData;
    this.multidataSales = sortArray.multiData;
  }

  calculation(reduceArticles: string[], initialData: IInitPerGroupData[], notesByGroup: ITopCustomerNotes[]) {
    return reduceArticles.map(element => {
      const filterByElement = initialData.filter(data => data.item === element);
      const calculatedByElement = this.calculationByElement(filterByElement, notesByGroup);
      return {
        liters: calculatedByElement.liters,
        item: calculatedByElement.group,
      };
    });
  }

  calculationByElement(filterByElement: IInitPerGroupData[], notesByGroup: ITopCustomerNotes[]) {
    const litersCalculated = filterByElement.reduce((totalLiters, item) => totalLiters + item.quantity * item.liter, 0);
    const item = filterByElement.length > 0 ? filterByElement[0].item : "";
    const objectByElement = {
      liters: litersCalculated,
      group: item,
    };
    return this.applyNoteValues(notesByGroup, objectByElement);
  }

  applyNoteValues(notesByGroup: ITopCustomerNotes[], objectByElement: any) {
    const matchingItem = notesByGroup.find(item => item.grupo === objectByElement.group);
    if (matchingItem) {
      objectByElement.liters += matchingItem.litros * 1;
      objectByElement.group = matchingItem.grupo;
    }
    return objectByElement;
  }
}

export enum VoucherTypes {
  invoices = "F",
  notes = "J",
  purchases = "P",
  refunds = "U",
  receivables = "R",
  bank = "G",
  cashier = "N",
  warehouseTransfer = "T",
  warehouseOutput = "H",
  inventoryReclassification = "L"
}

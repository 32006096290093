import { Component, OnInit } from '@angular/core';
import { MatDialog } from "@angular/material/dialog";
import { NgxSpinnerService } from "ngx-spinner";
import * as XLSX from "xlsx";
import { SetRowsService } from "../../services/set-rows.service";
import { ExportSiigoService } from "./export-siigo.service";
import {
  UntypedFormGroup,
  UntypedFormBuilder,
  Validators,
} from "@angular/forms";
import { CalculatePurchasesService } from "../../services/calculate-purchases.service";
import { TranslateService } from "@ngx-translate/core";
import { InventoryMovementsService } from "src/app/@shared/components/services/inventory-movement.service";
import { CalculateSiigoService } from "../../services/calculate-siigo.service";
import { IMovementInventory } from "src/app/@interfaces/movement-inventory.interface";
import { ReportsService } from "src/app/@pages/reports/reports.service";
import { CollectionsService } from "../../services/collections.service";
import { IAccountingAccounts } from "src/app/@interfaces/accountingAccounts.interface";
import { IPurchaseInvoice } from "src/app/@interfaces/purchaseInvoice.interface";
import { FunctionsSiigoService } from "../../services/functions-siigo.service";
import { IRefundsSingle } from "src/app/@interfaces/refunds.interface";
import { TransactionType } from 'src/app/@enums/siigo/siigo-invoices';
import { IQueries } from 'src/app/@interfaces/Siigo/quieries.interface';
import { IMultipleRows, ISiigoRow } from 'src/app/@interfaces/Siigo/rows.interface';
import { IQuota } from 'src/app/@interfaces/quotation.interface';
import { IDateFormat } from 'src/app/@interfaces/Siigo/format-date-siigo.interface';
import { IWarehouseTransfer } from '../../../../@interfaces/warehouse.interface';
import { WarehouseCsvService } from '../../services/warehouse-csv.service';
import { IWarehouseOutputAndEntry } from 'src/app/@interfaces/warehouse.interface';
import { IInventoryReclassification } from 'src/app/@interfaces/inventoryReclassification.interface';
import { ISiigoSales } from 'src/app/@interfaces/Siigo/siigo-sales.interface';
import { VoucherTypes } from '../../../../@enums/siigo/siigo-voucher';
import { ISiigoNote } from 'src/app/@interfaces/Siigo/siigo-notes.interface';
import { ITreasury } from 'src/app/@interfaces/treasury.interface';
import { ITransfers } from '../../../../@interfaces/transfers.interface';
import { IInvoicePayments, IPurchasePayments } from 'src/app/@interfaces/payments.interface';
import { CookieAuthService } from 'src/app/@shared/storage-variables/cookie-auth.service';
import { IStorageCompany } from 'src/app/@interfaces/company.interface';

@Component({
  selector: "app-export-siigo",
  templateUrl: "./export-siigo.component.html",
  styleUrls: ["./export-siigo.component.css"],
})
export class ExportSiigoComponent implements OnInit {
  lista: ISiigoRow[] = [];
  name = "MovimientosContables.xlsx";
  date = new Date();
  currentDay = this.date.getDate();
  currentMonth = this.date.getMonth() + 1;
  currentYear = this.date.getFullYear();
  year = this.date.getUTCFullYear();
  dates: UntypedFormGroup = this.fb.group({
    year: [this.date.getFullYear(), [Validators.required]],
  });
  companyObject = {} as IStorageCompany;
  companyName = '';
  dateMainFile = "";
  constructor(
    private dialog: MatDialog,
    private spinner: NgxSpinnerService,
    private setRowsService: SetRowsService,
    private exportSiigoService: ExportSiigoService,
    private calculatePurchasesService: CalculatePurchasesService,
    private fb: UntypedFormBuilder,
    private translate: TranslateService,
    private invetoryMovement: InventoryMovementsService,
    private calculateSiigoService: CalculateSiigoService,
    private reportsService: ReportsService,
    private collectionsService: CollectionsService,
    private functionsSiigoService: FunctionsSiigoService,
    private warehouseCsvService: WarehouseCsvService,
    private cookieAuthService: CookieAuthService
  ) {
    let dateInit = new Date();
    dateInit.setMonth(0);
    dateInit.setDate(1);
    this.setDateMain(dateInit, this.date);
  }

  ngOnInit(): void {
    this.getAuthValues();
  }

  getAuthValues() {
    this.companyObject = this.cookieAuthService.getCompanyObject!;
    this.companyName = this.companyObject.name;
  }

  setDateMain(dateInit: Date, dateEnd: Date) {
    const options: Intl.DateTimeFormatOptions = {
      month: "short",
      day: "numeric",
      year: "numeric",
    };
    this.dateMainFile =
    this.translate.instant("Of") +
    " : " +
    dateInit.toLocaleDateString("es-ES", options).toUpperCase() +
    " " +
    this.translate.instant("To") +
    " : " +
    dateEnd.toLocaleDateString("es-ES", options).toUpperCase();
  }

  update() {
    this.spinner.show();
    const year = this.dates.get("year")?.value;
    this.setDateWhenUpdate(parseInt(year));
    this.year = year;
    this.startGeneration(this.year);
  }

  setDateWhenUpdate(year: number) {
    let dateInit = new Date();
    dateInit.setMonth(0);
    dateInit.setDate(1);
    dateInit.setFullYear(year);
    let dateEnd = new Date();
    if (year !== this.date.getFullYear()) {
      dateEnd.setMonth(11);
      dateEnd.setDate(31);
      dateEnd.setFullYear(year);
    }
    this.setDateMain(dateInit, dateEnd);
  }

  async getAllQueries(yearSelected: number): Promise<IQueries> {
    const invoices = await this.calculateSiigoService.getAllInvoices(yearSelected);
    const invoicePayments = await this.calculateSiigoService.getAllPayments(yearSelected);
    const purchases = await this.calculateSiigoService.getAllPurchases(yearSelected);
    const purchasePayments = await this.calculateSiigoService.gatAllAccountsPayable(yearSelected);
    const refunds = await this.calculateSiigoService.getAllRefunds(yearSelected);
    const notes = await this.calculateSiigoService.getAllNotes(yearSelected);
    const transfers = await this.calculateSiigoService.getAllTransfers(yearSelected);
    const advancePayments = await this.calculateSiigoService.getAllAdvancePayments(yearSelected);
    const warehouseTransfers = await this.calculateSiigoService.getAllWarehouseTransfers(yearSelected);
    const warehouseOutputsAndEntries = await this.calculateSiigoService.getAllWarehouseOutputsAndEntries(yearSelected);
    const inventoryReclassifications = await this.calculateSiigoService.getAllInventoryReclassifications(yearSelected);
    const saleAccountingAccounts = await this.calculateSiigoService.getAccountingAccounts("Sale");
    const noteAccountingAccounts = await this.calculateSiigoService.getAccountingAccounts("Note");
    const firstCostAccountingAccounts = await this.calculateSiigoService.getAccountingAccounts("Inventory Cost 1");
    const secondCostAccountingAccounts = await this.calculateSiigoService.getAccountingAccounts("Inventory Cost 2");
    const purchaseAcountingAccounts = await this.calculateSiigoService.getAccountingAccounts("Purchase");
    const refundAccountingAccounts = await this.calculateSiigoService.getAccountingAccounts("Refund");
    const orderSaleAccounts = this.functionsSiigoService.getAccountsOrder(saleAccountingAccounts, firstCostAccountingAccounts, secondCostAccountingAccounts);
    return { 
      invoices: invoices, purchases: purchases, refunds: refunds,
      invoicePayments: invoicePayments, purchasePayments: purchasePayments,
      transfers: transfers, advancePayments: advancePayments,
      notes: notes, saleAccountingAccounts: saleAccountingAccounts,
      noteAccountingAccounts: noteAccountingAccounts,
      firstCostAccountingAccounts: firstCostAccountingAccounts,
      secondCostAccountingAccounts: secondCostAccountingAccounts,
      purchaseAcountingAccounts: purchaseAcountingAccounts,
      refundAccountingAccounts: refundAccountingAccounts,
      warehouseTransfers: warehouseTransfers,
      warehouseOutputsAndEntries: warehouseOutputsAndEntries,
      inventoryReclassifications: inventoryReclassifications,
      orderSaleAccounts: orderSaleAccounts
    }
  }

  async startGeneration(yearSelected: number) {
    this.lista = [];
    const warehouseID = await this.calculateSiigoService.getWarehouseId();
    const allQueries = await this.getAllQueries(yearSelected);
    const inventoryMovements = await this.invetoryMovement.inventoryMovement(
      allQueries.invoices, allQueries.purchases, 
      allQueries.refunds, allQueries.notes,
      allQueries.warehouseTransfers, allQueries.warehouseOutputsAndEntries,
      allQueries.inventoryReclassifications, warehouseID
    );
    const dateToday = 
      this.currentDay.toString() + '-' + 
      this.currentMonth.toString() + '-' + 
      this.currentYear.toString();
    allQueries.invoices.length <= 0 ? this.spinner.hide() : this.spinner.show();
    this.calculation(allQueries, inventoryMovements, dateToday);
  }

  calculation(
    allQueries: IQueries, 
    inventoryMovements: IMovementInventory[], 
    dateToday: string,
  ) {
    for (const invoice of allQueries.invoices) {
      const invoiceData = this.invoiceData(invoice, dateToday, allQueries);
      const siigoSales = this.exportSiigoService.setSales(
        this.createSalesObject(
          invoice, invoiceData, inventoryMovements, dateToday, allQueries
        )
      );
      this.setRowsOnList(siigoSales, allQueries, invoiceData.rows);
    }
    this.setNotes(
      this.createNotesObject(
        inventoryMovements, dateToday, allQueries
      )
    );
    this.createOtherDocuments(dateToday, allQueries, inventoryMovements);
  }

  createNotesObject(
    inventoryMovements: IMovementInventory[],
    dateToday: string, allQueries: IQueries
  ): ISiigoNote {
    return {
      inventoryMovements: inventoryMovements, 
      dateToDay: dateToday, 
      noteAccountingAccounts: allQueries.noteAccountingAccounts,
      firstCostAccountingAccounts: allQueries.firstCostAccountingAccounts, 
      secondCostAccountingAccounts: allQueries.secondCostAccountingAccounts,
      notes: allQueries.notes
    }
  }

  createSalesObject(
    invoice: IQuota, invoiceData: any, 
    inventoryMovements :IMovementInventory[],
    dateToday: string, allQueries: IQueries
  ): ISiigoSales {
    return {
      id_invoice: invoice.id_factura!,
      inventoryMovements: inventoryMovements,
      sales: invoice.venta,
      voucherNumber: invoice.billyInvoice![0].voucher,
      numberDocument: invoiceData.parsedData.documentNumber,
      invoiceYear: invoiceData.parsedData.parsedDate.year,
      invoiceMonth: invoiceData.parsedData.parsedDate.month,
      invoiceDay: invoiceData.parsedData.parsedDate.day,
      sellerId: parseInt(invoice.seller![0].id_seller.toString()) || 0,
      documentNit: invoiceData.parsedData.documentNit,
      dateToDay: dateToday,
      taxIncluded: invoice.tax_incl!,
      invoiceTax: invoice.tax,
      inventoryAccount: allQueries.saleAccountingAccounts.inventory,
      serviceAccount: allQueries.saleAccountingAccounts.service,
      firstCostAccountingAccounts: allQueries.firstCostAccountingAccounts,
      secondCostAccountingAccounts: allQueries.secondCostAccountingAccounts
    }
  }

  invoiceData(invoice: IQuota, dateToday: string, allQueries: IQueries) {
    const parsedData = this.parsedData(
      invoice.createdAt, invoice.invoiced_date!, invoice.dueDate!,
      invoice.billyInvoice![0].invoice_number, invoice.cliente[0].doc_nit,
      invoice.contfac![0].invoice!
    );
    const rows: IMultipleRows = this.createRows(
      invoice.billyInvoice![0].voucher, parsedData.documentNumber, 
      allQueries.saleAccountingAccounts, invoice.venta[0].total, 
      parsedData.parsedDate, parsedData.dueParsedDate,
      parseInt(invoice.seller![0].id_seller.toString()) || 0,
      parsedData.documentNit, dateToday, invoice.cliente[0].nombre
    )
    return { parsedData: parsedData, rows: rows }
  }

  parsedData(createdAt: string, invoiced_date: string, due_date: string, invoice_number: string, nit: string, invoiceID: string) {
    const parsedDate = this.functionsSiigoService.getDateSeparated(createdAt!, invoiced_date!);
    const dueParsedDate = this.functionsSiigoService.getDateSeparated(due_date!);
    const electronicNumber = invoice_number?.replace(/\D+/g, "");
    const invoiceNumber = invoiceID.replace(/\D+/g, "");
    const documentNumber = electronicNumber ? electronicNumber : invoiceNumber;
    const documentNit = nit.split("-")[0];
    return { parsedDate: parsedDate, documentNumber: documentNumber, documentNit: documentNit, dueParsedDate: dueParsedDate }
  }

  createRows(
    voucherNumber: number, documentNumber: string, 
    saleAccountingAccounts: IAccountingAccounts, total: number, 
    parsedDate: IDateFormat, dueParsedDate: IDateFormat,
    idSeller: number, documentNit: string,
    dateToday: string, name: string
  ): IMultipleRows {
    const mainRow = this.setRowsService.createInvoiceRow(
      VoucherTypes.invoices, voucherNumber, documentNumber, 
      "PENDING", total, parsedDate, dueParsedDate, idSeller, 
      documentNit,dateToday, name, "PENDING",
    )
    const row1: ISiigoRow = {
      ...mainRow, accountingAccount: saleAccountingAccounts.total, 
      debitCredit: TransactionType.Debit, paymentMethod: 1
    }
    const row2: ISiigoRow = {
      ...mainRow, accountingAccount: saleAccountingAccounts.tax, 
      debitCredit: TransactionType.Credit
    }
    const row3: ISiigoRow = {
      ...mainRow, accountingAccount: saleAccountingAccounts.withholding, 
      debitCredit: TransactionType.Debit, description: "AUTORRETENCION"
    }
    const row4: ISiigoRow = {
      ...mainRow, accountingAccount: saleAccountingAccounts.ica, 
      debitCredit: TransactionType.Credit, description: "AUTORRETENCION"
    }
    return { row1: row1, row2: row2, row3: row3, row4: row4 }
  }

  setRowsOnList(siigoSales: ISiigoRow[], allQueries: IQueries, rows: IMultipleRows) {
    let taxAmount = 0, withholdingAmount = 0, invoiceRows: ISiigoRow[] = [];
    siigoSales.forEach((element) => {
      invoiceRows.push(element);
      if (element.accountingAccount === allQueries.saleAccountingAccounts.inventory || element.accountingAccount === allQueries.saleAccountingAccounts.service) {
        taxAmount += parseFloat(element.taxValue.toFixed(2));
        withholdingAmount += parseFloat(element.total.toFixed(2));
      }
    });
    rows.row2.total = taxAmount;
    rows.row3.total = withholdingAmount * 0.0055;
    rows.row4.total = withholdingAmount * 0.0055;
    invoiceRows.push(rows.row1);
    invoiceRows.push(rows.row2);
    invoiceRows.push(rows.row3);
    invoiceRows.push(rows.row4);
    invoiceRows = this.functionsSiigoService.orderInvoiceRows(invoiceRows, allQueries.orderSaleAccounts);
    this.lista = this.lista.concat(invoiceRows);
  }

  createOtherDocuments(
    dateToday: string, allQueries: IQueries, 
    inventoryMovements: IMovementInventory[]
  ) {
    this.calculatePortfolio(
      allQueries.invoicePayments, dateToday, 
      allQueries.saleAccountingAccounts
    );
    this.calculateAccountsPayable(
      allQueries.purchasePayments, dateToday, 
      allQueries.purchaseAcountingAccounts
    );
    this.calculateTransfers(
      allQueries.transfers, dateToday
    );
    this.calculateAdvancePayments(
      allQueries.advancePayments, dateToday,
      allQueries.purchaseAcountingAccounts
    );
    this.calculateWarehouseTransfers(
      allQueries.warehouseTransfers, dateToday,
      inventoryMovements,
      allQueries.firstCostAccountingAccounts,
    );
    this.calculateWarehouseOutputs(
      dateToday, inventoryMovements,
      allQueries.firstCostAccountingAccounts,
      allQueries.secondCostAccountingAccounts,
      allQueries.warehouseOutputsAndEntries, 
    )
    this.calculateInventoryReclassification(
      allQueries.inventoryReclassifications, dateToday,
      inventoryMovements,
      allQueries.firstCostAccountingAccounts,
    )
    this.calculatePurchases(
      dateToday, allQueries.purchases, 
      allQueries.refunds, allQueries.purchaseAcountingAccounts, 
      allQueries.refundAccountingAccounts
    );
  }

  setNotes(notesData: ISiigoNote) {
    const debits = this.exportSiigoService.setNote(
      notesData,
      "Debito",
    );
    const credits = this.exportSiigoService.setNote(
      notesData,
      "Credito",
    );
    this.lista = this.lista.concat(debits);
    this.lista = this.lista.concat(credits);
  }

  calculatePurchases(
    dateToday: string, purchases: IPurchaseInvoice[], 
    refunds: IRefundsSingle[],accountingAccounts: IAccountingAccounts, 
    refundAccountingAccounts: IAccountingAccounts
  ) {
    const siigoPurchases = this.calculatePurchasesService.calculate(
      dateToday, purchases, refunds, 
      accountingAccounts, refundAccountingAccounts
    )
    this.lista = this.lista.concat(siigoPurchases);
    this.exportExcel();
  }

  calculatePortfolio(
    invoicePayments: IInvoicePayments[],
    dateToDay: string, 
    accountingAccounts: IAccountingAccounts
  ) {
    const collectionsArray = this.collectionsService.setCollections(
      invoicePayments, dateToDay, accountingAccounts
    );
    this.lista = this.lista.concat(collectionsArray);
  }

  calculateAccountsPayable(
    purchasePayments: IPurchasePayments[],
    dateToDay: string, 
    purchaseAccountingAccounts: IAccountingAccounts
  ) {
    const accountsPayableArray =  this.collectionsService.setAccountsPayable(
      purchasePayments, dateToDay, purchaseAccountingAccounts
    );
    this.lista = this.lista.concat(accountsPayableArray);
  }

  calculateTransfers(transfers: ITransfers[], dateToDay: string) {
    const transfersArray = this.collectionsService.setTransfers(
      transfers, dateToDay
    );
    this.lista = this.lista.concat(transfersArray);
  }

  calculateAdvancePayments(
    advancePayments: ITreasury[],
    dateToDay: string, 
    purchaseAccountingAccounts: IAccountingAccounts
  ) {
    const advancePaymentsArray = this.collectionsService.setAdvancePayments(
      advancePayments, dateToDay, purchaseAccountingAccounts
    );
    this.lista = this.lista.concat(advancePaymentsArray);
  }

  calculateWarehouseTransfers(
    transfers: IWarehouseTransfer[], dateToDay: string,
    inventoryMovements: IMovementInventory[], 
    firstCostAccountingAccounts: IAccountingAccounts
  ) {
    const warehouseTransfers = this.warehouseCsvService.setWarehouseTransfers(
      transfers, dateToDay, inventoryMovements, firstCostAccountingAccounts
    );
    this.lista = this.lista.concat(warehouseTransfers);
  }

  calculateWarehouseOutputs(
    dateToDay: string, 
    inventoryMovements: IMovementInventory[],
    firstCostAccountingAccounts: IAccountingAccounts, 
    secondCostAccountingAccounts: IAccountingAccounts,
    outputsAndEntries: IWarehouseOutputAndEntry[]) {
    const warehouseOutputs = this.warehouseCsvService.setInventoryOutputsAndEntries(
      outputsAndEntries, dateToDay, 
      inventoryMovements, 
      firstCostAccountingAccounts, 
      secondCostAccountingAccounts
    );
    this.lista = this.lista.concat(warehouseOutputs);
  }

  calculateInventoryReclassification(
    reclassifications: IInventoryReclassification[], dateToDay: string,
    inventoryMovements: IMovementInventory[],
     firstCostAccountingAccounts: IAccountingAccounts
  ) {
    const warehouseTransfers = this.warehouseCsvService.setInventoryReclassifications(
      reclassifications, dateToDay, 
      inventoryMovements,
      firstCostAccountingAccounts
    );
    this.lista = this.lista.concat(warehouseTransfers);
  }

  closeDialog() {
    this.dialog.closeAll();
  }

  exportExcel() {
    this.spinner.show();
    setTimeout(() => {
      const element = document.getElementById("tabSiigo");
      let mainSheet: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element);
      const book: XLSX.WorkBook = XLSX.utils.book_new();
      mainSheet = this.setFormatCellsForDate(mainSheet);
      this.reportsService.export(book, mainSheet, this.name);
    }, 5000);
  }

  setFormatCellsForDate(mainSheet: XLSX.WorkSheet) {
    this.lista.forEach((item, index) => {
      const date = item.dateToDay;
      mainSheet["AL" + (index + 6).toString()].z = "";
      mainSheet["AL" + (index + 6).toString()].v = date;
      mainSheet["AL" + (index + 6).toString()].t = "s";
    })
    return mainSheet;
  }
}

import { Injectable, OnDestroy } from "@angular/core";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { IArticles } from "src/app/@interfaces/article.interface";
import { InventoryService } from "src/app/@pages/inventory/inventory.service";
import { AlertsService } from './alerts.service';
import { TranslateService } from "@ngx-translate/core";
import Swal from "sweetalert2";
import { WarehouseService } from '../../../@pages/warehouse/warehouse.service';

@Injectable({
  providedIn: "root",
})
export class ArticleCostService implements OnDestroy {
  unsubscribe$ = new Subject();
  constructor(
    private inventoryService: InventoryService,
    private alertsService: AlertsService,
    private translate: TranslateService,
    private warehouseService: WarehouseService,
  ) {}

  updateAlertText(message: string) {
    Swal.getHtmlContainer()!.textContent = message;
  }

  onUpdateCompleted() {
    Swal.update({ showConfirmButton: true, title: this.translate.instant("Update completed!") })
    this.updateAlertText(this.translate.instant("Update completed!"));
    this.alertsService.refresh("/table", "/inventory");
  }

  async updateArticleCost(articleCode: string, quantity: number, value: number) {
    return new Promise<boolean>((resolve, reject) => {
      this.inventoryService
      .updateAmountAndValueAccumulated(
        articleCode, parseFloat(quantity.toString()), parseFloat(value.toString())
      ).pipe(takeUntil(this.unsubscribe$))
      .subscribe((result: IArticles) => {
        this.updateAlertText(
          this.translate.instant("Article code") + ' ' +
          articleCode + ' ' +
          this.translate.instant("updated.")
        )
        resolve(true);
      },(error) => {
        reject(error)
      });
    })
  }

  updateQuantityFromArray(
    id_article: number[],
    id_warehouse: number[],
    quantity: number[],
    operation_type: number
  ) {
    return new Promise<boolean>((resolve, reject) => {
      for (let index = 0; index < id_article.length; index++) {
        this.updateAvaliableQuantity(
          id_article[index],
          id_warehouse[index],
          quantity[index],
          operation_type
        )
      }
      resolve(true);
    })
  }

  async updateAvaliableQuantity(
    id_article: number,
    id_warehouse: number,
    quantity: number,
    operation_type: number
  ){
    return new Promise<boolean>((resolve, reject) => {
      const quantityToUpdate = operation_type === 4 ? quantity : Math.abs(quantity);
      this.warehouseService
      .updateAvaliableQuantity(
        id_article, 
        id_warehouse, 
        quantityToUpdate,
        operation_type
      )
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((result) => {
        resolve(true)
      },(error) => {
        reject(error)
      })
    })
  }

  async updateQuantityFromCodeArray(
    article_code: string[],
    id_warehouse: number[],
    quantity: number[],
    operation_type: number
  ) {
    return new Promise<boolean>(async (resolve, reject) => {
      for (const [index, item] of article_code.entries()) {
        const articleID = await this.getArticleID(item);
        await this.updateAvaliableQuantity(
          articleID,
          id_warehouse[index],
          quantity[index],
          operation_type
        ).then((result) => {
          resolve(result);
        }).catch((error) => {
          reject(error);
        })
      }
    })
  }

  getArticleID(code: string) {
    return new Promise<number>((resolve, reject) => {
      this.inventoryService
      .getAricleIdByCode(code)
      .subscribe((result: number) => {
        resolve(result)
      }, (error) => {
        reject(error)
      })
    })
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}

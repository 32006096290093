import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Concept } from 'src/app/@interfaces/notas.interface';
import { IPurchaseInvoice } from 'src/app/@interfaces/purchaseInvoice.interface';
import { IPurchaseQuery } from 'src/app/@interfaces/purchases.interface';
import { Subject } from 'rxjs';
import { IProvider } from 'src/app/@interfaces/provider.interface';
import { DialogComponent } from '../dialog.component';
import { NgxSpinnerService } from 'ngx-spinner';
import { MatDialog } from '@angular/material/dialog';
import { RefundsService } from './refunds.service';
import { FunctionsSiigoService } from '../../services/functions-siigo.service';
import { CookieAuthService } from 'src/app/@shared/storage-variables/cookie-auth.service';
import { IStorageCompany } from 'src/app/@interfaces/company.interface';

@Component({
  selector: 'app-refunds',
  templateUrl: './refunds.component.html',
  styleUrls: ['./refunds.component.css']
})
export class RefundsComponent implements OnInit {
  @Input() purchaseInvoice = {} as IPurchaseInvoice;
  dateToday = new Date();
  provider: UntypedFormControl = this.fb.control("", Validators.required);
  article: UntypedFormControl = this.fb.control("", Validators.required);
  refundForm: UntypedFormGroup = this.fb.group({
    itemRows: this.fb.array(
      [
        this.fb.group({
          ids: [],
          warehouse: [],
          article: this.article,
          quantity: [, Validators.required],
          quantity2: [, Validators.required],
          discount: [, Validators.required],
          price: [, Validators.required],
          price2: [, Validators.required],
          subtotal: [0],
          subtotal2: [0],
          subtotal3: [0],
          subtotalNegative: [0],
          quantityNegative: [0],
          priceNegative: [0],
        }),
      ],
      Validators.required
    ),
    providerCity: [""],
    customerCity: [""],
    tax_incl: false,
    tax: [, Validators.required],
    date: [this.dateToday, Validators.required],
    dueDate: [this.dateToday, Validators.required],
    concept: ["", Validators.required],
  });
  totalCalculated: number = 0;
  totalPurchase: number = 0;
  totalPurchase2: number = 0;

  totalWithholding: number = 0;
  totalWithholding2: number = 0;

  totalIcaTax: number = 0;
  totalIcaTax2: number = 0;

  totalTax: number = 0;
  totalWithoutTax: number = 0;

  get rowsArr() {
    return this.refundForm.get("itemRows") as UntypedFormArray;
  }
  concepts: Concept[] = [
    { value: "Discount", viewValue: "Discount (Partial)" }, //Parcial
    { value: "Emission by error", viewValue: "Emission by error (Total)" }, //Total
    {
      value: "Correction of values",
      viewValue: "Correction of values (Partial)",
    }, //Parcial
    {
      value: "Correction of quantities",
      viewValue: "Correction of quantities (Partial)",
    }, //Parcial
    {
      value: "The invoice will not be paid",
      viewValue: "The invoice will not be paid (Total)",
    }, //Total
    { value: "Customer return", viewValue: "Customer return (Total)" }, // Total
    {
      value: "Difference from actual price to price charged",
      viewValue: "Difference from actual price to price charged (Partial)",
    }, //Parcial
  ];
  purchases: Array<IPurchaseQuery> = [];
  user = '';
  rol = '';
  companyObject = {} as IStorageCompany;
  unsubscribe$ = new Subject();

  constructor(
    private fb: UntypedFormBuilder,
    private spinnerService: NgxSpinnerService,
    private dialog: MatDialog,
    private refundsService: RefundsService,
    private functionsSiigoService: FunctionsSiigoService,
    private cookieAuthService: CookieAuthService
  ) {
  }

  async ngOnInit() {
    this.spinner();
    this.getAuthValues();
    this.setPurchase(this.purchaseInvoice);
  }

  getAuthValues() {
    this.companyObject = this.cookieAuthService.getCompanyObject!;
    this.user = this.cookieAuthService.getUserId!;
    this.rol = this.cookieAuthService.getRolId!;
  }

  displayFnArticle(article: any) {
    return article && article ? article.descripcion : undefined;
  }

  displayFnProvider(provider: any) {
    return provider && provider ? provider.nombre : undefined;
  }

  setPurchase(purchaseInvoice: IPurchaseInvoice) {
    const { provider, withholdingTax, icaTax } = purchaseInvoice;
    this.purchases = purchaseInvoice.purchases;
    const [parameter] = this.purchases;
    const { total } = parameter;
    this.totalWithholding = withholdingTax!;
    this.totalIcaTax = icaTax!;
    this.totalPurchase = total;
    this.editPurchase(purchaseInvoice, this.purchases, provider);
  }

  editPurchase(purchaseInvoice: IPurchaseInvoice, purchases: IPurchaseQuery[], provider: IProvider[]) {
    const taxBoolean: boolean = purchaseInvoice.tax_incl === "true" ? true : false;
    this.refundForm.patchValue({
      tax: purchaseInvoice.tax,
      tax_incl: taxBoolean,
      providerCity: purchaseInvoice.providerCity,
      customerCity: purchaseInvoice.customerCity
    });
    this.provider.setValue(provider[0]);
    this.refundForm.setControl("itemRows", this.setPurchases(purchases));
  }

  setPurchases(purchases: IPurchaseQuery[]): UntypedFormArray {
    const formArray = new UntypedFormArray([]);
    for (let index = 0; index < purchases.length; index++) {
      formArray.push(
        this.fb.group({
          ids: purchases[index].id_purchases,
          warehouse: purchases[index].warehouse,
          article: purchases[index].articulo[0],
          quantity: purchases[index].quantity,
          quantity2: purchases[index].quantity,
          discount: purchases[index].discount,
          price: purchases[index].price,
          price2: purchases[index].price,
          subtotal: purchases[index].subtotal,
          subtotal2: purchases[index].subtotal,
          subtotal3: 0,
          subtotalNegative: 0,
          quantityNegative: 0,
          priceNegative: 0,
        })
      );
    }
    return formArray;
  }

  changeValues() {
    const concept = this.refundForm.value.concept; 
    if (concept === "Emission by error" || concept === "The invoice will not be paid" || concept === "Customer return") {
      for (let i = 0; i < this.rowsArr.length; i++) {
        this.rowsArr.at(i).get("price")?.setValue(0);
        this.rowsArr.at(i).get("quantity")?.setValue(0);
      }
      this.calculation();
    } else {
      this.calculation();
    }
  }

  async disableInputs(index: number, control: string, disableControl: string, originalControl: string) {
    const originalValue = this.rowsArr.controls[index].get(originalControl)?.value;
    const currentValue = await this.getInputValue(index, control);
    if (parseFloat(currentValue) !== originalValue) {
      this.rowsArr.controls[index].get(disableControl)?.disable();
    } else {
      this.rowsArr.controls[index].get(disableControl)?.enable();
    }
  }

  getInputValue(index: number, control: string) {
    return new Promise<string>((resolve, reject) => {
      this.rowsArr.controls[index].get(control)?.valueChanges.subscribe((value) => {
        resolve(value)
      })
    })
  }

  create() {
    this.refundsService.createRefund(
      parseInt(this.purchaseInvoice.id_invoice!.toString()),
      this.refundForm,
      this.rowsArr,
      this.totalPurchase2,
      this.totalWithholding2,
      this.totalIcaTax2
    )
  }

  calculation() {
    //Variables registerForm
    let price;
    let quantity;
    let discount;
    let tax;
    let subtotal;

    //Variables valuesForm
    let previousPrice;
    let previousQuantity;
    let previousSubtotal;
    let calcSubTax;
    let totalTax = 0;
    const contributorID = parseInt(this.provider.value.contributorType.id_contributor);

    for (let i = 0; i < this.rowsArr.length; i++) {
      if (this.refundForm.get("tax_incl")?.value === false) {
        if (this.rowsArr.value[i].article.tax[0].value === 0) {
          tax = this.functionsSiigoService.getContributorTax(contributorID, this.refundForm.value.tax);
        } else {
          tax = this.functionsSiigoService.getContributorTax(contributorID, this.rowsArr.value[i].article.tax[0].value);
        }
      } else {
        tax = 0;
      }
      //changing values
      price = this.rowsArr.at(i).get('price')?.getRawValue();
      quantity = this.rowsArr.at(i).get('quantity')?.getRawValue();
      discount = this.rowsArr.value[i].discount;

      //original values
      previousPrice = this.rowsArr.value[i].price2;
      previousQuantity = this.rowsArr.value[i].quantity2;
      previousSubtotal = this.rowsArr.value[i].subtotal2;

      if (tax >= 0) {
        if (discount != 0) {
          subtotal = quantity * price;
          discount = (discount / 100) * subtotal;
          subtotal = subtotal - discount;
          if (this.rowsArr.value[i].article.tax[0].value === 0) {
            tax = (tax / 100) * subtotal;
          } else {
            tax = tax * subtotal;
          }
          //positive price calculation
          this.rowsArr.value[i].priceNegative = price - previousPrice;
          //positive quantity calculation
          this.rowsArr.value[i].quantityNegative = quantity - previousQuantity;
          //subtotal calculation
          this.rowsArr.value[i].subtotal = subtotal + tax;
          //positive subtotal calculation
          calcSubTax = subtotal + tax;
          totalTax = totalTax + tax;
          this.rowsArr.value[i].subtotalNegative = calcSubTax - previousSubtotal;

          if (this.rowsArr.value[i].quantityNegative === 0) {
            this.rowsArr.value[i].quantityNegative = previousQuantity;
          }
          if (this.rowsArr.value[i].priceNegative === 0) {
            this.rowsArr.value[i].priceNegative = previousPrice;
          }

          //subtotal without tax
          this.rowsArr.value[i].subtotal3 = this.rowsArr.value[i].quantityNegative * this.rowsArr.value[i].priceNegative;
        } else {
          subtotal = quantity * price;
          if (this.rowsArr.value[i].article.tax[0].value === 0) {
            tax = (tax / 100) * subtotal;
          } else {
            tax = tax * subtotal;
          }
          //positive price calculation
          this.rowsArr.value[i].priceNegative = price - previousPrice;
          //positive quantity calculation
          this.rowsArr.value[i].quantityNegative = quantity - previousQuantity;
          //subtotal calculation
          this.rowsArr.value[i].subtotal = subtotal + tax;
          //positive subtotal calculation
          calcSubTax = subtotal + tax;
          totalTax = totalTax + tax;
          this.rowsArr.value[i].subtotalNegative = calcSubTax - previousSubtotal;

          if (this.rowsArr.value[i].quantityNegative === 0) {
            this.rowsArr.value[i].quantityNegative = previousQuantity;
          }
          if (this.rowsArr.value[i].priceNegative === 0) {
            this.rowsArr.value[i].priceNegative = previousPrice;
          }

          //subtotal without tax
          this.rowsArr.value[i].subtotal3 = this.rowsArr.value[i].quantityNegative * this.rowsArr.value[i].priceNegative;
        }
      }
    }
    this.totalPurchase2 = 0;
    this.totalCalculated = 0;

    for (let i = 0; i < this.rowsArr.length; i++) {
      //total without tax
      this.totalCalculated = this.totalCalculated + this.rowsArr.value[i].subtotal3;
      //negative invoice total
      this.totalPurchase2 = this.totalPurchase2 + this.rowsArr.value[i].subtotalNegative;
    }
    this.totalTax = totalTax;
    this.totalWithoutTax = Math.abs(this.totalCalculated);
  }

  closeDialog() {
    this.dialog.closeAll();
  }

  spinner(): void {
    this.spinnerService.show();
    setTimeout(() => {
      this.spinnerService.hide();
    }, 3000);
  }

  openDialogRefundList(purchaseInvoice: IPurchaseInvoice) {
    this.dialog.open(DialogComponent, {
      data: { refundsToFind: purchaseInvoice },
    });
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}

import { Injectable, OnDestroy } from "@angular/core";
import { Subject } from "rxjs";
import { CustomerService } from "../customer/customer.service";
import { SellersService } from "../sellers/sellers.service";
import { takeUntil } from "rxjs/operators";
import { QuotationsService } from "../quotations/quotations.service";
import { IQuota } from "src/app/@interfaces/quotation.interface";
import { ISeller } from "src/app/@interfaces/seller.interface";
import { ICustomer } from "src/app/@interfaces/customer.interface";
import { PortfolioService } from "../portfolio/portfolio.service";
import { CancelImportsService } from "src/app/@shared/components/services/cancel-imports.service";
import { AlertsService } from '../../@shared/components/services/alerts.service';
import { ArticleCostService } from '../../@shared/components/services/article-cost.service';
import { CookieAuthService } from "src/app/@shared/storage-variables/cookie-auth.service";
import { IImportInvoices } from "src/app/@interfaces/Import-files/import-invoices.interface";
import { TranslateService } from "@ngx-translate/core";
import { E } from "@angular/cdk/keycodes";

@Injectable({
  providedIn: "root",
})
export class ImportFIlesService implements OnDestroy {
  unsubscribe$ = new Subject();

  constructor(
    private customerService: CustomerService,
    private sellerService: SellersService,
    private quotationService: QuotationsService,
    private portfolio: PortfolioService,
    private cancelImportsService: CancelImportsService,
    private alertsService: AlertsService,
    private articleCostService: ArticleCostService,
    private cookieAuthService: CookieAuthService,
    private translate: TranslateService
  ) {}

  getIdCustomer(value: string) {
    return new Promise<number>((resolve, reject) => {
      this.customerService
      .getCustomerByNit(value.toString())
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((customer: ICustomer) => {
        if (customer) {
          resolve(parseInt(customer.id_cliente.toString()));
        }
      },async (error) => {
        const customerByName = await this.searchCustomerByName(value);
        if(customerByName.length <= 0) {
          this.alertsService.showErrorAlert(`This customer id does not exist`, value)
        }else {
          resolve(parseInt(customerByName[0].id_cliente.toString()))
        }
      });
    });
  }

  searchCustomerByName(value: string) {
    return new Promise<ICustomer[]>((resolve, reject) => {
      this.customerService
      .searchCustomer(value, null, null)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((result) => {
        if (result.customers) {
          resolve(result.customers)
        }else {
          reject(new Error("This customer id does not exist" + value));
        }
      },(error) => {
        this.alertsService.showErrorAlert(error).then((confirm) => {
          if (confirm.isConfirmed) {
            reject(error)
          }
        })
      })
    })
  }

  getIdSeller(nit: string) {
    return new Promise<number>((resolve, reject) => {
      this.sellerService
      .getSellerByNit(nit.toString())
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((seller: ISeller) => {
        if (seller) {
          resolve(parseInt(seller.id_seller.toString()));
        }
      },(error)=> {
        this.alertsService.showErrorAlert(`This seller id does not exist`, nit)
        .then((confirm) => {
          if (confirm.isConfirmed) {
            reject(new Error("This seller id does not exist" + nit));
          }
        });
      });
    });
  }

  createInvoice(invoiceObject: IImportInvoices) {
    const user = this.cookieAuthService.getUserId!;
    const companyObject = this.cookieAuthService.getCompanyObject;
    return new Promise<string>(async (resolve, reject) => {
      if (invoiceObject.articleCode.length > 0 && invoiceObject.type) {
        const invoiceStatus = this.validInvoiceType(invoiceObject);
        const initNumber = this.cancelImportsService.companyInvoiceName();
        const invoiceExitst: boolean = await this.validBillyInvoice(initNumber + invoiceObject.invoiceID.toString(), invoiceObject.year);
        if (!invoiceExitst) {
          this.quotationService
          .createQuotation(
            invoiceObject.taxPercent,
            invoiceObject.idCustomer,
            invoiceObject.articleCode,
            invoiceObject.quantity,
            invoiceObject.price,
            invoiceObject.discount,
            invoiceObject.subtotal,
            invoiceObject.total,
            invoiceObject.warehouseID,
            invoiceObject.createdDate,
            invoiceObject.dueDate,
            invoiceObject.idSeller,
            invoiceObject.taxIncluded,
            parseInt(user),
            invoiceObject.observations
          )
          .pipe(takeUntil(this.unsubscribe$))
          .subscribe((invoice: IQuota) => {
            if (!invoiceStatus.quotation) {
              this.quotationService.updateStatus(
                invoice.id_factura!, false, invoiceStatus.delivery, invoiceStatus.deliveryDate, invoiceStatus.invoice, invoiceStatus.invoicedDate, true, parseInt(user))
                .pipe(takeUntil(this.unsubscribe$)).subscribe((result) => {});
            }
            if (companyObject!.countries[0].name === "Colombia" && invoiceStatus.invoice) {
              this.quotationService.setBillyInvoice(invoice.id_factura!.toString(), initNumber + invoiceObject.invoiceID.toString(), initNumber + invoiceObject.invoiceID.toString(), invoiceObject.createdDate).pipe(takeUntil(this.unsubscribe$)).subscribe((result) => {});
            }
            this.articleCostService.updateQuantityFromCodeArray(invoiceObject.articleCode, invoiceObject.warehouseID, invoiceObject.quantity, 2);
            resolve("Item id:" + " " + "[ " + invoiceObject.invoiceID.toString() + " ]" + " >>>>>>>>>>>> " + "OK");
          },(error) => {
            this.alertsService.showErrorAlert('Error uploading invoice', invoiceObject.invoiceID.toString())
            .then((confirm) => {
              if (confirm.isConfirmed) {
                reject(error);
              }
            });
          });
        } else {
          resolve("Item id:" + " " + "[ " + invoiceObject.invoiceID.toString() + " ]" + " >>>>>>>>>>>> " + "Already Exitst");
        }
      }else {
        this.alertsService.showErrorAlert(`Error constructing the invoice`, ":" + invoiceObject.invoiceID)
        .then((confirm) => {
          if (confirm.isConfirmed) {
            reject("Error constructing the invoice");
          }
        });
      }
    });
  }

  validInvoiceType(invoiceObject: IImportInvoices) {
    return {
      quotation: this.translate.instant(invoiceObject.type) === this.translate.instant("Orders/Quotations") ? true : false,
      delivery: this.translate.instant(invoiceObject.type) === this.translate.instant("Deliveries") ? true : false,
      deliveryDate: this.translate.instant(invoiceObject.type) === this.translate.instant("Deliveries") ? invoiceObject.createdDate : '',
      invoice: this.translate.instant(invoiceObject.type) === this.translate.instant("Invoices") ? true : false,
      invoicedDate: this.translate.instant(invoiceObject.type) === this.translate.instant("Invoices") ? invoiceObject.createdDate : '',
    }
  }

  validBillyInvoice(invoice_number: string, year: number) {
    const companyObject = this.cookieAuthService.getCompanyObject;
    return new Promise<boolean>((resolve, reject) => {
      if (companyObject!.countries[0].name === "Colombia") {
        this.quotationService
        .validBillyInvoice(invoice_number, year)
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe((result: boolean) => {
          resolve(result);
        });
      } else {
        resolve(false);
      }
    });
  }

  createPayment(itemID: number, invoiceID: string, paymentTypeID: number, paymentMethod: number, paymentType: string, createdDate: string, year: number, valuePaymet: number, reference: string) {
    return new Promise<string>(async (resolve, reject) => {
      const idInvoice = await this.cancelImportsService.getInvoiceIDFromCollections(invoiceID, year);
      const cancelledInvoice = await this.validCancelledInvoice(idInvoice);
      if (cancelledInvoice) {
        resolve("Invoice id:" + " " + "[ " + idInvoice.toString() + " ]" + " >>>>>>>>>>>> " + "Already Cancelled");
      } else {
        const havePaymentType = await this.assingCashierBank(idInvoice, paymentTypeID, paymentMethod);
        if (havePaymentType) {
          this.portfolio
          .createPaymentInvoice(
            idInvoice,
            paymentTypeID,
            paymentType,
            createdDate,
            valuePaymet,
            reference,
            paymentType
          )
          .pipe(takeUntil(this.unsubscribe$))
          .subscribe(async (result) => {
            const cancelled = await this.cancelInvoices(result.id_payinvoice, idInvoice, itemID, paymentType);
            if(cancelled) {
              resolve("Item id:" + " " + "[ " + itemID.toString() + " ]" + " >>>>>>>>>>>> " + "OK");
            }else {
              reject(cancelled);
            }
          },(error) => {
            this.alertsService.showErrorAlert(`Error uploading payment`, ":" + itemID)
            .then((confirm) => {
              if (confirm.isConfirmed) {
                reject(error);
              }
            });
          });
        } else {
          this.alertsService.showErrorAlert(`Error uploading payment`, ":" + itemID)
          .then((confirm) => {
            if (confirm.isConfirmed) {
              reject("Error uploading payment");
            }
          });
        }
      }
    });
  }

  cancelInvoices(id_payinvoice: number, idInvoice: number, itemID: number, paymentType: string) {
    return new Promise<string | boolean>(async (resolve, reject) => {
      await this.cancelImportsService.cancelPayment(id_payinvoice);
      if (paymentType === "Cancellation") {
        const cancelled = await this.cancelImportsService.cancelInvoice(idInvoice);
        if(cancelled === false) {
          this.alertsService.showErrorAlert(`Error trying to update the invoice`, ":" + itemID)
          .then((confirm) => {
            if (confirm.isConfirmed) {
              reject("Error trying to update the invoice")
            }
          });
        }else {
          resolve(true);
        }
      }else {
        resolve(true);
      }
    })
  }

  async assingCashierBank(idInvoice: number, paymentTypeID: number, paymentMethod: number) {
    return new Promise<boolean>(async (resolve, reject) => {
      const paymentInvoiceExits = await this.validInvoicePayment(idInvoice, paymentTypeID);
      if (!paymentInvoiceExits) {
        this.portfolio.assingInvoicePays(idInvoice, paymentTypeID, paymentMethod).pipe(takeUntil(this.unsubscribe$)).subscribe((result) => {
          resolve(true);
        },(error) => {
          reject(false);
        });
      } else {
        resolve(true);
      }
    })
  }

  validCancelledInvoice(id_invoice: number) {
    return new Promise<boolean>((resolve, reject) => {
      this.quotationService
      .validCancelledInvoice(id_invoice)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((result: boolean) => {
        resolve(result);
      });
    });
  }

  validInvoicePayment(id_invoice: number, id_payment: number) {
    return new Promise<boolean>((resolve, reject) => {
      this.quotationService
      .validInvoicePaymentExits(id_invoice, id_payment)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((result: boolean) => {
        resolve(result);
      });
    });
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}

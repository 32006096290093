import { Component, OnInit, OnDestroy } from "@angular/core";
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import { ICompany } from "src/app/@interfaces/company.interface";
import { IRol } from "src/app/@interfaces/rol.interface";
import { CompanyService } from "src/app/@pages/company/company.service";
import { UsersService } from "src/app/@pages/users/users.service";
import { ValidatorsService } from "src/app/@shared/validator/validators.service";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { IDocumenType } from "src/app/@interfaces/authentication/documenType";
import { SellersService } from "src/app/@pages/sellers/sellers.service";
import { CookieAuthService } from "src/app/@shared/storage-variables/cookie-auth.service";
import { IPrefix } from "src/app/@interfaces/prefix.interface";
import { AlertsService } from '../../../../services/alerts.service';

@Component({
  selector: "app-register-user",
  templateUrl: "./register-user.component.html",
  styleUrls: ["./register-user.component.css"],
})
export class RegisterUserComponent implements OnInit, OnDestroy {
  rolList: Array<IRol> = [];
  companyList: Array<ICompany> = [];
  registerForm: UntypedFormGroup = this.fb.group(
    {
      name: ["", [Validators.required, Validators.pattern(this.validatorS.nombrePattern),],],
      username: ["", Validators.required],
      types_docs: ["", Validators.required],
      document: ["", [Validators.required, Validators.pattern(this.validatorS.documentPattern),],],
      email: ["", [Validators.required, Validators.pattern(this.validatorS.emailPattern)],],
      password: ["", Validators.required, Validators.pattern(this.validatorS.passwordPattern),],
      passwordConfirmation: ["", Validators.required],
      rol: ["", Validators.required],
      company: ["", Validators.required],
      prefix: [""]
    },
    {
      validators: [this.validatorS.equalFields("password", "passwordConfirmation")],
    }
  );
  companyId = '';
  unsubscribe$ = new Subject();
  id_user: string = "";
  id_seller: string = "";
  prefixList: IPrefix[] = [];
  documenTypeList: Array<IDocumenType> = [];

  constructor(
    private fb: UntypedFormBuilder,
    private validatorS: ValidatorsService,
    private companyS: CompanyService,
    private dialog: MatDialog,
    private userService: UsersService,
    private seller: SellersService,
    private cookieAuthService: CookieAuthService,
    private alertsService: AlertsService
  ) {}

  ngOnInit(): void {
    this.getAuthValues();
    this.userService
    .getRoles()
    .pipe(takeUntil(this.unsubscribe$))
    .subscribe((result) => {
      this.rolList = result;
    });
    this.companyS
    .getAllCompanies()
    .pipe(takeUntil(this.unsubscribe$))
    .subscribe((result) => {
      this.companyList = result;
    });
    this.userService
    .listDocumenTypes()
    .pipe(takeUntil(this.unsubscribe$))
    .subscribe((result) => {
      this.documenTypeList = result;
    });
  }

  onOptionSelected() {
    const value = this.registerForm.value.company;
    if (value.internal_electronic_invoicing === "true" && value.countries[0].name === "Colombia") {
      this.registerForm.get('prefix')?.setValidators(Validators.required);
      this.registerForm.get('prefix')?.updateValueAndValidity();
      this.prefixList = value.prefixes;
    }else {
      this.registerForm.get('prefix')?.clearValidators();
      this.registerForm.get('prefix')?.updateValueAndValidity();
      this.prefixList = [];
    }
  }

  getAuthValues() {
    const company = this.cookieAuthService.getCompanyObject;
    this.companyId = company!.Id_company!.toString();
  }

  //TODO: Remove this repated code that is in login component as well
  register() {
    if (this.registerForm.invalid === true) {
      this.alertsService.showErrorAlert("Please, fill in all the required fields!");
      return;
    } else {
      let valuesFormSelected = this.registerForm.value;
      this.userService
      .registerUser(
        parseFloat(this.registerForm.value.document),
        this.registerForm.value.name,
        this.registerForm.value.email,
        this.registerForm.value.username,
        this.registerForm.value.password,
        parseInt(this.registerForm.value.rol.Id_rol),
        parseInt(this.registerForm.value.types_docs.id_docs),
        parseInt(this.registerForm.value.company.Id_company),
        parseInt(this.registerForm.value.prefix.id)
      )
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((result: any) => {
        if (result.token !== "email" && result.token !== "document") {
          this.alertsService.showSuccessfullAlert("Now you may try starting session", "User Created")
          .then((data) => {
            if (valuesFormSelected.rol.name === "Invoicing operator") {
              if (data.isConfirmed) {
                this.registerSeller(parseInt(result.user.id),valuesFormSelected);
              }
            }
          });
          this.registerForm.reset();
          this.alertsService.refresh("/table", "/user");
        } else if (result.token === "email") {
          this.alertsService.showWarningAlert("Email already exists please try starting session");
        } else if (result.token === "document") {
          this.alertsService.showWarningAlert("Document already exists please try starting session");
        } else {
          this.alertsService.showErrorAlert("Something happened please check with an administrator");
        }
      });
    }
  }

  registerSeller(id_user: number, registerForm:any) {
    this.seller
    .registerSellersByUser(
      registerForm.document,
      parseInt(registerForm.types_docs.id_docs),
      registerForm.name,
      registerForm.email,
      parseInt(registerForm.company.countries[0].id_country),
      registerForm.company.Id_company
    )
    .pipe(takeUntil(this.unsubscribe$))
    .subscribe((result) => {
      if (result.nombre !== "sellerExist") {
        this.alertsService.showSuccessfullAlert("Seller created successfully")
        .then((data) => {
          if (data.isConfirmed) {
            this.registerUserSeller(id_user, parseInt(result.id_seller), registerForm.company.Id_company);
          }
        });
      } else if (result.nombre === "sellerExist") {
        this.alertsService.showWarningAlert("This Seller already exist");
      } else {
        this.alertsService.showErrorAlert("Something was wrong");
      }
    });
  }

  registerUserSeller(id_user: number, id_seller: number, company: string) {
    this.seller
    .registerUserSeller(id_user, id_seller, company)
    .pipe(takeUntil(this.unsubscribe$))
    .subscribe((result) => {
      if (result.nombre !== "UserSellerExist") {
        this.alertsService.showSuccessfullAlert("Related user and seller successfully");
      } else if (result.nombre === "UserSellerExist") {
        this.alertsService.showWarningAlert("Related user and seller was not created");
      } else {
        this.alertsService.showErrorAlert("Something was wrong")
      }
    });
  }

  closeDialog() {
    this.dialog.closeAll();
  }

  textValid(text: string) {
    return (
      this.registerForm.get(text)?.invalid &&
      this.registerForm.get(text)?.touched
    );
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
import { Injectable } from "@angular/core";
import { CrossVoucherTypes } from "src/app/@enums/siigo/siigo-crossvoucher";
import { TransactionType } from "src/app/@enums/siigo/siigo-invoices";
import { VoucherTypes } from "src/app/@enums/siigo/siigo-voucher";
import { ICreateMovementEntry } from "src/app/@interfaces/Siigo/create-movement-entry.interface";
import { IDateFormat } from "src/app/@interfaces/Siigo/format-date-siigo.interface";
import { ISiigoProvider, ISiigoRow } from "src/app/@interfaces/Siigo/rows.interface";
import { ISiigoNoteValues } from "src/app/@interfaces/Siigo/siigo-notes.interface";
import { ISiigoPurchaseValues } from "src/app/@interfaces/Siigo/siigo-purchases.interface";
import {
  ISiigoRefund,
  ISiigoRefundValues,
} from "src/app/@interfaces/Siigo/siigo-refunds.interface";
import { ISiigoSales } from "src/app/@interfaces/Siigo/siigo-sales.interface";
import { IAccountingAccounts } from "src/app/@interfaces/accountingAccounts.interface";
import { IArticles } from "src/app/@interfaces/article.interface";
import { IMovementInventory } from "src/app/@interfaces/movement-inventory.interface";
import {
  IInvoicePayments,
  IPurchasePayments,
} from "src/app/@interfaces/payments.interface";
import { IPurchaseInvoice } from "src/app/@interfaces/purchaseInvoice.interface";
import { IPurchaseQuery } from "src/app/@interfaces/purchases.interface";
import { ITransfers } from "src/app/@interfaces/transfers.interface";
import { ITreasury } from "src/app/@interfaces/treasury.interface";
import {
  IWarehouseOutputAndEntry,
  IWarehouseTransfer,
} from "src/app/@interfaces/warehouse.interface";
import { CalculateSiigoService } from "./calculate-siigo.service";
import { CookieAuthService } from "../../storage-variables/cookie-auth.service";

@Injectable({
  providedIn: "root",
})
export class SetRowsService {
  constructor(
    private calculateSiigoService: CalculateSiigoService,
    private cookieAuthService: CookieAuthService
  ) {}

  /**
   * @param {string} voucherType - Type of voucher for the sequence.
   * @param {number} voucherCode - Voucher code for the sequence.
   * @param {string} documentNumber - Document number or ID for the sequence.
   * @param {string} accountingAccount - Accounting account for the sequence.
   * @param {string} debitCredit - The sequence is Credit (C) or Debit (D).
   * @param {number} total - Sequence value.
   * @param {string} documentYear - Year of the sequence.
   * @param {string} documentMonth - Month of the sequence.
   * @param {string} documentDay - Day of the sequence.
   * @param {number} sellerId - Seller ID for the sequence.
   * @param {string} nit - NIT involved in the sequence.
   * @param {string} description - Sequence description.
   * @param {number} paymentMethod - 1 or 0.
   * @param {string} dateToDay - Today's date, format (DD-MM-YYYY).
   * @param {number} taxPercent - TAX percentage for the sequence.
   * @param {number} taxValue - TAX value for the sequence.
   * @param {number} withholdingBase - Withholding basis for the sequence.
   * @param {string} taxed - Sequence is taxed, Yes (S), No (N).
   * @param {string} productLine - Product line ID of the product in the sequence.
   * @param {string} productGroup - Product group ID in the sequence.
   * @param {string} productCode - Product code or ID in the sequence.
   * @param {string} quantity - Number of items for the sequence.
   * @param {string} warehouseCode - Warehouse code for the sequence.
   * @param {string} providerDocument - Provider Document or Provider ID for the sequence.
   * @param {string} voucherTypeCross - Type of crossover voucher for the sequence.
   * @param {string} documentNumberCross - Crossing voucher number or ID for the sequence.
   * @param {string} expirationNumber - Crossing expiration number for the sequence.
   * @param {string} expirationYear - Crossing voucher expiration year for the sequence.
   * @param {string} expirationMonth - Crossing document expiration month for the sequence.
   * @param {string} expirationDay - Crossing document expiration day for the sequence.
   * @param {string} exportDescription - Aditional description for the sequence.
   * @returns {ISiigoRow} - Based on the information received, this method returns an object that can be used for the generation of the SIIGO PYME accounting movements file.
   */

  setRow(
    voucherType: string,
    voucherCode: number,
    documentNumber: string,
    accountingAccount: string,
    debitCredit: string,
    total: number,
    documentYear: string,
    documentMonth: string,
    documentDay: string,
    sellerId: number,
    nit: string,
    description: string,
    paymentMethod: number,
    dateToDay: string,
    taxPercent: number,
    taxValue: number,
    withholdingBase: number,
    taxed: string,
    productLine: string,
    productGroup: string,
    productCode: string,
    quantity: string,
    warehouseCode: string,
    providerObject: ISiigoProvider,
    voucherTypeCross?: string,
    documentNumberCross?: string,
    expirationNumber?: string,
    expirationYear?: string,
    expirationMonth?: string,
    expirationDay?: string,
    exportDescription?: string
  ): ISiigoRow {
    const object = {
      voucherType: voucherType,
      voucherCode: voucherCode,
      documentNumber: documentNumber,
      accountingAccount: accountingAccount,
      debitCredit: debitCredit,
      total: total,
      documentYear: documentYear,
      documentMonth: documentMonth,
      documentDay: documentDay,
      sellerId: sellerId,
      nit: nit,
      description: description,
      paymentMethod: paymentMethod,
      dateToDay: dateToDay,
      taxPercent: taxPercent,
      taxValue: taxValue,
      withholdingBase: withholdingBase,
      taxed: taxed,
      productLine: productLine,
      productGroup: productGroup,
      productCode: productCode,
      quantity: quantity,
      warehouseCode: warehouseCode,
      providerObject: providerObject,
      voucherTypeCross: voucherTypeCross,
      documentNumberCross: documentNumberCross,
      expirationNumber: expirationNumber,
      expirationYear: expirationYear,
      expirationMonth: expirationMonth,
      expirationDay: expirationDay,
      exportDescription: exportDescription,
    };
    return object;
  }

  //Accounting Movements

  createInvoiceRow(
    voucherType: string,
    voucherNumber: number,
    documentNumber: string,
    accountingAccount: string,
    total: number,
    parsedDate: IDateFormat,
    dueParsedDate: IDateFormat,
    idSeller: number,
    documentNit: string,
    dateToday: string,
    description: string,
    transactionType: string
  ): ISiigoRow {
    return this.setRow(
      voucherType,
      voucherNumber,
      documentNumber,
      accountingAccount,
      transactionType,
      total,
      parsedDate.year,
      parsedDate.month,
      parsedDate.day,
      idSeller!,
      documentNit,
      description,
      0,
      dateToday,
      0,
      0,
      0,
      "",
      "",
      "",
      "",
      "",
      "",
      this.getEmptyProviderObject(),
      CrossVoucherTypes.invoices + voucherNumber,
      documentNumber,
      "1",
      dueParsedDate.year,
      dueParsedDate.month,
      dueParsedDate.day
    );
  }

  createSaleRow(
    salesObject: ISiigoSales | ISiigoNoteValues,
    article: IArticles,
    quantity: number,
    warehouse: number
  ) {
    const siigoID = article.id_siigo;
    const siigoIdSplit = this.calculateSiigoService.splitSiigoID(siigoID!);
    return this.setRow(
      salesObject.sales ? VoucherTypes.invoices : VoucherTypes.notes,
      salesObject.voucherNumber,
      salesObject.numberDocument,
      "",
      "",
      0,
      salesObject.invoiceYear,
      salesObject.invoiceMonth,
      salesObject.invoiceDay,
      salesObject.sellerId,
      salesObject.documentNit,
      article.descripcion,
      0,
      salesObject.dateToDay,
      0,
      0,
      0,
      "S",
      siigoID ? siigoIdSplit?.line! : "700",
      siigoID ? siigoIdSplit?.group! : article.grupo!.toString(),
      siigoID ? siigoIdSplit?.articleId! : article.id_articulo!.toString(),
      quantity!.toString(),
      warehouse.toString(),
      this.getEmptyProviderObject(),
      CrossVoucherTypes.invoices + salesObject.voucherNumber,
      salesObject.numberDocument,
      "1",
      salesObject.invoiceYear,
      salesObject.invoiceMonth,
      salesObject.invoiceDay
    );
  }

  createWarehouseOutputRow(
    outputsAndEntries: IWarehouseOutputAndEntry,
    transactionType: string,
    parsedDate: IDateFormat,
    cost: number,
    dateToday: string,
    accountingAccount: string
  ) {
    const siigoID = outputsAndEntries.article.id_siigo;
    const siigoIdSplit = this.calculateSiigoService.splitSiigoID(siigoID!);
    const companyObject = this.cookieAuthService.getCompanyObject!;
    const companyNit = companyObject.NIT.split("-")[0]
    return this.setRow(
      VoucherTypes.warehouseOutput,
      1,
      outputsAndEntries.id_movement.toString(),
      accountingAccount,
      transactionType,
      cost,
      parsedDate.year,
      parsedDate.month,
      parsedDate.day,
      1,
      companyNit,
      outputsAndEntries.article.descripcion,
      0,
      dateToday,
      0,
      0,
      0,
      "",
      siigoID ? siigoIdSplit?.line! : "700",
      siigoID ? siigoIdSplit?.group! : outputsAndEntries.article.grupo!.toString(),
      siigoID ? siigoIdSplit?.articleId! : outputsAndEntries.article.id_articulo.toString(),
      outputsAndEntries.amount.toString(),
      outputsAndEntries.warehouse.id_almacen.toString(),
      this.getEmptyProviderObject(),
      "",
      "",
      "",
      "",
      "",
      "",
      outputsAndEntries.description
    );
  }

  createReclassificationRow(
    reclassification: any,
    transactionType: string,
    parsedDate: IDateFormat,
    cost: number,
    articleProperty: string,
    warehouseProperty: string,
    dateToday: string,
    accountingAccount: string
  ) {
    const siigoID = reclassification[articleProperty].id_siigo;
    const siigoIdSplit = this.calculateSiigoService.splitSiigoID(siigoID!);
    const companyObject = this.cookieAuthService.getCompanyObject!;
    const companyNit = companyObject.NIT.split("-")[0]
    return this.setRow(
      VoucherTypes.inventoryReclassification,
      1,
      reclassification.id_reclassification.toString(),
      accountingAccount,
      transactionType,
      cost,
      parsedDate.year,
      parsedDate.month,
      parsedDate.day,
      1,
      companyNit,
      reclassification[articleProperty].descripcion,
      0,
      dateToday,
      0,
      0,
      0,
      "",
      siigoID ? siigoIdSplit?.line! : "700",
      siigoID ? siigoIdSplit?.group! : reclassification[articleProperty].grupo!.toString(),
      siigoID ? siigoIdSplit?.articleId! : reclassification[articleProperty].id_articulo.toString(),
      reclassification.value.toString(),
      reclassification[warehouseProperty].id_almacen.toString(),
      this.getEmptyProviderObject(),
      "",
      "",
      "",
      "",
      "",
      ""
    );
  }

  createWarehouseTransfersRow(
    element: IWarehouseTransfer,
    firstCostAccountingAccounts: IAccountingAccounts,
    transactionType: string,
    cost: number,
    formatCreatedDate: IDateFormat,
    dateToday: string
  ) {
    const siigoID = element.article.id_siigo;
    const siigoIdSplit = this.calculateSiigoService.splitSiigoID(siigoID!);
    const companyObject = this.cookieAuthService.getCompanyObject!;
    const companyNit = companyObject.NIT.split("-")[0]
    return this.setRow(
      VoucherTypes.warehouseTransfer,
      1,
      element.id_transfer.toString(),
      firstCostAccountingAccounts.total,
      transactionType,
      cost,
      formatCreatedDate.year,
      formatCreatedDate.month,
      formatCreatedDate.day,
      1,
      companyNit,
      element.article.descripcion,
      0,
      dateToday,
      0,
      0,
      0,
      "",
      siigoID ? siigoIdSplit?.line! : "700",
      siigoID ? siigoIdSplit?.group! : element.article.grupo!.toString(),
      siigoID ? siigoIdSplit?.articleId! : element.article.id_articulo.toString(),
      element.value.toString(),
      element.original.id_almacen.toString(),
      this.getEmptyProviderObject()
    );
  }

  createCollectionsRow(
    element: IInvoicePayments,
    formatCreatedDate: IDateFormat,
    dateToday: string
  ) {
    return this.setRow(
      VoucherTypes.receivables,
      1,
      element.id_correlative.toString(),
      "",
      "",
      element.value,
      formatCreatedDate.year,
      formatCreatedDate.month,
      formatCreatedDate.day,
      parseInt(element.factura.seller![0].id_seller.toString()) || 0,
      element.factura.cliente[0].doc_nit!.split("-")[0],
      "",
      0,
      dateToday,
      0,
      0,
      0,
      "",
      "",
      "",
      "",
      "",
      "",
      this.getEmptyProviderObject()
    );
  }

  createAccountsPayableRow(
    unstructuredData: any,
    element: IPurchasePayments,
    dateToday: string
  ) {
    return this.setRow(
      unstructuredData.voucherType.toString(),
      element.paymentType[0].id_payment,
      element.id_correlative.toString(),
      "",
      "",
      element.value,
      unstructuredData.formatCreatedDate.year,
      unstructuredData.formatCreatedDate.month,
      unstructuredData.formatCreatedDate.day,
      0,
      element.purchaseInvoice.provider[0].doc_nit!.split("-")[0],
      "",
      0,
      dateToday,
      0,
      0,
      0,
      "",
      "",
      "",
      "",
      "",
      "",
      this.getEmptyProviderObject()
    );
  }

  createTansferRow(
    unstructuredData: any,
    element: ITransfers,
    dateToday: string
  ) {
    return this.setRow(
      unstructuredData.voucherType.toString(),
      element.original.id_payment,
      element.id_correlative.toString(),
      "",
      "",
      unstructuredData.transferValue,
      unstructuredData.formatCreatedDate.year,
      unstructuredData.formatCreatedDate.month,
      unstructuredData.formatCreatedDate.day,
      1,
      "0",
      unstructuredData.transferDescription,
      0,
      dateToday,
      0,
      0,
      0,
      "N",
      "",
      "",
      "",
      "",
      "",
      this.getEmptyProviderObject()
    );
  }

  createAdvancePaymentsRow(
    unstructuredData: any,
    element: ITreasury,
    dateToday: string
  ) {
    return this.setRow(
      unstructuredData.voucher,
      element.paymentType.id_payment,
      element.id_correlative.toString(),
      "",
      "",
      element.value,
      unstructuredData.formatCreatedDate.year,
      unstructuredData.formatCreatedDate.month,
      unstructuredData.formatCreatedDate.day,
      1,
      unstructuredData.document!,
      unstructuredData.transferDescription,
      0,
      dateToday,
      0,
      0,
      0,
      "N",
      "",
      "",
      "",
      "",
      "",
      this.getEmptyProviderObject()
    );
  }

  createPurchaseRow(
    unstructuredData: any,
    purchase: IPurchaseInvoice,
    dateToday: string,
  ) {
    return this.setRow(
      VoucherTypes.purchases,
      1,
      unstructuredData.numberDocument!,
      "",
      "",
      0,
      unstructuredData.finalDate.year,
      unstructuredData.finalDate.month,
      unstructuredData.finalDate.day,
      0,
      unstructuredData.documentNit!,
      purchase.provider[0].nombre,
      0,
      dateToday,
      0,
      0,
      0,
      unstructuredData.isTaxable,
      "",
      "",
      "",
      "",
      "",
      unstructuredData.providerObject
    );
  }

  createPurchaseValuesRow(
    purchaseValues: ISiigoPurchaseValues,
    purchase: IPurchaseQuery,
    unstructuredData: any,
    providerObject: ISiigoProvider
  ) {
    const siigoID = unstructuredData.article.id_siigo;
    const siigoIdSplit = this.calculateSiigoService.splitSiigoID(siigoID!);
    return this.setRow(
      VoucherTypes.purchases,
      1,
      purchaseValues.numberDocument,
      unstructuredData.accountingAccount,
      TransactionType.Debit,
      unstructuredData.subtotal,
      purchaseValues.purchaseYear,
      purchaseValues.purchaseMonth,
      purchaseValues.purchaseDay,
      0,
      purchaseValues.documentNit,
      unstructuredData.article.descripcion,
      0,
      purchaseValues.dateToday,
      unstructuredData.pricesAndTax.percentIvaInt,
      unstructuredData.totalTax,
      0,
      purchaseValues.isTaxable,
      siigoID ? siigoIdSplit?.line! : "700",
      siigoID ? siigoIdSplit?.group! : unstructuredData.article.grupo!.toString(),
      siigoID ? siigoIdSplit?.articleId! : unstructuredData.article.id_articulo!.toString(),
      purchase.quantity!.toString(),
      purchase.warehouse.id_almacen.toString(),
      providerObject
    );
  }

  createRefundRow(unstructuredData: any, refundObject: ISiigoRefund) {
    return this.setRow(
      VoucherTypes.refunds,
      1,
      unstructuredData.numberDocument!,
      refundObject.refundAccountingAccounts.total, //P
      "D", //P
      unstructuredData.total, //P
      unstructuredData.parsedDate.year,
      unstructuredData.parsedDate.month,
      unstructuredData.parsedDate.day,
      0,
      refundObject.documentNit!,
      refundObject.name,
      0,
      refundObject.dateToday,
      0,
      0,
      0,
      refundObject.isTaxable,
      "",
      "",
      "",
      "",
      "",
      refundObject.providerObject
    );
  }

  createRefundValueRow(
    refundValues: ISiigoRefundValues,
    accountingAccount: string,
    unstructuredData: any,
    warehouseID: number,
  ) {
    const siigoID = unstructuredData.article.id_siigo;
    const siigoIdSplit = this.calculateSiigoService.splitSiigoID(siigoID!);
    return this.setRow(
      VoucherTypes.refunds,
      1,
      refundValues.numberDocument,
      accountingAccount,
      TransactionType.Credit,
      unstructuredData.subtotal,
      refundValues.purchaseYear,
      refundValues.purchaseMonth,
      refundValues.purchaseDay,
      0,
      refundValues.documentNit,
      unstructuredData.article.descripcion,
      0,
      refundValues.dateToday,
      unstructuredData.priceAndTax.percentIvaInt,
      unstructuredData.totalTax,
      0,
      refundValues.isTaxable,
      siigoID ? siigoIdSplit?.line! : "700",
      siigoID ? siigoIdSplit?.group! : unstructuredData.article.grupo!.toString(),
      siigoID ? siigoIdSplit?.articleId! : unstructuredData.article.id_articulo!.toString(),
      unstructuredData.quantity!.toString(),
      warehouseID.toString(),
      refundValues.providerObject
    );
  }

  //Inventory Movements

  createMovementEntry(data: ICreateMovementEntry): IMovementInventory {
    const numberDocument = data.element[data.idProperty];
    return {
      id_invoice: numberDocument,
      linie: 700,
      group: data.element[data.articleProperty].grupo!,
      product: data.element[data.articleProperty].descripcion,
      lin: 700,
      gro: data.element[data.articleProperty].grupo!,
      element_name: data.element[data.articleProperty].descripcion,
      reference: data.element[data.articleProperty].codigo!,
      unit: data.element[data.articleProperty].unidad!,
      description: data.element[data.articleProperty].descripcion,
      document: `${data.documentType}001-0000${numberDocument}-001`,
      date: data.date ? data.date : data.element.date,
      warehouse: data.warehouseId,
      unitary: 0,
      quant_previus: 0,
      value_previus: 0,
      entries: data.entries,
      entries_values: data.entries_values ? data.entries_values : 0,
      exits: data.exits,
      exits_values: data.exits_values ? data.entries_values! : 0,
      quantity: data.entries - data.exits,
      acc_quantity: 0,
      value: 0,
      acc_value: 0,
      cross_article: data.crossArtile,
    };
  }

  createMovementEntryFromArticleArray(
    data: ICreateMovementEntry
  ): IMovementInventory {
    const numberDocument = data.numberDocument
      ? data.numberDocument
      : data.element[data.idProperty];
    return {
      id_invoice: data.mainId!,
      linie: 700,
      group: data.element[data.articleProperty][0].grupo!,
      product: data.element[data.articleProperty][0].descripcion,
      lin: 700,
      gro: data.element[data.articleProperty][0].grupo!,
      element_name: data.element[data.articleProperty][0].descripcion,
      reference: data.element[data.articleProperty][0].codigo!,
      unit: data.element[data.articleProperty][0].unidad!,
      description: data.element[data.articleProperty][0].descripcion,
      document: `${data.documentType}001-0000${numberDocument}-001`,
      date: data.date!,
      warehouse: data.warehouseId,
      unitary: 0,
      quant_previus: 0,
      value_previus: 0,
      entries: data.entries,
      entries_values: data.entries_values ? data.entries_values : 0,
      exits: data.exits,
      exits_values: data.exits_values ? data.entries_values! : 0,
      quantity: data.entries - data.exits,
      acc_quantity: 0,
      value: 0,
      acc_value: 0,
      cross_article: data.crossArtile,
    };
  }

  createReclassificationEntry(
    element: any,
    warehouseId: number,
    entries: number,
    exits: number,
    documentType: string,
    articleProperty: string,
    idProperty: string,
    crossArtile?: string
  ): IMovementInventory {
    const numberDocument = element[idProperty];
    return {
      id_invoice: numberDocument,
      linie: 700,
      group: element[articleProperty].grupo!,
      product: element[articleProperty].descripcion,
      lin: 700,
      gro: element[articleProperty].grupo!,
      element_name: element[articleProperty].descripcion,
      reference: element[articleProperty].codigo!,
      unit: element[articleProperty].unidad!,
      description: element[articleProperty].descripcion,
      document: `${documentType}001-0000${numberDocument}-001`,
      date: element.date,
      warehouse: warehouseId,
      unitary: 0,
      quant_previus: 0,
      value_previus: 0,
      entries: entries,
      entries_values: 0,
      exits: exits,
      exits_values: 0,
      quantity: entries - exits,
      acc_quantity: 0,
      value: 0,
      acc_value: 0,
      cross_article: crossArtile,
    };
  }

  getEmptyProviderObject(): ISiigoProvider {
    return {
      providerDocument: '',
      providerPrefix: '',
      providerDocumentYear: '',
      providerDocumentMonth: '',
      providerDocumentDay: '',
    }
  }
}

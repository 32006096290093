import { IWarehouse, IWarehouseOutputAndEntry } from './../../../@interfaces/warehouse.interface';
import { Injectable, OnDestroy } from "@angular/core";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { INotasGrap } from "src/app/@interfaces/notas.interface";
import { IPurchaseInvoice } from "src/app/@interfaces/purchaseInvoice.interface";
import { IAccountingAccounts } from "src/app/@interfaces/accountingAccounts.interface";
import { IInvoicePayments, IPurchasePayments } from "src/app/@interfaces/payments.interface";
import { ITransfers } from "src/app/@interfaces/transfers.interface";
import { ITreasury } from "src/app/@interfaces/treasury.interface";
import { IRefundsSingle } from "src/app/@interfaces/refunds.interface";
import { IWarehouseTransfer } from "src/app/@interfaces/warehouse.interface";
import { IArticles } from "src/app/@interfaces/article.interface";
import { QueriesSiigoService } from "./queries-siigo.service";
import { IQuota } from "src/app/@interfaces/quotation.interface";
import { IInventoryReclassification } from 'src/app/@interfaces/inventoryReclassification.interface';
import { WarehouseService } from 'src/app/@pages/warehouse/warehouse.service';
import { AlertsService } from './alerts.service';

@Injectable({
  providedIn: "root",
})
export class CalculateSiigoService implements OnDestroy {
  csvToken = "0d6f94e88c4d66b4e080f7adb7c8183b27e1546c";
  date = new Date();
  unsubscribe$ = new Subject();

  constructor(
    private queriesSiigoService: QueriesSiigoService,
    private warehouseService: WarehouseService,
    private alertsService: AlertsService
  ) {}

  calculateTax(taxIncl: string, discount: number, price: number, percentTax: number) {
    let value = 0;
    if (taxIncl === "true") {
      if (discount !== 0) {
        const priceWithoutTax = price / percentTax;
        const descuento = (discount / 100) * priceWithoutTax;
        const finalPrice = priceWithoutTax - descuento;
        value = finalPrice;
      } else {
        value = price / percentTax;
      }
    } else if (taxIncl === "false") {
      if (discount !== 0) {
        let descuento = (discount / 100) * price;
        value = price - descuento;
      } else {
        value = price;
      }
    }
    return value;
  }

  getAllPurchases(year: number) { 
    return new Promise<IPurchaseInvoice[]>((resolve, reject) => {
      this.queriesSiigoService
      .getAllCsvPurchases(year)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((result: IPurchaseInvoice[]) => {
        resolve(result);
      });
    });
  }

  getAllRefunds(year: number) { 
    return new Promise<IRefundsSingle[]>((resolve, reject) => {
      this.queriesSiigoService
      .getAllCsvRefunds(year)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((result: IRefundsSingle[]) => {
        resolve(result);
      });
    });
  }

  getAllPayments(year: number) { 
    return new Promise<IInvoicePayments[]>((resolve, reject) => {
      this.queriesSiigoService
      .getAllPaymentsWithInvoice(year)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((result: IInvoicePayments[]) => {
        resolve(result);
      });
    });
  }

  gatAllAccountsPayable(year: number) { 
    return new Promise<IPurchasePayments[]>((resolve, reject) => {
      this.queriesSiigoService
      .getAllPaymentsWithPurchase(year)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((result: IPurchasePayments[]) => {
        resolve(result);
      });
    })
  }

  getAllTransfers(year: number) { 
    return new Promise<ITransfers[]>((resolve, reject) => {
      this.queriesSiigoService
      .getAllCsvTransfers(year)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((result: ITransfers[]) => {
        resolve(result);
      });
    })
  }

  getAllAdvancePayments(year: number) { 
    return new Promise<ITreasury[]>((resolve, reject) => {
      this.queriesSiigoService
      .getAllCsvAdvencePayments(year)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((result: ITreasury[]) => {
        resolve(result);
      });
    })
  }

  getAllWarehouseTransfers(year: number) { 
    return new Promise<IWarehouseTransfer[]>((resolve, reject) => {
      this.queriesSiigoService
      .getAllCsvWarehouseTransfers(year)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((result: IWarehouseTransfer[]) => {
        resolve(result);
      });
    })
  }

  getProducts() { 
    return new Promise<IArticles[]>((resolve, reject) => {
      this.queriesSiigoService
      .getAllCsvArticles()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((product: IArticles[]) => {
        resolve(product);
      });
    });
  }

  getAllInvoices(year: number) {
    return new Promise<IQuota[]>((resolve, reject) => {
      this.queriesSiigoService
      .getCsvInvoices(year)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((result: IQuota[]) => {
        resolve(result);
      });
    });
  }

  getAllNotes(year: number) { 
    return new Promise<INotasGrap[]>((resolve, reject) => {
      this.queriesSiigoService
      .getAllCsvNotes(year)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((result: INotasGrap[]) => {
        resolve(result);
      });
    });
  }

  getAccountingAccounts(type: string) { 
    return new Promise<IAccountingAccounts>((resolve, reject) => {
      this.queriesSiigoService
      .getAccountingAccount(type)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((result: IAccountingAccounts) => {
        resolve(result);
      });
    });
  }

  getAllWarehouseOutputsAndEntries(year: number) {
    return new Promise<IWarehouseOutputAndEntry[]>((resolve, reject) => {
      this.queriesSiigoService
      .getAllCsvWarehouseOutputs(year)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((result: IWarehouseOutputAndEntry[]) => {
        resolve(result)
      })
    })
  }

  getAllInventoryReclassifications(year: number) {
    return new Promise<IInventoryReclassification[]>((resolve, reject) => {
      this.queriesSiigoService
      .getAllCsvInventoryReclassifications(year)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((result: IInventoryReclassification[]) => {
        resolve(result)
      })
    })
  }

  getAllWarehouses() {
    return new Promise<IWarehouse[]>((resolve, reject) => {
      this.warehouseService
      .listWarehouses()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((result) => {
        resolve(result);
      });
    })
  }

  async getWarehouseId() {
    const warehouseList = await this.getAllWarehouses();
    const warehouseSelected = await this.alertsService.showSelectAlert(
      "Select a warehouse for opening", warehouseList, "nombre", "Sum openings"
    );
    return warehouseSelected.id_almacen;
  }

  splitSiigoID(id: string) {
    if (!/^\d+$/.test(id)) {
      return null;
    }
    const line = id.slice(0, 3);
    const group = id.slice(3, 7);
    const articleId = id.slice(7);
    return {
      line: line,
      group: group,
      articleId: articleId
    };
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}

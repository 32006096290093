import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { LoginRoutingModule } from './login-routing.module';
import { LoginComponent } from './login.component';
import { ReactiveFormsModule } from '@angular/forms';
import { MaterialModule } from 'src/app/material.module';
import { MatIconModule } from '@angular/material/icon';
import { DialogModule } from 'src/app/@shared/components/dialog/dialog.module';
import { TranslateModule } from '@ngx-translate/core';
import { NgxSpinnerModule } from 'ngx-spinner';
import { ForgotPasswordComponent } from 'src/app/@shared/components/dialog/authentication/forgot-password/forgot-password.component';


@NgModule({
  declarations: [LoginComponent],
  imports: [
    CommonModule,
    LoginRoutingModule,
    ReactiveFormsModule,
    MatIconModule,
    MaterialModule,
    DialogModule,
    TranslateModule,
    NgxSpinnerModule
  ], exports: [
    LoginComponent
  ]
})
export class LoginModule { }


import { Injectable } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { BillyService } from 'src/app/@pages/billyService/billy.service';
import { QuotationsService } from '../../../../@pages/quotations/quotations.service';
import { INotas } from '../../../../@interfaces/notas.interface';
import { IBillyCreditNote, IGraphQToBilly, IItemsCreditNote } from 'src/app/@interfaces/Billy/billy.interface';
import { AlertsService } from '../../services/alerts.service';
import { throwError } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class BillyNotesService {
    billyGraphQL?: IGraphQToBilly;
    billyNote?: IBillyCreditNote;
    constructor(
      private quotation: QuotationsService, 
      private spinnerService: NgxSpinnerService, 
      private billyService: BillyService,
      private alertsService: AlertsService
    ) { }

    sendDian(Variables: INotas) {
      return new Promise<any> ((resolve, reject) => {
        this.quotation.getBillyInvo(Variables.note_fact.toString()).subscribe((items) => {
        this.billyGraphQL = items;
        let discrepancy = '';
        if (Variables.concepto.toString() === 'Emission by error' || Variables.concepto.toString() === 'The invoice will not be paid' || Variables.concepto.toString() === 'Customer return') {
          discrepancy = '2';
        }else if (Variables.concepto.toString() === 'Discount' || Variables.concepto.toString() === 'Correction of values' || Variables.concepto.toString() === 'Correction of quantities' || Variables.concepto.toString() === 'Difference from actual price to price charged'){
          discrepancy = '1';
        }
        this.billyNote = this.setNoteBilly(Variables, discrepancy, this.billyGraphQL!.invoice_number);
        this.billyService.postCreditNote(this.billyNote).subscribe((data) => {
          if (data) {
            this.spinnerService.hide();
            this.alertsService.showSuccessfullAlert("Credit Note send to Billy")
            .then((result) => {
                if (result.isConfirmed) {
                  this.spinnerService.show();
                  resolve({data: data, confirm: result.isConfirmed});
                }
            });
          }
        }, (error) => {
            this.spinnerService.hide();
            this.billyService.handleError(error);
          });
        })
      })
    }

    setNoteBilly(nota: INotas, discrepancy: string, id_fact: string) {
      let noteC!: IBillyCreditNote;
      let items: Array<IItemsCreditNote> = [];
      
      if (discrepancy === "2") {
        items = [];
      }
      if (discrepancy === "1") {
          let ids = '';
          let quants = 0;
          let objItems = {} as IItemsCreditNote;
        for (let i = 0; i < nota.valores[0].codigo.length; i++) {
          ids = nota.valores[0].codigo[i];
          quants = nota.valores[0].cantidad[i];
          if(quants < 0) {
            quants = 0;
          }
          objItems = {
            "type": "item",
            "id": ids,
            "attributes":{
              "quantity": quants,
            }
          }
          items.push(objItems);
        } 
      }
  
      noteC = {
        "data": {
          "type": "credit-note",
          "attributes": {
            "invoiceNumber": id_fact,
            "discrepancyId": discrepancy,
            "items": items
          }
        }
      }
    return noteC;
  }
}

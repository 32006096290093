import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit,
  OnChanges,
  SimpleChanges,
  OnDestroy,
} from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { IContFact } from "src/app/@interfaces/contfac.interface";
import { IQuotation } from "src/app/@interfaces/quotation.interface";
import { QuotationsService } from "src/app/@pages/quotations/quotations.service";
import { DialogComponent } from "../dialog/dialog.component";
import { IVentaQuery } from "src/app/@interfaces/venta.interface";
import { IInvoicePayments } from "src/app/@interfaces/payments.interface";
import { PortfolioService } from "src/app/@pages/portfolio/portfolio.service";
import { NgxSpinnerService } from "ngx-spinner";
import { IValidNotas } from "../../../@interfaces/notas.interface";
import { BillyInvoiceService } from "./billyInvoice.service";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { CreatePDFService } from "../services/createPDF.service";
import { TCreatedPdf } from "pdfmake/build/pdfmake";
import { AlertsService } from "../services/alerts.service";
import { CookieAuthService } from "../../storage-variables/cookie-auth.service";
import { IStorageCompany } from "src/app/@interfaces/company.interface";

@Component({
  selector: "app-card",
  templateUrl: "./card.component.html",
  styleUrls: ["./card.component.css"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CardComponent implements OnInit, OnChanges, OnDestroy {
  modify: boolean = false;
  Sales: Array<IVentaQuery> = [];
  Invoice: Array<IContFact> = [];

  @Input() quotation = {} as IQuotation;
  @Input() cardType = 0;
  @Input() notes: Array<IValidNotas> = [];
  haveCredit: boolean = true;
  haveDebit: boolean = true;
  idBilly: any;
  pdf: any;
  invoicePayments: Array<IInvoicePayments> = [];
  totalPaid: number = 0;
  companyId = '';
  companyName = ''
  companyObject = {} as IStorageCompany;
  user = "";
  @Input() disableButton = false;
  unsubscribe$ = new Subject();
  isProspective = false;
  constructor(
    private billyInvoiceService: BillyInvoiceService,
    public createPDFService: CreatePDFService,
    private spinnerService: NgxSpinnerService,
    private quotationService: QuotationsService,
    public dialog: MatDialog,
    private portfolio: PortfolioService,
    private alertsService: AlertsService,
    private cookieAuthService: CookieAuthService
  ) {}

  ngOnInit(): void {
    this.getAuthValues();
    this.portfolio
    .getPaymenInvoice(this.quotation.id_factura!)
    .pipe(takeUntil(this.unsubscribe$))
    .subscribe((result) => {
      this.invoicePayments = result;
      this.totalPaid = 0;
      this.invoicePayments.forEach((element) => {
        this.totalPaid += element.value;
      });
    });
    this.isProspective = parseInt(this.quotation.cliente[0].id_customerType!.toString()) === 3 ? true : false;
  }

  getAuthValues() {
    this.companyObject = this.cookieAuthService.getCompanyObject!;
    this.companyId = this.companyObject.Id_company!.toString();
    this.companyName = this.companyObject.name;
    this.user = this.cookieAuthService.getUserId!;
  }

  ngOnChanges(changes: SimpleChanges): void {
    for (let item in changes) {
      if (item === "notes") {
        const values: Array<IValidNotas> = changes[item].currentValue;
        if (values.length > 0) {
          this.calcNotes(values);
        }
      }
    }
  }

  calcNotes(notes: IValidNotas[]) {
    notes.forEach((data) => {
      if (data.note_fact.toString() === this.quotation.id_factura?.toString()) {
        if (data.tipo_nota === "Credito") {
          this.haveCredit = false;
        } else if (data.tipo_nota === "Debito") {
          this.haveDebit = false;
        }
      }
    });
  }

  updateStatus(
    id_factura: number,
    rejectQ: Boolean,
    invoiceQ: Boolean,
    deliveryQ: Boolean
  ): void {
    let deliveryDate = "";
    let invoiceDate = "";
    if (deliveryQ) {
      deliveryDate = new Date().toISOString();
      invoiceDate = "";
    } else if (invoiceQ) {
      invoiceDate = new Date().toISOString();
      deliveryDate = "";
    }
    this.quotationService
    .updateStatus(
      id_factura,
      rejectQ,
      deliveryQ,
      deliveryDate,
      invoiceQ,
      invoiceDate,
      false,
      parseInt(this.user)
    )
    .pipe(takeUntil(this.unsubscribe$))
    .subscribe((result) => {
      if (result) {
        this.spinnerService.hide();
        var action = "";
        if (rejectQ) {
          action = "Reject";
        } else if (invoiceQ) {
          action = "Invoice";
          if (this.companyObject.Id_company!.toString() !== "7" && "9") {
            this.billyInvoiceService.updateArticleQuantities(
              this.quotation
            )
          }
        } else if (deliveryQ) {
          action = "Delivery";
        }
        this.alertsService.showSuccessfullAlert(
          "Status has been changed succesfully.", 
          action
        ).then((data) => {
          if (data.isConfirmed) {
            this.alertsService.refresh("/card", "/sales")
          }
        });
      } else {
        this.alertsService.showErrorAlert("error trying to update the quotation")
      }
    });
  }

  validation(    
    id_factura: number,
    rejectQ: Boolean,
    invoiceQ: Boolean,
    deliveryQ: Boolean
  ) {
    if(this.isProspective && !rejectQ) {
      this.alertsService.showErrorAlert("You cannot change the status because the customer is a prospect!");
    }else {
      this.changeStatus(id_factura, rejectQ, invoiceQ, deliveryQ);
    }
  }

  changeStatus(
    id_factura: number,
    rejectQ: Boolean,
    invoiceQ: Boolean,
    deliveryQ: Boolean,
  ) {
    this.alertsService.showConfirmAlert("You won not be able to revert this!")
    .then((result) => {
      if (result.isConfirmed) {
        this.spinnerService.show();
        this.updateStatus(id_factura, rejectQ, invoiceQ, deliveryQ);
      }
    });
  }

  //Send invoices to Dian
  sendDian(quotation: IQuotation) {
    if (this.companyObject.external_electronic_invoicing === "true") {
      this.billyInvoiceService.sendDian(quotation);
    } else {
      this.alertsService.showWarningAlert(
        "This company is not configured to send invoices to DIAN, or is not available in your country."
      )
    }
  }

  openDialog(state: number) {
    if (state === 1 || state === 3) {
      this.dialog.open(DialogComponent, {
        data: { id_factura: this.quotation.id_factura },
      });
    } else {
      this.alertsService.showWarningAlert("You cannot edit a quote in this state.")
    }
  }

  generateReport(openReport: boolean) {
    return new Promise<TCreatedPdf>((resolve, reject) => {
      const imageElement = $("#img").attr("src");
      this.quotationService
      .getQuotation(parseInt(this.quotation.id_factura!.toString()))
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(async (result) => {
        this.quotation = result;
        const paymentsDescriptions: string[] = this.quotation.payment!.map(
          (payment) => payment.description
        );
        const getPdf = await this.createPDFService.createPDF(
          openReport,
          "Cancelled",
          this.quotation,
          imageElement,
          paymentsDescriptions.join(", ")
        );
        resolve(getPdf!);
      });
    });
  }

  configPayments(
    identify_invoice: number, configInvoice: boolean,
    invoicePayments: IInvoicePayments[], id_customer: number) 
  {
    this.dialog.open(DialogComponent, {
      disableClose: true,
      data: { identify_invoice, configInvoice, invoicePayments, id_customer },
    })
    .afterClosed()
    .pipe(takeUntil(this.unsubscribe$))
    .subscribe(async (dialogClosed) => {
      if (dialogClosed) {
        if (dialogClosed.sended === true) {
          const getPdf = await this.generateReport(false);
          getPdf?.getBase64((data) => {
            this.portfolio
            .sendMailPayment(parseInt(this.quotation.id_factura!.toString()), data)
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe((res) => {});
          });
        }
        if (dialogClosed.refresh === true) {
          this.alertsService.refresh("/card", "/portfolio");
        }
      } else {
        return;
      }
    });
  }

  openDialogDelivery(id_factura: number) {
    var dataD: { id_quotation: number; id_customer: number, isProspective: boolean } = {
      id_quotation: id_factura,
      id_customer: this.quotation.cliente[0].id_cliente,
      isProspective: this.isProspective
    };
    this.dialog.open(DialogComponent, {
      data: { deliveryData: dataD },
    });
  }

  openDialogCredit(id_factura: number) {
    this.dialog.open(DialogComponent, {
      data: { credit: id_factura, boolC: true },
    });
  }

  openDialogDebit(id_factura: number) {
    this.dialog.open(DialogComponent, {
      data: { debit: id_factura, boolD: true },
    });
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}

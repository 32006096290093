import { Injectable } from "@angular/core";
import { Apollo } from "apollo-angular";
import { ApiService } from "src/app/@graphql/services/api.service";
import { map } from "rxjs/internal/operators/map";
import {
  GETALL_CSV_ADVENCEPAYMENTS,
  GETALL_CSV_ARTICLES,
  GETALL_CSV_INVENTORY_RECLASSIFICATIONS,
  GETALL_CSV_NOTES,
  GETALL_CSV_PURCHASES,
  GETALL_CSV_REFUNDS,
  GETALL_CSV_TRANSFERS,
  GETALL_CSV_WAREHOUSETRANSFERS,
  GETALL_CSV_WAREHOUSE_OUTPUTS_ENTRIES,
  GETALL_PAYMENTS_WITH_PURCHASE,
  GET_ACCOUNTING_ACCOUNT,
  GET_ALLPAYMENTS_WITHINVOICE,
  GET_CSV_INVOICES,
} from "src/app/@graphql/operations/csvQueries";
import { CookieAuthService } from "../../storage-variables/cookie-auth.service";

@Injectable({
  providedIn: "root",
})
export class QueriesSiigoService extends ApiService {
  constructor(apollo: Apollo, private cookieAuthService: CookieAuthService) {
    super(apollo);
  }

  getAllCsvPurchases(year: number) {
    const companyObject = this.cookieAuthService.getCompanyObject;
    const company = companyObject?.Id_company;
    return this.query(GETALL_CSV_PURCHASES, { year, company }).pipe(
      map((result: any) => {
        return result.getAllCsvPurchases;
      })
    );
  }

  getAllCsvRefunds(year: number) {
    const companyObject = this.cookieAuthService.getCompanyObject;
    const company = companyObject?.Id_company;
    return this.query(GETALL_CSV_REFUNDS, { year, company }).pipe(
      map((result: any) => {
        return result.getAllCsvRefunds;
      })
    );
  }

  getAllPaymentsWithInvoice(year: number) {
    const companyObject = this.cookieAuthService.getCompanyObject;
    const company = companyObject?.Id_company;
    return this.query(GET_ALLPAYMENTS_WITHINVOICE, { year, company }).pipe(
      map((result: any) => {
        return result.getAllPaymentsWithInvoice;
      })
    );
  }

  getAllPaymentsWithPurchase(year: number) {
    const companyObject = this.cookieAuthService.getCompanyObject;
    const company = companyObject?.Id_company;
    return this.mutation(GETALL_PAYMENTS_WITH_PURCHASE, {
      year,
      company,
    }).pipe(
      map((result: any) => {
        return result.getAllPaymentsWithPurchase;
      })
    );
  }

  getAllCsvTransfers(year: number) {
    const companyObject = this.cookieAuthService.getCompanyObject;
    const company = companyObject?.Id_company;
    return this.query(GETALL_CSV_TRANSFERS, { year, company }).pipe(
      map((result: any) => {
        return result.getAllCsvTransfers;
      })
    );
  }

  getAllCsvAdvencePayments(year: number) {
    const companyObject = this.cookieAuthService.getCompanyObject;
    const company = companyObject?.Id_company;
    return this.query(GETALL_CSV_ADVENCEPAYMENTS, { year, company }).pipe(
      map((result: any) => {
        return result.getAllCsvAdvencePayments;
      })
    );
  }

  getAllCsvWarehouseTransfers(year: number) {
    const companyObject = this.cookieAuthService.getCompanyObject;
    const company = companyObject?.Id_company;
    return this.query(GETALL_CSV_WAREHOUSETRANSFERS, { year, company }).pipe(
      map((result: any) => {
        return result.getAllCsvWarehouseTransfers;
      })
    );
  }

  getAllCsvArticles() {
    const companyObject = this.cookieAuthService.getCompanyObject;
    const company = companyObject?.Id_company;
    return this.query(GETALL_CSV_ARTICLES, { company }).pipe(
      map((result: any) => {
        return result.getAllCsvArticles;
      })
    );
  }

  getCsvInvoices(year: number) {
    const companyObject = this.cookieAuthService.getCompanyObject;
    const company = companyObject?.Id_company;
    return this.query(GET_CSV_INVOICES, { year, company }).pipe(
      map((result: any) => {
        return result.getCsvInvoices;
      })
    );
  }

  getAllCsvNotes(year: number) {
    const companyObject = this.cookieAuthService.getCompanyObject;
    const company = companyObject?.Id_company;
    return this.query(GETALL_CSV_NOTES, { year, company }).pipe(
      map((result: any) => {
        return result.getAllCsvNotes;
      })
    );
  }

  getAccountingAccount(document_type: string) {
    const companyObject = this.cookieAuthService.getCompanyObject;
    const company = companyObject?.Id_company;
    return this.query(GET_ACCOUNTING_ACCOUNT, { document_type, company }).pipe(
      map((result: any) => {
        return result.getAccountingAccount;
      })
    );
  }

  getAllCsvWarehouseOutputs(year: number) {
    const companyObject = this.cookieAuthService.getCompanyObject;
    const company = companyObject?.Id_company;
    return this.query(GETALL_CSV_WAREHOUSE_OUTPUTS_ENTRIES, { year, company }).pipe(
      map((result: any) => {
        return result.getAllCsvWarehouseOutputsAndEntries;
      })
    );
  }

  getAllCsvInventoryReclassifications(year: number) {
    const companyObject = this.cookieAuthService.getCompanyObject;
    const company = companyObject?.Id_company;
    return this.query(GETALL_CSV_INVENTORY_RECLASSIFICATIONS, { year, company }).pipe(
      map((result: any) => {
        return result.getAllCsvInventoryReclassifications;
      })
    );
  }
}

import { Injectable } from "@angular/core";
import { Label, MultiDataSet, SingleDataSet } from "ng2-charts";
import { IDollarPerClientData, ITopCustomerNotes } from "src/app/@interfaces/Analysis/dollar-per-client.interface";
import { IDrillData } from "src/app/@interfaces/Analysis/drill.interface";
import { ILiterPerClientData } from "src/app/@interfaces/Analysis/liter-per-client.interface";
import { ILiterPerGroupData } from "src/app/@interfaces/Analysis/liter-per-group.interface";
import { ILiterPerItemData } from "src/app/@interfaces/Analysis/liter-per-item.interface";
import { ICompany } from "src/app/@interfaces/company.interface";
import { INotasGrap } from "src/app/@interfaces/notas.interface";
import { IQuota } from "src/app/@interfaces/quotation.interface";
import { CookieAuthService } from "../../storage-variables/cookie-auth.service";

@Injectable({
  providedIn: "root",
})
export class CalculateAnalysis {
  constructor(private cookieAuthService: CookieAuthService) {}

  reduceArrays(array: any[]) {
    return array.filter((item, index) => {
      return array.indexOf(item) === index;
    });
  }

  filterInvoices(invoices: IQuota[]) {
    const companyObject = this.cookieAuthService.getCompanyObject;
    let filterInvoices: IQuota[] = [];
    if (companyObject!.external_electronic_invoicing === "true" || companyObject!.internal_electronic_invoicing === "true") {
      filterInvoices = invoices.filter((data) => data.estado === 4 && data.billyInvoice![0].invoice_number !== "PENDING");
    } else {
      filterInvoices = invoices.filter((data) => data.estado === 4);
    }
    return filterInvoices;
  }

  extractDataFromInvoices(invoices: IQuota[]) {
    let dollarPerClientData: IDollarPerClientData = {
      initialData: [],
      invoicesArray: [],
      customersArray: []
    };
    let drillData: IDrillData = {
      articlesArray: [],
      groupsArray: [],
      invoicesArray: [],
      initialData: []
    };
    let literPerClientData: ILiterPerClientData = {
      invoicesArray: [],
      customersArray: [],
      initialData: []
    };
    let literPerGroupData: ILiterPerGroupData = {
      articlesArray: [],
      invoicesArray: [],
      initialData: []
    };
    let literPerItemData: ILiterPerItemData = {
      articlesArray: [],
      invoicesArray: [],
      initialData: []
    };
    let arrayInvoices: number[] = [];
    const filterInvoices = this.filterInvoices(invoices);
    filterInvoices.forEach((invoice) => {
      arrayInvoices.push(invoice.id_factura!);
      literPerClientData.customersArray.push(invoice.cliente[0].nombre);
      dollarPerClientData.customersArray.push(invoice.cliente[0].nombre);
      invoice.venta.forEach((sale) => {
        dollarPerClientData.initialData.push({
          invoice: invoice.id_factura!,
          article: sale.codigo,
          customer: invoice.cliente[0].nombre,
          value:
            sale.cantidad * sale.precio -
            (sale.descuento / 100) * (sale.cantidad * sale.precio),
        });

        drillData.articlesArray.push(sale.articulo[0].descripcion);
        drillData.groupsArray.push(sale.articulo[0].grupoArt![0].nombre);
        drillData.initialData.push({
          item: sale.articulo[0].descripcion,
          code: sale.articulo[0].codigo!,
          quantity: sale.cantidad,
          liter: parseFloat(sale.articulo[0].presentacion!),
          group: sale.articulo[0].grupoArt![0].nombre!,
        });

        literPerClientData.initialData.push({
          customer: invoice.cliente[0].nombre,
          article: sale.articulo[0].descripcion,
          code: sale.articulo[0].codigo!,
          item: invoice.id_factura!,
          calculation: sale.cantidad * parseFloat(sale.articulo[0].presentacion!),
        });

        literPerGroupData.articlesArray.push(sale.articulo[0].grupoArt![0].nombre!.toString());
        literPerGroupData.initialData.push({
          item: sale.articulo[0].grupoArt![0].nombre,
          quantity: sale.cantidad,
          liter: parseFloat(sale.articulo[0].presentacion!),
        });

        literPerItemData.articlesArray.push(sale.articulo[0].descripcion);
        literPerItemData.initialData.push({
          item: sale.articulo[0].descripcion,
          code: sale.articulo[0].codigo!,
          quantity: sale.cantidad,
          liter: parseFloat(sale.articulo[0].presentacion!),
        });
      });
    });

    return {
      dollarPerClientData: dollarPerClientData,
      drillData: drillData,
      literPerClientData: literPerClientData,
      literPerGroupData: literPerGroupData,
      literPerItemData: literPerItemData,
      arrayInvoices: arrayInvoices
    }
  }

  getNoteValues(notes: INotasGrap[], compareNumbers: number[]) {
    let arraySingleIds: any[] = [];
    let arrayMultipleIds: any[] = [];
    const reduceNumbers = this.reduceArrays(compareNumbers);
    notes.forEach((item) => {
      reduceNumbers.forEach((number) => {
        if (item.note_fact === parseInt(number.toString())) {
          arrayMultipleIds.push({
            note_id: parseInt(item.id_nota!.toString())!,
            invoice_id: parseInt(item.note_fact.toString()),
          });
          arraySingleIds.push(parseInt(item.id_nota!.toString()));
        }
      });
    });
    return { arraySingleIds: arraySingleIds, arrayMultipleIds: arrayMultipleIds }
  }

  constructFinalArray(filteredArray: any[], sortProperty: string, validProperty: string, sliceNumber: number, multiple?: boolean) {
    let multiData: MultiDataSet = [];
    let chartData: SingleDataSet = [];
    const arraySorted = filteredArray
    .filter(item => item[validProperty] !== "")
    .sort((a, b) => b[sortProperty] - a[sortProperty])
    .slice(0, sliceNumber);
    const chartLabels: Label[] = arraySorted.map(item => item[validProperty]);
    arraySorted.forEach((item) => {
      chartData.push(parseFloat(item[sortProperty].toFixed(2)));
      multiple ? multiData.push(chartData) : multiData = [];
    });
    return { 
      chartLabels: chartLabels,
      chartData: chartData,
      multiData: multiData
    }
  }

  constructAvgArray(array: any[], months: number, calculatedProperty: string) {
    return array
    .filter(item => item.item !== "")
    .map(item => {
      const calculateTotal = item[calculatedProperty];
      const calculateAverage = calculateTotal / months;
      const roundedAvg = parseFloat(calculateAverage.toFixed(2));
      const roundedTotal = parseFloat(calculateTotal.toFixed(2));
      return {
        item: item.item,
        avg: roundedAvg,
        total: roundedTotal,
      };
    });
  }

  calculateByElementCustomer(customers: string[], reduceInitialArray: any[], reduceNotesArray: ITopCustomerNotes[], invoiceProperty: string, articleProperty: string, valueProperty: string, subtotalProperty: string) {
    const result = customers.map(customer => {
      const filterByName = this.filterArrayByCustomer(reduceInitialArray, customer);
      const valuesByCustomer = this.calculateByCustomer(filterByName, reduceNotesArray, invoiceProperty, articleProperty, valueProperty, subtotalProperty);
      return {
        amount: valuesByCustomer.amount,
        customer: valuesByCustomer.name,
      };
    });
    const totals = result.reduce((acc, curr) => acc + curr.amount, 0);
    return { arrayFiltered: result, totals: totals };
  }

  calculateByCustomer(arrayFiltered: any[], notesArray: ITopCustomerNotes[], invoiceProperty: string, articleProperty: string, valueProperty: string, subtotalProperty: string) {
    const result = arrayFiltered.reduce((accumulator, client) => {
      const filteredNotes = notesArray.filter(data =>
        data.fact.toString().trim() === client[invoiceProperty].toString().trim() &&
        data.articulo.trim() === client[articleProperty].trim()
      );
      const noteValueTotal = filteredNotes.reduce((subtotal, noteValue) =>
        subtotal + noteValue[subtotalProperty] * 1, 0);
      accumulator.amount += noteValueTotal + client[valueProperty];
      accumulator.name = client.customer;
      return accumulator;
    }, { amount: 0, name: "" });
    return result;
  }

  filterArrayByCustomer(arrayTopInit: any[], element: string) {
    return arrayTopInit.filter((item: any) => item.customer === element);
  }
}

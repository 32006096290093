import { Component, OnInit, OnDestroy } from "@angular/core";
import {
  UntypedFormControl,
  UntypedFormGroup,
  UntypedFormBuilder,
  Validators,
  UntypedFormArray,
} from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import { IArticles } from "src/app/@interfaces/article.interface";
import { ICustomer } from "src/app/@interfaces/customer.interface";
import { IQuotation } from "src/app/@interfaces/quotation.interface";
import { IWarehouse } from "src/app/@interfaces/warehouse.interface";
import { CustomerService } from "src/app/@pages/customer/customer.service";
import { InventoryService } from "src/app/@pages/inventory/inventory.service";
import { QuotationsService } from "src/app/@pages/quotations/quotations.service";
import { WarehouseService } from "src/app/@pages/warehouse/warehouse.service";
import { ISeller } from "src/app/@interfaces/seller.interface";
import { NgxSpinnerService } from "ngx-spinner";
import { takeUntil } from "rxjs/operators";
import { Subject } from "rxjs";
import { RegisterSalesService } from "./register-sale.service";
import { UsersService } from "src/app/@pages/users/users.service";
import { IUserSeller } from "src/app/@interfaces/userSeller.interface";
import { AlertsService } from "../../../services/alerts.service";
import { ErrorsService } from '../../../services/errors.service';
import { environment } from "src/environments/environment";
import { CookieAuthService } from "src/app/@shared/storage-variables/cookie-auth.service";

@Component({
  selector: "app-register",
  templateUrl: "./register.component.html",
  styleUrls: ["./register.component.css"],
})
export class RegisterComponent implements OnInit, OnDestroy {
  sellerList: Array<IUserSeller> = [];
  sellerCustomer = {} as ISeller;
  dateToday = new Date();
  totalRow: number = 0;
  totalQuot: number = 0;
  taxVer: number = 0;
  newCustomer: UntypedFormControl = this.fb.control("", Validators.required);
  newArticle: UntypedFormControl = this.fb.control("", Validators.required);
  registerForm: UntypedFormGroup = this.fb.group({
    itemRows: this.fb.array([
      this.fb.group({
        article: this.newArticle,
        quantity: [, [Validators.required, Validators.min(0.001)]],
        price: [, [Validators.required, Validators.min(0.001)]],
        discount: [0, Validators.required],
        subtotal: [0, Validators.required],
        warehouse: ["", Validators.required],
      }),
    ]),
    tax_incl: false,
    tax: [0, [Validators.maxLength(2), Validators.min(0)]],
    date: [this.dateToday, Validators.required],
    dueDate: [this.dateToday, Validators.required],
    seller: ["", [Validators.required]],
    positive_balance: ["0", [Validators.required]],
    observations: [null],
  });
  get rowsArr() {
    return this.registerForm.get("itemRows") as UntypedFormArray;
  }
  CustomersList: Array<ICustomer> = [];
  ArticleList: Array<IArticles> = [];
  WarehouseList: Array<IWarehouse> = [];
  AutCompControl = new UntypedFormControl();
  Quotations = [] as IQuotation[];
  Articles = [] as IArticles[];
  newArticleEmpy = {} as IArticles;
  Customers = [] as ICustomer[];
  companyId = '';
  user = '';
  rol = '';
  userSeller = '';
  currentWarehouse: number = 0;
  showDevelopWarning: boolean = false;
  unsubscribe$ = new Subject();

  constructor(
    private spinnerService: NgxSpinnerService,
    private customers: CustomerService,
    private article: InventoryService,
    public dialog: MatDialog,
    public quotation: QuotationsService,
    public warehouse: WarehouseService,
    private fb: UntypedFormBuilder,
    private registerSalesService: RegisterSalesService,
    private userS: UsersService,
    private alertsService: AlertsService,
    private errorsService: ErrorsService,
    private cookieAuthService: CookieAuthService
  ) {} 

  async ngOnInit() {
    this.getAuthValues();
    this.warehouse
      .listWarehouses()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((result) => {
        this.WarehouseList = result;
      });
    this.userS
      .getAllSellerUser()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((result: any) => {
        this.sellerList = result;
      });
    this.getInputValues();
    this.setValuesByEnviroment();
  }

  getAuthValues() {
    const company = this.cookieAuthService.getCompanyObject;
    this.companyId = company!.Id_company!.toString();
    this.user = this.cookieAuthService.getUserId!;
    this.rol = this.cookieAuthService.getRolId!;
    this.userSeller = this.cookieAuthService.getUserSellerId!;
  }

  setValuesByEnviroment() {
    if(!environment.production) {
      const formArray = new UntypedFormArray([]);
      formArray.push(
        this.fb.group({
          article: this.newArticle,
          quantity: [1, [Validators.required, Validators.min(0.001)]],
          price: [1, [Validators.required, Validators.min(0.001)]],
          discount: [0, Validators.required],
          subtotal: [1, Validators.required],
          warehouse: ["", Validators.required],
        })
      )
      this.registerForm.setControl("itemRows", formArray);
      this.showDevelopWarning = true;
    }
  }

  getInputValues() {
    if (this.rol === "1" || this.rol === "3") {
      this.newCustomer.valueChanges
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe((value: any) => {
          if (!value.__typename) {
            this.customers
              .searchCustomer(value, null, null)
              .pipe(takeUntil(this.unsubscribe$))
              .subscribe((result: any) => {
                this.CustomersList = result.customers;
              });
          }
        });
    } else {
      this.newCustomer.valueChanges
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe((value: any) => {
          if (!value.__typename) {
            this.customers
              .searchCustomerByUserSeller(value, parseInt(this.userSeller!), null, null)
              .pipe(takeUntil(this.unsubscribe$))
              .subscribe((result: any) => {
                this.CustomersList = result;
              });
          }
        });
    }
    this.newArticle.valueChanges
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((value: any) => {
        if (typeof value === "string") {
          this.article
            .searchArticle(value, parseInt(this.currentWarehouse.toString()), null, null)
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe((result: any) => {
              this.ArticleList = result.articles;
            });
        }
      });
  }

  updateCurrentWareouse(id: number) {
    this.currentWarehouse = id;
  }

  addNewRow() {
    if (this.registerForm.invalid === true) {
      return;
    }
    const newRow = this.fb.group({
      article: this.newArticle,
      quantity: [, Validators.required],
      price: [, Validators.required],
      discount: [0, Validators.required],
      subtotal: [0, Validators.required],
      warehouse: ["", Validators.required],
    });
    newRow.get("article")?.setValue(this.newArticleEmpy);
    this.rowsArr.push(newRow);
    this.calculation();
  }

  deleteRow(rowIndex: number) {
    if (this.rowsArr.length > 1) {
      this.rowsArr.removeAt(rowIndex);
    } else if (this.rowsArr.length === 1) {
      this.alertsService.showWarningAlert("You should not remove the last row");
    }
    this.calculation();
  }

  validRegister() {
    if (this.registerForm.invalid === true) {
      this.alertsService.showErrorAlert(
        "Please, fill in all the required fields!"
      );
      this.spinnerService.hide();
      return false;
    } else if (this.rowsArr.length > 11) {
      this.alertsService.showErrorAlert(
        "The quotation cannot have more than 11 items, please delete"
      );
      this.spinnerService.hide();
      return false;
    } else if(this.errorsService.checkDuplicateArticles(this.registerForm)){
      this.alertsService.showErrorAlert(
        "You cannot add the same item more than once on the same invoice"
      );
      this.spinnerService.hide();
      return false;
    } else {
      return true;
    }
  }

  validCustomer(variables: IQuotation) {
    if (!variables!.cliente[0]?.id_cliente) {
      this.alertsService.showErrorAlert("This customer does not exist");
      this.spinnerService.hide();
      return false;
    } else {
      return true;
    }
  }

  validArticle(variables: IQuotation) {
    if (!variables!.venta[0]?.codigo[0]) {
      this.alertsService.showErrorAlert("This article does not exist");
      this.spinnerService.hide();
      return false;
    } else {
      return true;
    }
  }

  register() {
    this.spinnerService.show();
    let validResgister = this.validRegister();
    if (validResgister) {
      this.calculation();
      const variables = this.registerSalesService.setQuotationData(
        this.registerForm,
        this.rowsArr,
        this.newCustomer
      );
      let validCustomer = this.validCustomer(variables!);
      let validArticle = this.validArticle(variables!);
      if (validCustomer && validArticle) {
        this.quotation
          .createQuotation(
            variables!.tax,
            variables!.cliente[0].id_cliente,
            variables!.venta[0].codigo,
            variables!.venta[0].cantidad,
            variables!.venta[0].precio,
            variables!.venta[0].descuento,
            variables!.venta[0].subtotal,
            variables!.venta[0].total,
            variables!.venta[0].id_almacen,
            variables!.createdAt,
            variables!.dueDate,
            variables!.seller![0].id_seller,
            variables!.tax_incl!,
            parseInt(this.user!),
            this.registerForm.get("observations")?.value
          )
          .pipe(takeUntil(this.unsubscribe$))
          .subscribe((result) => {
            if (result) {
              this.spinnerService.hide();
              this.alertsService
                .showSuccessfullAlert("Quotation created successfully!")
                .then((result) => {
                  if (result.isConfirmed) {
                    this.closeDialog();
                    this.alertsService.refresh("/card", "/sales");
                  }
                });
            } else {
              this.spinnerService.hide();
              this.alertsService.showErrorAlert(
                "Quotation has not been created"
              );
            }
          });
      }
    }
  }

  calculation() {
    let priceCal;
    let quantityCal;
    let discountCal;
    let taxCal;
    let subsubtotal;

    for (let i = 0; i < this.rowsArr.length; i++) {
      if (this.registerForm.get("tax_incl")?.value === false) {
        if (this.rowsArr.value[i].article.tax[0].value === 0) {
          taxCal = this.registerForm.value.tax;
        } else {
          taxCal = this.rowsArr.value[i].article.tax[0].value;
        }
      } else {
        taxCal = 0;
      }
      priceCal = this.rowsArr.value[i].price;
      quantityCal = this.rowsArr.value[i].quantity;
      discountCal = this.rowsArr.value[i].discount;
      if (taxCal) {
        if (discountCal != 0) {
          subsubtotal = quantityCal * priceCal;
          discountCal = (discountCal / 100) * subsubtotal;
          subsubtotal = subsubtotal - discountCal;
          if (this.rowsArr.value[i].article.tax[0].value === 0) {
            taxCal = (taxCal / 100) * subsubtotal;
          } else {
            taxCal = taxCal * subsubtotal;
          }
          this.rowsArr.value[i].subtotal = subsubtotal + taxCal;
        } else {
          subsubtotal = quantityCal * priceCal;
          if (this.rowsArr.value[i].article.tax[0].value === 0) {
            taxCal = (taxCal / 100) * subsubtotal;
          } else {
            taxCal = taxCal * subsubtotal;
          }
          this.rowsArr.value[i].subtotal = priceCal * quantityCal + taxCal;
        }
      } else if (discountCal != 0) {
        discountCal = (discountCal / 100) * (priceCal * quantityCal);
        this.rowsArr.value[i].subtotal = priceCal * quantityCal - discountCal;
      } else {
        this.rowsArr.value[i].subtotal = priceCal * quantityCal;
      }
    }
    this.totalQuot = 0;
    for (let i = 0; i < this.rowsArr.length; i++) {
      this.totalQuot = this.totalQuot + this.rowsArr.value[i].subtotal;
    }
  }

  displayFnArticle(article: any) {
    return article && article
      ? article.descripcion +
          new String(` - (`) +
          article.tax[0].value +
          new String(`)`)
      : undefined;
  }

  displayFnCustomer(customer: any) {
    return customer && customer ? customer.nombre : undefined;
  }

  closeDialog() {
    this.dialog.closeAll();
  }

  textValid(text: string, index?: number) {
    return (
      this.registerForm.get(text)?.invalid &&
      this.registerForm.get(text)?.touched
    );
  }

  arrayvalid(text: string) {
    return this.rowsArr.get(text)?.invalid && this.rowsArr.get(text)?.touched;
  }

  getSeller(customer: any) {
    const seller = customer.userSeller.seller;
    this.registerForm.get("seller")?.setValue(seller);
  }

  taxValid(tax: boolean) {
    return tax === true ? true : false;
  }

  compareSeller(item1: any, item2: any) {
    if (item1 === null || item2 === null) {
      return false;
    }
    return item1.id_seller === item2.id_seller;
  }

  getPositiveBalance(customer: any) {
    const positive_balance = customer.positive_balance;
    this.registerForm.get("positive_balance")?.setValue(positive_balance);
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
